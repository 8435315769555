/*
API:s
	- addCssVariables
	- addCssVariablesToBody
	- addToHead
	- cleanUrlChange
	- createUrl
	- decodeBase64
	- DOC
	- emitCustomEvent
	- encodeBase64
	- getComputedStyle
	- getElementById
	- getUrlParts
	- goToUrl
	- injectInlineCssRules
	- injectLineupProps
	- inViewport
	- listenTo
	- nodeDim
	- onUrlChange
	- onWindow
	- removeCssVariables
	- removeCssVariablesFromBody
	- requestAnimFrame
	- UFormData
	- updateUrl
	- WIN
*/

export const WIN = (typeof window !== 'undefined') ? window : null
export const DOC = (typeof document !== 'undefined') ? document : null

const pathToUrl = path => {
	if (!path)
		return null

	if (path[0] == '/')
		return `http://hello${path}`
	if (path[0] == 'h' && path[1] == 't' && path[2] == 't' && path[3] == 'p')
		return path 

	return `http://hello/${path}`
}

const stateFn = (fn, fnName, history) => function (...args) {
	const urlParts = getUrlParts(pathToUrl(args[2])) || {}
	const hash = urlParts.hash
	if (DOC && hash) {
		const options = arguments[3]||{}
		if (!options.noScroll) {
			const scrollOptions = options.smoothScroll ? { behavior:'smooth' } : {}
			// The timeout is used to execute the funtion after the 'fn.apply' is executed
			setTimeout(() => {
				const el = DOC.querySelector(hash)
				if (el)
					el.scrollIntoView(scrollOptions)
			}, 50)
		}
	}
	WIN.dispatchEvent(new CustomEvent('locationchange', { bubbles: true, detail: { type:fnName, url:urlParts } }))
	return fn.apply(history, arguments)
}

if (WIN && WIN.history && WIN.history.pushState)
	(function(history) {
		const pushState = history.pushState
		const replaceState = history.replaceState

		history.pushState = stateFn(pushState, 'pushstate', history)
		history.replaceState = stateFn(replaceState, 'replacestate', history)

	})(WIN.history)

/**
 * Gets the computed style of a DOM element. 
 * 
 * @param  {DOM} 	domEl		Required DOM element. 	
 * @param  {String}	cssProp		Optional CSS prop.
 * 
 * @return {Object}				Either a CSS property value, or the entire DOM with computed properties. 
 */
export const getComputedStyle = (domEl, cssProp) => {
	if (WIN && WIN.getComputedStyle && domEl) {
		if (cssProp)
			return WIN.getComputedStyle(domEl)[cssProp]
		else
			return WIN.getComputedStyle(domEl)
	}
	else
		return ''
}

export const onWindow = (eventName, callback) => {
	if (!eventName || !callback || !WIN)
		return

	const fn = () => callback(WIN)
	WIN.addEventListener(eventName, fn)
	return () => WIN.removeEventListener(eventName, fn)
}

export const getElementById = (...args) => DOC ? DOC.getElementById(...args) : null

export const requestAnimFrame = fn => WIN ? WIN.requestAnimationFrame(fn) : fn()

export const nodeDim = (node, options) => {
	const ttl = (options || {}).ttl || 2000
	let lastCalled = 0
	let cachedValue
	return noCache => {
		const now = Date.now()
		if (noCache || (now - lastCalled) > ttl)
			cachedValue = node.getBoundingClientRect()
		lastCalled = now
		
		return {
			bottom: cachedValue.bottom,
			height: cachedValue.height,
			left: cachedValue.left,
			right: cachedValue.right,
			top: cachedValue.top,
			width: cachedValue.width,
			x: cachedValue.x,
			y: cachedValue.y
		}
	}
}

const getExt = path => (path ? (path.match(/\.([^.]*?)$/)||[])[0] : null) || null 

/**
 * Breaks down a URL's parts. 
 * 
 * @param  {String} url/	e.g., 'https://fonts.gstatic.com/s/materialicons/v70/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2'
 * 
 * @return {[type]} parts.href				'https://fonts.gstatic.com/s/materialicons/v70/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2'
 * @return {[type]} parts.origin			'https://fonts.gstatic.com'
 * @return {[type]} parts.protocol			'https:'
 * @return {[type]} parts.username			''
 * @return {[type]} parts.password			''
 * @return {[type]} parts.host				'fonts.gstatic.com'
 * @return {[type]} parts.hostname			'fonts.gstatic.com'
 * @return {[type]} parts.port				''
 * @return {[type]} parts.pathname			'/s/materialicons/v70/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2'
 * @return {[type]} parts.search			''
 * @return {[type]} parts.searchParams		URLSearchParams {}
 * @return {[type]} parts.hash				''
 * @return {[type]} parts.ext				'.woff2'
 */
export const getUrlParts = url => {
	const parts = WIN ? new WIN.URL(url) : new URL(url)
	parts.ext = null 
	if (parts.pathname)
		parts.ext = getExt(parts.pathname)
	return parts
}

/**
 * Browses to another URL or update the current path (if the browser does not support updating the path, 
 * then a redirect is used.)
 * 
 * @param  {String}  url		
 * @param  {String}  path   
 * @param  {String}  target
 * @param  {Boolean} smoothScroll	When true, the scroll is smooth if the URL was using a hash.
 * @param  {Boolean} noScroll		When true, no scroll occurs if the URL uses a hash.
 * 
 * @return {Void}
 */
export const goToUrl = ({ url, path, pathname, target, smoothScroll, noScroll }) => {
	if (!WIN)
		return

	if (url) {
		if (!target || target != '_blank')
			WIN.location.href = url
		else
			WIN.open(url, '_blank')
	} else if (path||pathname) {
		if (WIN.history && WIN.history.pushState)
			WIN.history.pushState(null, '', path||pathname, { smoothScroll, noScroll })
		else
			WIN.location.href = WIN.location.origin + (/^\//.test(path) ? path : `/${path}`)
	}
}

/**
 * 
 * @param  {String}  pathname			
 * @param  {String}  hash			
 * @param  {String}  search			
 * @param  {Boolean} options.noScroll		When true, no scroll occurs if the URL uses a hash.
 * @param  {Boolean} options.smoothScroll	When true, the scroll is smooth if the URL was using a hash.
 * @param  {Boolean} options.history		Default true. When false, the update is not kept in history (i.e., back button won't work)
 * @param  {Boolean} options.multiSelect	
 * @param  {Boolean} options.unselect		When true, clicking the button when it is selected unselects it.
 * 
 * @return {Void}			
 */
export const updateUrl = ({ pathname, hash, search }, options) => {
	if (!WIN || !WIN.location.href || (!pathname && !hash && !search))
		return

	options = options || {}
	const urlSearchParams = options.multiSelect ? new URLSearchParams(WIN.location.search) : null
	const unselect = options.unselect
	const u = new URL(WIN.location.href)
	u.hostname = 'hello'
	u.protocol = 'http'
	u.port = ''
	if (pathname)
		u.pathname = pathname
	if (hash)
		u.hash = hash
	if (search)
		for (let [k,v] of Object.entries(search)) {
			const currentValue = urlSearchParams ? urlSearchParams.get(k) : null
			let value = v 
			if (currentValue) {
				const values = currentValue.split(',')
				value = values.includes(v) 
					? unselect ? values.filter(x => x != v).join(',') : currentValue 
					: `${currentValue},${v}`
			}
			u.searchParams.set(k,value)
		}

	if (WIN.history && WIN.history.pushState) {
		const updatedPath = u.toString().replace('http://hello', '')
		if (options.history === false)
			WIN.history.replaceState(null, '', updatedPath, options)
		else
			WIN.history.pushState(null, '', updatedPath, options)
	} else
		WIN.location.href = u.toString()
}

export const onUrlChange = handler => {
	if (!WIN || !handler)
		return 

	WIN.addEventListener('locationchange', handler)
	WIN.addEventListener('popstate', handler)
}

export const cleanUrlChange = handler => {
	if (!WIN || !handler)
		return 

	WIN.removeEventListener('locationchange', handler)
	WIN.removeEventListener('popstate', handler)
}

/**
 * Create a URL object from a config file.
 * 
 * @param  {Object} urlConfig	e.g., { pathname:'/world', hash:'friend' }
 * 
 * @return {[type]} url.href				'http://hello/world#friend'
 * @return {[type]} url.origin				'http://hello'
 * @return {[type]} url.protocol			'http:'
 * @return {[type]} url.username			''
 * @return {[type]} url.password			''
 * @return {[type]} url.host				'hello'
 * @return {[type]} url.hostname			'hello'
 * @return {[type]} url.port				''
 * @return {[type]} url.pathname			'/world'
 * @return {[type]} url.search				''
 * @return {[type]} url.searchParams		URLSearchParams {}
 * @return {[type]} url.hash				'#friend'
 */
export const createUrl = urlConfig => {
	urlConfig = urlConfig || {}
	const u = new URL('http://hello')
	for (let [k,v] of Object.entries(urlConfig)) {
		if (k == 'search' && typeof(v) == 'object')
			for (let [k2,v2] of Object.entries(v))
				u.searchParams.set(k2,v2)
		else if (k !== 'host' && k !== 'href' && k !== 'origin' && k !== 'searchParams') 
			u[k] = v

	}
	return u
}

function DummyFormData() { return this }
export const UFormData = WIN === null ? DummyFormData : WIN.FormData

export const decodeBase64 = WIN
	? WIN.atob
	: (data => Buffer.from(data, 'base64').toString())

export const encodeBase64 = WIN
	? WIN.btoa
	: (data => Buffer.from(data).toString('base64'))

const _headElCache = {}
/**
 * Adds a new DOM element in the page's head. Example:
 * 
 * 	<link data-id="my-custom-link" rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Orelega+One&display=swap">
 *
 * If 'id' is specified and if that id already exist, then it is skipped.
 * 
 * @param  {String} doms[].tag				e.g., 'link'
 * @param  {String} doms[].id				e.g., 'my-custom-link'
 * @param  {String} doms[].attributes		e.g., [['rel', 'stylesheet'], ['href', 'https://fonts.googleapis.com/css2?family=Orelega+One&display=swap']]
 * @param  {String} doms[].style			Only valid if tag is 'style' (e.g., 'body{ margin:0; }')
 * @param  {String} doms[].script			Only valid if tag is 'script' (e.g., 'var hello = "world";')
 * 
 * @return {Void}      
 */
export const addToHead = doms => {
	if (!DOC || !DOC.head)
		return 

	for (let i=0,l=doms.length;i<l;i++) {
		const { tag, attributes, id, style, script } = doms[i]
		if (!tag || !attributes || !attributes[0])
			continue

		if (id && (_headElCache[id] || DOC.head.querySelector(`${tag}[data-id="${id}"]`)))
			continue			

		const el = DOC.createElement(tag)
		for (let j=0,ll=attributes.length;j<ll;j++) {
			const [attr, value] = attributes[j]
			if (!attr || value === undefined || value === undefined)
				continue
			el.setAttribute(attr, value)
		}

		if (id) {
			el.setAttribute('data-id', id)
			_headElCache[id] = true
		}

		if (tag == 'style' && style) {
			if (el.styleSheet) 
				el.styleSheet.cssText = style
			else 
				el.appendChild(DOC.createTextNode(style))
		} else if (tag == 'script' && script)
			el.innerHTML = script

		DOC.head.appendChild(el)

	}
}


const getCssRules = () => {
	let inlineCss = ''
	if (!DOC || !DOC.styleSheets)
		return inlineCss

	const l = DOC.styleSheets.length
	if (!l)
		return inlineCss 

	for(let i=0;i<l;i++) {
		const sheet = DOC.styleSheets[i]
		if (!sheet || (sheet && sheet.href && !sheet.href.startsWith(WIN.location.origin)) || !sheet.rules)
			continue 

		const k = sheet.rules.length
		if (!k)
			continue
		for (let j=0;j<k;j++){
			const rule = sheet.rules[j]
			if (!rule || !rule.cssText)
				continue
			inlineCss += rule.cssText 
		}
	}

	return inlineCss
}

/**
 * Copies the CSS rules created by emotion CSS and inject them inline in the page's head. This is used for the page
 * prerender task. 
 * 
 * @return {Void}
 */
export const injectInlineCssRules = () => {
	const style = getCssRules()
	if (style)
		addToHead([{
			tag:'style',
			attributes:[['type','text/css']], 
			style
		}])
}

/**
 * Injects a <script type="text/javascript">var __lineupProps=...;<script> inside the page's head. 
 * This is used for the page prerender task. 
 * 
 * @return {Void}
 */
export const injectLineupProps = (props) => {
	if (!props)
		return

	addToHead([{
		tag:'script',
		attributes:[['type','text/javascript']], 
		script: `var __lineupProps = ${JSON.stringify(props)};`
	}])
}

export const emitCustomEvent = (eventName, data) => {
	if (!DOC || !eventName)
		return

	DOC.dispatchEvent(new CustomEvent(eventName, { bubbles: true, detail: data||{} }))
}

export const listenTo = (eventName, fn) => {
	if (!DOC || !eventName || !fn)
		return () => null

	DOC.addEventListener(eventName, fn)

	return () => DOC.removeEventListener(eventName, fn)
}

export const addCssVariables = el => vars => {
	for (let [k,v] of Object.entries(vars))
		el.style.setProperty(k,v)
}

export const removeCssVariables = el => vars => {
	for (let [k] of Object.entries(vars))
		el.style.removeProperty(k)
}

export const addCssVariablesToBody = DOC ? addCssVariables(DOC.body) : () => null
export const removeCssVariablesFromBody = DOC ? removeCssVariables(DOC.body) : () => null

/**
 * Registers an viewport intersection observer
 * 
 * @param  {DOM}		node
 * @param  {[Object]}	callbacks			e.g., [{ threshold:0, intersectStart: () => ..., intersectEnd: () => ... }]		
 * @param  {Object}		options.rootMargin	e.g., '50px 0 50px 0px'
 * 
 * @return {Function}	disconnect
 */
export const inViewport = (node, callbacks, options) => {
	if (!node || !WIN || !WIN.IntersectionObserver || !callbacks)
		return () => null

	const l = callbacks.length

	if (!l)
		return () => null

	const thresholds = callbacks.map(c => c.threshold)
	const rootMargin = options ? options.rootMargin : undefined

	const getCallback = entry => {
		const ratio = entry.intersectionRatio
		let thresholdIndex = 0, dist=100000
		if (l > 1)
			for (let i=0;i<l;i++) {
				const c = callbacks[i]
				const t = Math.abs(ratio-(c.threshold||0))
				if (t<dist) {
					dist = t
					thresholdIndex = i
				}
			}

		return entry.isIntersecting 
			? callbacks[thresholdIndex].intersectStart 
			: callbacks[thresholdIndex].intersectEnd
	}

	const observer = new WIN.IntersectionObserver(entries => {
		const e = entries[0]
		const callback = getCallback(e) 
		if (callback)
			callback(e)
	}, { root: null, threshold:thresholds, rootMargin })

	observer.observe(node)

	return () => {
		try {
			observer.disconnect()
		} catch(e) {
			console.log(e)
		}
	}
}

/**
 * 
 * @param  {String}		onPressedHandler.emitEvent		
 * @param  {[String]}	onPressedHandler.emitEventSeq		
 * @param  {Number}		context.clickEventSeqIndex		Tracks the emitEventSeq
 * @param  {Boolean}	context.multiSelect		
 * @param  {Boolean}	context.unselect		
 * 
 * @return {Void}
 */
export const processPressHandler = (onPressedHandler, context) => {
	if (onPressedHandler) {
		if (onPressedHandler.emitEvent)
			emitCustomEvent(onPressedHandler.emitEvent,{})
		if (onPressedHandler.emitEventSeq && onPressedHandler.emitEventSeq[0] && context) {
			if (context.clickEventSeqIndex === undefined || context.clickEventSeqIndex === null)
				context.clickEventSeqIndex = 0
			let eventName = onPressedHandler.emitEventSeq[context.clickEventSeqIndex++]
			if (eventName === undefined) {
				context.clickEventSeqIndex = 0
				eventName = onPressedHandler.emitEventSeq[context.clickEventSeqIndex++]
			}

			emitCustomEvent(eventName,{})
		}

		if (onPressedHandler.setUrl) {
			const { multiSelect, unselect } = context || {}
			updateUrl(onPressedHandler.setUrl, { multiSelect, unselect, smoothScroll:onPressedHandler.setUrl.smoothScroll })
		}
	}
}







