import { css, keyframes } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
import { transition, transform, animation as anim } from '../../../core/css'

export const DEFAULT_FONT_SIZE = 16
export const SELECT_ICON_SIZE_TO_FONT_SIZE_RATIO = 24/DEFAULT_FONT_SIZE
export const DEFAULT_TRANSITION_CURVE = 'cubic-bezier(.4,0,.2,1)'
export const DEFAULT_ANIMATION_DURATION = 1000
export const SPIN_KEYFRAME = keyframes(`
to {
	${transform.css('rotate(360deg)')}
}`)

export const rootClass = css(`
display: flex;
align-items: center;
justify-content: center;
padding: var(--lu-icon-padding);
height: var(--lu-icon-size);

& i {
	color: var(--lu-icon-color);
	font-size: var(--lu-icon-size);
	width: var(--lu-icon-size);
	font-weight: var(--lu-icon-font-weight);
	${transform.css('var(--lu-icon-transform-start)')};
	${transition.css('var(--lu-icon-transition)')};
}

& .spin-animation {
	${anim.css(`${SPIN_KEYFRAME} var(--lu-icon-animation) linear infinite`)}
}
`)

