import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
import { transition } from '../../../core/css'

export const rootClass = css(`
width: 100%;
display: grid;
grid-template-columns: var(--lu-row-grid-columns);
grid-template-rows: var(--lu-row-grid-rows);
height: var(--lu-row-height);
flex: var(--lu-row-flex);
background-color: var(--lu-row-background-color);
opacity: var(--lu-row-opacity);
${transition.css('var(--lu-row-transition)')};

&.row-grad-on {
	background-image: var(--lu-row-background-image);
}
`)