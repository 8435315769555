const _resetStyle = '{margin: 0px;padding: 0px;}'
const resetTagStyle = `
	h1${_resetStyle}
	h2${_resetStyle}
	h3${_resetStyle}
	h4${_resetStyle}
	h5${_resetStyle}
	h6${_resetStyle}
	p${_resetStyle}
	span${_resetStyle}
	div${_resetStyle}
	img${_resetStyle}
	i${_resetStyle}
	section${_resetStyle}
	main${_resetStyle}
	footer${_resetStyle}
	nav${_resetStyle}
	header${_resetStyle}
	box${_resetStyle}
	ul${_resetStyle}
	li${_resetStyle}
	select${_resetStyle}
	option${_resetStyle}
	input${_resetStyle}
	textarea${_resetStyle}
	label${_resetStyle}
	fieldset${_resetStyle}
	legend${_resetStyle}
`
const grey = {
	'50': '#fafafa',
	'100': '#f5f5f5',
	'200': '#eeeeee',
	'300': '#e0e0e0',
	'400': '#bdbdbd',
	'500': '#9e9e9e',
	'600': '#757575',
	'700': '#616161',
	'800': '#424242',
	'900': '#212121'
}

const theme = {
	// Color palette: https://material-ui.com/customization/color/#color-palette
	// Color builder: https://material-ui.com/customization/color/#playground
	palette: {
		primary: {
			light: '#834bff',
			main: '#651fff',
			dark: '#4615b2',
			contrastText: '#fff'
		},
		secondary: {
			light: '#dd33fa',
			main: '#d500f9',
			dark: '#9500ae',
			contrastText: '#fff'
		},
		danger: {
			light: '#e57373',
			main: '#f44336',
			dark: '#d32f2f',
			contrastText: '#fff'
		},
		outline: {
			light: '#949494',
			main: '#949494',
			dark: '#000',
			contrastText: '#949494'
		},
		border: {
			light: grey[300],
			main: grey[500],
			dark: grey[900]
		},
		disabled: {
			main: grey[400],
			contrastText: grey[200]	
		}
	},
	color: {
		grey
	},
	text: {
		font: {
			family: '"Roboto", "Helvetica", "Arial", sans-serif',
			size: 16,
			letterSpacing: '0.2px',
			color: grey[900],
			lineHeight: 1.25
		}
	},
	resetTagStyle
}

export default theme
// Colors
export const VAR_PRIMARY_COLOR = `var(--lu-color-primary, ${theme.palette.primary.main})`
export const VAR_SECONDARY_COLOR = `var(--lu-color-secondary, ${theme.palette.secondary.main})`
export const VAR_ON_PRIMARY_COLOR = `var(--lu-font-color-on-primary, ${theme.palette.primary.contrastText})`
export const VAR_ON_SECONDARY_COLOR = `var(--lu-font-color-on-secondary, ${theme.palette.secondary.contrastText})`
export const VAR_DANGER_COLOR = `var(--lu-color-danger, ${theme.palette.danger.main})`
export const VAR_DISABLED_COLOR = `var(--lu-color-disabled, ${theme.palette.disabled.main})`
export const VAR_IDLE_COLOR = `var(--lu-color-idle, ${theme.color.grey[600]})`
export const VAR_FONT_COLOR = `var(--lu-font-color, ${theme.text.font.color})`
export const VAR_ON_DISABLED_COLOR = `var(--lu-font-color-disabled, ${theme.palette.disabled.contrastText})`
export const VAR_BORDER_COLOR = `var(--lu-border-color, ${theme.palette.border.main})`
// Fonts
export const VAR_FONT_FAMILY = `var(--lu-font-family, ${theme.text.font.family})`
export const VAR_FONT_SIZE = `var(--lu-font-size, ${theme.text.font.size}px)`
export const VAR_FONT_LETTER_SPACING = `var(--lu-font-letter-spacing, ${theme.text.font.letterSpacing}px)`
export const VAR_FONT_LINE_HEIGHT = `var(--lu-font-line-height, ${theme.text.font.lineHeight})`
export const VAR_FONT_STYLE = 'var(--lu-font-style, unset)'
// Border
export const VAR_BASE_BORDER = 'var(--lu-base-border, 1px)'
export const VAR_BASE_BORDER_COLOR = `var(--lu-base-border-color, ${grey[500]})`
export const VAR_BASE_BORDER_RADIUS = 'var(--lu-base-border-radius, 4px)'






