<script>
	/* 
	=======================================================
	START - DEPENDENCIES
	=======================================================
	*/

	import { createEventDispatcher } from 'svelte'
	import { writable } from 'svelte/store'
	import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
	import { COS_18, COS_36, COS_54, COS_72, SIN_18, SIN_36, SIN_54, SIN_72 } from '../../../core/constants'
	import { getId, createBaseEvents, formatValue, exists } from '../../../core/index'
	import theme, { VAR_BASE_BORDER_RADIUS, VAR_BASE_BORDER, VAR_BASE_BORDER_COLOR, VAR_PRIMARY_COLOR, VAR_DANGER_COLOR, VAR_FONT_SIZE, VAR_IDLE_COLOR } from '../../../core/theme'
	import { paddingParse, unitParse, cornerParse } from '../../../core/parse'
	import { setVariables, transition, parseTextAlign, sizeCssVariables } from '../../../core/css'
	import { chooseColor } from '../../../core/color'
	import { WIN } from '../../../core/dom'
	import { rootClass, MAIN_TRANSITION_CURVE } from './style'
	import { typeable } from './typeable'
	import { addSelectList } from './addSelectList'
	import Icon from '../../icon/src/Icon.svelte'
	

	/* 
	=======================================================
	END - DEPENDENCIES
	=======================================================
	*/









	/* 
	=======================================================
	START - CONSTANTS
	=======================================================
	*/

	const DEFAULT_HEIGHT = 56
	const DEFAULT_FONT_SIZE = 16
	const SELECT_ICON_VERTICALLY_OFFSET_TO_HEIGHT_RATIO = 2/DEFAULT_HEIGHT
	const SMALL_FONT_TO_NORMAL_FONT_RATIO = 0.75
	const INPUT_BOTTOM_TO_HEIGHT_RATIO = 10/DEFAULT_HEIGHT
	const LABEL_PAD_TOP_TO_HEIGHT_RATIO = 8/DEFAULT_HEIGHT
	const LABEL_UP_OUTLINED_GUTTER_TO_HEIGHT_RATIO = 4/DEFAULT_FONT_SIZE*SMALL_FONT_TO_NORMAL_FONT_RATIO
	const INPUT_FILLED_HOR_PAD_TO_HEIGHT_RATIO = 12/DEFAULT_HEIGHT
	const INPUT_OUTLINED_HOR_PAD_TO_HEIGHT_RATIO = 14/DEFAULT_HEIGHT
	const TEXT_HELPER_PAD_TOP_TO_HEIGHT_RATIO = 6/DEFAULT_HEIGHT
	const LIST_ITEM_HEIGHT_TO_HEIGHT_RATIO = 48/DEFAULT_HEIGHT
	const LIST_ITEM_HORIZONTAL_PADDING_TO_HEIGHT_RATIO = 16/DEFAULT_HEIGHT
	const DEFAULT_BACKGROUND_COLOR = theme.color.grey['100']
	const DEFAULT_BACKGROUND_ACTIVE_COLOR = theme.color.grey['300']
	const DEFAULT_HOVER_BORDER_COLOR = theme.palette.border.dark
	const TEXTFIELD_OUTLINED_CLASS = 'textfield-outlined'
	const TEXTFIELD_UNDERLINED_CLASS = 'textfield-underlined'
	const ALLOWED_MODES = ['text', 'multiline', 'number', 'date', 'datetime', 'password', 'email', 'file', 'select']
	const ID = getId()
	const INPUT_ID = `input${ID}`
	const DEFAULT_BORDER_RADIUS_OBJ = cornerParse(VAR_BASE_BORDER_RADIUS)

	/* 
	=======================================================
	END - CONSTANTS
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT PROPERTIES
	=======================================================
	*/

	// Meta
	export let id = ID
	export let name = ''
	// Content
	export let label = null
	export let placeholder = null
	export let value = ''
	export let options = []
	export let mode = 'text' // 'text', 'multiline', 'number', 'date', 'datetime' (this includes hours), 'password', 'email', 'file', 'select'
	export let helperText = '' // This can also be an array, in which case each item represents a new line
	// Style
	export let color = null
	export let backgroundColor = null // Default grey['100']
	export let border = null // Border's thickness when idle
	export let borderColor = null
	export let borderActive = 2 // Border's thickness when focus
	export let borderRadius = null // Number or object (e.g., { left: 4, right:0 } or { top:4, bottom:2 } or { top: { left:1, right:2 }, bottom: { left:3, right:4 } } or { left: { top:1, bottom:2 }, right: { top:3, bottom:4 } })
	export let errorColor = null
	export let fontSize = null
	export let fontFamily = null
	export let fontColor = null 
	export let fontLetterSpacing = null
	export let fontJustify = null
	export let placeholderFontColor = null
	export let variant = 'underlined' // 'underlined' (default), 'outlined', 'filled'
	export let underline = true // Only valid when variant is 'underlined'. When false, the underline is off.
	export let outline = false // Only valid when variant is 'filled'. When true, the underline is switched for outline.
	export let labelUp = false
	export let labelAbove = false
	export let labelMargin = null // Only valid when labelAbove is true
	export let labelFontSize = null // Only valid when labelAbove is true
	export let labelJustify = null // Only valid when labelAbove is true
	export let labelFontWeight = null // Only valid when labelAbove is true
	export let labelFontStyle = null // Only valid when labelAbove is true
	export let labelFontColor = null // Only valid when labelAbove is true
	export let width = '100%' // Number (unit px) or string. If string, then this is CSS (e.g., '100%', 'calc(100% - 12px)')
	export let height = DEFAULT_HEIGHT
	export let padding = null // Can be an object such as { top: 5, right: 6, bottom: 7, left: 8 }
	export let margin = null // Can be an object such as { top: 5, right: 6, bottom: 7, left: 8 }
	export let helperTextListStyle = 'none' // CSS value for the 'list-style-type' property. Valid values: 'disc' (aka bullet), 'circle', 'none', 'decimal', 'square', 'lower-latin' (e.g., 'a', 'b'), 'lower-roman' (e.g., 'i', 'ii')
	export let helperTextFontColor = null
	export let showCharCounter = false // If maxChar > 0, then shows a char counter in the bottom right
	export let selectIcon = 'filled' // Permitted values: 'filled', 'outlined', 'filled-round', 'outlined-round'
	export let listBorderRadius = null // Only valid when 'mode' is 'select'. Default: 'borderRadius'
	export let listBackgroundColor = null // Only valid when 'mode' is 'select'. Default 'white'
	// Behavior
	export let rows = 5 // Only applicable for type == 'multiline'
	export let allowResize = false // Only applicable for type == 'multiline'
	export let maxChar = -1
	export let minChar = -1
	export let max = null
	export let min = null
	export let invalid = false
	export let step = 1
	export let disabled = false
	export let readonly = false
	/**
	 * onHover behavior
	 * 
	 * @type {Boolean}	onHover.onFocus								Default false. When true, the onHover properties are applied to 'onFocus'
	 * @type {String}	onHover.backgroundColor						Default grey['300']
	 * @type {String}	onHover.borderColor							Default '#000'
	 */
	export let onHover = null
	/**
	 * onFocus behavior
	 * 
	 * @type {String}	onFocus.backgroundColor						Default grey['300']
	 * @type {String}	onFocus.borderColor							Default '#000'
	 * @type {String}	onFocus.glowBorderColor						Only valid when variant is 'filled' and outline is on
	 */
	export let onFocus = null

	/* 
	=======================================================
	END - COMPONENT PROPERTIES
	=======================================================
	*/







	/* 
	=======================================================
	START - COMPONENT EVENTS
	=======================================================
	*/

	const dispatch = createEventDispatcher()
	createBaseEvents(dispatch, () => formatValue(name, `textfield_${mode}`, mode == 'file' ? (inputEl||{}).files||[] : value))

	const emitEvent = eventName => (data,fn) => { 
		if (fn)
			fn()
		dispatch(eventName, data||{})
	}
	
	/**
	 * on:invalid
	 * 
	 * @param  {String}		data.id		This component's ID
	 * @param  {String}		data.type	This input's type
	 * @param  {Object}		data.value	This input's value
	 * @param  {DOM}		data.el		This input's DOM
	 * 
	 * @return {Void}
	 */
	const emitInvalid = emitEvent('invalid')
	
	/**
	 * on:valid
	 * 
	 * @param  {String}		data.id		This component's ID
	 * @param  {String}		data.type	This input's type
	 * @param  {Object}		data.value	This input's value
	 * @param  {DOM}		data.el		This input's DOM
	 * 
	 * @return {Void}
	 */
	const emitValid = emitEvent('valid')

	/**
	 * on:change
	 * 
	 * @param  {String}		data.id		This component's ID
	 * @param  {String}		data.type	This input's type
	 * @param  {Object}		data.value	This input's value
	 * 
	 * @return {Void}
	 */
	const emitChange = emitEvent('change')

	/* 
	=======================================================
	END - COMPONENT EVENTS
	=======================================================
	*/









	/* 
	=======================================================
	START - REACTIVE STATE PROPS
	=======================================================
	*/

	let labelUpClass,
		eventClasses='',
		inputEl, // Used to (1) configure the input's type (Svelte 3 cannot set the type attribute dynamically) (2) check the native input's validity (e.g., email input).
		counterLabel='',
		adjustableHeight=height, // Used by the textarea
		selectOptionsOn = false,
		selectedOptionId,
		listSize,
		listOptionStyle

	/* 
	=======================================================
	END - REACTIVE STATE PROPS
	=======================================================
	*/









	/* 
	=======================================================
	START - PRIVATE FUNCTIONS
	=======================================================
	*/

	const adjustListOptionPosition = () => {
		if (mode != 'select' || !WIN || !listSize || !listSize.height || !inputEl)
			return {}
		
		const windowHeight = WIN.innerHeight
		const { y:top, x:left, width:listWidth } = inputEl.getBoundingClientRect()

		const maxListHeight = 0.8*windowHeight
		const doesListFullyFitInWindow = listSize.height < maxListHeight
		const listHeight = doesListFullyFitInWindow ? listSize.height : maxListHeight
		const doesListFullyFitUnderInput = top + listHeight < windowHeight
		const doesListFullyFitAboveInput = top >= listHeight
		const inputAtBottom = (top + height/2) > windowHeight/2
		const _border = border || 1
		const borderThickness = variant == 'outlined' ? 0 : ((borderActive > _border ? borderActive : _border) || 0)

		const listTopOffset = doesListFullyFitUnderInput 
			? top + height + borderThickness
			: doesListFullyFitAboveInput 
				? top - listHeight
				: inputAtBottom ? windowHeight - listHeight : 0
		const listOriginY = doesListFullyFitUnderInput ? 'top' : doesListFullyFitAboveInput ? 'bottom' : 'center'

		// console.log({
		// 	doesListFullyFitUnderInput,
		// 	doesListFullyFitAboveInput,
		// 	listTopOffset,
		// 	windowHeight,
		// 	top,
		// 	listHeight
		// })

		return { 
			listOriginY, 
			listHeight: Math.round(listHeight), 
			listWidth: Math.round(listWidth),
			listPosX: WIN.scrollX + left,
			listPosY: WIN.scrollY + listTopOffset
		}

		// listOptionStyle = `--list-top: ${listTopOffset}px;--list-height: ${listHeight}px;--list-origin-y: ${listOriginY};`
	}

	const focusInput = () => {
		if (inputEl && inputEl.focus)
			inputEl.focus()
	}

	const addEventClass = className => () => {
		if (!eventClasses)
			eventClasses = className
		else if (eventClasses.indexOf(className) < 0)
			eventClasses = `${eventClasses} ${className}`.replace(/\s+/g,' ').trim()
	}

	const removeEventClass = className => () =>{
		if (eventClasses && eventClasses.indexOf(className) >= 0)
			eventClasses = eventClasses.replace(className, '').replace(/\s+/g,' ').trim()
	}

	const createEventClassManager = className => ({
		add: addEventClass(className),
		remove: removeEventClass(className)
	})

	const _textFieldSelectClickClass = createEventClassManager('textfield-options-on')

	/**
	 * Show or hide the select option's list
	 * 
	 * @param  {Boolean} options.forceOpen
	 * @param  {Boolean} options.forceClose
	 * @param  {Boolean} options.showActive
	 * @param  {Boolean} options.event
	 * 
	 * @return {Void}
	 */
	const showHideSelectOptions = (options={}) => {
		if (mode != 'select')
			return

		const { forceOpen, forceClose, showActive } = options || {}
		let show = forceOpen === true ? true : forceClose === true ? false : !selectOptionsOn

		if (show) { 
			const { listOriginY, listHeight, listPosX, listPosY, listWidth } = adjustListOptionPosition(options.event)
			selectState.update(v => {
				let mutationOccured = false
				if ((showActive && v.active !== true) || (!showActive && v.active !== false)) {
					mutationOccured = true
					v.active = showActive ? true : false
				}
				if (!v.show) {
					mutationOccured = true
					v.show = true
				}
				if (v.originY != listOriginY) {
					mutationOccured = true
					v.originY = listOriginY
				}
				if (v.height != listHeight) {
					mutationOccured = true
					v.height = listHeight
				}
				if (v.width != listWidth) {
					mutationOccured = true
					v.width = listWidth
				}
				if (v.x != listPosX) {
					mutationOccured = true
					v.x = listPosX
				}
				if (v.y != listPosY) {
					mutationOccured = true
					v.y = listPosY
				}

				return mutationOccured ? {...v} : v
			})

			selectOptionsOn = true
			_textFieldSelectClickClass.add()
		} else {
			selectState.update(v => {
				let mutationOccured = false
				if (v.active) {
					mutationOccured = true
					v.active = false
				}
				if (v.show) {
					mutationOccured = true
					v.show = false
				}

				return mutationOccured ? {...v} : v
			})

			selectOptionsOn = false
			_textFieldSelectClickClass.remove()
		}
	}

	const getMode = mode => {
		if (ALLOWED_MODES.indexOf(mode) >= 0)
			return mode == 'datetime' 
				? 'datetime-local' 
				: mode == 'select' ? 'text' : mode 
		else 
			return 'text'
	}

	/**
	 * [description]
	 * @param  {String} variant			Valid values: 'filled', 'outlined', 'underlined'
	 * @param  {Number} height			Input's height (default: 56)
	 * @param  {Object} borderRadius	e.g., { topLeft:4, bottomLeft:4, topRight:4, bottomRight:4 }		
	 * 
	 * @return {Number}	padding.left		e.g., 6
	 * @return {String}	padding.leftCss		e.g., '6px' or 'max(6px, var(--lu-base-border-radius, 4px))'
	 * @return {Number}	padding.right		e.g., 6
	 * @return {String}	padding.rightCss	e.g., '6px' or 'max(6px, var(--lu-base-border-radius, 4px))'
	 */
	const getInputHorizontalPadding = ({ variant, height, borderRadius }) => {
		let _inputHorizontalPaddingToHeightRatio = 0
		if (variant == 'filled')
			_inputHorizontalPaddingToHeightRatio = INPUT_FILLED_HOR_PAD_TO_HEIGHT_RATIO
		else if (variant == 'outlined')
			_inputHorizontalPaddingToHeightRatio = INPUT_OUTLINED_HOR_PAD_TO_HEIGHT_RATIO

		const _inputHorizontalPadding = Math.round(_inputHorizontalPaddingToHeightRatio * height)

		if (variant == 'underlined')
			return { 
				left: _inputHorizontalPadding, 
				right: _inputHorizontalPadding,
				leftCss: `${_inputHorizontalPadding}px`,
				rightCss: `${_inputHorizontalPadding}px`
			}

		// Dealing with large border left
		if (borderRadius) {
			const topLeft = borderRadius.topLeft || 0
			const bottomLeft = borderRadius.bottomLeft || 0
			const topRight = borderRadius.topRight || 0
			const bottomRight = borderRadius.bottomRight || 0
			const biggestBorderLeft = topLeft > bottomLeft ? topLeft : bottomLeft
			const biggestBorderRight = topRight > bottomRight ? topRight : bottomRight
			
			const left = _inputHorizontalPadding > biggestBorderLeft ? _inputHorizontalPadding : biggestBorderLeft
			const right = _inputHorizontalPadding > biggestBorderRight ? _inputHorizontalPadding : biggestBorderRight

			return {
				left, 
				right,
				leftCss: `${left}px`,
				rightCss: `${right}px`
			}
		} else 
			return { 
				left: _inputHorizontalPadding, 
				right: _inputHorizontalPadding,
				leftCss: `max(${_inputHorizontalPadding}px, ${VAR_BASE_BORDER_RADIUS})`,
				rightCss: `max(${_inputHorizontalPadding}px, ${VAR_BASE_BORDER_RADIUS})`
			}
	}

	const getWidth = (width, paddingObj={}) => {
		const w = unitParse(width)
		if (w == '100%') {
			const horPadding = (paddingObj.left || 0) + (paddingObj.right || 0)
			if (horPadding > 0)
				return `calc(100% - ${horPadding}px)`
		}

		return w
	}

	const getBorderCss = ({ borderActive, onHover }) => {
		const borderHoverColor = (onHover ? onHover.borderColor : null) || DEFAULT_HOVER_BORDER_COLOR
		return {
			main: `${VAR_BASE_BORDER} solid ${VAR_BASE_BORDER_COLOR}`,
			hover: `${VAR_BASE_BORDER} solid ${borderHoverColor}`,
			focus: `${borderActive}px solid ${VAR_PRIMARY_COLOR}`,
			error: `${borderActive}px solid ${VAR_DANGER_COLOR}`
		}
	}

	const getBackgroundCss = ({ backgroundColor, onHover, onFocus }) => {
		const backgroundHoverColor = (onHover ? onHover.backgroundColor : null) || DEFAULT_BACKGROUND_ACTIVE_COLOR
		const backgroundFocusColor = (onFocus ? onFocus.backgroundColor : null) || DEFAULT_BACKGROUND_ACTIVE_COLOR

		return {
			main: backgroundColor || DEFAULT_BACKGROUND_COLOR,
			hover: backgroundHoverColor,
			focus: backgroundFocusColor
		}
	}

	const setCounterLabel = (value, maxChar, showCharCounter) => {
		if (!showCharCounter || !maxChar || maxChar < 0)
			return 

		if (!value)
			counterLabel = `0 / ${maxChar}`
		else
			counterLabel = `${value.length || 0} / ${maxChar}`
	}

	const pickBig = (a=0) => (b=0) => a > b ? a : b

	const getTopLeftCornerPoints = (border, radius) => {
		const nominalRadius = radius-border
		if (nominalRadius < 7)
			return [
				`${border}px ${radius}px`, // A		
				`${radius}px ${border}px` // B
			]
		else 
			return [
				`${border}px ${radius}px`, // A
				`${border + nominalRadius*(1-COS_18)}px ${radius - nominalRadius*SIN_18}px`, // B
				`${border + nominalRadius*(1-COS_36)}px ${radius - nominalRadius*SIN_36}px`, // C
				`${border + nominalRadius*(1-COS_54)}px ${radius - nominalRadius*SIN_54}px`, // D
				`${border + nominalRadius*(1-COS_72)}px ${radius - nominalRadius*SIN_72}px`, // E
				`${radius}px ${border}px` // F
			]
	}

	const getTopRightCornerPoints = (border, radius) => {
		const nominalRadius = radius-border
		if (nominalRadius < 7)
			return [
				`calc(100% - ${radius}px) ${border}px`, // A
				`calc(100% - ${border}px) ${radius}px`, // B
			]
		else 
			return [
				`calc(100% - ${radius}px) ${border}px`, // A
				`calc(100% - ${radius-nominalRadius*COS_72}px) ${border+nominalRadius*(1-SIN_72)}px`, // B
				`calc(100% - ${radius-nominalRadius*COS_54}px) ${border+nominalRadius*(1-SIN_54)}px`, // C
				`calc(100% - ${radius-nominalRadius*COS_36}px) ${border+nominalRadius*(1-SIN_36)}px`, // D
				`calc(100% - ${radius-nominalRadius*COS_18}px) ${border+nominalRadius*(1-SIN_18)}px`, // E
				`calc(100% - ${border}px) ${radius}px`, // F
			]
	}

	const getBottomRightCornerPoints = (border, radius) => {
		const nominalRadius = radius-border
		if (nominalRadius < 7)
			return [
				`calc(100% - ${border}px) calc(100% - ${radius}px)`, // A
				`calc(100% - ${radius}px) calc(100% - ${border}px)`, // B
			]
		else 
			return [
				`calc(100% - ${border}px) calc(100% - ${radius}px)`, // A
				`calc(100% - ${border+nominalRadius*(1-COS_18)}px) calc(100% - ${radius-nominalRadius*SIN_18}px)`, // B
				`calc(100% - ${border+nominalRadius*(1-COS_36)}px) calc(100% - ${radius-nominalRadius*SIN_36}px)`, // C
				`calc(100% - ${border+nominalRadius*(1-COS_54)}px) calc(100% - ${radius-nominalRadius*SIN_54}px)`, // D
				`calc(100% - ${border+nominalRadius*(1-COS_72)}px) calc(100% - ${radius-nominalRadius*SIN_72}px)`, // E
				`calc(100% - ${radius}px) calc(100% - ${border}px)`, // F
			]
	}

	const getBottomLeftCornerPoints = (border, radius) => {
		const nominalRadius = radius-border
		if (nominalRadius < 7)
			return [
				`${radius}px calc(100% - ${border}px)`, // A
				`${border}px calc(100% - ${radius}px)`, // B
			]
		else 
			return [
				`${radius}px calc(100% - ${border}px)`, // A
				`${radius - nominalRadius*SIN_18}px calc(100% - ${border+nominalRadius*(1-COS_18)}px)`, // B
				`${radius - nominalRadius*SIN_36}px calc(100% - ${border+nominalRadius*(1-COS_36)}px)`, // C
				`${radius - nominalRadius*SIN_54}px calc(100% - ${border+nominalRadius*(1-COS_54)}px)`, // D
				`${radius - nominalRadius*SIN_72}px calc(100% - ${border+nominalRadius*(1-COS_72)}px)`, // AE
				`${border}px calc(100% - ${radius}px)`, // F
			]
	}

	/**
	 * Used to cover the input autocomplete's edge case where some browser use background color.
	 * Without this mask, the input covers up the textfield's borders.
	 * 
	 * @param  {Object} borderRadius		
	 * @param  {Number} borderThickness		
	 * @param  {Number} borderOffset		Used in the multiline case to prevent the text to get too close to the borders.
	 * @return {[type]}                 [description]
	 */
	const getInputClipPath = (borderRadius={}, borderThickness=0, borderOffset=0) => {
		const a = borderThickness + borderOffset + 1
		const pickB = pickBig(a)
		const topLeftRadius = pickB(borderRadius.topLeft || 0)
		const topRightRadius = pickB(borderRadius.topRight || 0)
		const bottomRightRadius = pickB(borderRadius.bottomRight || 0)
		const bottomLeftRadius = pickB(borderRadius.bottomLeft || 0)

		const points = [
			...getTopLeftCornerPoints(a, topLeftRadius),
			...getTopRightCornerPoints(a, topRightRadius),
			...getBottomRightCornerPoints(a, bottomRightRadius),
			...getBottomLeftCornerPoints(a, bottomLeftRadius)
		]
		return `polygon(${points.join(', ')})`
	}

	const validateInput = () => {
		if (!inputEl || !inputEl.validity)
			return

		const data = {
			id: ID,
			el: inputEl,
			mode,
			value,
			details: inputEl.validity
		}

		if (inputEl.validity.valid)
			emitValid(data)
		else 
			emitInvalid(data)
	}

	const getHelperTextHtml = helperText => helperText && Array.isArray(helperText) 
		? helperText.length ? `<ul class="helper-text-list">${helperText.map(t => `<li>${t}</li>`).join('')}</ul>` : ''
		: `<p>${helperText}</p>`

	const getFilename = (file='', label, placeholder) => !file 
		? label ? '' : (placeholder||'') 
		: file.split(/[\\/]/).slice(-1)[0]

	const isDate = mode => mode  == 'date' || mode == 'datetime'

	const getLabelTransformCss = (variant, height) => {
		const _isOutlined = variant == 'outlined'
		const _inputBottom = _isOutlined ? 0 : Math.round(INPUT_BOTTOM_TO_HEIGHT_RATIO * height)
		const _selectIconVerticalOffset = Math.round(SELECT_ICON_VERTICALLY_OFFSET_TO_HEIGHT_RATIO*height)

		return {
			up: _isOutlined
				? `translateY(calc(-${SMALL_FONT_TO_NORMAL_FONT_RATIO/2} * ${VAR_FONT_SIZE})) scale(${SMALL_FONT_TO_NORMAL_FONT_RATIO})`
				: `translateY(${Math.round(LABEL_PAD_TOP_TO_HEIGHT_RATIO * height)}px) scale(${SMALL_FONT_TO_NORMAL_FONT_RATIO})`,
			down: _isOutlined 
				? `translateY(calc(${height/2}px - 0.625 * ${VAR_FONT_SIZE}))`
				: `translateY(calc(${height - _inputBottom}px - 1.25 * ${VAR_FONT_SIZE}))`,
			selectIcon: _isOutlined 
				? `translateY(calc(${height/2}px - 0.75 * ${VAR_FONT_SIZE}))`
				: `translateY(calc(${height - _inputBottom - _selectIconVerticalOffset}px - 1.25 * ${VAR_FONT_SIZE}))`
		}
	}

	/**
	 * Format the label so that the invisible legend uses uppercase to get proper spacing and the real label is trimmed.
	 * @param  {String} label				e.g., 'first name  '
	 * 	
	 * @return {string} output.label			Trimmed label (e.g., 'first name')
	 * @return {string} output.legend			Trimmed uppercased label (e.g., 'FIRST NAME')
	 * @return {string} output.legendLetter		e.g., 'F'. This is used to create a dummy fieldset with 
	 *                                       	the exact same size as the normal one so that we can hide the legend gap.
	 */
	const getFomattedLabels = (label,labelAbove) => {
		if (!label || labelAbove)
			return { value:'', letter:'' }

		const labelTrimmed = label.trim()

		return {
			value: labelTrimmed,
			letter: labelTrimmed[0]
		}
	}

	const getSelectIconMaterialClass = selectIcon => {
		if (!selectIcon || selectIcon == 'filled')
			return { type: 'filled', icon: 'arrow_drop_down' }
		else if (selectIcon == 'filled-round')
			return { type: 'round', icon: 'arrow_drop_down' }
		else if (selectIcon == 'outlined')
			return { type: 'filled', icon: 'expand_more' }
		else if (selectIcon == 'outlined-round')
			return { type: 'round', icon: 'expand_more' }
		return { type: 'filled', icon: 'arrow_drop_down' }
	}

	const getRootVars = ({ color, padding, margin, borderRadius, variant, fontSize, helperText, helperTextListStyle, width, height, fontFamily, border, borderActive, errorColor, mode, label, rows, adjustableHeight, allowResize, readonly, labelTransformCss, backgroundColor,borderColor, onHover, onFocus, fontColor, fontLetterSpacing, placeholderFontColor, helperTextFontColor, outlineClass, underlineClass, fontJustify, labelAbove, labelMargin, labelFontSize, labelJustify, labelFontWeight, labelFontStyle, labelFontColor }) => {

		const _isOutlined = variant == 'outlined'
		const _isFilled = variant == 'filled'
		const _isUnderlined = !_isOutlined && !_isFilled
		const _isMultiline = mode == 'multiline'
		const _paddingObj = paddingParse(padding)
		const _label = labelAbove ? null : label

		// Border config
		const _borderActive = borderActive > border ? borderActive : border
		const _border = getBorderCss({ borderActive:_borderActive, onHover })

		const _borderRadiusExists = borderRadius !== null && borderRadius !== undefined
		const _borderRadius = cornerParse(_borderRadiusExists ? borderRadius : 4)
		const _cssBorderRadius = _isOutlined || outlineClass || !underlineClass
			? _borderRadiusExists ? _borderRadius.css : DEFAULT_BORDER_RADIUS_OBJ.css
			: cornerParse({ 
				topLeft: (_borderRadiusExists ? _borderRadius : DEFAULT_BORDER_RADIUS_OBJ).topLeft, 
				topRight: (_borderRadiusExists ? _borderRadius : DEFAULT_BORDER_RADIUS_OBJ).topRight 
			}, { css:true })
		const _glowBorderColor = _isFilled && outlineClass && onFocus && onFocus.glowBorderColor ? onFocus.glowBorderColor : ''

		// Font config
		const _fontHeight = fontSize||theme.text.font.size
		const _fontSmallHeight = Math.round(SMALL_FONT_TO_NORMAL_FONT_RATIO*_fontHeight)
		const _fontJustify = !fontJustify ? 'left' : fontJustify == 'center' || fontJustify == 'right' ? fontJustify : 'left'

		const _helperTextExtraPaddingLeft = helperText && Array.isArray(helperText) && helperTextListStyle != 'none' 
			? Math.round(1.25*_fontSmallHeight)
			: 0

		const _inputBottom = !_isOutlined 
			? Math.round(INPUT_BOTTOM_TO_HEIGHT_RATIO * height)
			: 0
		
		const _inputPadding = getInputHorizontalPadding({ 
			variant, 
			height, 
			borderRadius:_borderRadiusExists ? _borderRadius : null
		})

		const _helperTextPadTop = Math.round(TEXT_HELPER_PAD_TOP_TO_HEIGHT_RATIO * height)
		const _width = getWidth(width, _paddingObj)

		const _background = getBackgroundCss({ backgroundColor, onHover, onFocus })
		
		// Label config
		const _labelUpGutter = Math.round(LABEL_UP_OUTLINED_GUTTER_TO_HEIGHT_RATIO * _fontSmallHeight)
		const _labelPaddingTop = _isOutlined
			? (height - _fontHeight)/2 - _fontHeight/8
			: height - _fontHeight - _inputBottom - _fontHeight/4
		const _labelMargin = labelAbove && labelMargin 
			? labelMargin.bottom && labelMargin.bottom < 0 && !_isUnderlined ? { ...labelMargin, bottom:0 } : labelMargin
			: {}

		if (_isMultiline) {
			rows = !rows || rows < 1 ? 1 : rows
			const smallestHeight = height + (rows-1)*_fontHeight
			if (!adjustableHeight || adjustableHeight < 0 || adjustableHeight < smallestHeight)
				adjustableHeight = smallestHeight
		} else 
			adjustableHeight = height

		const _adjustTextareaToNotHideLabel = _isMultiline && _label && !_isOutlined
		const _textAreaHeight = _adjustTextareaToNotHideLabel
			? adjustableHeight - _labelPaddingTop
			: adjustableHeight
		const _textAreaMarginTop = _adjustTextareaToNotHideLabel ? _labelPaddingTop : 0
		const _textAreaPaddingTop = _adjustTextareaToNotHideLabel ? 0 : _labelPaddingTop

		const _outlinedAutocompleteMaskPoligon = getInputClipPath(_borderRadius, _borderActive)
		const _labelColor = labelFontColor ? chooseColor(labelFontColor) : `var(--lu-color-primary, ${theme.palette.primary.main})`

		// Placeholder config
		const _placeholderFontColor = placeholderFontColor || VAR_IDLE_COLOR
		const _placeholderOpacity = _label ? 0 : 1

		const mainTheme = `
			${sizeCssVariables('--lu-textfield-width', _width, { all:true }).join(';')};
			${sizeCssVariables('--lu-textfield-height', height, { all:true }).join(';')};
			${border ? `--lu-base-border: ${border}px;` : ''}
			${borderColor ? `--lu-base-border-color: ${borderColor};` : ''}
			${placeholderFontColor ? `--lu-color-idle: ${placeholderFontColor};` : ''}
			--lu-textfield-adj-height: ${`${adjustableHeight||0}px`};
			--lu-textfield-area-height: ${`${_textAreaHeight}px`};
			--lu-textfield-area-mt: ${`${_textAreaMarginTop}px`};
			--lu-textfield-area-pt: ${`${_textAreaPaddingTop}px`};
			--lu-textfield-area-resize: ${allowResize ? 'vertical' : 'none'};
			--lu-textfield-bgd-focus: ${_background.focus};
			--lu-textfield-bgd-hover: ${_background.hover};
			--lu-textfield-bgd-main: ${_background.main};
			--lu-textfield-border-error: ${_border.error};
			--lu-textfield-border-focus: ${_border.focus};
			--lu-textfield-border-hover: ${_border.hover};
			--lu-textfield-border-main: ${_border.main};
			--lu-textfield-border-radius: ${_cssBorderRadius};
			--lu-textfield-box-shadow: ${_glowBorderColor ? `0px 0px 0px ${1.5 * _borderActive}px ${_glowBorderColor}` : 'none'};
			--lu-textfield-box-shadow: ${_glowBorderColor ? `0px 0px 0px ${1.5 * _borderActive}px ${_glowBorderColor}` : 'none'};
			--lu-textfield-filled-bgd-focus: ${_isFilled ? _background.focus : 'transparent'};
			--lu-textfield-filled-pt: ${`${height - 2*(_fontHeight/2 + _inputBottom + _fontHeight/8)}px`};
			--lu-textfield-font-justify: ${_fontJustify};
			--lu-textfield-help-color: ${helperTextFontColor || _placeholderFontColor};
			--lu-textfield-help-list-style: ${helperTextListStyle||'none'};
			--lu-textfield-help-pl: ${`${_inputPadding.left + _helperTextExtraPaddingLeft}px`};
			--lu-textfield-help-pt: ${`${_helperTextPadTop}px`};
			--lu-textfield-input-cursor: ${readonly ? 'pointer' : 'text'};
			--lu-textfield-input-opacity: ${mode == 'file' ? 0 : 1};
			--lu-textfield-input-pl: ${_inputPadding.leftCss};
			--lu-textfield-input-pr: ${_inputPadding.rightCss};
			--lu-textfield-label-font-color: ${_labelColor};
			--lu-textfield-label-font-size: ${labelFontSize ? `${labelFontSize}px;` : '1em'};
			--lu-textfield-label-font-style: ${labelFontStyle||'normal'};
			--lu-textfield-label-font-weight: ${labelFontWeight||'normal'};
			--lu-textfield-label-height: ${`calc(1.3 * ${labelFontSize ? `${labelFontSize}px` : VAR_FONT_SIZE})`};
			--lu-textfield-label-justify-content: ${parseTextAlign(labelJustify, 'left')};
			--lu-textfield-label-margin: ${paddingParse(_labelMargin).css};
			--lu-textfield-label-t-down: ${labelTransformCss.down};
			--lu-textfield-label-t-icon: ${labelTransformCss.selectIcon};
			--lu-textfield-label-t-up: ${labelTransformCss.up};
			--lu-textfield-lgd-ml: ${`${-2*_labelUpGutter}px`};
			--lu-textfield-lgd-pr: ${`${3*_labelUpGutter}px`};
			--lu-textfield-margin: ${paddingParse(margin).css||0};
			--lu-textfield-mask: ${_outlinedAutocompleteMaskPoligon};
			--lu-textfield-padding: ${paddingParse(padding).css||0};
			--lu-textfield-placeholder-color: ${_placeholderFontColor};
			--lu-textfield-placeholder-opacity: ${_placeholderOpacity};
			--lu-textfield-placeholder-trans: ${transition.css({ opacity: MAIN_TRANSITION_CURVE }, _label, false, true)};
		`

		return css(mainTheme+setVariables({ primary:color, danger:errorColor, fontFamily, fontSize, fontColor, fontLetterSpacing }))
	}

	/**
	 * WARNING: Styling the option list cannot involve CSS variables defined on the textfield component because the
	 * list is not nested under it. Instead, it is added at the bottom of the <body> tag. This design was necessary
	 * to garantee that the list can always appear above any element. 
	 * 
	 * @param  {String}		mode						e.g., 'select'
	 * @param  {Writable}	selectState									
	 * @param  {[Object]} 	options						e.g., ['Apple', 'Banana', 'Orange']
	 * @param  {Number} 	height
	 * @param  {Number} 	listBorderRadius
	 * @param  {Number} 	listBackgroundColor																
	 * 
	 * @return {[Object]}	config.options						
	 * @return {Number}		config.backgroundColor						
	 * @return {Number}		config.borderRadius						
	 * @return {Number}		config.rowHeight						
	 * @return {Number}		config.rowPadding.left						
	 * @return {Number}		config.rowPadding.right						
	 * @return {Writable}	config.selectState						
	 * 
	 */
	const getSelectListConfig = (mode, selectState, options, height, borderRadius, listBorderRadius, listBackgroundColor) => {
		if (mode == 'select') {
			const _borderRadius = exists(listBorderRadius) ? listBorderRadius : borderRadius
			const rowPadding = Math.round(LIST_ITEM_HORIZONTAL_PADDING_TO_HEIGHT_RATIO*height)
			return {
				options,
				rowHeight: Math.round(LIST_ITEM_HEIGHT_TO_HEIGHT_RATIO*height),
				rowPadding: { 
					left: rowPadding,
					right: rowPadding
				},
				borderRadius: _borderRadius,
				backgroundColor: listBackgroundColor,
				selectState
			}
		} else 
			return null
	}

	/* 
	=======================================================
	END - PRIVATE FUNCTIONS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT HANDLERS
	=======================================================
	*/
	
	const onBlur = () => {
		validateInput()
	}
	
	const onClick = (event, options={}) => {
		showHideSelectOptions({ showActive:options.showActive, event })
	}
	
	const onKeyPress = e => {
		if (e && e.key == 'Enter') 
			onClick(e, { showActive:true })
	}

	const onListItemSelected = (data={}) => {
		const { id, value:item } = data.detail || {}
		selectedOptionId = id || null
		value = item || ''
		showHideSelectOptions({ forceClose:true })
		focusInput()
	}

	const onListItemEscape = () => {
		showHideSelectOptions({ forceClose:true })
		focusInput()
	}

	const onListBlur = () => {
		showHideSelectOptions({ forceClose:true })
		focusInput()
	}

	const onListSizeChange = (data={}) => {
		listSize = data.detail || {}
	}

	/* 
	=======================================================
	END - EVENT HANDLERS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT LIFECYCLE
	=======================================================
	*/
	
	// Value block. This reactive statement is fired at least each time the value change. Add any value dependent state changes 
	// here to minimize unecessary style re-rendering (refer to 'Style re-rendering block' below)
	$: {	
		emitChange(mode == 'select' ? { id:selectedOptionId, value, mode } : { value, mode })	
		setCounterLabel(value, maxChar, showCharCounter)
		labelUpClass = label && !labelAbove && (value || labelUp || isDate(mode)) ? 'textfield-label-up' : ''
	}
	$: outlineClass = variant == 'outlined' || (variant == 'filled' && outline) ? TEXTFIELD_OUTLINED_CLASS : ''
	$: underlineClass = variant != 'outlined' && underline ? TEXTFIELD_UNDERLINED_CLASS : ''
	$: stateClasses = `variant-${variant}`
	$: labelTransformCss = getLabelTransformCss(variant, height)
	$: readonlyState = readonly === true || mode == 'select'
	$: helperTextHtml = getHelperTextHtml(helperText)
	$: fieldType = getMode(mode) // Sets the input type. This is a hack because Svelte 3 does not support dynamic field type :(
	$: _onFocus = onHover && onHover.onFocus ? { ...onHover, ...(onFocus||{}) } : onFocus
	$: formattedLabel = getFomattedLabels(label,labelAbove)
	$: selectMaterialIcon = getSelectIconMaterialClass(selectIcon)
	$: rootVars = getRootVars({ color, padding, margin, borderRadius, variant, fontSize, helperText, helperTextListStyle, width, height, fontFamily, border, borderActive, errorColor, mode, label, labelAbove, rows, adjustableHeight, allowResize, readonly:readonlyState, labelTransformCss, backgroundColor,borderColor, onHover, onFocus:_onFocus, fontColor, fontLetterSpacing, placeholderFontColor, helperTextFontColor, outlineClass, underlineClass, fontJustify, labelMargin, labelFontSize, labelJustify, labelFontWeight, labelFontStyle, labelFontColor })
	$: selectState = mode == 'select' ? new writable({}) : null
	$: selectListConfig = getSelectListConfig(mode, selectState, options, height, borderRadius, listBorderRadius, listBackgroundColor)

	/* 
	=======================================================
	END - EVENT LIFECYCLE
	=======================================================
	*/
</script>

<div 
	{id}
	data-type="textfield"
	class="{rootVars} {rootClass} {`variant-${variant}`} {outlineClass||underlineClass} {eventClasses} {labelUpClass} {invalid ? 'textfield-invalid' : ''} {disabled ? 'textfield-disabled' : ''}"
	style="{listOptionStyle}"
	>
	{#if label && labelAbove} 
	<div class="textfield-label-above-container">
		<label for="{INPUT_ID}" class="textfield-font textfield-label-above">
			{label}
		</label>
	</div>
	{/if}
	<div class="textfield-container">
		{#if mode == 'file'}
			<div class="textfield-text-clone textfield-font {value ? '' : 'textfield-placeholder'}">{getFilename(value, label, placeholder)}</div>
		{/if}
		{#if mode != 'multiline'}
			{#if mode == 'select'}
				<input type="hidden" {name} bind:value={selectedOptionId}>
				{#key selectListConfig}
				<div 
					use:addSelectList={selectListConfig}
					on:click={onListItemSelected}
					on:escape={onListItemEscape}
					on:blur={onListBlur}
					on:sizechange={onListSizeChange}>
				</div>
				{/key}
				<div class="textfield-select-expand-icon">
					<!-- The icon's size is automatically adjusted based on the --lu-font-size variable -->
					<Icon 
						icon={selectMaterialIcon.icon} 
						type={selectMaterialIcon.type}
						transitionOn={true}
						transformEnd="rotate(180deg)"
						parentTransitionStartClass="textfield-options-on">
					</Icon>
				</div>
			{/if}
			<input 
			id="{INPUT_ID}"
			use:typeable={fieldType}
			type="text" 
			readonly={readonlyState || undefined}
			name={mode == 'select' ? `${name}_text` : name}
			{placeholder}
			maxlength={maxChar > 0 ? maxChar : undefined} 
			minlength={minChar > 0 ? minChar : undefined} 
			max={max !== null ? max : undefined} 
			min={min !== null ? min : undefined} 
			step={mode == 'number' ? (step||1) : undefined}
			class="textfield-font"
			disabled={disabled ? true : undefined}
			bind:value={value}
			bind:this={inputEl}
			on:blur={onBlur}
			on:click|stopPropagation={onClick}
			on:keypress={onKeyPress}
			>
		{:else}
			<textarea
			id="{INPUT_ID}"
			use:typeable={fieldType}
			{name}
			{placeholder}
			readonly={readonlyState || undefined}
			maxlength={maxChar > 0 ? maxChar : undefined} 
			minlength={minChar > 0 ? minChar : undefined} 
			disabled={disabled ? true : undefined}
			class="textfield-font"
			bind:value={value}
			bind:this={inputEl}
			on:blur={onBlur}
			></textarea> 
		{/if}
		{#if formattedLabel.value}
			<label 
				class="textfield-label" 
				for="{INPUT_ID}"
				>
				<span class="textfield-font textfield-label--active">{formattedLabel.value}</span>
				<span class="textfield-font textfield-label--inactive">{formattedLabel.value}</span>	
			</label>
		{/if}
		{#if variant == 'outlined' && formattedLabel.value}
			<fieldset class="textfield-border textfield-border--inactive textfield-border--add-label">
					<legend class="textfield-legend textfield-font" >{formattedLabel.value}</legend>
			</fieldset>
			<fieldset class="textfield-border textfield-border--inactive textfield-border--add-label textfield-border--labelup">
					<legend class="textfield-legend textfield-font textfield-legend-hide" >{formattedLabel.letter}</legend>
			</fieldset>
			<fieldset class="textfield-border textfield-border--active textfield-border--add-label">
					<legend class="textfield-legend textfield-font" >{formattedLabel.value}</legend>
			</fieldset>
		{:else}
			<fieldset class="textfield-border textfield-border--inactive"></fieldset>
			<fieldset class="textfield-border textfield-border--active"></fieldset>
		{/if}
		<div class="text-helper textfield-font">
			{#if maxChar > 0 && showCharCounter}
			<div class="textfield-counter textfield-font">
				{counterLabel}
			</div>
			{/if}
			{#if helperText}
			{@html helperTextHtml||''}
			{/if}
		</div>
	</div>
</div>
