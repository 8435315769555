<script>
	/* 
	=======================================================
	START - DEPENDENCIES
	=======================================================
	*/

	import { createEventDispatcher, onDestroy } from 'svelte'
	import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
	import { getId, createBaseEvents, formatValue, exists } from '../../../core/index'
	import { paddingParse, addUnit } from '../../../core/parse'
	import { transform } from '../../../core/css'
	import { chooseColor } from '../../../core/color'
	import { emitCustomEvent, listenTo } from '../../../core/dom'
	import theme, { VAR_PRIMARY_COLOR, VAR_ON_PRIMARY_COLOR } from '../../../core/theme'
	import { rootClass, TRANSITION_CURVE } from './style'
	import Ripple from '../../ripple/src/Ripple.svelte'
	import Icon from '../../icon/src/Icon.svelte'

	/* 
	=======================================================
	END - DEPENDENCIES
	=======================================================
	*/









	/* 
	=======================================================
	START - CONSTANTS
	=======================================================
	*/

	const ID = getId() // e.g., '_cwd23d'
	const DEFAULT_SIZE = 16
	const DEFAULT_BORDER_TO_SIZE_RATIO = 2/DEFAULT_SIZE
	const DEFAULT_BORDER_RADIUS_TO_SIZE_RATIO = 3/DEFAULT_SIZE
	const DEFAULT_BACKGROUND_SIZE_TO_SIZE_RATIO = 42/DEFAULT_SIZE
	const DEFAULT_DISABLED_COLOR = theme.color.grey[300]
	const TICK_SIZE_TO_SIZE_RATIO = 12/DEFAULT_SIZE
	const DEFAULT_BORDER_COLOR = theme.color.grey[500]

	/* 
	=======================================================
	END - CONSTANTS
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT PROPERTIES
	=======================================================
	*/

	// Content
	export let id = ID
	export let name = ''
	export let icon = null
	export let value = null 
	export let radioValue = null // Only valid when mode == 'radio'. This value allows to output a string or number instead of a boolean.
	// Style
	export let color = null
	export let size = null
	export let backgroundSize = null
	export let backgroundWidth = null
	export let backgroundHeight = null
	export let backgroundBorderRadius = null
	export let padding = 0 // Can be an object such as { top: 5, right: 6, bottom: 7, left: 8 }
	export let margin = 0
	export let border = null
	export let borderColor = null
	export let borderRadius = null // Number of object (e.g., { left: 4, right:0 } or { top:4, bottom:2 } or { top: { left:1, right:2 }, bottom: { left:3, right:4 } } or { left: { top:1, bottom:2 }, right: { top:3, bottom:4 } })
	export let activeLight = null // Valid values: 'dark' or 'bright'. This controls the ripple/hover/focus tone.
	export let rippleOpacity = 0.2
	export let disabledColor = null
	export let tickColor = null
	export let tickWeight = null // valid values: 'normal' (default), 'bold'
	export let tickStyle = null // valid values: 'sharp', 'round'
	// Behavior
	export let rippleOff = false
	export let disabled = false
	export let indeterminate = false // When true, the indeterminate state is supported.
	export let backgroundOff = false
	export let mode = 'checkbox' // valid values: 'checkbox' (default), 'radio'
	export let on = null // e.g., [{event:'hover', config:{ backgroundTransition: 'expand', borderColor: '#000' } }]

	/* 
	=======================================================
	END - COMPONENT PROPERTIES
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT EVENTS
	=======================================================
	*/

	const dispatch = createEventDispatcher()
	createBaseEvents(dispatch, () => formatValue(
		name, 
		mode, 
		localValue ? mode == 'radio' ? (radioValue || localValue) : localValue : false))
	const emitEvent = eventName => data => dispatch(eventName, data||{})
	
	/**
	 * on:change
	 *
	 * @param  {String}		data.id
	 * @param  {String}		data.name
	 * 
	 * @return {Void}
	 */
	const emitChange = emitEvent('change')

	/* 
	=======================================================
	END - COMPONENT EVENTS
	=======================================================
	*/









	/* 
	=======================================================
	START - REACTIVE STATE PROPS
	=======================================================
	*/

	let rippleClickEvent,
		node,
		localValue,
		onHoverHandler,
		onTickHandler,
		onUntickHandler,
		unsubscribes = []

	/* 
	=======================================================
	END - REACTIVE STATE PROPS
	=======================================================
	*/









	/* 
	=======================================================
	START - PRIVATE FUNCTIONS
	=======================================================
	*/

	const unsubscribeAllListeners  = () => {
		if (!unsubscribes || !unsubscribes.length)
			return 

		while(unsubscribes[0] !== undefined) {
			const u = unsubscribes.pop()
			u()
		}
	}

	const registerSubscribers = on => {
		if (!on || !on.length)
			return 

		unsubscribeAllListeners()

		for (let i=0;i<on.length;i++) {
			const handler = on[i]
			if (!handler)
				continue

			if (handler.event == 'hover')
				onHoverHandler = handler
			else if (handler.event == 'tick')
				onTickHandler = handler
			else if (handler.event == 'untick')
				onUntickHandler = handler
			else if (handler.event) {
				if (handler.tick)
					unsubscribes.push(listenTo(handler.event, () => {
						if (!localValue) {
							setLocalValue(true)
							emitChange({ id, name, value:true })
						}
					}))
				else if (handler.untick) {
					unsubscribes.push(listenTo(handler.event, () => {
						if (localValue) {
							setLocalValue(false)
							emitChange({ id, name, value:false })
						}
					}))
				}
			}
		}
	}

	const setLocalValue = newVal => {
		if (newVal && onTickHandler && onTickHandler.emitEvent)
			emitCustomEvent(onTickHandler.emitEvent, { value:newVal })
		if (!newVal && onUntickHandler && onUntickHandler.emitEvent)
			emitCustomEvent(onUntickHandler.emitEvent, { value:newVal })

		localValue = newVal 
	}

	const getRootVars = ({ color, size, borderRadius, border, borderColor, padding, margin, activeColor, disabledColor, onHover, backgroundSize, backgroundWidth, backgroundHeight, backgroundBorderRadius, backgroundOff, icon, mode, tickColor }) => {

		const _size = size || DEFAULT_SIZE
		const _isRadio = mode == 'radio'
		const _background_size = backgroundSize || Math.round(DEFAULT_BACKGROUND_SIZE_TO_SIZE_RATIO * _size)
		const _asymmetricBackground = backgroundWidth && backgroundHeight
		const _borderRadiusExists = _isRadio || exists(borderRadius)
		const _borderRadius = _isRadio 
			? addUnit(_size)
			: _borderRadiusExists 
				? addUnit(borderRadius)
				: `var(--lu-base-border-radius, ${Math.round(DEFAULT_BORDER_RADIUS_TO_SIZE_RATIO * _size)}px)`
		const _defaultBorder = `${Math.round(DEFAULT_BORDER_TO_SIZE_RATIO * _size)}px`
		const _border = exists(border) ? addUnit(border) : `var(--lu-base-border, ${_defaultBorder})`
		const _containerBorderRadius = backgroundOff
			? _borderRadius
			: _asymmetricBackground 
				? addUnit(backgroundBorderRadius || 0) 
				: `${Math.ceil(_background_size/2)}px`
		const _borderColor = exists(borderColor) ? chooseColor(borderColor) : `var(--lu-base-border-color, ${DEFAULT_BORDER_COLOR})`

		let _onHoverTransformTransitions = ['opacity'],
			_onHoverColorTransition = '', 
			_onHoverTransform = {}, 
			_onHoverBorderColor

		if (onHover) {
			if (onHover.backgroundTransition) {
				_onHoverTransformTransitions.push(...transform.props)
				if (onHover.backgroundTransition == 'expand')
					_onHoverTransform = { on:'scale(1)', off: 'scale(0)', origin:'center center' }
			}

			if (onHover.borderColor) {
				_onHoverColorTransition = `border ${TRANSITION_CURVE}`
				_onHoverBorderColor = chooseColor(onHover.borderColor)
			}
		}	

		const _disabledBorderColor = disabledColor || DEFAULT_DISABLED_COLOR

		return css(`
		--lu-checkbox-color: ${chooseColor(color)};
		--lu-checkbox-tick-color: ${chooseColor(tickColor||VAR_ON_PRIMARY_COLOR)};
		--lu-checkbox-border: ${icon ? '0px' : _border};
		--lu-checkbox-border-radius: ${icon ? '0px' : _borderRadius};
		--lu-checkbox-border-color: ${_borderColor};
		--lu-checkbox-size: ${_size}px;
		--lu-checkbox-padding: ${paddingParse(padding).css};
		--lu-checkbox-margin: ${paddingParse(margin).css};
		--lu-checkbox-width: ${backgroundOff ? `calc(var(--lu-checkbox-size) + 2*max(var(--lu-checkbox-border),${_defaultBorder}))` : `${_asymmetricBackground ? backgroundWidth : _background_size}px`};
		--lu-checkbox-height: ${backgroundOff ? `calc(var(--lu-checkbox-size) + 2*max(var(--lu-checkbox-border),${_defaultBorder}))` : `${_asymmetricBackground ? backgroundHeight : _background_size}px`};
		--lu-checkbox-halo-border-radius: ${_containerBorderRadius};
		--lu-checkbox-background-color: ${activeColor||'#000'};
		--lu-checkbox-hover-border-trans: ${_onHoverColorTransition || 'border 0s ease 0s'};
		--lu-checkbox-hover-trans: ${_onHoverTransformTransitions.map(x => `${x} ${TRANSITION_CURVE}`).join(',')};
		--lu-checkbox-hover-will-change: ${_onHoverTransformTransitions};
		--lu-checkbox-hover-trans-on: ${_onHoverTransform.on||'none'};
		--lu-checkbox-hover-trans-off: ${_onHoverTransform.off||'none'};
		--lu-checkbox-hover-border: ${icon ? 0 : `var(--lu-checkbox-border) solid ${_onHoverBorderColor||'var(--lu-checkbox-border-color)'}`};
		--lu-checkbox-content-border: ${icon ? 0 : 'var(--lu-checkbox-border) solid var(--lu-checkbox-color)'};
		--lu-checkbox-content-border-trans: ${icon ? 'border 0s ease 0s' : 'border 1ms linear'};
		--lu-checkbox-content-background-color: ${icon || _isRadio ? 'transparent' : 'var(--lu-checkbox-color)'};
		--lu-checkbox-disabled-border: ${icon ? 0 : `var(--lu-checkbox-border) solid ${_disabledBorderColor}`};
		--lu-checkbox-disabled-background-color: ${icon ? 'transparent' : _disabledBorderColor};
		`)
	}

	/* 
	=======================================================
	END - PRIVATE FUNCTIONS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT HANDLERS
	=======================================================
	*/

	const onclick = () => {
		if (disabled)
			return
		if (node)
			node.focus()
	}

	const onkeypress = e => {
		if (disabled)
			return

		if (e && e.key == 'Enter')
			onmousedown(e)
	}

	const onmousedown = e => {
		if (disabled)
			return

		if (mode == 'radio') {
			if (!localValue) {
				setLocalValue(true)
				emitChange({ id, name, value:radioValue||localValue })
			}
		} else {
			const v = !localValue
			setLocalValue(v)
			emitChange({ id, name, value:v })
		}
		rippleClickEvent = e
	}

	const unselectThisRadio = () => {
		if (localValue) 
			localValue = false
	}

	onDestroy(() => {
		unsubscribeAllListeners()
	})

	/* 
	=======================================================
	END - EVENT HANDLERS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT LIFECYCLE
	=======================================================
	*/

	$: registerSubscribers(on)
	$: activeColor = !activeLight 
		? VAR_PRIMARY_COLOR
		: activeLight == 'bright' ? '#fff' : activeLight == 'dark' ? '#000' : activeLight == 'primary' ? VAR_PRIMARY_COLOR : activeLight
	
	$: rootVars = getRootVars({ color, size, borderRadius, border, borderColor, padding, margin, activeColor, disabledColor, onHover:(onHoverHandler||{}).config, backgroundSize, backgroundWidth, backgroundHeight, backgroundBorderRadius, backgroundOff, icon, mode, tickColor })

	$: {
		setLocalValue(value)
	}

	$: indeterminateState = indeterminate && (localValue === null || localValue === undefined)

	/* 
	=======================================================
	END - EVENT LIFECYCLE
	=======================================================
	*/
</script>

<div 
	{id}
	data-type="{mode}" 
	class="{rootVars} {rootClass}{disabled ? ' checkbox-disabled' : ''}{localValue || indeterminateState ? ' checkbox-ticked' : ''}" 
	>
	<div 
		class="checkbox-container" 
		tabindex="{disabled ? '-1' : '0'}"
		on:mousedown={onmousedown}
		on:click={onclick}
		on:keypress={onkeypress}
		bind:this={node}>
		<div class="{backgroundOff ? '' : 'checkbox-container-accent'}"></div>
		<div class="checkbox-content">
			{#if mode == 'radio'}
			<div id="radio{id}" data-radio-name={name} on:click="{unselectThisRadio}"></div>
			<input class="checkbox-input" type="radio" name="{name}" value={radioValue||value} tabindex="-1">
			<div class="radio-button"></div>
			{:else}
			<input class="checkbox-input" type="checkbox" name="{name}" checked={localValue} tabindex="-1">
			{#if icon}
			<Icon 
				icon={icon}
				type={localValue ? 'filled' : 'outlined'}
				bold={tickWeight == 'bold'} 
				color={disabled ? (disabledColor || DEFAULT_DISABLED_COLOR) : 'var(--lu-checkbox-color)'}
				size={size||DEFAULT_SIZE}>
			</Icon>
			{:else}
			<div class="checkbox-tick">
				<Icon 
					icon={indeterminateState ? 'remove' : 'done'}
					type={tickStyle == 'round' ? 'round' : 'filled'}
					bold={tickWeight == 'bold'} 
					color={'var(--lu-checkbox-tick-color)'}
					size={Math.round(TICK_SIZE_TO_SIZE_RATIO*(size||DEFAULT_SIZE))}>
				</Icon>
			</div>
			{/if}
			{/if}
		</div>
		{#if !rippleOff && !disabled}
		<Ripple 
			color={activeColor}
			opacity={rippleOpacity}
			centeredOrigin={true}
			clickEvent={rippleClickEvent}>
		</Ripple>
		{/if}
	</div>
</div>
