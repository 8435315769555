import { afterUpdate } from 'svelte'

export const WIN = (typeof window !== 'undefined') ? window : null

export const createBaseEvents = (dispatch, getVal) => {
	if (!getVal)
		getVal = () => null
	afterUpdate(() => dispatch('afterupdate', getVal()))
}

export const getId = () => '_'+Math.random().toString(36).substr(2, 9)

export const formatValue = (name, type, value) => ({ name, type, value })

export const exists = o => o !== undefined && o !== null

/**
 * Example: mergeObjFactory('none', null)({ transform:'hello' },{ transform:'none' }) -> { transform:'hello' }
 * 
 * @param  {...[type]} overridableValues
 * @return {Function}                   
 */
export const mergeObjFactory = (...overridableValues) => {
	const ol = overridableValues.length 
	const firstVal = overridableValues[0]
	const overrideFn = ol
		? ol == 1 
			? val => val !== firstVal 
			: val => !overridableValues.some(x => x === val)
		: val => val !== null

	return (...objs) => {
		const result = {}
		for (let i=0,l=objs.length;i<l;i++)
			for (let [k,v] of Object.entries(objs[i]||{}))
				if (overrideFn(v) || result[k] === undefined)
					result[k] = v
		return result
	}
}

export const mergeObj = mergeObjFactory()

export const deepMergeObj = (...objs) => objs.reduce((acc, obj) => { 
	obj = obj || {}
	if (typeof(obj) != 'object' || Array.isArray(obj) || (obj instanceof Date))
		return acc
	
	Object.keys(obj).forEach(property => {
		const val = obj[property]
		const originVal = acc[property]
		const readyToMerge = !originVal || !val || typeof(val) != 'object' || Array.isArray(val) || typeof(originVal) != 'object' || Array.isArray(originVal)
		acc[property] = readyToMerge ? val : mergeObj(originVal, val)	
	})

	return acc
}, {})

/**
 * Gets the value for that prop. 
 * 
 * @param  {Object} obj		e.g., { name:'Nic', friends:[{ name:'Michael' }], events:[['hello'],['world']] }
 * @param  {String} prop	e.g., 'name', or 'friends[0]' or 'events[1][0]'
 * 
 * @return {Object} value	e.g., 'Nic', { name:'Michael' }, 'world' 
 */
const getPropValue = (obj, prop) => {
	if (!prop)
		return obj 
	const arrayPositions = prop.match(/\[(.*?)\]/g)
	if (arrayPositions) {
		const p = prop.match(/^(.*?)\[/)[1]
		let value = p ? obj[p] : obj
		for (let i=0,l=arrayPositions.length;i<l;i++) {
			const idx = arrayPositions[i].replace(/(^.|.$)/g,'')
			value = (value||[])[idx]
		}
		return value
	} else
		return obj[prop]
}
/**
 * Gets an object's property based on the property path. 
 * 
 * @param  {Object} obj   Original object.
 * @param  {String} prop  e.g., 'name' or 'project.name' or 'friends[1].events[0][1].name' or '[2]'
 * 
 * @return {Object}       Value.
 */
export const getProperty = (obj,prop) => {
	if (!prop)
		return obj 
	
	obj = obj || {}
	const props = prop.split('.').filter(x => x)
	const l = props.length
	const lastIndex = l-1
	let value = obj
	for (let i=0;i<l;i++) {
		if (i == lastIndex)
			value = getPropValue(value, props[i])
		else
			value = getPropValue(value, props[i]) || {}
	}

	// if (prop.indexOf('services') >= 0) {
	// console.log({
	// 	prop,
	// 	obj,
	// 	value
	// })
	// }
	return value
}

/**
 * Traverses an object's tree and apply a function to all leaves. 
 * 
 * @param  {Object}   obj
 * @param  {Function} fn
 * 
 * @return {Object}
 */
const applyFuncToObj = (obj,fn) => {
	if (obj === null || obj === undefined || !fn)
		return obj 

	if (typeof(obj) != 'object')
		return fn(obj)

	if (Array.isArray(obj))
		return obj.map(o => applyFuncToObj(o,fn))

	let result = {}
	for (let [k,v] of Object.entries(obj)) {
		if (Array.isArray(v))
			result[k] = v.map(o => applyFuncToObj(o,fn))
		else if (typeof(v) == 'object')
			result[k] = applyFuncToObj(v,fn)
		else
			result[k] = fn(v)
	}

	return result
}

const _dataPrefix = v => v[0] == '$' && v[1] == 'd' && v[2] == 'a' && v[3] == 't' && v[4] == 'a' && v[5] == '.' && v[6]
export const injectData = data => {
	if (!data || typeof(data) != 'object')
		return v => v 

	const replaceRefWithValue = v => v && typeof(v) == 'string' && _dataPrefix(v)
		? getProperty(data, v.replace('$data.',''))
		: v

	return v => {
		if (v) {
			const t = typeof(v)
			if (t == 'string') {
				if (_dataPrefix(v))
					return getProperty(data, v.replace('$data.',''))
			} else if (t == 'object')
				return applyFuncToObj(v, replaceRefWithValue)
		}
		return v
	}
}

/*eslint-disable */
const MOBILE_USER_AGENT_01 = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i
const MOBILE_USER_AGENT_02 = /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i
/*eslint-enable */
let _isMobile = null
export const isMobile = () => {
	if (_isMobile === null) {
		if (!WIN || !WIN.navigator || !WIN.navigator.userAgent) {
			_isMobile = false
		} else {
			const userAgent = WIN.navigator.userAgent
			_isMobile = MOBILE_USER_AGENT_01.test(userAgent) || MOBILE_USER_AGENT_02.test(userAgent.substr(0,4))
		}
	}
	return _isMobile 
}
















