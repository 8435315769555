<script>
	/* 
	=======================================================
	START - DEPENDENCIES
	=======================================================
	*/

	import { createEventDispatcher, onDestroy } from 'svelte'
	import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
	import { VAR_FONT_FAMILY, VAR_FONT_SIZE, VAR_FONT_COLOR, VAR_BASE_BORDER_RADIUS, VAR_FONT_LETTER_SPACING } from '../../../core/theme'
	import { getId, createBaseEvents, deepMergeObj, exists, isMobile } from '../../../core/index'
	import { addUnit, paddingParse, cornerParse, alignParse, rangeParse } from '../../../core/parse'
	import { chooseColor } from '../../../core/color'
	import { transition as trans, getGradientColor, getTransitionCss } from '../../../core/css'
	import { getBoxShadow } from '../../../core/elevation'
	import { onUrlChange, cleanUrlChange, WIN, listenTo, processPressHandler } from '../../../core/dom'
	import { rootClass } from './style'
	import Ripple from '../../ripple/src/Ripple.svelte'
	import Icon from '../../icon/src/Icon.svelte'

	/* 
	=======================================================
	END - DEPENDENCIES
	=======================================================
	*/









	/* 
	=======================================================
	START - CONSTANTS
	=======================================================
	*/

	const ID = getId() // e.g., '_cwd23d'
	const NO_TRANSITION = 'all 0s ease 0s'

	/* 
	=======================================================
	END - CONSTANTS
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT PROPERTIES
	=======================================================
	*/

	// Content
	export let id = ID
	export let html = null
	export let name = null
	export let leadingIcon = null
	export let trailingIcon = null
	// Style
	export let width = null
	export let height = null
	export let padding = 0
	export let margin = 0
	export let color = null // e.g. 'red', 'primary', ['red', 'blue']
	export let colorOrientation = null // Only usefull when color is an array.
	export let fontColor = null
	export let fontSize = null
	export let fontColorOrientation = null
	export let fontWeight = null
	export let fontFamily = null
	export let fontLetterSpacing = null
	export let fontTransformText = null
	export let border = null
	export let borderColor = null
	export let borderRadius = null
	export let elevation = null
	export let elevationStrength = null
	export let elevationColor = null
	export let elevationOffset = null // { x:2,y:2 }
	export let overlayColor = null
	export let overlayOpacity = null
	export let overlayScale = null
	export let transition = null
	export let on = null
	export let rippleOpacity = 0.2 
	export let rippleColor = 'white'
	export let contentDirection = 'row' // Valid values: 'row', 'column'
	export let contentGap = null 
	export let contentPadding = 0
	export let contentOpacity = null
	export let contentJustify = null // Valid values: 'left', 'center', 'right'
	export let underlineOn = false
	export let underlineWidth = null
	export let underlineHeight = null
	export let underlineColor = null
	export let underlineOpacity = null
	export let underlineScale = null
	export let underlineScaleOrigin = null // Valid values: 'left', 'center' (default), 'right'
	export let underlineRounded = false
	export let underlineOffset = null
	export let underlinePosOrigin = null // Valid values: 'top', 'center', 'bottom' (default)
	// Behavior
	export let multiSelect = false // Only usefull when 'onClick.setUrl.search' and 'select.url.search' are set
	export let unselect = false // When true, clicking the button when it is selected unselects it. Only usefull when 'onClick.setUrl.search' and 'select.url.search' are set.
	export let disabled = false
	export let rippleOn = false
	export let pressedCondition = null // Object that determines when the button is in a 'pressed' state. Examples: 
	// { default:true, onClick: true } -> Selected/Unselected when the button is clicked. Default is selected.
	// { url: { search:{ name:'fred' } } } -> Selected when the URL contains a search query parametes name=fred

	/* 
	=======================================================
	END - COMPONENT PROPERTIES
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT EVENTS
	=======================================================
	*/

	const dispatch = createEventDispatcher()
	createBaseEvents(dispatch)
	const emitEvent = eventName => (data,fn) => { 
		if (fn)
			fn()
		dispatch(eventName, data||{})
	}
	
	/**
	 * on:click
	 *
	 * @param  {String}		data.id
	 * @param  {String}		data.name
	 * 
	 * @return {Void}
	 */
	const emitClick = emitEvent('click')

	/* 
	=======================================================
	END - COMPONENT EVENTS
	=======================================================
	*/









	/* 
	=======================================================
	START - REACTIVE STATE PROPS
	=======================================================
	*/

	let hovering = false,
		pressed = false,
		urlMatches = false,
		rippleClickEvent = null,
		node = null,
		clickEventContext = { clickEventSeqIndex: 0 }, // Only used when the 'emitEventSeq' property is set in the 'press' event.
		updatePressedState,
		customEventUnsubscribers = null,
		customEvents = {}


	/* 
	=======================================================
	END - REACTIVE STATE PROPS
	=======================================================
	*/









	/* 
	=======================================================
	START - PRIVATE FUNCTIONS
	=======================================================
	*/

	const zeroOneParse = rangeParse()

	/**
	 * Registers the 'updatePressedState' event handler to URL changes.  
	 * 
	 * @param  {String} pressedCondition.url.protocol			'https:'
	 * @param  {String} pressedCondition.url.hostname			'fonts.gstatic.com'
	 * @param  {String} pressedCondition.url.port				''
	 * @param  {String} pressedCondition.url.pathname			'/s/materialicons/v70/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2'
	 * @param  {String} pressedCondition.url.search				''
	 * @param  {String} pressedCondition.url.hash				''
	 * 
	 * @return {Void}
	 */
	const registerPressedCondition = pressedCondition => {
		if (!WIN || !pressedCondition || !pressedCondition.url)
			return 

		deregisterPressedCondition()
		const keyValuePairs = Object.entries(pressedCondition.url)
		const searchKeyValuePairs = pressedCondition.url.search && typeof(pressedCondition.url.search) == 'object' 
			? Object.entries(pressedCondition.url.search) 
			: null

		updatePressedState = (data) => {
			const url = data && data.detail && data.detail.url ? data.detail.url : WIN.location
			urlMatches = true
			for (let [k,v] of keyValuePairs)
				if (k == 'search' && searchKeyValuePairs) {
					const searchParams = new WIN.URLSearchParams(url.search)
					for (let [k2,refValue] of searchKeyValuePairs) {
						const currentValue = searchParams.get(k2)
						let matches = currentValue == refValue
						if (multiSelect)
							matches = currentValue && currentValue.split(',').includes(refValue)

						if (!matches) {
							urlMatches = false
							break
						}
					}
					if (!urlMatches)
						break
				} else if (k == 'hash') {
					const hashVal = (url[k]||'').replace('#','')
					if (hashVal != (v||'').replace('#','')) {
						urlMatches = false
						break						
					}
				} else if (url[k] != v) {
					urlMatches = false
					break
				}
			
			if (pressed != urlMatches)
				pressed = urlMatches
		}

		onUrlChange(updatePressedState)
	}

	const deregisterPressedCondition = () => {
		if (updatePressedState)
			cleanUrlChange(updatePressedState)
	}

	const setRippleClickEvent = e => {
		if (!rippleEnabled)
			return

		if (e && e.clientX !== undefined && e.clientY !== undefined)
			rippleClickEvent = e 
		else if (node) {
			const { x, y, width, height } = node.getBoundingClientRect() || {}
			rippleClickEvent = { clientX: x+~~(width/2), clientY:y+~~(height/2) }
		}
	}

	const initIcon = (icon, fontWeight) => {
		if (!icon)
			return null 

		const copyIcon = {...icon}
		if (!copyIcon.color)
			copyIcon.color = 'var(--lu-btn-font-color)'
		if (!exists(copyIcon.bold) && (fontWeight == '500' || fontWeight == 'bold'))
			copyIcon.bold = true
		if (!copyIcon.size)
			copyIcon.size = 'var(--lu-btn-font-size)'

		return copyIcon
	}

	const getState = (originalState, customEvents, hovering, onHoverConfig, pressed, onPressedConfig) => {
		let newState = {...originalState}

		const applyHoverConfig = hovering && onHoverConfig
		const applyPressedConfig = pressed && onPressedConfig
		const applyCustomEventConfig = customEvents && customEvents.config

		if (applyHoverConfig || applyPressedConfig || applyCustomEventConfig) {
			if (applyHoverConfig)
				newState = deepMergeObj(newState, onHoverConfig)
			if (applyPressedConfig)
				newState = deepMergeObj(newState, onPressedConfig)
			if (applyCustomEventConfig)
				newState = deepMergeObj(newState, customEvents.config)
		}

		newState.leadingIcon = initIcon(newState.leadingIcon, newState.fontWeight)
		newState.trailingIcon = initIcon(newState.trailingIcon, newState.fontWeight)
		
		return newState
	}

	const getHtmlContent = (html, customEvents, hovering, onHoverConfig, pressed, onPressedConfig) => {
		const applyHoverConfig = hovering && onHoverConfig && exists(onHoverConfig.html)
		const applyPressedConfig = pressed && onPressedConfig && exists(onPressedConfig.html)
		const applyCustomEventConfig = customEvents && customEvents.config && exists(customEvents.config.html)

		if (applyHoverConfig)
			return onHoverConfig.html
		else if (applyPressedConfig)
			return onPressedConfig.html
		else if (applyCustomEventConfig)
			return customEvents.config.html
		else 
			return html
	}

	const getWillChange = transition => {
		if (!transition)
			return 'auto'

		let willChange = ''
		if (transition.width)
			willChange += 'width'
		if (transition.height)
			willChange += (willChange ? ', height' : 'height')

		return willChange||'auto'
	}

	const getRootTransition = (transition, isGradientColor) => {
		const transDetected = transition && (
			transition.elevation
			|| transition.elevation
			|| transition.width
			|| transition.height
			|| transition.color
			|| transition.borderRadius
			|| transition.border)
		if (transDetected) {
			const transProps = {}
			if (transition.elevation)
				transProps['box-shadow'] = getTransitionCss(transition.elevation)
			if (transition.width)
				transProps.width = getTransitionCss(transition.width)
			if (transition.height)
				transProps.height = getTransitionCss(transition.height)
			if (transition.borderRadius)
				transProps['border-radius'] = getTransitionCss(transition.borderRadius)
			if (transition.border)
				transProps.border = getTransitionCss(transition.border)
			if (transition.color)
				transProps[isGradientColor ? 'background-image' : 'background-color'] = getTransitionCss(transition.color)

			return trans.css(transProps, true, false, true)
		} else 
			return NO_TRANSITION
	}

	const getOverlayTransition = (transition, transformOn) => {
		const t = transition||{}
		const applyColor = t.overlayColor
		const applyOpacity = t.overlayOpacity
		const applyTransform = t.overlayScale

		if (applyColor || applyOpacity || applyTransform) {
			const transProps = {}
			if (applyOpacity)
				transProps.opacity = getTransitionCss(applyOpacity)
			if (applyColor)
				transProps['background-color'] = getTransitionCss(applyColor)
			if (transformOn)
				transProps.transform = getTransitionCss(applyTransform)

			return trans.css(transProps, true, false, true)
		} else 
			return NO_TRANSITION
	}

	const getUnderlineTransition = (transition, transformOn) => {
		const t = transition||{}
		const applyOpacity = t.underlineOpacity
		const applyTransform = t.underlineScale

		if (applyOpacity || applyTransform) {
			const transProps = {}
			if (applyOpacity)
				transProps.opacity = getTransitionCss(applyOpacity)
			if (transformOn)
				transProps.transform = getTransitionCss(applyTransform)

			return trans.css(transProps, true, false, true)
		} else 
			return NO_TRANSITION
	}

	const getContentTransition = transition => {
		const t = transition||{}
		const applyOpacity = t.contentOpacity

		if (applyOpacity) {
			const transProps = {}
			if (applyOpacity)
				transProps.opacity = getTransitionCss(applyOpacity)

			return trans.css(transProps, true, false, true)
		} else 
			return NO_TRANSITION
	}

	const unsubscribeAllCustomEvents = () => {
		if (!customEventUnsubscribers)
			return 

		const l = customEventUnsubscribers.length
		if (!l)
			return 

		while (customEventUnsubscribers[0] !== undefined) {
			const u = customEventUnsubscribers.pop()
			u()
		}
	}

	const subscribeToCustomEvents = on => {
		if (!on )
			return
		
		unsubscribeAllCustomEvents()
		customEventUnsubscribers = []

		const l = on.length
		for (let i=0;i<l;i++){
			const handler = on[i]
			if (handler.event == 'hover' || handler.event == 'press' || !handler.config)
				continue

			customEventUnsubscribers.push(listenTo(handler.event, () => {
				customEvents = {
					name: handler.event,
					config: handler.config
				}
			}))
		}
	}

	const isColorGradient = color => color && Array.isArray(color) && color.length > 1

	const getRootVars = ({ width, height, padding, margin, color, colorOrientation, fontColor, fontSize, fontColorOrientation, fontWeight, fontFamily, fontLetterSpacing, fontTransformText, border, borderColor, borderRadius, overlayColor, overlayOpacity, overlayScale, elevation, elevationStrength, elevationColor, elevationOffset, transition, contentDirection, contentGap, contentPadding, contentJustify, contentOpacity, underlineColor, underlineWidth, underlineHeight, underlineOffset, underlineRounded, underlinePosOrigin, underlineOpacity, underlineScale, underlineScaleOrigin }) => {
		
		const _gradientColorOn = isColorGradient(color) 
		const _gradientFontColorOn = isColorGradient(fontColor) 
		const _gradientUnderlineOn = isColorGradient(underlineColor)
		const _overlayTransformOn = exists(overlayScale)
		const _underlineTransformOn = exists(underlineScale)
		const [_color, _backgroundImage] = _gradientColorOn
			? ['transparent', getGradientColor({ orientation:colorOrientation||0 })(...color.map(c => chooseColor(c||'transparent')))] 
			: [chooseColor(color||'transparent'), 'none']
		const [_fontColor, _fontBackgroundImage] = _gradientFontColorOn
			? ['transparent', getGradientColor({ orientation:fontColorOrientation||0 })(...fontColor.map(c => chooseColor(c||'transparent')))] 
			: [chooseColor(fontColor||VAR_FONT_COLOR), 'none']
		const [_underlineColor, _underlineBackgroundImage] = _gradientUnderlineOn
			? ['transparent', getGradientColor({ orientation:90 })(...underlineColor.map(c => chooseColor(c||'transparent')))] 
			: [chooseColor(underlineColor), 'none']
		const _shadow = getBoxShadow({
			elevation: elevation||0, 
			strength: elevationStrength, 
			color: elevationColor, 
			offset: elevationOffset,
			defaultValue: 'none'
		})
		const _underlineTop = underlinePosOrigin === 'top'
		const { x:uOffsetX, y:uOffsetY } = underlineOffset || {}

		return {
			contentClass: _gradientFontColorOn ? 'lu-btn-text-gradient' : '',
			vars: css(`
			--lu-btn-width: ${width ? addUnit(width) : 'auto'};
			--lu-btn-height: ${height ? addUnit(height) : 'auto'};
			--lu-btn-padding: ${padding ? paddingParse(padding).css : '0px'};
			--lu-btn-margin: ${margin ? paddingParse(margin).css : '0px'};
			--lu-btn-color: ${_color};
			--lu-btn-background-image: ${_backgroundImage};
			--lu-btn-font-color: ${_fontColor};
			--lu-btn-font-background-image: ${_fontBackgroundImage};
			--lu-btn-font-family: ${fontFamily||VAR_FONT_FAMILY};
			--lu-btn-font-weight: ${fontWeight||'normal'};
			--lu-btn-font-size: ${fontSize ? addUnit(fontSize) : `calc(0.875*${VAR_FONT_SIZE})`};
			--lu-btn-font-letter-spacing: ${exists(fontLetterSpacing) ? addUnit(fontLetterSpacing) : VAR_FONT_LETTER_SPACING};
			--lu-btn-border-radius: ${exists(borderRadius) ? cornerParse(borderRadius).css : VAR_BASE_BORDER_RADIUS};
			--lu-btn-overlay-color: ${overlayColor ? chooseColor(overlayColor) : 'transparent'};
			--lu-btn-overlay-opacity: ${exists(overlayOpacity) ? zeroOneParse(overlayOpacity) : 0};
			--lu-btn-overlay-transform: ${_overlayTransformOn ? `scale(${zeroOneParse(overlayScale)})` : 'none'};
			--lu-btn-box-shadow: ${_shadow};
			--lu-btn-border: ${border ? `${border}px solid ${chooseColor(borderColor||'primary')}` : 0};
			--lu-btn-text-transform: ${fontTransformText||'none'};
			--lu-btn-content-direction: ${contentDirection||'row'};
			--lu-btn-content-gap: ${contentGap ? addUnit(contentGap) : 0};
			--lu-btn-content-justify: ${alignParse(contentJustify||'center').justifyContent};
			--lu-btn-content-padding: ${contentPadding ? paddingParse(contentPadding).css : '0px'};
			--lu-btn-content-opacity: ${exists(contentOpacity) ? zeroOneParse(contentOpacity) : 1};
			--lu-btn-ul-y: ${exists(uOffsetY) ? addUnit(uOffsetY) : '0px'};
			--lu-btn-ul-x: ${exists(uOffsetX) ? addUnit(uOffsetX) : '0px'};
			--lu-btn-ul-bottom: ${_underlineTop ? 'unset' : 'var(--lu-btn-ul-y)'};
			--lu-btn-ul-top: ${_underlineTop ? 'var(--lu-btn-ul-y)' : 'unset'};
			--lu-btn-ul-width: ${underlineWidth ? addUnit(underlineWidth) : '100%'};
			--lu-btn-ul-height: ${underlineHeight ? addUnit(underlineHeight) : '2px' };
			--lu-btn-ul-color: ${_underlineColor};
			--lu-btn-ul-background-image: ${_underlineBackgroundImage};
			--lu-btn-ul-border-radius: ${underlineRounded ? '40px' : '0px'};
			--lu-btn-ul-opacity: ${exists(underlineOpacity) ? zeroOneParse(underlineOpacity) : 1};
			--lu-btn-ul-transform: ${_underlineTransformOn ? `scaleX(${zeroOneParse(underlineScale)})` : 'none'};
			--lu-btn-ul-transform-origin: ${exists(underlineScaleOrigin) ? underlineScaleOrigin : 'center'};
			--lu-btn-root-transition: ${getRootTransition(transition||{}, _gradientColorOn)};
			--lu-btn-overlay-transition: ${getOverlayTransition(transition||{}, _overlayTransformOn)};
			--lu-btn-ul-transition: ${getUnderlineTransition(transition||{}, _underlineTransformOn)};
			--lu-btn-content-transition: ${getContentTransition(transition||{})};
			--lu-btn-will-change: ${getWillChange(transition)};
			`)
		}
	}

	/* 
	=======================================================
	END - PRIVATE FUNCTIONS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT HANDLERS
	=======================================================
	*/

	const onHover = () => {
		hovering = true
	}

	const onLeave = () => {
		hovering = false
	}

	const onMouseDown = e => {
		if (alternatePressedAfterEachPress) 
			pressed = !pressed	
		else 
			pressed = true
		
		processPressHandler(onPressedHandler, clickEventContext)
		// if (onPressedHandler) {
		// 	if (onPressedHandler.emitEvent)
		// 		emitCustomEvent(onPressedHandler.emitEvent,{})
		// 	if (onPressedHandler.emitEventSeq && onPressedHandler.emitEventSeq[0]) {
		// 		let eventName = onPressedHandler.emitEventSeq[clickEventSeqIndex++]
		// 		if (eventName === undefined) {
		// 			clickEventSeqIndex = 0
		// 			eventName = onPressedHandler.emitEventSeq[clickEventSeqIndex++]
		// 		}

		// 		emitCustomEvent(eventName,{})
		// 	}

		// 	if (onPressedHandler.setUrl)
		// 		updateUrl(onPressedHandler.setUrl, { multiSelect, unselect, smoothScroll:onPressedHandler.setUrl.smoothScroll })
		// }

		setRippleClickEvent(e)
		emitClick({ id, name })
	}

	const onMouseUp = () => { 
		if (!alternatePressedAfterEachPress && !urlMatches)
			pressed = false
	}

	onDestroy(() => {
		deregisterPressedCondition()
		unsubscribeAllCustomEvents()
	})

	/* 
	=======================================================
	END - EVENT HANDLERS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT LIFECYCLE
	=======================================================
	*/

	$: {
		clickEventContext.multiSelect = multiSelect
		clickEventContext.unselect = unselect
	}
	$: alternatePressedAfterEachPress = pressedCondition && pressedCondition.onClick
	$: rippleEnabled = !disabled && rippleOn
	$: onHoverConfig = on && !isMobile() ? (on.find(e => e.event == 'hover')||{}).config : null
	$: onPressedHandler = on ? on.find(e => e.event == 'press') : null 
	$: onPressedConfig = onPressedHandler ? onPressedHandler.config : null
	$: { 
		if (pressedCondition && WIN) {
			if (exists(pressedCondition.default))
				pressed = pressedCondition.default
			registerPressedCondition(pressedCondition) 
			if (updatePressedState)
				updatePressedState()
		}
	}
	$: state = getState({ 
		width, 
		height, 
		padding, 
		margin, 
		color, 
		colorOrientation, 
		fontColor,
		fontSize,
		fontColorOrientation, 
		fontWeight, 
		fontFamily, 
		fontLetterSpacing, 
		fontTransformText, 
		border, 
		borderColor, 
		borderRadius,
		overlayColor,
		overlayOpacity,
		overlayScale,
		elevation, 
		elevationStrength, 
		elevationColor,
		elevationOffset,
		transition,
		contentDirection,
		contentGap,
		leadingIcon,
		trailingIcon,
		contentPadding, 
		contentJustify,
		contentOpacity,
		underlineWidth,
		underlineHeight,
		underlineColor,
		underlineRounded,
		underlinePosOrigin,
		underlineOffset,
		underlineOpacity,
		underlineScale,
		underlineScaleOrigin
	}, customEvents, hovering, onHoverConfig, pressed, onPressedConfig)
	$: rootVars = getRootVars(state)
	$: htmlContent = getHtmlContent(html, customEvents, hovering, onHoverConfig, pressed, onPressedConfig)
	$: { subscribeToCustomEvents(on) }

	/* 
	=======================================================
	END - EVENT LIFECYCLE
	=======================================================
	*/
</script>

<div 
	{id}
	data-type="button"
	data-name="{name}"
	tabindex="{disabled ? '-1' : '0'}"
	class="{rootVars.vars} {rootClass}"
	on:mousedown={onMouseDown}
	on:mouseup={onMouseUp}
	on:mouseover={onHoverConfig ? onHover : undefined}
	on:mouseleave={onHoverConfig ? onLeave : undefined}
	bind:this={node}>
	{@html htmlContent}
	<div class="lu-btn-overlay"></div>
	{#if underlineOn}
	<div class="lu-btn-underline"></div>
	{/if}
	<div class="lu-btn-content {rootVars.contentClass}" style="justify-content: var(--lu-btn-content-justify);">
		{#if state.leadingIcon}
		<Icon {...state.leadingIcon}></Icon>
		{/if}
		{#if htmlContent}
		<span>{@html htmlContent}</span>
		{/if}
		{#if state.trailingIcon}
		<Icon {...state.trailingIcon}></Icon>
		{/if}
	</div>
	{#if rippleEnabled}
	<Ripple 
		color={chooseColor(rippleColor)}
		opacity={rippleOpacity}
		clickEvent={rippleClickEvent}>
	</Ripple>
	{/if}
</div>
