<script>
	/* 
	=======================================================
	START - DEPENDENCIES
	=======================================================
	*/

	import { getId } from '../../../core/index'
	import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
	import { addUnit, paddingParse } from '../../../core/parse'
	import { chooseColor } from '../../../core/color'
	import { transform, transition } from '../../../core/css'
	import { VAR_FONT_SIZE, VAR_FONT_COLOR } from '../../../core/theme'
	import '../../../core/icons/material'
	import bootstrapIcons from '../../../core/icons/bootstrap'
	import { SELECT_ICON_SIZE_TO_FONT_SIZE_RATIO, DEFAULT_TRANSITION_CURVE, DEFAULT_ANIMATION_DURATION, rootClass } from './style'

	/* 
	=======================================================
	END - DEPENDENCIES
	=======================================================
	*/









	/* 
	=======================================================
	START - CONSTANTS
	=======================================================
	*/

	const ID = getId() // e.g., '_cwd23d'
	const NO_TRANSITION = 'all 0s ease 0s'

	/* 
	=======================================================
	END - CONSTANTS
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT PROPERTIES
	=======================================================
	*/

	// Content
	export let icon 
	// Style
	export let type = null // Valid values: 'filled', 'outlined', 'round', 'sharp', 'two-tone'
	export let size = null
	export let bold = false
	export let padding = 0
	export let color = null
	// Behavior
	export let transitionDuration = 200
	export let transitionCurve = null
	export let transitionOn = false
	export let transformStart = null // e.g., 'rotate(90deg)'
	export let transformEnd = null // e.g., 'rotate(180deg)'
	export let parentTransitionStartClass = null // this class that is set in a parent dom wrapping this component starts the transition
	export let animationOn = null
	export let animationDuration = null 
	export let animation = null // e.g., 'spin'

	/* 
	=======================================================
	END - COMPONENT PROPERTIES
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT EVENTS
	=======================================================
	*/


	/* 
	=======================================================
	END - COMPONENT EVENTS
	=======================================================
	*/









	/* 
	=======================================================
	START - REACTIVE STATE PROPS
	=======================================================
	*/

	/* 
	=======================================================
	END - REACTIVE STATE PROPS
	=======================================================
	*/









	/* 
	=======================================================
	START - PRIVATE FUNCTIONS
	=======================================================
	*/

	const getRootVars = ({ size, bold, transitionDuration, transitionCurve, transformStart, transformEnd, transitionOn, padding, color, animationDuration }) => {
		
		const _transitionOn = transitionOn && (transformStart || transformEnd)
		const _transition = `${transitionDuration}ms ${transitionCurve || DEFAULT_TRANSITION_CURVE}`

		return css(`
		--lu-icon-font-size: ${size ? addUnit(size) : VAR_FONT_SIZE};
		--lu-icon-size: calc(${SELECT_ICON_SIZE_TO_FONT_SIZE_RATIO} * var(--lu-icon-font-size));
		--lu-icon-padding: ${paddingParse(padding).css};
		--lu-icon-color: ${color ? chooseColor(color) : VAR_FONT_COLOR};
		--lu-icon-font-weight: ${bold ? 'bold' : 'normal'};
		--lu-icon-transform-start: ${transformStart||'none'};
		--lu-icon-transition: ${_transitionOn ? transition.css({ transform: _transition }, true, false, true) : NO_TRANSITION};
		--lu-icon-animation: ${animationDuration||DEFAULT_ANIMATION_DURATION}ms;
		`)
	}

	const getTransformEndClass = (parentTransitionStartClass, transformEnd) => {
		if (!parentTransitionStartClass || !transformEnd)
			return '' 
		else 
			return css(`
			.${parentTransitionStartClass} & i {
				${transform.css(transformEnd)}
			}
			`)
	}


	/* 
	=======================================================
	END - PRIVATE FUNCTIONS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT HANDLERS
	=======================================================
	*/

	

	/* 
	=======================================================
	END - EVENT HANDLERS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT LIFECYCLE
	=======================================================
	*/

	$: rootVars = getRootVars({ size, bold, transitionDuration, transitionCurve, transformStart, transformEnd, parentTransitionStartClass, transitionOn, padding, color, animationDuration })
	$: transformEndClass = getTransformEndClass(parentTransitionStartClass, transformEnd)
	$: isBootstrap = icon && icon.indexOf('bi-') == 0
	$: ligature = isBootstrap ? '' : icon
	$: iconClass = isBootstrap 
		? icon 
		: `material-icons${!type || type == 'filled' ? '' : `-${type}`}`
	$: animationClass = animationOn && animation ? ` ${animation}-animation` : ''
	$: {
		if (isBootstrap)
			bootstrapIcons.load()
	}

	/* 
	=======================================================
	END - EVENT LIFECYCLE
	=======================================================
	*/
</script>

<div 
	id={ID} 
	data-type="icon" 
	class="{rootVars} {rootClass} {transformEndClass}"
	>
	<i class="{iconClass}{animationClass}">{ligature}</i>
</div>



