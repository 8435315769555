import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
import { transform } from '../../../core/css'

export const rootClass = css(`
position: relative;
display: flex;
flex-direction: column;

/* START - BASE */
width: var(--lu-width);
max-width: var(--lu-width-max);
min-width: var(--lu-width-min);
height: var(--lu-height);
max-height: var(--lu-height-max);
min-height: var(--lu-height-min);
padding: var(--lu-padding);
margin: var(--lu-margin);
background-color: var(--lu-background-color);
border: var(--lu-border);
border-radius: var(--lu-border-radius);
border-color: var(--lu-border-color);
box-shadow: var(--lu-box-shadow);
/* END - BASE */

cursor: var(--lu-cursor);
transition: var(--lu-hover-trans);

&.lu-component-hover:hover {
	${transform.css('var(--lu-hover-transform)')}
	box-shadow: var(--lu-hover-box-shadow);
}

& .lu-component-preview {
	position: absolute;
	background-color: #0095cc;
	opacity: 0.15;
}
`)
