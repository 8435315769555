// _
// animation
// backgroundClip
// border
// boxShadow
// boxSizing
// fontCss
// getGradientColor
// getTransitionCss
// gradient
// gradientText
// noUserSelectCss
// parseTextAlign
// setVariables
// sizeCssVariables
// textFillColor
// transform
// transition

import { getUrlParts, addToHead, addCssVariablesToBody, removeCssVariablesFromBody } from './dom'
import { injectGlobal } from '../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
import { VAR_FONT_FAMILY,VAR_FONT_SIZE,VAR_FONT_COLOR,VAR_PRIMARY_COLOR,VAR_FONT_LETTER_SPACING,VAR_FONT_LINE_HEIGHT,VAR_FONT_STYLE } from './theme'

const TRANSFORM_PROPERTIES = ['transform', '-moz-transform', '-webkit-transform']
const BOX_SHADOW_PROPERTIES = ['box-shadow','-moz-box-shadow','-webkit-box-shadow','-ms-box-shadow','-o-box-shadow']
const BOX_SIZING_PROPERTIES = ['box-sizing', '-moz-box-sizing', '-webkit-box-sizing']
const ANIMATION_PROPERTIES = ['animation', '-moz-animation', '-webkit-animation']
const BACKGROUND_CLIP_PROPERTIES = ['background-clip', '-moz-background-clip', '-webkit-background-clip']
const TEXT_FILL_COLOR_PROPERTIES = ['text-fill-color', '-moz-text-fill-color', '-webkit-text-fill-color']
const TRANSITION_PROPERTIES = ['transition', '-webkit-transition', '-moz-transition', '-o-transition']
const WEB_SAFE_FONTS = {
	'serif': ['Times New Roman', 'American Typewriter', 'Didot', 'Garamond', 'Georgia'],
	'sans-serif': ['Arial', 'Arial Black', 'Helvetica', 'Impact', 'Tahoma', 'Trebuchet MS', 'Verdana'],
	'monospace': ['Courier', 'Andalé Mono', 'Courier New', 'Monaco'],
	'cursive': ['Brush Script MT', 'Bradley Hand', 'Comic Sans MS'],
	'fantasy': ['Luminari']
}

const mergePropVariants = variants => (value, toggle=true) => value && toggle ? variants.map(x => `${x}:${value};`).join('') : ''
const mergeTransitionPropVariants = variants => transition => transition ? variants.map(x => `${x} ${transition}`).join(',') : ''

const getCssPropHelper = (variants, cssFn) => ({
	css: cssFn || mergePropVariants(variants),
	props: variants,
	transition: mergeTransitionPropVariants(variants)
})

const isColorLegit = (color, ...denyList) => color && color != 'primary' && color != 'secondary' && denyList.every(c => c != color)

const parseToPx = v => !v || isNaN(v*1) ? (v||'') : `${v}px`

const parseCssProperty = (prop, values, toggle=true) => {
	const v = values[0]
	if ((v === 0 || v) && toggle) {
		if (prop == 'border')
			return border.css({ thickness:v, type:values[1], color:values[2], boxSizing:values[3] }, values[2]) // boxSizing:border-box
		else if (prop == 'transform')
			return transform.css(v)
		else if (prop == 'box-sizing')
			return boxSizing.css(v)
		else if (prop == 'animation')
			return animation.css(v)
		else if (prop == 'background-clip')
			return backgroundClip.css(v)
		else if (prop == 'text-fill-color')
			return textFillColor.css(v)
		else
			return `${prop}:${v};` 
	} else 
		return ''
}

const getGradientColorDetails = (color, defaultColor) => {
	const _color = defaultColor || VAR_PRIMARY_COLOR
	if (!color)
		return { color:_color, end:'' }

	if (typeof(color) == 'string')
		return { color:color||_color, end:'' }
	else
		return { color:color.color||_color, end:color.end === null || color.end === undefined ? '' : `${color.end}%` }
}

const gradientTextCss = (config, toggle=true) => {
	const orientation = config && config.orientation ? config.orientation : 0
	if (toggle)
		return (...colors) => `
		${_('background-image', [`linear-gradient(${orientation}deg, ${colors})`])}
		${_('background-clip', ['text'])}
		${_('text-fill-color', ['transparent'])}`
	else
		return () => `
		${_('background-image', ['unset'])}
		${_('background-clip', ['unset'])}
		${_('text-fill-color', ['unset'])}`
}

export const textGradientCss = backgroundImageValue => `
display: block; /* For safari */
background-image: ${backgroundImageValue};
${backgroundClip.css('text')}
${textFillColor.css('transparent')}
`

/**
 * 
 * @param  {String} fontFamily			'Orelega One'
 * @param  {String} fontFamilySrc		'https://fonts.googleapis.com/css2?family=Orelega+One&display=swap'
 * @param  {String} fontWeight			'400'
 * @param  {String} fontFormat			''
 * 
 * @return {Void}
 */
const injectFontGlobally = (fontFamily, fontFamilySrc, fontWeight, fontFormat) => {
	if (!fontFamily || _loadedFonts[fontFamily])
		return 

	const urlParts = getUrlParts(fontFamilySrc)
	// If the 'fontFamilySrc' contains a file extension which is not CSS, then this is a explicit font.
	if (urlParts.ext && urlParts.ext != '.css') {
		fontFormat = fontFormat || urlParts.ext.replace('.','')
		injectGlobal(`
		@font-face {
			font-family: '${fontFamily}';
			font-display: block;
			font-style: normal;
			${fontWeight ? `font-weight: ${fontWeight};` : ''}
			src: url(${fontFamilySrc})${fontFormat ? ` format('${fontFormat}')` : ''};
		}`)
	} else { // Load CSS 
		const newHeadElements = []
		const googleFont = fontFamilySrc.indexOf('fonts.googleapis.com') >= 0
		if (googleFont && !_loadedFonts['google-font-preconnect']) {
			newHeadElements.push({ tag:'link', attributes:[['rel', 'preconnect'],['href', 'https://fonts.gstatic.com']] })
			_loadedFonts['google-font-preconnect'] = true
		}
		newHeadElements.push({ tag:'link', attributes:[['href', fontFamilySrc],['rel', 'stylesheet']] })

		addToHead(newHeadElements)
	}

	_loadedFonts[fontFamily] = true
}

let _loadedFonts = {}
const loadFontFace = (fontFamily, fontFamilySrc) => {
	if (!fontFamily || !fontFamilySrc)
		return 
	
	const firstFontFamily = ((fontFamily.match(/^(.*),/)||[])[1]||'').replace(/["']/g,'')
	if (!firstFontFamily)
		return 

	injectFontGlobally(firstFontFamily, fontFamilySrc)
}

/**
 * Adds the default web safe font to a font to guarantee safe fallback. 
 * 
 * @param  {String} fontFamily		e.g., 'charter, serif'
 * 
 * @return {String} safeFontFamily	e.g., '"charter", "Times New Roman", serif'
 */
const addWebSafeFont = fontFamily => {
	if (!fontFamily)
		return fontFamily
	
	const fonts = fontFamily.split(',').map(x => x.trim().replace(/["']/g,''))
	const l = fonts.length
	if (!l || l < 2)
		return fontFamily

	const genericFamily = fonts[l-1]
	if (!genericFamily)
		return fontFamily

	const webSafeFonts = WEB_SAFE_FONTS[genericFamily]
	if (!webSafeFonts)
		return fontFamily

	if (fonts.some(f => webSafeFonts.some(x => x == f)))
		return fontFamily

	const defaultWebSafeFont = webSafeFonts[0]
	let safeFontFamily = ''
	for (let i=0;i<l-1;i++)
		safeFontFamily += `"${fonts[i]}", `

	safeFontFamily += `"${defaultWebSafeFont}", ${genericFamily}`
	return safeFontFamily
}

export const transform = getCssPropHelper(TRANSFORM_PROPERTIES)
export const boxSizing = getCssPropHelper(BOX_SIZING_PROPERTIES)
export const boxShadow = getCssPropHelper(BOX_SHADOW_PROPERTIES)
export const animation = getCssPropHelper(ANIMATION_PROPERTIES)
export const backgroundClip = getCssPropHelper(BACKGROUND_CLIP_PROPERTIES)
export const textFillColor = getCssPropHelper(TEXT_FILL_COLOR_PROPERTIES)
const _transition = getCssPropHelper(TRANSITION_PROPERTIES)

/**
 * Creates the CSS 'transition' property:
 * 
 * @type {Object}
 * @ret {Object}
 */
export const transition = {
	/**
	 * Creates the CSS 'transition' property.
	 * 
	 * @param  {Object}  props		e.g., { 'transform': '200ms linear', opacity: '100ms cubic-bezier(0.4, 0, 0.2, 1)' }
	 * @param  {Boolean} toggle     
	 * @param  {Boolean} willChange 
	 * 
	 * @return {String}             e.g., 'transition: transform 200ms linear, -moz-transform 200ms linear, -webkit-transform 200ms linear, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1); will-change: transform,-moz-transform,-webkit-transform,opacity;'
	 */
	css: (props, toggle=true, willChange=false, valueOnly=false) => {
		
		if (!toggle)
			return ''

		if (typeof(props) == 'string') 
			return _transition.css(props)
		
		const trans = []
		const transitionNames = []
		for (let prop in (props || {})) {
			const v = props[prop]
			if (!v)
				continue
			if (prop == 'transform') {
				transitionNames.push(...transform.props)
				trans.push(transform.transition(props[prop]))
			} else if (prop == 'box-sizing') {
				transitionNames.push(...boxSizing.props)
				trans.push(boxSizing.transition(props[prop]))
			} else if (prop == 'background-clip') {
				transitionNames.push(...backgroundClip.props)
				trans.push(backgroundClip.transition(props[prop]))
			} else if (prop == 'text-fill-color') {
				transitionNames.push(...textFillColor.props)
				trans.push(textFillColor.transition(props[prop]))
			} else {
				transitionNames.push(prop)
				trans.push(`${prop} ${v}`)
			}
		}

		if (trans.length)
			return valueOnly 
				? willChange ? [`${trans}`, transitionNames.join(',')] : `${trans}`
				: `${_transition.css(`${trans}`)};${willChange ? `will-change: ${transitionNames};` : ''}`
		else 
			return ''
	}
}

export const border = {
	css: ({ thickness, type, color, boxSizing:bs }, toggle=true) => toggle && thickness
		? `border:${thickness}px ${type||'solid'} ${color||'black'};${bs ? boxSizing.css(bs) : ''}` 
		: ''
}

export const noUserSelectCss = `
-webkit-touch-callout: none; /* iOS Safari */
-webkit-user-select: none; /* Safari */
-khtml-user-select: none; /* Konqueror HTML */
-moz-user-select: none; /* Old versions of Firefox */
-ms-user-select: none; /* Internet Explorer/Edge */
user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
`

export const fontCss = (config, toggle=true) => {
	if (!toggle)
		return ''
	
	const { family, size, color, weight, style, letterSpacing, noUserSelect, transform, lineHeight } = config||{}
	
	return `
	font-family: ${family||VAR_FONT_FAMILY};
	font-size: ${size ? parseToPx(size) : VAR_FONT_SIZE};
	font-style: ${style||VAR_FONT_STYLE};
	line-height: ${lineHeight||VAR_FONT_LINE_HEIGHT};
	letter-spacing: ${letterSpacing ? parseToPx(letterSpacing) : VAR_FONT_LETTER_SPACING};
	color: ${color||VAR_FONT_COLOR};
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	${weight ? `font-weight: ${weight};` : ''}
	${transform ? `text-transform: ${transform};` : ''}
	${noUserSelect ? noUserSelectCss : ''}
	`
}

export const _ = (prop, values, toggle=true) => {
	if (typeof(prop) == 'object')
		return Object.keys(prop).reduce((acc,p) => acc+parseCssProperty(p, [prop[p]]), '')
	else 
		return parseCssProperty(prop, values, toggle)
}

export const setVariables = ({ body, primary, secondary, danger, onPrimary, onSecondary, fontSize, fontFamily, fontFamilySrc, fontColor, fontWeight, fontLetterSpacing, borderColor, borderRadius, disabledColor, disabledFontColor, lineHeight }) => {
	let cssVariables = body ? {} : ''
	const pushFn = body ? ((k,v) => cssVariables[k] = v) : ((k,v) => cssVariables += `${k}:${v};`)

	if (isColorLegit(primary, '--lu-color-primary'))
		pushFn('--lu-color-primary', primary)
	if (isColorLegit(secondary, '--lu-color-secondary'))
		pushFn('--lu-color-secondary', secondary)
	if (isColorLegit(danger, '--lu-color-danger'))
		pushFn('--lu-color-danger', danger)
	if (isColorLegit(onPrimary, '--lu-font-color-on-primary'))
		pushFn('--lu-font-color-on-primary', onPrimary)
	if (isColorLegit(onSecondary, '--lu-font-color-on-secondary'))
		pushFn('--lu-font-color-on-secondary', onSecondary)
	if (fontFamily) {
		pushFn('--lu-font-family', addWebSafeFont(fontFamily))
		if (fontFamilySrc)
			loadFontFace(fontFamily, fontFamilySrc)
	}
	if (fontSize)
		pushFn('--lu-font-size', addUnit(fontSize))
	if (isColorLegit(fontColor, '--lu-font-color'))
		pushFn('--lu-font-color', fontColor)
	if (fontWeight)
		pushFn('--lu-font-weight', fontWeight)
	if (lineHeight || lineHeight === 0)
		pushFn('--lu-font-line-height', addUnit(lineHeight))
	if (fontLetterSpacing || fontLetterSpacing === 0)
		pushFn('--lu-font-letter-spacing', addUnit(fontLetterSpacing))
	if (isColorLegit(borderColor, '--lu-border-color'))
		pushFn('--lu-border-color', borderColor)
	if (isColorLegit(disabledColor, '--lu-color-disabled'))
		pushFn('--lu-color-disabled', disabledColor)
	if (isColorLegit(disabledFontColor, '--lu-font-color-disabled'))
		pushFn('--lu-font-color-disabled', disabledFontColor)
	if (borderRadius !== null && borderRadius !== undefined)
		pushFn('--lu-base-border-radius', addUnit(borderRadius))

	if (body) {
		addCssVariablesToBody(cssVariables)
		return () => removeCssVariablesFromBody(cssVariables)
	}
	else
		return cssVariables
}

export const parseTextAlign = (textAlign, defaultValue) => {
	defaultValue = defaultValue || 'center'
	if (!textAlign)
		return defaultValue
	if (textAlign == 'center')
		return textAlign
	if (textAlign == 'flex-start' || textAlign == 'left')
		return 'flex-start'
	if (textAlign == 'flex-end' || textAlign == 'right')
		return 'flex-end'
	return defaultValue
}

const getGradientOrientation = o => o === null || o === undefined 
	? '0deg' 
	: isNaN(o*1) ? o : `${o*1}deg`

/**
 * Create a function that accepts one or many colors and returns the value for the 'background-image' CSS property. 
 * 
 * @param  {Number}  config.orientation		Gradient's orientation degrees (default 0)
 * @param  {Boolean} toggle
 * 				
 * @return {Function}	
 */
export const getGradientColor = (config, toggle=true) => {
	if (toggle)
		/**
		 * 
		 * @param  {[String]} colors	e.g., ['blue', 'green']
		 * 
		 * @return {String}				e.g., 'linear-gradient(0deg, blue 50%, green 50%)'
		 */
		return (...colors) => {
			const seq = colors.map(c => {
				const { color, end } = getGradientColorDetails(c)
				return `${color}${end? ` ${end}`:''}`
			}).join(', ')
			return `linear-gradient(${getGradientOrientation(config && config.orientation ? config.orientation : 0)}, ${seq})`
		}
	else
		return primary => `linear-gradient(to right, ${primary||VAR_PRIMARY_COLOR} 50%, ${primary||VAR_PRIMARY_COLOR} 50%)`
}

export const gradientText = getCssPropHelper(['background-image', ...BACKGROUND_CLIP_PROPERTIES, ...TEXT_FILL_COLOR_PROPERTIES], gradientTextCss)

const addUnit = v => isNaN(v*1) ? v : `${v}px`

/**
 * 
 * @param  {String}  cssVarName				e.g., '--lu-img-width'
 * @param  {Object}  values					e.g., 800, [500,800] or [500, '50%', 800] (1) 
 * @param  {Boolean} options.stringOutput	False, when true, the output is a CSS string.
 * 
 * @return {[String]}            			e.g., ['--lu-img-width:500px'] or ['--lu-img-width-max:500px']
 *
 * (1) The 'values' can be a single value, or an array with either 2 or 3 items.
 * 		- Single value: The CSS 'width' to that value.
 * 		- Array with 2 items: The CSS width is set to 'auto' while 'min-width' is set to the 1st item and 'max-width' is set to the 2nd.
 * 		- Array with 3 items: 'min-width' is set to the 1st item, 'width' is set to the 2nd and 'max-width' is set to the 3rd.
 */
export const sizeCssVariables = (cssVarName, values, options) => {
	const stringOutput = options && options.stringOutput
	const isArray = Array.isArray(values)
	const l = isArray ? values.length : 0
	let results
	if (l > 1) {
		const [minVal, width, maxVal] = l == 2 ? [values[0], 'auto', values[1]] : values
		const minV = minVal === null || minVal === undefined 
			? `${cssVarName}-min:auto`
			: `${cssVarName}-min:${addUnit(minVal)}`
		const maxV = maxVal === null || maxVal === undefined 
			? `${cssVarName}-max:auto`
			: `${cssVarName}-max:${addUnit(maxVal)}`
		const nominalV = width === null || width === undefined 
			? `${cssVarName}:auto`
			: `${cssVarName}:${addUnit(width)}`

		results = [minV, maxV, nominalV]
	} else if (values !== null && values !== undefined) {
		const _v = isArray ? values[0] : values 
		const v = `${cssVarName}:${isArray && (_v === null || _v == undefined) ? 'auto' : addUnit(_v)}`
		results = [`${cssVarName}-min:auto`, `${cssVarName}-max:auto`, v]
	}
	else 
		results = [`${cssVarName}-min:auto`, `${cssVarName}-max:auto`, `${cssVarName}:auto`]

	if (stringOutput) 
		return results.join(';')+';'
	else 
		return results
}

export const gradient = {
	css: (backgroundImage, clip='text', fillColor='transparent') => `
	display: block; /* For safari */
	background-image: ${backgroundImage};
	background-clip: ${clip};
	-moz-background-clip: ${clip};
	-webkit-background-clip: ${clip};
	text-fill-color: ${fillColor};
	-moz-text-fill-color: ${fillColor};
	-webkit-text-fill-color: ${fillColor};
	`
}

export const getTransitionCss = ({ duration, curve }) => `${duration||100}ms ${curve||'linear'}`








