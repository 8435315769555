import { writable } from 'svelte/store'
import { getId } from '../../../core/index'

const _globalStore = {}

const destroy = id => {
	if (id)
		_globalStore[id] = null
}

export const getStore = id => {
	// console.log({ id })
	return _globalStore[id]
}

export const createStore = () => {
	const storeId = getId()

	_globalStore[storeId] = {
		id: storeId,
		resizing: writable(false),
		dragging: writable(false),
		destroy	
	}

	return _globalStore[storeId]
}