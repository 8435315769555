// addToCardinal
// addUnit
// alignParse
// cornerParse
// getNumberDetails
// intParse
// opacityParse
// paddingParse
// transformOriginParse
// unitParse

/**
 * Converts a value to an cardinal object (e.g., { top: '0px', bottom: '0px', left: '0px', right: '0px' })
 * 
 * @param   {Number|Object} val				e.g., 10 or { top: 10, bottom: 10 }
 * @param   {Number} 		val.top			Default 0	
 * @param   {Number} 		val.bottom		Default 0	
 * @param   {Number} 		val.left		Default 0	
 * @param   {Number} 		val.right		Default 0	
 * 
 * @return  {Number} 		output.top		e.g., 10
 * @return  {Number} 		output.bottom	e.g., 15
 * @return  {Number} 		output.left		e.g., 5
 * @return  {Number} 		output.right	e.g., 0
 * @return  {String} 		output.css		e.g., '10px 0px 15px 5px'
 */
export const paddingParse = (val) => {
	val = val || 0
	if (typeof(val) != 'object') {
		const v = val
		return {
			top: v,
			bottom: v,
			left: v,
			right: v,
			css: cardinalToCss(v,v,v,v)
		}
	} else {
		const result = {
			top: 0,
			bottom: 0,
			left: 0,
			right: 0
		}

		if (val.top)
			result.top = val.top
		if (val.bottom)
			result.bottom = val.bottom
		if (val.left)
			result.left = val.left
		if (val.right)
			result.right = val.right

		result.css = cardinalToCss(result.top,result.right,result.bottom,result.left) 

		return result
	}
}

const _cornerObjectParse = ({ top, left, bottom, right }) => {
	const topObj = !top ? {} : typeof(top) == 'number' ? { left:top, right:top } : typeof(top) == 'object' ? { left:top.left||0, right:top.right||0 } : {}
	const bottomObj = !bottom ? {} : typeof(bottom) == 'number' ? { left:bottom, right:bottom } : typeof(bottom) == 'object' ? { left:bottom.left||0, right:bottom.right||0 } : {}
	const rightObj = !right ? {} : typeof(right) == 'number' ? { top:right, bottom:right } : typeof(right) == 'object' ? { top:right.top||0, bottom:right.bottom||0 } : {}
	const leftObj = !left ? {} : typeof(left) == 'number' ? { top:left, bottom:left } : typeof(left) == 'object' ? { top:left.top||0, bottom:left.bottom||0 } : {}

	const topLeft = topObj.left || leftObj.top || 0
	const topRight = topObj.right || rightObj.top || 0
	const bottomLeft = bottomObj.left || leftObj.bottom || 0
	const bottomRight = bottomObj.right || rightObj.bottom || 0

	return {
		topLeft,
		topRight,
		bottomLeft,
		bottomRight,
		css: cardinalToCss(topLeft, topRight, bottomRight, bottomLeft)
	}
}

const positiveNumber = n => n < 0 ? 0 : n

export const addToCardinal = (cardinal, value) => {
	let { topLeft, topRight, bottomLeft, bottomRight, top, bottom, left, right } = cardinal || {} 
	if (top === undefined) {
		topLeft = positiveNumber((topLeft||0)+value)
		topRight = positiveNumber((topRight||0)+value)
		bottomLeft = positiveNumber((bottomLeft||0)+value)
		bottomRight = positiveNumber((bottomRight||0)+value)
		return { topLeft, topRight, bottomLeft, bottomRight, css:cardinalToCss(topLeft, topRight, bottomRight, bottomLeft) }
	} else {
		top = positiveNumber((top||0)+value)
		bottom = positiveNumber((bottom||0)+value)
		left = positiveNumber((left||0)+value)
		right = positiveNumber((right||0)+value)
		return { top, bottom, left, right, css:cardinalToCss(top,right,bottom,left) }
	}
}

/**
 * 
 * @param  {Number} args[0]	top
 * @param  {Number} args[1]	right
 * @param  {Number} args[2]	bottom
 * @param  {Number} args[3]	left
 * 
 * @return {String} css		e.g., '0px, 10px, 10px, 5px'
 */
const cardinalToCss = (...args) => `${addUnit(args[0]||0)} ${addUnit(args[1]||0)} ${addUnit(args[2]||0)} ${addUnit(args[3]||0)}`

/**
 * Converts a corner value (usually used for border-radius) into a corner object. 
 * 
 * @param  {Number|Object}	val					e.g., 10 
 *                                 				or { top: { left: 10 }, bottom: { right: 10 } } 
 *                                 				or { left: { top:10 } }
 *                                 				or { top: 10 }, which is a short for { top: { right: 10, left: 10 } }
 *
 * @return {String|Object}	output				The output type depends on the 'options.css' value.
 * @return {Number}			output.topLeft		e.g., '10px'
 * @return {Number}			output.topRight		e.g., '10px'
 * @return {Number}			output.bottomLeft	e.g., '10px'
 * @return {Number}			output.bottomRight	e.g., '10px'
 * @return {Number}			output.css			e.g., '0px 10px 5px 3px'
 */
export const cornerParse = (val, options={}) => {
	val = val || 0
	const t = typeof(val)

	let parsedObj
	if (t == 'object') {
		if (val.topLeft !== undefined || val.topRight !== undefined || val.bottomLeft !== undefined || val.bottomRight !== undefined)
			parsedObj = {
				topLeft: val.topLeft||0,
				topRight: val.topRight||0,
				bottomLeft: val.bottomLeft||0,
				bottomRight: val.bottomRight||0,
				css: cardinalToCss(val.topLeft, val.topRight, val.bottomRight, val.bottomLeft)
			}
		else 
			parsedObj = _cornerObjectParse(val)
	} else 
		parsedObj = {
			topLeft: val, 
			topRight: val,
			bottomLeft: val,
			bottomRight: val,
			css: cardinalToCss(val, val, val, val)
		}

	if (options.css)
		return parsedObj.css
	else
		return parsedObj
}

/**
 * Converts a value to a string unit or a Object unit (options.object true)
 * 
 * @param  {Number|String}		value					e.g., 16 or '1rem'.
 * @param  {Boolean} 			options.unit			Default 'px'.
 * @param  {Number} 			options.defaultValue	
 * 
 * @return {String}         							e.g., '16px'
 */
export const unitParse = (value, options) => {
	const { unit='px', defaultValue } = options||{}
	if (value === null || value === undefined)
		return defaultValue || null

	return typeof(value) == 'number' ? `${value}${unit}` : value
}

export const addUnit = v => {
	if (v === null || v === undefined)
		return '0px'
	const _v = v*1
	return isNaN(_v) ? v : `${_v}px`
}

/**
 * 
 * @param  {String|Object}	align					e.g., 'top' or { horizontal:'left', vertical:'center' }
 * @param  {String|Object}	defaultValue.horizontal	Default 'center'
 * @param  {String|Object}	defaultValue.vertical	Default 'center'
 * 
 * @return {String}			output.horizontal		Valid values: 'left', 'center', 'right'
 * @return {String}			output.vertical			Valid values: 'top', 'center', 'bottom'
 * @return {String}			output.alignItems		Valid values: 'flex-start', 'center', 'flex-end'
 * @return {String}			output.justifyContent	Valid values: 'flex-start', 'center', 'flex-end'
 */
export const alignParse = (align, defaultValue) => {
	align = align || {}
	const t = typeof(align)

	const { horizontal:defaultHorizontal='center', vertical:defaultVertical='center' } = defaultValue || {}

	let horizontal = defaultHorizontal, vertical = defaultVertical

	if (t == 'string') {
		if (align == 'top') {
			horizontal = 'center' 
			vertical = 'top'
		}
		else if (align == 'center') {
			horizontal = 'center' 
			vertical = 'center'
		}
		else if (align == 'bottom') {
			horizontal = 'center' 
			vertical = 'bottom'
		}
		else if (align == 'left') {
			horizontal = 'left' 
			vertical = 'center'
		}
		else if (align == 'right') {
			horizontal = 'right' 
			vertical = 'center'
		}
		else {
			horizontal = defaultHorizontal 
			vertical = defaultVertical
		}
	} else if (t == 'object') {
		horizontal = !align.horizontal || (align.horizontal != 'left' && align.horizontal != 'center' && align.horizontal != 'right')
			? defaultHorizontal
			: align.horizontal,
		vertical =  !align.vertical || (align.vertical != 'top' && align.vertical != 'center' && align.vertical != 'bottom')
			? defaultVertical
			: align.vertical
	}

	return {
		horizontal,
		vertical,
		alignItems: vertical == 'center' ? 'center' : vertical == 'top' ? 'flex-start' : 'flex-end',
		justifyContent: horizontal == 'center' ? 'center' : horizontal == 'left' ? 'flex-start' : 'flex-end'
	}
}

export const intParse = (nbr, defaultValue=0) => {
	if (nbr === null || nbr === undefined) 
		return defaultValue

	if (typeof(nbr) == 'number')
		return nbr 
	const v = nbr * 1
	if (!isNaN(v))
		return v 
	else
		return defaultValue
}

/**
 * Breaks down a string number in its value and its unit. 
 * 
 * @param  {String}  nbr					e.g., '12px' or '12%'
 * @param  {Boolean} options.decimalPerc	Default false. When true, percentage are converted to decimal
 * 
 * @return {Number}  value					e.g., 12
 * @return {String}  unit					e.g., 'px' or '%'
 */
export const getNumberDetails = (nbr, options) => {
	const _v = nbr*1
	if (!isNaN(_v))
		return { value:_v, unit:null } 

	let [value=null, unit=null] = (`${nbr}`.match(/^([0-9]+?)([^0-9]+?)$/)||[])[1,2]
	value = value ? value*1 : 0
	if (options && options.decimalPerc && unit == '%')
		value = value/100
	
	return { value, unit }
}

/**
 * Creates range function. 
 * 
 * @param  {[Number]}	range			Default: [0,1]
 * @param  {Number}		defaultValue	Default: 1
 * 
 * @return {Function}	
 */
export const rangeParse = (range, defaultValue) => {
	const [start=0,end=1] = range || []
	if (defaultValue === undefined)
		defaultValue = end

	return o => {
		if (o === null || o === undefined) 
			return defaultValue
		else {
			const _o = o*1
			if (isNaN(_o))
				return defaultValue
			else 
				return _o < start ? start : _o > end ? end : _o
		}
	}
}

export const opacityParse = rangeParse()

export const transformOriginParse = value => {
	if (!value)
		return '50% 50% 0'
	if (Array.isArray(value))
		return value.join(' ')
	else 
		return value
}



