import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
import { transition, transform } from '../../../core/css'

// This transparent image is used as a hack to prevent empty media to be wrapped with a border. This is default
// browser behavior that cannot be styled (ref: https://stackoverflow.com/questions/10441362/how-can-i-remove-the-border-around-an-image-without-a-source). 
export const TRANSPARENT_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='

export const rootClass = css(`
position: relative;
box-shadow: var(--lu-media-box-shadow);
border: var(--lu-media-border) solid var(--lu-media-border-color);
border-radius: var(--lu-media-border-radius);
margin: var(--lu-media-margin);
padding: var(--lu-media-padding);
width: var(--lu-media-width);
height: var(--lu-media-height);
background-color: var(--lu-media-img-bgd-color);
background-image: var(--lu-media-img-bgd-gradient);
cursor: var(--lu-media-img-cursor, default);
${transition.css('var(--lu-media-root-transition)')};

& figure {
	width: 100%;
	height: 100%;
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0;
	margin-inline-end: 0;

	& .lu-media-container {
		position: relative;
		overflow: hidden;
		width: calc(100% - 2*var(--lu-media-frame));
		height: calc(100% - 2*var(--lu-media-frame));
		margin: var(--lu-media-frame);
		display: flex;
		border-radius: var(--lu-media-inner-border-radius);
		align-items: center;
		justify-content: center;
	}

	& .lu-media-aspect-ratio {
		float:left;
		padding-top:var(--lu-media-img-aspect-ratio);
	}

	& picture,
	& img {
		position: var(--lu-media-img-position);
		width: var(--lu-media-img-size);
		height: var(--lu-media-img-size);
		object-fit: var(--lu-media-img-object-fit, cover);
		object-position: var(--lu-media-img-rel-position);
		${transform.css('var(--lu-media-img-scale)')}
		${transition.css('var(--lu-media-img-transition)')};
	}

	& figcaption {
		font-family: var(--lu-media-font-family);
		font-size: var(--lu-media-font-size);
		font-style: var(--lu-media-font-style);
		line-height: var(--lu-media-font-line-height);
		letter-spacing: var(--lu-media-font-letter-spacing);
		color: var(--lu-media-font-color);
		box-sizing: border-box;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		position: var(--lu-media-caption-pos, static);
		inset: var(--lu-media-caption-inset);
		text-align: var(--lu-media-text-align);
		margin: var(--lu-media-caption-margin);
		padding: var(--lu-media-caption-padding);
		background-color: var(--lu-media-caption-bg-color);
	}
}
`)