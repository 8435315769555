import { WIN, DOC, inViewport, updateUrl, emitCustomEvent } from '../../../core/dom'
import { getNumberDetails } from '../../../core/parse'
import { exists } from '../../../core/index'

const createSubscription = (unsubscribes, unsubscribeCallback) => (node, name, fn) => {
	node.addEventListener(name, fn)
	unsubscribes.push(() => {
		if (unsubscribeCallback)
			unsubscribeCallback()
		node.removeEventListener(name, fn)
	})
}

const emitNodeEvent = node => (name, data) => node.dispatchEvent(new CustomEvent(name, { detail: data }))

/**
 * Creates the config for the 'getScrollTranslation' method. 
 * 
 * @param  {String}  eventName					Valid values: 'translatex' or 'translatex'
 * @param  {Boolean} config.active.inScreen
 * @param  {String}  config.value.origin		Valid value: 'x' (use the scrollX value) or 'y' (use the scrollX value)
 * @param  {Number}  config.value.factor		Default 1
 * 
 * @return {Boolean} config.activeWhenInScreen	
 * @return {String}  config.originValue			Valid value: 'x' (use the scrollX value) or 'y' (use the scrollX value)
 * @return {Number}  config.factor				Default 1
 */
const getScrollTranslationConfig = (eventName, config) => {
	const activeWhenInScreen = !config || !config.active || config.active.inScreen === undefined || config.active.inScreen
	const originValue = !config || !config.value || !config.value.origin 
		? eventName == 'translatex' ? 'x' : 'y'
		: config.value.origin == 'x' ? 'x' : 'y'
	const factor = !config || !config.value || (config.value.factor === null || config.value.factor === undefined) 
		? 1 
		: isNaN(config.value.factor*1) ? 1 : config.value.factor

	return {
		activeWhenInScreen,
		originValue,
		factor
	}
}

/**
 * Gets the translation if it is allowed. 
 * 
 * @param  {DOM} 	  layerNode			
 * @param  {Number}   scroll.x		
 * @param  {Number}   scroll.y
 * @param  {Number}   scroll.dx
 * @param  {Number}   scroll.dy
 * @param  {Boolean}  config.activeWhenInScreen		Default true, which means that the scroll takes into account
 *                                               	the scroll distance from the bottom of the container (1)
 * @param  {String}   config.originValue			Valid value: 'x' (use the scrollX value) or 'y' (use the scrollX value)
 * @param  {Number}   config.factor					Default 1
 * @param  {Function} getScrollContainerDim			Gets the width and height of the scroll container.
 * 
 * @return {Number}	 	
 *
 * (1) When testing, this will create the weird effect where the layer will seem to jump too far when the window scroll starts
 */
const getScrollTranslation = (layerNode, scroll, config, getScrollContainerDim) => {
	const { activeWhenInScreen, originValue, factor } = config
	const { top, bottom, right, left, height, width } = layerNode.getBoundingClientRect()
	let amountScrolledX = scroll.x 
	let amountScrolledY = scroll.y
	if (activeWhenInScreen) {
		const { height:containerBottom, width:containerRight } = getScrollContainerDim()
		const layerAboveContainer = bottom < 0
		const layerUnderContainer = top > containerBottom 
		const layerInFrontContainer = right < 0 
		const layerAfterContainer = left > containerRight

		if (layerAboveContainer)
			amountScrolledY = containerBottom+height
		else if (layerUnderContainer)
			amountScrolledY = 0
		else
			amountScrolledY = containerBottom-top

		if (layerInFrontContainer)
			amountScrolledX = containerRight+width
		else if (layerAfterContainer)
			amountScrolledX = 0
		else
			amountScrolledX = containerRight-left
	} 

	return originValue == 'x' ? amountScrolledX*factor : amountScrolledY*factor
}

/**
 *
 * @param  {Boolean} showMode							Determines whether this config describes a show effect or a hide effect.
 * @param  {Object}  effects[].name						e.g., 'fade', 'slide'
 * @param  {Object}  effects[].transition				e.g., { duration:200, curve:'linear' }
 * @param  {Object}  effects[].direction				Only valid for 'slide' (e.g., 'up', 'down', 'left', 'right')
 * @param  {Object}  effects[].start					e.g., 0 (1) or '-100%' (2)
 * @param  {Object}  effects[].end						e.g., 1 (1) or 0 (2)
 * 
 * @return {Object}  config.show.translateX				e.g., 10 (for '10px') or '10%'
 * @return {Object}  config.show.translateY				e.g., 10 (for '10px') or '10%'
 * @return {Number}  config.show.opacity				e.g., 0.5 for 50%
 * @return {Number}  config.show.transitionDuration 	Longest effect duration
 * @return {Object}  config.hide.translateX				e.g., 10 (for '10px') or '10%'
 * @return {Object}  config.hide.translateY				e.g., 10 (for '10px') or '10%'
 * @return {Number}  config.hide.opacity				e.g., 0.5 for 50%
 * @return {Number}  config.hide.transitionDuration		Longest effect duration
 * 
 * (1) For the 'fade' effect, CSS 'opacity' is the controlled variable. 
 * (2) For the 'slide' effect, CSS 'tranform' with the 'translateX' or 'translateY' function is the controlled variable. Values
 * expressed in % are relative the the layer's size. 
 */
const getDisplayEffectConfig = (showMode, effects) => {
	const config = { show:{transitionDuration:0}, hide:{transitionDuration:0} }
	if (!effects || !effects[0])
		return config

	for (let i=0;i<effects.length;i++) {
		const effect = effects[i]
		const prop = effect.name == 'fade'
			? 'opacity'
			: effect.name == 'slide' 
				? /(up|down)/.test(effect.direction) ? 'translateY' : 'translateX'
				: null
		if (prop && exists(effect.start) && exists(effect.end)) {
			const duration = effect.transition ? effect.transition.duration||0 : 0
			if (duration > config.show.transitionDuration) {
				config.show.transitionDuration = duration
				config.hide.transitionDuration = duration
			}

			if (showMode) {
				config.show[prop] = effect.end
				config.hide[prop] = effect.start
			} else {
				config.hide[prop] = effect.end
				config.show[prop] = effect.start
			}
		}
	}

	return config
}

/**
 * Determines if the current scroll is in the zone. 
 * 
 * @param  {Object}   zone.top					100 (for '100px') or '12%'
 * @param  {Object}   zone.right				100 (for '100px') or '12%'
 * @param  {Object}   zone.bottom				100 (for '100px') or '12%'
 * @param  {Object}   zone.left					100 (for '100px') or '12%'
 * @param  {Function} getScrollContainerDim		Gets the width and height of the scroll container.
 * 
 * @return {Boolean}					
 */
const isScrollInZone = (zone, getScrollContainerDim) => {
	const top = zone.top === undefined || zone.top === null ? null : getNumberDetails(zone.top, { decimalPerc:true })
	const right = zone.right === undefined || zone.right === null ? null : getNumberDetails(zone.right, { decimalPerc:true })
	const bottom = zone.bottom === undefined || zone.bottom === null ? null : getNumberDetails(zone.bottom, { decimalPerc:true })
	const left = zone.left === undefined || zone.left === null ? null : getNumberDetails(zone.left, { decimalPerc:true })
	
	const isBelowTop = top === null ? () => [true,null] : !top.unit ? y => [y >= top.value,null] : top.unit == '%' 
		? (y,dim) => {
			const d = dim || getScrollContainerDim()
			return [y >= top.value*d.height, d]
		}
		: () => [true,null]
	const isAboveBottom = bottom === null ? () => [true,null] : !bottom.unit ? y => [y <= bottom.value,null] : bottom.unit == '%' 
		? (y,dim) => {
			const d = dim || getScrollContainerDim()
			return [y <= bottom.value*d.height, d]
		}
		: () => [true,null]
	const isAfterLeft = left === null ? () => [true,null] : !left.unit ? x => [x >= left.value,null] : left.unit == '%' 
		? (x,dim) => {
			const d = dim || getScrollContainerDim()
			return [x >= left.value*d.width, d]
		}
		: () => [true,null]
	const isBeforeRight = right === null ? () => [true,null] : !right.unit ? x => [x <= right.value,null] : right.unit == '%' 
		? (x,dim) => {
			const d = dim || getScrollContainerDim()
			return [x <= right.value*d.width, d]
		}
		: () => [true,null]

	/**
	 * Determines if the current scroll is in the zone. 
	 * 
	 * @param  {Number} scrollX		Scroll distance from the top in pixels.
	 * @param  {Number} scrollY		Scroll distance from the left in pixels.
	 * 
	 * @return {Boolean}	
	 */
	return (scrollX, scrollY) => {
		let result = isBelowTop(scrollY)
		if (!result[0])
			return false
		result = isAboveBottom(scrollY, result[1])
		if (!result[0])
			return false
		result = isAfterLeft(scrollX, result[1])
		if (!result[0])
			return false
		result = isBeforeRight(scrollX, result[1])
		if (!result[0])
			return false
		return true
	}
}

const getWinScrollPos = node => coord => coord == 'x' ? node.scrollX : coord == 'y' ? node.scrollY : null
const getElScrollPos = node => coord => coord == 'x' ? node.scrollLeft : coord == 'y' ? node.scrollTop : null
const getWinDim = node => () => ({ width:node.innerWidth, height:node.innerHeight })
const getElDim = node => () => node.getBoundingClientRect()

/**
 * Creates a Svelte Action with the following custom events:
 * 	- on:translatex: data: { event:Event, scroll:Object, config:Object }
 * 	- on:translatey: data: { event:Event, scroll:Object, config:Object }
 * 	
 * @param  {DOM}	node					
 * @param  {String} handlers[].layerId
 * @param  {String} handlers[].event		e.g., 'scroll', 'click', 'my-custom-event'.
 * @param  {String} handlers[].selector		CSS selector that emits the event. Only valid for native events. (1)
 * @param  {Object} handlers[].translateX	e.g., { active: { inScreen:true }, value: { origin:'x', factor:-1 } }	
 * @param  {Object} handlers[].translateY	e.g., { active: { inScreen:true }, value: { origin:'x', factor:-1 } }	
 * @param  {Object} handlers[].show			e.g., { zone:{top:'100%'}, effects:[{ name:'fade', transition:{ duration:200, curve:'linear' } }] }	
 * 
 * @return {Void}
 *
 * (1) For native events such as 'scroll', NULL means use the window object.
 */
export function triggerable (node, handlers) {
	const unsubscribes = []
	if (WIN && DOC && handlers && handlers[0]) {
		const addSubscriber = createSubscription(unsubscribes)
		const emitEvent = emitNodeEvent(node)
		const scrollHandler = handlers.find(h => h.event == 'scroll')
		const inViewportHandler = handlers.find(h => h.event == 'inViewport')
		const customEventHandlers = handlers.filter(h => h.event && h.event != 'scroll' && h.event != 'inViewport')

		if (scrollHandler) {
			let eventNode
			try {
				eventNode = scrollHandler.selector ? DOC.querySelector(scrollHandler.selector) : WIN
				if (!eventNode)
					return 
			} catch(err) {
				console.log(`WARNING - ${err.message}`)
				return
			}

			const [scrollPos, getDim] = scrollHandler.selector 
				? [getElScrollPos(eventNode), getElDim(eventNode)] 
				: [getWinScrollPos(eventNode), getWinDim(eventNode)]
			let x=null,y=null
			const getScrollData = () => {
				const scrollX = scrollPos('x')
				const scrollY = scrollPos('y')
				const dx = x === null ? 0 : scrollX-x
				const dy = y === null ? 0 : scrollY-y
				x = scrollX
				y = scrollY
				return [x,y,dx,dy]
			}

			// Translation handlers
			if (scrollHandler.translateX || scrollHandler.translateY) {
				/**
				 * Creates a configured scroll handler for the translate behavior.
				 *
				 * @param  {String}  name						Valid values: 'translatex' or 'translatex'
				 * @param  {Boolean} config.active.inScreen
				 * @param  {String}  config.value.origin		Valid value: 'x' (use the scrollX value) or 'y' (use the scrollX value)
				 * @param  {Number}  config.value.factor		Default 1
				 * 
				 * @return {Void}
				 */
				const processScrollEvent = (name, config) => {
					const _config = getScrollTranslationConfig(name, config)
					/**
					 * Handles the scroll events 
					 * 
					 * @return {Void}
					 */
					return () => {
						const scrollData = getScrollData()
						const scroll = { x:scrollData[0], y:scrollData[1], dx:scrollData[2], dy:scrollData[3] }
						const translate = getScrollTranslation(node, scroll, _config, getDim)
						emitEvent(name, { translate })
					}
				}
				if (scrollHandler.translateX) {
					const processScrollEventForTranslateX = processScrollEvent('translatex', scrollHandler.translateX)
					addSubscriber(eventNode, 'scroll', processScrollEventForTranslateX)
				}
				if (scrollHandler.translateY) {
					const processScrollEventForTranslateY = processScrollEvent('translatey', scrollHandler.translateY)
					addSubscriber(eventNode, 'scroll', processScrollEventForTranslateY)
				}
			}

			// Show handlers
			if (scrollHandler.show) {
				/**
				 * Creates a configured scroll handler for the show/hide behavior.
				 * 
				 * @param  {Object} config.zone						e.g., { top:'100%' }
				 * @param  {Object} config.effects[].name			e.g., 'fade', 'slide'
				 * @param  {Object} config.effects[].transition		e.g., { duration:200, curve:'linear' }
				 * @param  {Object} config.effects[].direction		Only valid for 'slide' (e.g., 'up', 'down', 'left', 'right')
				 * @param  {Object} config.effects[].start			e.g., 0 (1) or '-100%' (2)
				 * @param  {Object} config.effects[].end			e.g., 1 (1) or 0 (2)
				 * 
				 * @return {Void}
				 * 
				 * (1) For the 'fade' effect, CSS 'opacity' is the controlled variable. 
				 * (2) For the 'slide' effect, CSS 'tranform' with the 'translateX' or 'translateY' function is the controlled variable. Values
				 * expressed in % are relative the the layer's size. 
				 * 
				 */
				const processScrollEvent = (config) => {
					const _isScrollInZone = isScrollInZone(config.zone||{}, getDim)
					const displayConfig = getDisplayEffectConfig(true, config.effects)
					/**
					 * Handles the scroll events 
					 * 
					 * @return {Void}
					 */
					return () => {
						const scrollData = getScrollData()
						const scroll = { x:scrollData[0], y:scrollData[1], dx:scrollData[2], dy:scrollData[3] }
						if (_isScrollInZone(scroll.x, scroll.y))
							emitEvent('show', displayConfig.show)
						else
							emitEvent('hide', displayConfig.hide)
					}
				}

				const processScrollEventToShow = processScrollEvent(scrollHandler.show)
				addSubscriber(eventNode, 'scroll', processScrollEventToShow)
			}

			if (scrollHandler.emit && (scrollHandler.emit.scrollDown || scrollHandler.emit.scrollUp)) {
				/**
				 * Creates a configured scroll handler for the emit behavior.
				 * 
				 * @param  {String} config.scrollDown				Event name that must be emitted when scroll down.
				 * @param  {String} config.scrollUp					Event name that must be emitted when scroll up.
				 * 
				 * @return {Void}
				 * 
				 * (1) For the 'fade' effect, CSS 'opacity' is the controlled variable. 
				 * (2) For the 'slide' effect, CSS 'tranform' with the 'translateX' or 'translateY' function is the controlled variable. Values
				 * expressed in % are relative the the layer's size. 
				 * 
				 */
				const processScrollEvent = (config) => {
					/**
					 * Handles the scroll events 
					 * 
					 * @return {Void}
					 */
					return () => {
						const scrollData = getScrollData()
						const scroll = { x:scrollData[0], y:scrollData[1], dx:scrollData[2], dy:scrollData[3] }
						if (scroll.dy > 0 && config.scrollDown)
							DOC.dispatchEvent(new CustomEvent(config.scrollDown, { bubbles: true }))
						else if (scroll.dy < 0 && config.scrollUp)
							DOC.dispatchEvent(new CustomEvent(config.scrollUp, { bubbles: true }))
					}
				}

				const processScrollEventToEmit = processScrollEvent(scrollHandler.emit)
				addSubscriber(eventNode, 'scroll', processScrollEventToEmit)
			}
		}

		if (inViewportHandler) {
			let inViewportCb
			if (inViewportHandler.setUrl)
				inViewportCb = () => {
					updateUrl(inViewportHandler.setUrl, { noScroll:true })
				}
			else if (inViewportHandler.emitEvent)
				inViewportCb = () => {
					emitCustomEvent(inViewportHandler.emitEvent)
				}
			
			if (inViewportCb)
				unsubscribes.push(inViewport(node, [{
					threshold: 0,
					intersectStart: inViewportCb
				}], { rootMargin:'0px 0px -95% 0px' }))
		}

		if (customEventHandlers && customEventHandlers[0]) {
			for (let i=0,l=customEventHandlers.length;i<l;i++) {
				const eventHandler = customEventHandlers[i]
				const showConfig = eventHandler.show
				const hideConfig = eventHandler.hide
				if (showConfig || hideConfig) {
					/**
					 * Creates a configured custom handler for the show/hide behavior.
					 *
					 * @param  {Boolean} showMode
					 * @param  {Object}  config.effects[].name			e.g., 'fade', 'slide'
					 * @param  {Object}  config.effects[].transition	e.g., { duration:200, curve:'linear' }
					 * @param  {Object}  config.effects[].direction		Only valid for 'slide' (e.g., 'up', 'down', 'left', 'right')
					 * @param  {Object}  config.effects[].start			e.g., 0 (1) or '-100%' (2)
					 * @param  {Object}  config.effects[].end			e.g., 1 (1) or 0 (2)
					 * 
					 * @return {Void}
					 * 
					 * (1) For the 'fade' effect, CSS 'opacity' is the controlled variable. 
					 * (2) For the 'slide' effect, CSS 'tranform' with the 'translateX' or 'translateY' function is the controlled variable. Values
					 * expressed in % are relative the the layer's size. 
					 * 
					 */
					const processCustomEvent = (showMode, config) => {
						const displayConfig = getDisplayEffectConfig(showMode, config.effects)
						/**
						 * Handles the custom events 
						 * 
						 * @return {Void}
						 */
						return showMode
							? () => emitEvent('show', displayConfig.show)
							: () => emitEvent('hide', displayConfig.hide)
					}
					const processEvent = processCustomEvent(showConfig, showConfig||hideConfig)
					addSubscriber(DOC, eventHandler.event, processEvent)
				}
			}
		}
	}

	return {
		destroy() {
			while(unsubscribes[0]) {
				const u = unsubscribes.pop()
				u()
			}
		}
	}
}




