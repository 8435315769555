import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
import { boxSizing, noUserSelectCss, _ } from '../../../core/css'

export const rootClass = css(`
outline: none;
${boxSizing.css('border-box')}
position: relative;
overflow-x: var(--lu-cell-overflow-x); 
overflow-y: var(--lu-cell-overflow-y);
background-color: var(--lu-cell-background-color);
display: flex;

&.cell-edit {
	cursor: var(--lu-cell-cursor, default);
}

& .cell-body {
	width: 100%;
	height: auto;
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: 1fr;
	grid-template-areas: "celllayer";
}

& .cell-aspect-ratio {
	float:left;
	width:0px;
	padding-top: var(--lu-cell-aspect-ratio);
}

& .cell-layer {
	grid-area: celllayer;
	display: var(--lu-layer-display, flex);
	align-items: var(--lu-layer-align-items, flex-start);
	justify-content: var(--lu-layer-justify-content, flex-start);
}

& .cell-layer-container {
	${noUserSelectCss}
	width: 100%;
	height: var(--lu-layer-height);
	position: var(--lu-layer-position);
	top: var(--lu-layer-sticky-top);
	bottom: var(--lu-layer-sticky-bottom);
	display: flex;
	align-items: var(--lu-layer-align-items, flex-start);
	justify-content: var(--lu-layer-justify-content, flex-start);
	${_('transform', ['var(--lu-layer-transform)'])}
	opacity: var(--lu-layer-opacity);
	transform-origin: var(--lu-layer-transform-origin);
	transition: var(--lu-layer-transition);
}

& .disable-component-on-edit {
	pointer-events: none;
	${noUserSelectCss}
}
`)

export const cellSelectedClass = css(`
& .hide-edge {
	display: none;
}

& .cell-edge {
	${noUserSelectCss}
	position: absolute;
	z-index: 1;
}

& .cell-v-edge {
	height: 100%;
	width: 2px;
	background: linear-gradient(0deg, #00c4cc 50%, transparent 50%);
	background-repeat: repeat-y;
	background-size: 1px 8px;	
}

& .cell-h-edge {
	width: 100%;
	height: 2px;
	background: linear-gradient(90deg, #00c4cc 50%, transparent 50%);
	background-repeat: repeat-x;
	background-size: 8px 1px;
}

& .top-edge {
	top:-1px;
	background-position: 0% 0%;
}

& .right-edge {
	right:-1px;
	background-position: 100% 0px;
}

& .bottom-edge {
	bottom:-1px;
	background-position: 100% 100%;
}

& .left-edge {
	left:-1px;
	background-position: 0px 100%;
}

& .cell-selected,
.cell-hover-enabled &:hover {
	& .hide-edge {
		display: block;
	}

	& .cell-v-edge {
		background: unset;
		background-repeat: unset;
		background-size: unset;	
	}

	& .cell-h-edge {
		background: unset;
		background-repeat: unset;
		background-size: unset;
	}

	& .top-edge {
		background-position: unset;
	}

	& .right-edge {
		background-position: unset;
		z-index:3;
	}

	& .bottom-edge {
		background-position: unset;
		z-index:3;
	}

	& .left-edge {
		background-position: unset;
	}

	& .cell-edge {
		background-color: #00c4cc;
	}
}

.cell-hover-enabled &:hover :not(.cell-selected) .cell-edge {
	opacity: 0.5;
	z-index:1;
}

& .cell-selected:not(.cell-multi-select) {
	& .resize-marker {
		position: absolute;
		background: white;
		border-radius: 3px;
		box-shadow: 0 0 5px 1px rgb(57 76 96 / 15%), 0 0 0 1px rgb(53 71 90 / 20%);
		z-index: 3;

		&.vert {
			height: 16px;
			width: 4px;
			top: calc(50% - 8px);
			left: -1px;
		}

		&.hor {
			height: 4px;
			width: 16px;
			left: calc(50% - 8px);
			top: -1px;
		}
	}
}

& .cell-selected {
	& .v-resize-cursor:hover {
		cursor: col-resize;
	}

	& .h-resize-cursor:hover {
		cursor: row-resize;
	}
}
`)