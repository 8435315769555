/**
 * Creates a new array whose head is the array's item and whose tail is the item.
 * Contrary to the native 'push' API, this function does not use variable array length
 * and is therefore faster and more memory efficient.
 * 
 * @param  {Array}	array
 * @param  {Object}	item
 * @return {Array}	
 */
export const push = (array, item) => {
	if (!array)
		return [item]

	const l = array.length
	if (l === undefined)
		throw new Error('Variable \'array\' is not an Array.')

	if (l == 0)
		return [item]

	const a = new Array(l+1)
	for (let i=0;i<l;i++)
		a[i] = array[i]

	a[l] = item

	return a
}

/**
 * Creates a new array made of the original array items except the one located at the 'index'.
 * Contrary to the native 'pop' or 'slice' API, this function does not use variable array length
 * and is therefore faster and more memory efficient.
 * 
 * @param  {Array}	array
 * @param  {Number}	index	If undefined or null, this API is similar to the native 'pop'.
 * @return {Array}	
 */
export const pop = (array, index) => {
	if (!array)
		return []

	const l = array.length
	if (l === undefined)
		throw new Error('Variable \'array\' is not an Array.')

	if (l == 0)
		return []

	const idx = index === undefined || index === null ? l-1 : index < 0 ? 0 : index >=l ? l-1 : index

	const a = new Array(l-1)
	let offset = 0

	for (let i=0;i<l;i++) {
		if (i == idx) {
			offset = 1 
			continue
		}

		a[i-offset] = array[i]
	}

	return a
}

/**
 * Creates a new array made of the original array items minus the ones that match the 'matchFn' predicate. 
 * 
 * @param  {Array}		array
 * @param  {Function}	matchFn		(obj:Object) => :Boolean
 * 
 * @return {Array}	
 */
export const remove = (array, matchFn, index=0, lastIndex, keepCounter) => {
	if (index == 0) {
		lastIndex = array.length-1
		if (lastIndex < 0)
			return []
		keepCounter = 0
	}

	const item = array[index]
	const match = matchFn(item,index)
	if (match)
		keepCounter++

	if (index == lastIndex) {
		const newArray = keepCounter ? new Array(keepCounter) : []
		keepCounter--
		if (match && keepCounter >= 0) {
			newArray[keepCounter] = item
			keepCounter--
		}			
		return lastIndex ? { array:newArray, keepCounter } : newArray
	} else {
		const results = remove(array, matchFn, index+1, lastIndex, keepCounter)
		if (match && results.keepCounter >= 0) {
			results.array[results.keepCounter] = item
			results.keepCounter--
		}

		return index ? results : results.array
	}
}

/**
 * Creates a new array made of the original array items minus the ones that match the 'transformFn' predicate. 
 * 
 * @param  {Array}		array
 * @param  {Function}	transformFn		(obj:Object) => :Object
 * @param  {Boolean}	excludeEmpty
 * 
 * @return {Array}	
 */
export const map = (array, transformFn, excludeEmpty=false, index=0, lastIndex, keepCounter) => {
	if (index == 0) {
		lastIndex = array.length-1
		if (lastIndex < 0)
			return []
		keepCounter = 0
	}

	const transformedItem = transformFn(array[index],index)
	if (excludeEmpty) {
		if (transformedItem !== null && transformedItem !== undefined)
			keepCounter++
	} else
		keepCounter++


	if (index == lastIndex) {
		const newArray = keepCounter ? new Array(keepCounter) : []
		keepCounter--
		if (transformedItem && keepCounter >= 0) {
			newArray[keepCounter] = transformedItem
			keepCounter--
		}			
		return lastIndex ? { array:newArray, keepCounter } : newArray
	} else {
		const results = map(array, transformFn, excludeEmpty, index+1, lastIndex, keepCounter)
		if (transformedItem && results.keepCounter >= 0) {
			results.array[results.keepCounter] = transformedItem
			results.keepCounter--
		}

		return index ? results : results.array
	}
}

export const chain = (...fns) => (arg, ...options) => fns.reduce((acc,fn) => fn ? fn(acc, ...options) : acc, arg)

/**
 * Finds the items that matches the predicate. The example below finds the first effect that matches the name 'fade':
 * 
 * 		find(handlers, 'fade', 'show', '[]effects', 'name')
 * 
 * @param  {Array}    		array
 * @param  {Object}    		value
 * @param  {...[String]} 	props
 * 
 * @return {Object}			
 */
export const find = (array, value, props, verify, propsCount=null, propsFullDetails) => {
	if (!array || !array[0])
		return

	if (propsCount === null)
		propsCount = props ? props.length : 0
	const noProps = propsCount == 0
	if (!verify)
		verify = typeof(value) == 'function' ? value : v => v == value
	if (!propsFullDetails && !noProps) {
		propsFullDetails = {}
		for (let i=0;i<propsCount;i++) {
			const prop = props[i]
			const isArray = prop[0] == '['
			const propName = isArray ? prop.substring(2) : prop 
			propsFullDetails[prop] = [propName,isArray]
		}
	}

	for (let i=0,l=array.length;i<l;i++) {
		const item = array[i]
		if (noProps) {
			if (verify(item))
				return item 
		} else if (item !== undefined && item !== null) {
			let val = item,
				loopOver = false,
				endLoop = propsCount-1,
				result,
				isArray = false
			for (let j=0;j<propsCount;j++) {
				loopOver = j == endLoop
				const propDetails = propsFullDetails[props[j]]
				val = val[propDetails[0]]
				if (val === undefined || val === null)
					break

				if (propDetails[1]) {
					isArray = true
					result = find(val, value, props.slice(j+1), verify, propsCount-1-j, propsFullDetails)
					break
				}
			}

			if (isArray && result !== undefined)
				return result
			if (loopOver && verify(val))
				return item
		}
	}

	return
}


/**
 * Collects into an array all the items that matches a predicate. The example below collects all the effects that have the name 'fade':
 * 
 * 		collect(handlers, 'fade', 'show', '[]effects', 'name')
 * 
 * @param  {Array}    		array
 * @param  {Object}    		value
 * @param  {...[String]} 	props
 * 
 * @return {Object}			
 */
export const collect = (array, value, props, verify, propsCount, propsFullDetails, index=0, lastIndex, matchCount) => {
	if (!array)
		return []

	if (index === 0) {
		lastIndex = array.length-1
		if (lastIndex < 0)
			return []

		propsCount = props ? props.length : 0
		verify = typeof(value) == 'function' ? value : v => v == value
		propsFullDetails = {}
		for (let i=0;i<propsCount;i++) {
			const prop = props[i]
			const isArray = prop[0] == '['
			const propName = isArray ? prop.substring(2) : prop 
			propsFullDetails[prop] = [propName,isArray]
		}
		matchCount = 0
	}

	const item = array[index]
	let items
	let itemMatches = false, itemsMatch = false, itemsMatchCount = 0

	if (propsCount == 0) {
		if (verify(item)) {
			itemMatches = true 
			matchCount++
		}
	} else if (item !== undefined && item !== null) {
		let val = item,
			loopOver = false,
			endLoop = propsCount-1,
			isArray = false
		for (let j=0;j<propsCount;j++) {
			loopOver = j == endLoop
			const propDetails = propsFullDetails[props[j]]
			val = val[propDetails[0]]
			if (val === undefined || val === null)
				break

			if (propDetails[1]) {
				isArray = true
				items = collect(val, value, props.slice(j+1), verify, propsCount-1-j, propsFullDetails)
				break
			}
		}

		if (isArray) {
			itemsMatchCount = items.length
			if (itemsMatchCount > 0) {
				itemsMatch = true
				matchCount += itemsMatchCount
			}
		} else if (loopOver && verify(val)) {
			itemMatches = true
			matchCount++
		}
	}
	
	let result
	if (index == lastIndex) 
		result = {
			collected: new Array(matchCount),
			cursor: matchCount-1
		}
	else
		result = collect(array, value, props, verify, propsCount, propsFullDetails, index+1, lastIndex, matchCount)

	if (itemMatches)
		result.collected[result.cursor--] = item
	else if (itemsMatch) {
		for (let i=itemsMatchCount;i>0;i--)
			result.collected[result.cursor--] = items[i-1]
	}

	return index == 0 ? result.collected : result
}



