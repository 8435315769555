<script>
	/* 
	=======================================================
	START - DEPENDENCIES
	=======================================================
	*/

	import { createEventDispatcher } from 'svelte'
	import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
	import { getId, createBaseEvents } from '../../../core/index'
	import { paddingParse, alignParse } from '../../../core/parse'
	import { VAR_FONT_COLOR, VAR_PRIMARY_COLOR } from '../../../core/theme'
	import { setVariables, sizeCssVariables, getGradientColor } from '../../../core/css'
	import { chooseColor } from '../../../core/color'
	import { rootClass } from './style'

	/* 
	=======================================================
	END - DEPENDENCIES
	=======================================================
	*/









	/* 
	=======================================================
	START - CONSTANTS
	=======================================================
	*/

	const ID = getId() // e.g., '_cwd23d'

	/* 
	=======================================================
	END - CONSTANTS
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT PROPERTIES
	=======================================================
	*/

	// Content
	export let id = ID
	export let html = ''
	// Style
	export let width = null
	export let height = null
	export let nowrap = false
	export let fontSize = null
	export let fontFamily = null
	export let fontColor = null
	export let fontColorGradientOrientation = 0
	export let fontLetterSpacing = null
	export let fontTransformText = null // Valid values: 'capitalize', 'uppercase', 'lowercase'
	export let lineHeight = null
	export let padding = 0 // Can be an object such as { top: 5, right: 6, bottom: 7, left: 8 }
	export let margin = 0
	export let textAlign = null // e.g., { horizontal: 'left', vertical: 'top' }
	export let linkUnderline = true
	export let linkFontColor = null
	export let linkFontWeight = null
	export let linkFontStyle = null

	/* 
	=======================================================
	END - COMPONENT PROPERTIES
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT EVENTS
	=======================================================
	*/

	const dispatch = createEventDispatcher()
	createBaseEvents(dispatch)
	// const emitEvent = eventName => data => dispatch(eventName, data||{})
	
	/**
	 * on:toohigh
	 *
	 * @param  {Object}		data
	 * 
	 * @return {Void}
	 */
	// const emitTooHigh = emitEvent('toohigh')

	/* 
	=======================================================
	END - COMPONENT EVENTS
	=======================================================
	*/









	/* 
	=======================================================
	START - REACTIVE STATE PROPS
	=======================================================
	*/

	// let rootClass=''

	/* 
	=======================================================
	END - REACTIVE STATE PROPS
	=======================================================
	*/









	/* 
	=======================================================
	START - PRIVATE FUNCTIONS
	=======================================================
	*/

	const isColorGradient = color => color && Array.isArray(color) && color.length > 1

	const getRootVars = ({ width, height, padding, textAlign, fontSize, fontFamily, fontColor, fontLetterSpacing, lineHeight, linkUnderline,linkFontColor, linkFontWeight, linkFontStyle, nowrap, fontTransformText, fontColorGradientOrientation }) => {

		const _textAlign = alignParse({ 
			horizontal: (textAlign||{}).horizontal || 'left', 
			vertical: (textAlign||{}).vertical || 'top' 
		})
		
		const _gradientOn = isColorGradient(fontColor)
		const _fontColor = fontColor == 'primary' ? VAR_PRIMARY_COLOR : VAR_FONT_COLOR
		const _linkFontColor = chooseColor(linkFontColor)

		return {
			classes: _gradientOn ? 'text-grad-on' : '',
			rootVars: css(`
			${setVariables({ fontFamily, fontSize, fontColor:_gradientOn ? null : chooseColor(fontColor), fontLetterSpacing, lineHeight })}
			${sizeCssVariables('--lu-text-width', width, { stringOutput:true })}
			${sizeCssVariables('--lu-text-height', height, { stringOutput:true })}
			--lu-text-padding: ${paddingParse(padding).css||0};
			--lu-text-margin: ${paddingParse(margin).css||0};
			--lu-text-align-items: ${_textAlign.justifyContent};
			--lu-text-justify-content: ${_textAlign.alignItems};
			--lu-text-font-color: ${_fontColor};
			--lu-text-grad: ${_gradientOn ? getGradientColor({ orientation:fontColorGradientOrientation })(...fontColor.map(f => chooseColor(f))) : 'none'};
			--lu-text-font-text-transform: ${fontTransformText||'none'};
			--lu-text-link-font-color: ${_linkFontColor};
			--lu-text-link-font-style: ${linkFontStyle||'normal'};
			--lu-text-link-font-weight: ${linkFontWeight||'normal'};
			--lu-text-link-decoration: ${linkUnderline ? 'underline' : 'none'};
			--lu-text-nowrap: ${nowrap ? 'nowrap' : 'normal'};
			`)
		}
	}

	/* 
	=======================================================
	END - PRIVATE FUNCTIONS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT HANDLERS
	=======================================================
	*/


	/* 
	=======================================================
	END - EVENT HANDLERS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT LIFECYCLE
	=======================================================
	*/
	
	$: vars = getRootVars({ width, height, padding, margin, textAlign, fontSize, fontFamily, fontColor, fontLetterSpacing, lineHeight, linkUnderline,linkFontColor, linkFontWeight, linkFontStyle, nowrap, fontTransformText, fontColorGradientOrientation })

	/* 
	=======================================================
	END - EVENT LIFECYCLE
	=======================================================
	*/
</script>

<div 
	{id}
	data-type="text"  
	class="{vars.rootVars} {rootClass} {vars.classes}">
	{@html html}
</div>
