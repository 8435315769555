<script>
	/* 
	=======================================================
	START - DEPENDENCIES
	=======================================================
	*/

	import { onDestroy, createEventDispatcher } from 'svelte'
	import { getId, createBaseEvents } from '../../../core/index'
	import Paper from '../../paper/src/Paper.svelte'
	import List from '../../list/src/List.svelte'
	import { rootClass } from './selectList'

	/* 
	=======================================================
	END - DEPENDENCIES
	=======================================================
	*/









	/* 
	=======================================================
	START - CONSTANTS
	=======================================================
	*/

	const ID = getId() // e.g., '_cwd23d'

	/* 
	=======================================================
	END - CONSTANTS
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT PROPERTIES
	=======================================================
	*/

	// Content
	export let selectState = null
	export let options = []
	// Style
	export let borderRadius = null
	export let backgroundColor = null
	export let rowHeight = 0
	export let rowPadding = 0
	// Behavior

	/* 
	=======================================================
	END - COMPONENT PROPERTIES
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT EVENTS
	=======================================================
	*/

	const dispatch = createEventDispatcher()
	createBaseEvents(dispatch)
	const emitEvent = eventName => (data,fn) => { 
		if (fn)
			fn()
		dispatch(eventName, data||{})
	}
	
	/**
	 * on:click
	 *
	 * @param  {Object}		data
	 * 
	 * @return {Void}
	 */
	const emitClick = emitEvent('click')
	
	/**
	 * on:escape
	 *
	 * @param  {Object}		data
	 * 
	 * @return {Void}
	 */
	const emitEscape = emitEvent('escape')
	
	/**
	 * on:blur
	 *
	 * @param  {Object}		data
	 * 
	 * @return {Void}
	 */
	const emitBlur = emitEvent('blur')
	
	/**
	 * on:sizechange
	 *
	 * @param  {Object}		data
	 * 
	 * @return {Void}
	 */
	const emitSizeChange = emitEvent('sizechange')

	/* 
	=======================================================
	END - COMPONENT EVENTS
	=======================================================
	*/









	/* 
	=======================================================
	START - REACTIVE STATE PROPS
	=======================================================
	*/

	let selectedOptionId,
		show = false,
		active = false,
		width = null,
		height = 0,
		originY = 0,
		posX = 0,
		posY = 0


	/* 
	=======================================================
	END - REACTIVE STATE PROPS
	=======================================================
	*/









	/* 
	=======================================================
	START - PRIVATE FUNCTIONS
	=======================================================
	*/

	/**
	 * Format the options to thei canonical format (i.e., { selected: <Boolean>, id: <String>, value: <String> }).
	 * Also remove the duplicated IDs.
	 * 
	 * @param  {[Object]}	options					e.g., ['Apple', 'Orange'] or [{ id:13, value: 'Apple' }, { value: 'Orange' }]
	 * @param  {String}		selectedOptionId		e.g., '13'
	 * 
	 * @return {String}   	output[].id				e.g., 'Apple' or '13'               
	 * @return {String}   	output[].value			e.g., 'Apple'
	 * @return {Boolean}   	output[].selected		e.g., true
	 */
	const getSelectOptions = (options=[], selectedOptionId) => options.reduce((acc, option) => {
		
		let id, value, selected

		const t = typeof(option)
		if (t != 'object')
			id = value = t == 'string' ? option : `${option}`
		else {
			id = (option.id === null || option.id === undefined ? option.value : option.id) || ''
			value = (option.value === null || option.value === undefined ? option.id : option.value) || ''
			selected = option.selected

			id = typeof(id) == 'string' ? id : `${id}`
			value = typeof(value) == 'string' ? value : `${value}`
		}

		selected = selectedOptionId == id && !acc.selected
		if (selected)
			acc.selected = selected

		if (acc.ids.indexOf(id) < 0) {
			acc.ids.push(id)
			acc.options.push({ id, value, selected })
		}

		return acc

	}, { options:[], selected:false, ids:[] }).options

	const getRootVars = ({ show, originY, height, width, posX, posY }) => {
		const vars = [
			`--lu-selectlist-top: ${posY}px`,
			`--lu-selectlist-left: ${posX}px`,
			`--lu-selectlist-transform: scale(${show ? 1 : 0})`,
			`--lu-selectlist-opacity:${show ? 1 : 0}`,
			`--lu-selectlist-origin-y: ${originY}`,
			`--lu-selectlist-height: ${height}px`,
			`--lu-selectlist-width: ${width}px`
		].join(';')

		return vars
	}

	/* 
	=======================================================
	END - PRIVATE FUNCTIONS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT HANDLERS
	=======================================================
	*/
 
	const unsubscribe = !selectState ? null : selectState.subscribe(value => {
		if (!value)
			return

		if (show != value.show)
			show = value.show
		if (active != value.active)
			active = value.active
		if (width != value.width)
			width = value.width
		if (height != value.height)
			height = value.height
		if (originY != value.originY)
			originY = value.originY
		if (posX != value.x)
			posX = value.x
		if (posY != value.y)
			posY = value.y
	})

	const onClick = (data) => {
		selectedOptionId = data.id
		emitClick(data)
	}

	onDestroy(() => {
		if (unsubscribe)
			unsubscribe()
	})

	/* 
	=======================================================
	END - EVENT HANDLERS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT LIFECYCLE
	=======================================================
	*/
	
	$: rootVars = getRootVars({ show, originY, height, width, posX, posY })



	/* 
	=======================================================
	END - EVENT LIFECYCLE
	=======================================================
	*/
</script>

<div id={ID} data-type="tf-select" style={rootVars} class="{rootClass}">
	<Paper
		elevation={8}
		{backgroundColor}
		{borderRadius}
		{width}>
		<List
			items={getSelectOptions(options, selectedOptionId)}
			clickable={true}
			rowHoverEffect={true}
			rowAlign={{ horizontal:'left', vertical:'center' }}
			focus={show}
			width="100%"
			height="var(--lu-selectlist-height)"
			{rowHeight}
			{rowPadding}
			{active}
			on:click={d => onClick(d.detail)}
			on:escape={d => emitEscape(d.detail)}
			on:blur={d => emitBlur(d.detail)}
			on:sizechange={d => emitSizeChange(d.detail)}>
		</List>
	</Paper>
</div>
