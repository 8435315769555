<svelte:options immutable={true}/>
<script>
	/* 
	=======================================================
	START - DEPENDENCIES
	=======================================================
	*/

	import { createEventDispatcher, onMount, getContext, onDestroy, afterUpdate } from 'svelte'
	import { writable } from 'svelte/store'
	import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
	import { transition } from '../../../core/css'
	import { alignParse, addUnit, opacityParse, transformOriginParse } from '../../../core/parse'
	import { map, find, collect } from '../../../core/collection'
	import { nodeDim, emitCustomEvent } from '../../../core/dom'
	import { chooseColor } from '../../../core/color'
	import { exists } from '../../../core/index'
	import { getStore } from './store'
	import Lineup from './Lineup.svelte'
	import { triggerable } from './triggerable'
	import { rootClass, cellSelectedClass } from './cellStyle'

	/* 
	=======================================================
	END - DEPENDENCIES
	=======================================================
	*/









	/* 
	=======================================================
	START - CONSTANTS
	=======================================================
	*/

	/* 
	=======================================================
	END - CONSTANTS
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT PROPERTIES
	=======================================================
	*/

	// Content
	export let cell = null
	export let firstCell = false
	export let lastCell = false
	export let toggledLayerIds = null 		// e.g., '2;4;6'
	export let storeId = null
	// Style
	export let rowVisible = true
	// Behavior
	export let resizeableEdgeTop = true
	export let resizeableEdgeRight = true
	export let resizeableEdgeBottom = true
	export let resizeableEdgeLeft = true
	export let edit = false
	export let inject = v => v

	/* 
	=======================================================
	END - COMPONENT PROPERTIES
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT EVENTS
	=======================================================
	*/

	const dispatch = createEventDispatcher()
	const emitEvent = eventName => data => dispatch(eventName, data||{})
	
	/**
	 * on:mousedown
	 *
	 * @param  {Object}		data.id					Cell's ID
	 * @param  {[Layer]}	data.layers
	 * 
	 * @return {Void}
	 */
	const emitMouseDown = emitEvent('mousedown')

	/**
	 * on:dragstart
	 *
	 * @param  {String}		data.id			Cell's ID.
	 * @param  {Number}		data.clientX	Cursor X position.
	 * @param  {Number}		data.clientY	Cursor Y position.
	 * 
	 * @return {Void}
	 */
	const emitDragStart = emitEvent('dragstart')

	/**
	 * on:delete
	 *
	 * @param  {String}		data.id				Cell's ID
	 * @param  {Boolean}	data.selected		If true, delete all selected cells on top of this cell	
	 * 
	 * @return {Void}
	 */
	const emitDelete = emitEvent('delete')

	/**
	 * on:nextcell
	 *
	 * @param  {String}		data.id					Cell's ID
	 * @param  {String}		data.direction			Next cell direction. Valid values: 'left', 'right', 'down', 'up'.
	 * 
	 * @return {Void}
	 */
	const emitSelectNextCell = emitEvent('nextcell')

	/**
	 * on:valuechange
	 *
	 * @param  {String}	 data.id				Cell's ID
	 * @param  {Object}	 data.value
	 * @param  {String}	 data.layer.id
	 * @param  {String}	 data.layer.component
	 * @param  {String}	 data.name				Field name
	 * @param  {String}	 data.type				Field type, e.g., 'textfield', 'slider'
	 * 
	 * @return {Void}
	 */
	const emitValueChange = emitEvent('valuechange')

	/**
	 * on:componentclick
	 *
	 * @param  {String}		data.id					Cell's ID
	 * @param  {Object}		data.layer	
	 * 
	 * @return {Void}
	 */
	const emitComponentClick = emitEvent('componentclick')

	/**
	 * on:mount
	 *
	 * @param  {String}		data.id				Cell's ID
	 * @param  {Function}	data.getDim	
	 * 
	 * @return {Void}
	 */
	const emitMount = emitEvent('mount')

	/**
	 * on:destroy
	 *
	 * @param  {String}		data.id				Cell's ID
	 * 
	 * @return {Void}
	 */
	const emitDestroy = emitEvent('destroy')

	/**
	 * on:resizestart
	 *
	 * @param  {String}		data.id		Cell's ID
	 * @param  {String}		data.side	Valid values: 'left', 'right' or 'bottom'
	 * @param  {Function}	data.getDim	Get cell's dimensions
	 * 
	 * @return {Void}
	 */
	const emitResizeStart = emitEvent('resizestart')

	/**
	 * on:dragend
	 *
	 * 
	 * @return {Void}
	 */
	const emitDragEnd = emitEvent('dragend')


	/* 
	=======================================================
	END - COMPONENT EVENTS
	=======================================================
	*/









	/* 
	=======================================================
	START - REACTIVE STATE PROPS
	=======================================================
	*/

	let editMode,
		store = getStore(storeId),
		node,
		getDim,
		valueUnsubscribes,
		layerValues = {},
		animatedLayerVars = writable({})
	let dragging = store.dragging
	let resizing = store.resizing
	/* 
	=======================================================
	END - REACTIVE STATE PROPS
	=======================================================
	*/









	/* 
	=======================================================
	START - PRIVATE FUNCTIONS
	=======================================================
	*/

	const { getReactiveCellLayerValue, getReactiveCellSelectedState } = getContext('cellvalues')
	const { getComponentById } = getContext('componentstore')

	/**
	 * 
	 * @param  {String} layerId
	 * @param  {Object} data.value		
	 * @param  {String} data.type		Component's type (e.g., 'textfield')
	 * @param  {String} data.name		Field name
	 * @return {[type]}         
	 */
	const afterContentUpdate = (layer, data) => {
		// console.log(`Cell ${cell.id} CONTENT re-rendered`)
		const layerId = layer.id
		// Emit component's value changes when the cell is not in edit mode.
		const existingValue = layerValues[layer.id]
		if (!editToggled && data && existingValue != data.value) {
			const mountEvent = existingValue === undefined
			layerValues[layerId] = data.value
			const skipUntoggledRadio = data.type == 'radio' && !data.value
			if (!mountEvent && !skipUntoggledRadio) {
				emitValueChange({ 
					id:cell.id, 
					value:data.value, 
					name:data.name, 
					type:data.type,
					layer
				})
			}
		}
	}

	const getComponentClass = component => {
		if (!component)
			throw new Error('Missing required \'component\' argument.')
		if (!component.id)
			throw new Error('Missing required \'component.id\' argument.')
		if (component.type == 'lineup')
			return Lineup
		else {
			const comp = getComponentById(component.id)
			if (!comp)
				throw new Error(`Component ID ${component.id} not found.`)
			return comp.class
		}
	}


	/**
	 * Creates subsription to each layer's component's value changes. Each subscription mutates the 
	 * 'layerValues' object, which forces a re-render of the layer's component. 
	 * 
	 * @param  {String}     cellId
	 * @param  {[Layer]}    layers
	 * 
	 * @return {[Function]} unsubscribes	Array of unsubscribe functions that must be executed when 
	 *                                   	this component is destroyed.
	 */
	const subscribeToValueChanges = (cellId, layers) => {
		const l = layers.length
		const unsubscribes = new Array(l)
		for (let i=0;i<l;i++) {
			const layer = layers[i]
			const reactVal = getReactiveCellLayerValue(cellId, layer)
			if (!reactVal)
				continue
			unsubscribes[i] = reactVal.subscribe(v => {
				layerValues = {...layerValues}
				layerValues[layer.id] = v
			})
		}

		return unsubscribes
	}

	/**
	 * Injects the data inside the component's props. 
	 * 
	 * @param  {Object} props
	 * 
	 * @return {Object} resolvedProps
	 */
	const getComponentProps = obj => {
		const { id, type, ...props } = obj || {}
		if (type == 'lineup') {
			props.id = id
			if (props.data && typeof(props.data) == 'object') {
				const _props = { ...props, data:{} }
				_props.data = inject(props.data)
				return _props
			} else 
				return props
		} else
			return inject(props)
	}

	/**
	 * Filter layers based on the toggledLayerIds and each layer's combo 'toggled'/'locked' property.
	 * 
	 * @param  {[Layer]} 	layers
	 * @param  {String}  	toggledLayerIds		e.g., '2;4;6'
	 * 
	 * @return {Layer} 		toggledLayers[][0]	Layer
	 * @return {Function} 	toggledLayers[][1]	getLayoutCssVars
	 * @return {string} 	toggledLayers[][2]	layerCSSclass
	 * @return {Boolean} 	toggledLayers[][3]	toggled
	 * @return {Function} 	toggledLayers[][4]	componentClickHandler
	 * @return {Object} 	toggledLayers[][5]	componentProps
	 * @return {Object} 	toggledLayers[][6]	layerHandlers
	 */
	const getToogledLayers = (layers, toggledLayerIds) => {
		const toggledIds = (toggledLayerIds||'').split(';').filter(x => x !== '')
		const tLayers = map(layers||[], l => {
			const toggled = l.locked ? l.toggled : toggledIds.some(i => i == l.id)	
			const handlers = inject(l.handlers)		

			return [
				l,												// 1. Layer instance
				getLayoutCssVars(l,toggled),					// 2. getLayoutCssVars
				getLayerVars(l),								// 3. layerCSSclass (e.g., 'css-1m90mag')
				toggled,										// 4. toggled
				getLayerComponentClickHandler(handlers),		// 5. componentClickHandler
				getComponentProps(l.component),					// 6. { height:26, width:28 }
				handlers										// 7. handlers with their value injected
			]
		})

		return tLayers
	}

	const focus = () => {
		if (node)
			node.focus()
	}

	/**
	 * Gets the the value for the CSS function 'translateX' or 'translateY'.
	 * 
	 * @param  {Object} 	refStart 	If set, this means the translate moves forward from this value (e.g., 12 for '12px' or '34%')
	 * @param  {Object} 	refEnd   	If set, this means the translate moves backward from this value (e.g., 12 for '12px' or '34%')
	 * @param  {Object}		offset		Usually used to move the layer away from its final position to create a sliding effect. (e.g., 12 for '12px' or '34%')
	 * 
	 * @return {Function} 	translate	e.g., 12 => '12px' or 'calc(100% - 12px)'
	 */
	const getTranslate = (refStart, refEnd, offset) => {
		const offsetExists = exists(offset)
		const offsetIsNbr = offsetExists ? !isNaN(offset*1) : false
		const _offset = offsetIsNbr ? offset*1 : offset

		let fn
		if (exists(refStart)) {
			const isNbr = !isNaN(refStart*1)
			const _refStart = isNbr ? refStart*1 : refStart
			if (isNbr) {
				if (offsetExists)
					fn = offsetIsNbr ? v => `calc(${_refStart+_offset}px + ${addUnit(v)})` : v => `calc(${_refStart}px + ${_offset} + ${addUnit(v)})`
				else 
					fn = v => `calc(${_refStart}px + ${addUnit(v)})` 
			} else {
				if (offsetExists)
					fn = offsetIsNbr ? v => `calc(${_refStart} + ${_offset}px + ${addUnit(v)})` : v => `calc(${_refStart} + ${addUnit(v)} + ${_offset})`
				else 
					fn = v => `calc(${_refStart} + ${addUnit(v)})`
			}
		} else if (exists(refEnd)) { 
			const isNbr = !isNaN(refEnd*1)
			const _refEnd = isNbr ? refEnd*1 : refEnd
			if (isNbr) {
				if (offsetExists)
					fn = offsetIsNbr ? v => `calc(100% - ${_refEnd+_offset}px + ${addUnit(v)})` : v => `calc(100% - ${_refEnd}px - ${_offset} + ${addUnit(v)})`
				else 
					fn = v => `calc(100% - ${_refEnd}px + ${addUnit(v)})`
			} else {
				if (offsetExists)
					fn = offsetIsNbr ? v => `calc(100% - ${_refEnd} - ${_offset}px + ${addUnit(v)})` : v => `calc(100% - ${_refEnd}  + ${addUnit(v)} - ${_offset})`
				else 
					fn = v => `calc(100% - ${_refEnd}  + ${addUnit(v)})`
			}
		} else {
			if (offsetExists)
				fn = offsetIsNbr ? v => `calc(${_offset}px + ${addUnit(v)})` : v => `calc(${_offset} + ${addUnit(v)})`
			else
				fn = v => addUnit(v)
		}

		return value => exists(value) ? fn(value||0) : null 
	}

	/**
	 * Gets the configured function that can create a CSS transform value. 
	 * 
	 * @param  {Object} layer.top										Layer's static position (e.g., 12 for '12px' or '34%')
	 * @param  {Object} layer.right										Layer's static position (e.g., 12 for '12px' or '34%')
	 * @param  {Object} layer.bottom									Layer's static position (e.g., 12 for '12px' or '34%')
	 * @param  {Object} layer.left										Layer's static position (e.g., 12 for '12px' or '34%')
	 * @param  {Object} layer.handlers[].show.zone						e.g., { top:'100%' }
	 * @param  {Object} layer.handlers[].show.effects[].name			e.g., 'fade', 'slide'
	 * @param  {Object} layer.handlers[].show.effects[].transition		e.g., { duration:200, curve:'linear' }
	 * @param  {Object} layer.handlers[].show.effects[].direction		Only valid for 'slide' (e.g., 'up', 'down', 'left', 'right')
	 * @param  {Object} layer.handlers[].show.effects[].start			e.g., 0 (1) or '-100%' (2)
	 * @param  {Object} layer.handlers[].show.effects[].end				e.g., 1 (1) or 0 (2)
	 * 
	 * @return {Function}
	 *
	 * (1) For the 'fade' effect, CSS 'opacity' is the controlled variable. 
	 * (2) For the 'slide' effect, CSS 'tranform' with the 'translateX' or 'translateY' function is the controlled variable. Values
	 * expressed in % are relative the the layer's size. 
	 */
	const getCssTransform = (layer) => {
		// Create the axis translate function using the layer's statis position (i.e., its original absolute position inside their cell).
		const getHorizontalTranslate = getTranslate(layer.left, layer.right)
		const getVerticalTranslateFromRef = getTranslate(layer.top, layer.bottom)
		const scaleCss = exists(layer.scale) 
			? Array.isArray(layer.scale) 
				? `${exists(layer.scale[0]) ? `scaleX(${layer.scale[0]})` : ''} ${exists(layer.scale[1]) ? `scaleY(${layer.scale[1]})` : ''}`
				: `scale(${layer.scale})`
			: ''
		/**
		 * [description]
		 * @param  {Number} data.translateX		
		 * @param  {Number} data.translateY
		 * 
		 * @return {String} transformCSS									e.g., ' translateX(15px) translateY(10px)'
		 */
		return data => {
			data = data || {}

			let transformCSS = scaleCss
			const translateX = getHorizontalTranslate(data.translateX)
			const translateY = getVerticalTranslateFromRef(data.translateY)
			if (translateX !== null)
				transformCSS += ` translateX(${translateX})`
			if (translateY !== null)
				transformCSS += ` translateY(${translateY})`

			return transformCSS || 'none'
		}
	}

	const createAnimatedLayerVars = (layer,toggled) => {
		const defaultOpacity = getLayerDefaultOpacity(layer)
		const defaultTranslate = getLayerDefaultTranslate(layer.handlers)
		return { translateX:defaultTranslate.x, translateY:defaultTranslate.y, show:toggled, opacity:defaultOpacity }
	}

	const resetAnimatedLayerVars = () => {
		animatedLayerVars.update(() => {
			const newVal = {}
			for (let i=0;i<toggledLayers.length;i++) {
				const [layer,,,toggled] = toggledLayers[i]
				newVal[layer.id] = createAnimatedLayerVars(layer,toggled)
			}

			return newVal
		})
	}

	/**
	 * 
	 * 
	 * @param  {Object} handlers[].show.zone					e.g., { top:'100%' }
	 * @param  {Object} handlers[].show.effects[].name			e.g., 'fade', 'slide'
	 * @param  {Object} handlers[].show.effects[].transition	e.g., { duration:200, curve:'linear' }
	 * @param  {Object} handlers[].show.effects[].direction		Only valid for 'slide' (e.g., 'up', 'down', 'left', 'right')
	 * @param  {Object} handlers[].show.effects[].start			e.g., 0 (1) or '-100%' (2)
	 * @param  {Object} handlers[].show.effects[].end			e.g., 1 (1) or 0 (2)
	 * 
	 * @return {String} cssTransition							e.g., transform 100ms linear, opacity 200ms ease-in	
	 *
	 * (1) For the 'fade' effect, CSS 'opacity' is the controlled variable. 
	 * (2) For the 'slide' effect, CSS 'tranform' with the 'translateX' or 'translateY' function is the controlled variable. Values
	 * expressed in % are relative the the layer's size. 
	 */
	const getCssTransition = (handlers) => {
		const defaultTransition = 'all 0s ease 0s'
		if (!handlers || !handlers[0])
			return defaultTransition

		const trans = {}
		collect(
			handlers, 
			fx => {
				if (fx && fx.transition && fx.transition.duration) {
					const isFade = fx.name == 'fade'
					const isSlide = fx.name == 'slide'
					if (isFade || isSlide) {
						const prop = isFade ? 'opacity' : isSlide ? 'transform' : null
						if (prop && !trans[prop])
							trans[prop] = `${fx.transition.duration}ms ${fx.transition.curve||'linear'}`
					}
				}
			},
			['show', '[]effects'])

		return trans.opacity || trans.transform
			? transition.css(trans, true, false, true)
			: defaultTransition
	}

	/**
	 * 
	 * @param  {Number} layer.opacity								
	 * @param  {Object} layer.handlers[].show.zone					e.g., { top:'100%' }
	 * @param  {Object} layer.handlers[].show.effects[].name		e.g., 'fade', 'slide'
	 * @param  {Object} layer.handlers[].show.effects[].transition	e.g., { duration:200, curve:'linear' }
	 * @param  {Object} layer.handlers[].show.effects[].direction	Only valid for 'slide' (e.g., 'up', 'down', 'left', 'right')
	 * @param  {Object} layer.handlers[].show.effects[].start		e.g., 0 (1) or '-100%' (2)
	 * @param  {Object} layer.handlers[].show.effects[].end			e.g., 1 (1) or 0 (2)
	 * 
	 * @return {Object} defaultOpacity								e.g., 0 or 1
	 *
	 * (1) For the 'fade' effect, CSS 'opacity' is the controlled variable. 
	 * (2) For the 'slide' effect, CSS 'tranform' with the 'translateX' or 'translateY' function is the controlled variable. Values
	 * expressed in % are relative the the layer's size. 
	 */
	// OK
	const getLayerDefaultOpacity = (layer) => {
		const handlers = layer.handlers
		const layerOpacity = exists(layer.opacity) ? (layer.opacity < 0 ? 0 : layer.opacity > 1 ? 1 : layer.opacity) : null

		if (layerOpacity !== null)
			return layerOpacity

		const defaultOpacity = 1
		if (!handlers || !handlers[0])
			return defaultOpacity

		// Find the first fade effect
		const fadeEffect = find(
			handlers, 
			fx => fx && fx.name == 'fade' && exists(fx.start) && exists(fx.end), 
			['show', '[]effects'])

		if (!fadeEffect)
			return defaultOpacity

		return fadeEffect.start
	}

	/**
	 * 
	 * 
	 * @param  {Object} handlers[].show.zone					e.g., { top:'100%' }
	 * @param  {Object} handlers[].show.effects[].name			e.g., 'fade', 'slide'
	 * @param  {Object} handlers[].show.effects[].transition	e.g., { duration:200, curve:'linear' }
	 * @param  {Object} handlers[].show.effects[].direction		Only valid for 'slide' (e.g., 'up', 'down', 'left', 'right')
	 * @param  {Object} handlers[].show.effects[].start			e.g., 0 (1) or '-100%' (2)
	 * @param  {Object} handlers[].show.effects[].end			e.g., 1 (1) or 0 (2)
	 * 
	 * @return {Object} defaultTranslate.x							e.g., 10 (for '10px') or '10%'
	 * @return {Object} defaultTranslate.y							e.g., 10 (for '10px') or '10%'
	 *
	 * (1) For the 'fade' effect, CSS 'opacity' is the controlled variable. 
	 * (2) For the 'slide' effect, CSS 'tranform' with the 'translateX' or 'translateY' function is the controlled variable. Values
	 * expressed in % are relative the the layer's size. 
	 */
	// OK
	const getLayerDefaultTranslate = (handlers) => {
		const defaultTranslate = { x:0,y:0 }
		if (!handlers || !handlers[0])
			return defaultTranslate

		// Find the first slide effect
		const slideEffect = find(
			handlers, 
			fx => fx && fx.name == 'slide' && fx.direction, 
			['show', '[]effects'])

		if (!slideEffect)
			return defaultTranslate

		if (/(up|down)/.test(slideEffect.direction))
			defaultTranslate.y = slideEffect.start
		else
			defaultTranslate.x = slideEffect.start
		
		return defaultTranslate
	}
	
	/**
	 * Gets the configured function that can create a CSS transform value. 
	 * 
	 * @param  {Object} layer.top											Layer's static position (e.g., 12 for '12px' or '34%')
	 * @param  {Object} layer.right											Layer's static position (e.g., 12 for '12px' or '34%')
	 * @param  {Object} layer.bottom										Layer's static position (e.g., 12 for '12px' or '34%')
	 * @param  {Object} layer.left											Layer's static position (e.g., 12 for '12px' or '34%')
	 * @param  {Object} layer.handlers[].show.zone						e.g., { top:'100%' }
	 * @param  {Object} layer.handlers[].show.effects[].name			e.g., 'fade', 'slide'
	 * @param  {Object} layer.handlers[].show.effects[].transition		e.g., { duration:200, curve:'linear' }
	 * @param  {Object} layer.handlers[].show.effects[].direction		Only valid for 'slide' (e.g., 'up', 'down', 'left', 'right')
	 * @param  {Object} layer.handlers[].show.effects[].start			e.g., 0 (1) or '-100%' (2)
	 * @param  {Object} layer.handlers[].show.effects[].end			e.g., 1 (1) or 0 (2)
	 * 
	 * @return {Function}
	 *
	 * (1) For the 'fade' effect, CSS 'opacity' is the controlled variable. 
	 * (2) For the 'slide' effect, CSS 'tranform' with the 'translateX' or 'translateY' function is the controlled variable. Values
	 * expressed in % are relative the the layer's size. 
	 */
	const getLayoutCssVars = (layer, toggled) => {
		const layerId = layer.id
		const getLayerCssTransform = getCssTransform(layer)
		const defaultLayerVars = createAnimatedLayerVars(layer, toggled)
		const editOnOpacity = opacityParse(layer.opacity)
		const editOnDisplay = toggled ? 'flex' : 'none'
		const editOnTransform = getLayerCssTransform({ translateX:0, translateY:0 })
		const editOnLayoutCssVars = `--lu-layer-transform:${editOnTransform};--lu-layer-display:${editOnDisplay};--lu-layer-anim-opacity:${editOnOpacity};` 

		return (animatedVars, editOn) => {
			if (editOn)
				return editOnLayoutCssVars
			else {
				const animatedLayerVars = animatedVars[layerId]
				const layerAnimatedVars = animatedLayerVars || defaultLayerVars
				const transformCss = getLayerCssTransform(layerAnimatedVars)
				const layerVisible = (layerAnimatedVars.show === undefined && toggled) || layerAnimatedVars.show
				const displayCss = layerVisible ? 'flex' : 'none'
				const opacityVar = animatedLayerVars ? `--lu-layer-anim-opacity:${layerAnimatedVars.opacity};` : ''

				return `--lu-layer-transform:${transformCss};--lu-layer-display:${displayCss};${opacityVar}`
			}
		}
	}

	const getLayerVars = layer => {
		if (!layer)
			layer = {}
		const _align = layer.align 
			? alignParse(layer.align, { horizontal:'left', vertical:'top' }) 
			: {}

		const _sticky = layer.sticky || {}
		const _stickyTop = _sticky.type == 'top' || _sticky.type == 'top_screen'
		const _stickyBottom = _sticky.type == 'bottom' || _sticky.type == 'bottom_screen'
		const _stickyOn = _stickyTop || _stickyBottom
		const _fixedStickyOn = _stickyOn && (layer.sticky.type == 'top_screen' || layer.sticky.type == 'bottom_screen')
		const _cssTransition = getCssTransition(layer.handlers)
		const _opacity = opacityParse(layer.opacity)
		const _transformOrigin = transformOriginParse(layer.transformOrigin)
		const _height = layer.component && layer.component.height && `${layer.component.height}`.indexOf('%') > 0 ? '100%' : 'auto'

		return css(
		//minifystart
			`--lu-layer-align-items: ${_align.alignItems||'flex-start'};
		--lu-layer-justify-content: ${_align.justifyContent||'flex-start'};
		--lu-layer-position: ${_stickyOn ? _fixedStickyOn ? 'fixed' : 'sticky' : 'static'};
		--lu-layer-sticky-top: ${_stickyOn && _stickyTop ? addUnit(layer.sticky.value||0) : 'auto'};
		--lu-layer-sticky-bottom: ${_stickyOn && _stickyBottom ? addUnit(layer.sticky.value||0) : 'auto'};
		--lu-layer-transition: ${_cssTransition};
		--lu-layer-opacity: var(--lu-layer-anim-opacity, ${_opacity});
		--lu-layer-height: ${_height};
		--lu-layer-transform-origin: ${_transformOrigin};`
		//minifyend
		)
	}

	/**
	 * 
	 * @param  {String} 	handlers[].event		e.g., 'scroll', 'click'
	 * @param  {String} 	handlers[].emit			e.g., 'my-custom-event'
	 * 
	 * @return {Function}	componentClickHandler
	 */
	const getLayerComponentClickHandler = handlers => {
		if (!handlers || !handlers[0])
			return null  

		const clickHandler = handlers.find(h => h.event == 'click')
		if (!clickHandler || !clickHandler.emit)
			return null

		return () => emitCustomEvent(clickHandler.emit)
	}

	const getRootVars = cell => {
		const aspectRatio = cell.aspectRatio && cell.aspectRatio.width > 0 && cell.aspectRatio.height > 0
			? `${(100*cell.aspectRatio.height/cell.aspectRatio.width).toFixed(2)}%` 
			: '0%'

		return css(`
		--lu-cell-background-color: ${chooseColor(inject(cell.backgroundColor),'transparent')};
		--lu-cell-aspect-ratio: ${aspectRatio};
		`)
	}

	const getCssStyle = (selected, multiSelect, editToggled, cell, rowVisible) => {
		const cursor = editToggled && selected && !multiSelect ? 'move' : 'default'
		const overflow = cell.overflow||{}

		return `--lu-cell-cursor:${cursor};--lu-cell-overflow-x: ${rowVisible ? overflow.x||'visible' : 'hidden'};--lu-cell-overflow-y: ${rowVisible ? overflow.y||'visible' : 'hidden'};`
	}

	/* 
	=======================================================
	END - PRIVATE FUNCTIONS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT HANDLERS
	=======================================================
	*/

	const onRightClick = () => {
		// e.preventDefault()
		console.log('RIGHT CLICK')
	}

	const onDragStart = e => {
		if (draggable) {
			dragging.set(true)
			emitDragStart({ id:cell.id, clientX: e.clientX, clientY: e.clientY })
			e.dataTransfer.setData('text', cell.id)
			e.dataTransfer.setDragImage(new Image(),0,0)
		}
	}

	const onMouseDown = () => {
		emitMouseDown({ id:cell.id, layers:cell.layers })
	}

	const onKeyDown = event => {
		event.preventDefault()
		if (event.key == 'Backspace')
			emitDelete({ id:cell.id, selected:true })
		else {
			const direction = event.key == 'ArrowLeft' ? 'left' : event.key == 'ArrowRight' ? 'right' : event.key == 'ArrowDown' ? 'down' : event.key == 'ArrowUp' ? 'up' : null
			if (direction)
				emitSelectNextCell({ id:cell.id, direction })
		}
	}

	const onComponentClick = (layer, componentClickHandler) => {
		if (editToggled || !layer.component)
			return 
		
		if (componentClickHandler)
			componentClickHandler()

		emitComponentClick({ id:cell.id, layer })
	}

	const onEdgeDown = side => {
		if (resizeable) {
			resizing.set(true)
			emitResizeStart({ id:cell.id, side, getDim })
		}
	}

	const onDragOver = e => e.preventDefault()

	/**
	 * Mutates a the reactive 'animatedLayerVars' store with a layer's translation request.
	 * 
	 * @param  {Layer} 	 layer 
	 * @param  {Boolean} toggled 
	 * @param  {String}  axis			Valid values: 'x' or 'y'
	 * @param  {Number}  data.translate		
	 * 
	 * @return {Void}
	 */
	const onTranslateLayer = (layer, toggled, axis, data) => {
		if (editToggled)
			return 

		const layerId = layer.id
		animatedLayerVars.update(v => {
			if (!v[layerId])
				v[layerId] = createAnimatedLayerVars(layer, toggled)

			const layerVars = v[layerId]
			let mutationOccured = false
			if (axis == 'x' && layerVars.translateX !== data.translate) {
				mutationOccured = true
				layerVars.translateX = data.translate
			}
			else if (axis == 'y' && layerVars.translateY !== data.translate) {
				mutationOccured = true
				layerVars.translateY = data.translate
			}

			return mutationOccured ? { ...v } : v // first option creates a re-render
		})
	}

	/**
	 * Mutates a the reactive 'animatedLayerVars' store with a layer's display request.
	 * 
	 * @param  {Layer} 	 layer		
	 * @param  {Boolean} toggled 
	 * @param  {String}  action				Valid values: 'show' or 'hide'	
	 * @param  {Number}  data.translateX		
	 * @param  {Number}  data.translateY
	 * @param  {Number}  data.opacity
	 * @param  {Number}  data.transitionDuration
	 * 				
	 * @return {Void}
	 */
	const onDisplayLayer = (layer, toggled, action, data) => {
		if (editToggled)
			return 

		const layerId = layer.id

		animatedLayerVars.update(v => {
			if (!v[layerId])
				v[layerId] = createAnimatedLayerVars(layer, toggled)

			const layerVars = v[layerId]
			let mustShow, mustHide
			if (action == 'show' && (!layerVars.show || layerVars.hiding) && !layerVars.showing)
				mustShow = true
			else if (action == 'hide' && (layerVars.show || layerVars.showing) && !layerVars.hiding)
				mustHide = true

			// If layer transformations exists, execute them in the next tick
			let transformLayer
			if (mustShow||mustHide) {
				// Cancel pending post update execution
				if (layerVars.clearShowPostUpdate)
					layerVars.clearShowPostUpdate()

				const { translateX, translateY, opacity } = data||{}
				const translateXChanged = exists(translateX) && layerVars.translateX !== translateX
				const translateYChanged = exists(translateY) && layerVars.translateY !== translateY
				const opacityChanded = exists(opacity) && layerVars.opacity !== opacity
				if (translateXChanged || translateYChanged || opacityChanded) {
					transformLayer = layerVars => {
						if (translateXChanged)
							layerVars.translateX = translateX
						if (translateYChanged)
							layerVars.translateY = translateY
						if (opacityChanded)
							layerVars.opacity = opacity
					}
				}
			}

			if (mustShow) {
				layerVars.show = true
				if (transformLayer) {// Delay the execution of layer transform after the layer is displayed.
					const showPostUpdate = setTimeout(() => {
						animatedLayerVars.update(v => {
							const layerVars = v[layerId]
							layerVars.showing = false
							if (layerVars.show) { // confirm the layer is still visible. It could have been hidden in the meantime.
								transformLayer(layerVars)
								return {...v}
							} else 
								return v
						})
					}, 50)
					layerVars.showing = true
					layerVars.clearShowPostUpdate = () => {
						layerVars.showing = false
						clearTimeout(showPostUpdate)
					}
				}
			} else if (mustHide) {
				const delayHidding = transformLayer && data.transitionDuration > 0
				if (transformLayer)
					transformLayer(layerVars)
				if (delayHidding) {
					const showPostUpdate = setTimeout(() => { // Delay the hidding the layer after the its transformation are done.
						animatedLayerVars.update(v => {
							const layerVars = v[layerId]
							layerVars.hiding = false
							if (layerVars.show) {
								layerVars.show = false
								return {...v}
							} else 
								return v
						})
					}, data.transitionDuration)

					layerVars.hiding = true
					layerVars.clearShowPostUpdate = () => {
						if (layerVars.hiding)
							layerVars.hiding = false
						clearTimeout(showPostUpdate)
					}
				} else
					layerVars.show = false
			}

			return mustShow||mustHide ? { ...v } : v // first option creates a re-render
		})
	}

	onMount(() => {
		valueUnsubscribes = subscribeToValueChanges(cell.id, cell.layers)

		if (!getDim && node)
			getDim = nodeDim(node)
		
		emitMount({ id:cell.id, getDim })
	})

	onDestroy(() => {
		if (valueUnsubscribes)
			valueUnsubscribes.map(f => f())
		
		emitDestroy({ id:cell.id })
	})

	afterUpdate(() => {
		// console.log(`Cell ${cell.id} re-rendered`)
	})

	/* 
	=======================================================
	END - EVENT HANDLERS
	=======================================================
	*/









	/* 
	=======================================================
	START - REACTIVE VALUES
	=======================================================
	*/

	$: rootVars = getRootVars(cell)
	$: editToggled = edit || editMode || false
	$: cellSelected = getReactiveCellSelectedState(cell.id)
	$: selected = $cellSelected ? $cellSelected.selected : false
	$: multiSelect = $cellSelected ? $cellSelected.multi : false
	$: toggledLayers = getToogledLayers(cell.layers, toggledLayerIds)
	$: emptyCell = toggledLayers.length == 0
	$: resizeable = editToggled && !$dragging && !multiSelect
	$: draggable = editToggled && !$resizing && !multiSelect
	$: cssStyle = getCssStyle(selected, multiSelect, editToggled, cell, rowVisible)
	$: { if (selected) focus() }
	$: { resetAnimatedLayerVars(editToggled, toggledLayers) }

	// Uncomment this block to log which property handlers re-renders.
	// 
	// $: { if (rootVars) console.log(`Cell ${cell.id}: rootVars changed`) }
	// $: { if (editToggled) console.log(`Cell ${cell.id}: editToggled changed`) }
	// $: { if (cellSelected) console.log(`Cell ${cell.id}: cellSelected changed`) }
	// $: { if (selected) console.log(`Cell ${cell.id}: selected changed`) }
	// $: { if (multiSelect) console.log(`Cell ${cell.id}: multiSelect changed`) }
	// $: { if (toggledLayers) console.log(`Cell ${cell.id}: toggledLayers changed`) }
	// $: { if (emptyCell) console.log(`Cell ${cell.id}: emptyCell changed`) }
	// $: { if (resizeable) console.log(`Cell ${cell.id}: resizeable changed`) }
	// $: { if (draggable) console.log(`Cell ${cell.id}: draggable changed`) }
	// $: { if (cssStyle) console.log(`Cell ${cell.id}: cssStyle changed`) }

	/* 
	=======================================================
	END - REACTIVE VALUES
	=======================================================
	*/
</script>

<div 
	id={cell.id} 
	data-type="cell"  
	tabindex={editToggled ? 0 : undefined} 
	style="{cssStyle}"
	class="{rootVars} {rootClass} {cellSelectedClass} cell-filled {editToggled ? 'cell-edit' : ''}"
	{draggable}
	on:contextmenu={editToggled ? onRightClick : null}
	on:dragstart={editToggled ? onDragStart : null}
	on:dragend={emitDragEnd}
	on:dragover={onDragOver}
	on:mousedown={editToggled ? onMouseDown : null}
	on:keydown={editToggled ? onKeyDown : null}
	bind:this={node}
	>
	<div class="cell-aspect-ratio"></div>
	{#if editToggled && (resizeableEdgeTop || resizeableEdgeRight || resizeableEdgeBottom || resizeableEdgeLeft)}
	<div class="{selected ? ' cell-selected' : ''}{multiSelect ? ' cell-multi-select' : ''}">
		<div class="cell-edge cell-h-edge top-edge {!selected && !resizeableEdgeTop ? 'hide-edge' : ''}"></div>
		<div 
			class="cell-edge cell-v-edge right-edge {!selected && !resizeableEdgeRight ? 'hide-edge' : ''} {!lastCell && resizeable ? 'v-resize-cursor' : ''}" 
			on:mousedown={() => onEdgeDown('right')}>
			{#if !lastCell && resizeable}
			<div class="resize-marker vert"></div>
			{/if}
		</div>
		<div class="cell-edge cell-h-edge bottom-edge {!selected && !resizeableEdgeBottom ? 'hide-edge' : ''} {resizeable ? 'h-resize-cursor' : ''}"
			on:mousedown={() => onEdgeDown('bottom')}>
			{#if resizeable}
			<div class="resize-marker hor"></div>
			{/if}
		</div>
		<div 
			class="cell-edge cell-v-edge left-edge {!selected && !resizeableEdgeLeft ? 'hide-edge' : ''} {!firstCell && resizeable ? 'v-resize-cursor' : ''}"
			on:mousedown={() => onEdgeDown('left')}>
			{#if !firstCell && resizeable}
			<div class="resize-marker vert"></div>
			{/if}
		</div>
	</div>
	{/if}
	<div class="cell-body{editToggled ? ' disable-component-on-edit': ''}">
		{#if emptyCell}
			<div style="width: 100%;height: 40px;"></div>
		{:else}
			{#each toggledLayers as layerData (layerData[0].id)}
			<div id="{layerData[0].id}" data-type="layer" style="{layerData[1]($animatedLayerVars, editToggled)}" class="{layerData[2]} cell-layer">
				{#key layerData[6]}
				<div 
					class="cell-layer-container"
					use:triggerable={layerData[6]}
					on:translatex={d => onTranslateLayer(layerData[0], layerData[3], 'x', d.detail)}
					on:translatey={d => onTranslateLayer(layerData[0], layerData[3], 'y', d.detail)}
					on:show={d => onDisplayLayer(layerData[0], layerData[3], 'show', d.detail)}
					on:hide={d => onDisplayLayer(layerData[0], layerData[3], 'hide', d.detail)}>
					<svelte:component 
						this={getComponentClass(layerData[0].component)} 
						{...layerData[5]}
						value={layerValues[layerData[0].id]}
						on:afterupdate={d => afterContentUpdate(layerData[0], d.detail)}
						on:click={d => onComponentClick(layerData[0], layerData[4], d.detail)}/>
				</div>
				{/key}
			</div>
			{/each}
		{/if}
	</div>
</div>
