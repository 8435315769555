// NOTE: The following functions were extracted from the 'Lineup.svelte' component in order to be easily unit tested with
// Mocha
// Functions:
// - addBreakpoint
// - addLayers
// - adjustRowCellsWidth
// - deleteBreakpoint
// - getActiveBreakpoint
// - getActiveViewportRange
// - getCell
// - getCurrentBreakpointViewport
// - getCurrentViewportRange
// - getRowCells
// - getViewportRanges
// - processTriggers
// - removeLayer
// - updateGrids

// {
// 	id: '_12345',
// 	type: 'lineup',
// 	name: 'sample_01',
//	grids:[{
//		viewportWidth: 0,
//		rows: [
//			[{
//				id:'_1',
//				width: 33,
//				widthUnit: '%',
//				layers: [{
//					id:0,
//					name: 'layer_0'
//					toggled: true,
//					component: {
//						id: 'button',
//						padding: 10,
//						text:'Submit',
//						width:100
//					}
//				}]
//			},{
//				id:'_2',
//				width: 67,
//				widthUnit: '%',
//				layers:[]
//			}],
//			[{
//				id:'_3',
//				width: 70,
//				widthUnit: '%',
//				layers:[{
//					id:0,
//					name: 'layer_0'
//					toggled: true,
//					component: {
//						id: 'textfield',
//						name: 'short_comment',
//						padding: 10,
//						variant: 'outlined',
//						placeholder: 'Enter something'
//					}
//				}]
//			},{ 
//				id:'_4',
//				width:30,
//				widthUnit: '%',
//				layers:[{
//					id:0,
//					name: 'layer_0'
//					toggled: true,
//					component: {
//						id: 'textfield',
//						name: 'reason',
//						padding: 10,
//						variant: 'outlined',
//						placeholder: 'Enter something'
//					}
//				}]
//			}],
//			[{
//				id:'_5',
//				width: 100,
//				widthUnit: '%',
//				layers:[{
//					id:0,
//					name: 'layer_0'
//					toggled: true,
//					component: {
//						id: 'textfield',
//						name: 'additional_note',
//						padding: 10,
//						variant: 'outlined',
//						placeholder: 'Enter something'
//					}
//				}]
//			}]
//		]
//	}]
// }

import { injectGlobal } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
import { push, pop } from '../../../core/collection'
import { goToUrl, DOC } from '../../../core/dom'
import { fetch as webFetch } from '../../../core/fetch'

const BODY_CLASS = 'lu-body-23wuh5gs'
const PAGE_CLASS = 'lu-page-body-23wuh5gs'

injectGlobal(`
.${BODY_CLASS} {
	margin: 0;
	overflow: hidden;

	&.${PAGE_CLASS} {
		width: 100%;
		position: fixed;
		overscroll-behavior: none;
	}
}
`)

const getId = () => '_'+Math.random().toString(36).substr(2, 9)

/**
 * Gets all the viewports defined in the cells' breakpoints sorted in ascending order.
 * 
 * @param  {Number}		grids[].viewportWidth
 * @param  {String}		options.format						Valid value: 'string'. This options changes the return type to string. 
 *                                       					This can prevent the output to re-render.
 * 
 * @return {[Number]}	viewports							[500, 800, 1200]
 */
export const getViewportRanges = (grids, options) => {
	const range = Array.from((grids||[]).reduce((acc,g) => {
		acc.add(g.viewportWidth || 0)
		return acc
	}, new Set())).sort((x,y) => x>y?1:-1)

	return options && options.format == 'string' ? `${range}` : range
}

/**
 * Gets the current viewport range based on the current window's viewport. 
 * NOTE: The output is a string rather than an array because the same string will not trigger a re-render while
 * the a new array will, even if that array contains the same values.
 * 
 * @param  {[Number]} 	viewportRanges			Array from the 'getViewportRanges' function (e.g., [500, 800, 1200]).
 * @param  {Number} 	viewportWidth			Current window's viewport width (e.g., 300, 645 or 1600)
 * 
 * @return {String}		currentViewportRange	e.g., '0,500', '500,800'  or '800,1200+'
 */
export const getCurrentViewportRange = (viewportRanges, viewportWidth, emitViewportChange) => {
	viewportWidth = viewportWidth*1
	const l = !viewportRanges ? 0 : viewportRanges.length
	let currentViewportRange = '0,0+'
	if (l == 0)
		currentViewportRange = '0,0+'
	else if (l == 1)
		currentViewportRange = `${viewportRanges[0]},${viewportRanges[0]}+`
	else {
		const rangeContainsAnInfiniteViewportConfig = !viewportRanges[0]
		currentViewportRange = viewportRanges.reduce((acc, vpLimit, i) => {
			const lastItem = i==l-1
			if (!acc.done) {
				if (viewportWidth <= vpLimit) {
					acc.done = true
					if (lastItem) {
						if (rangeContainsAnInfiniteViewportConfig)
							acc.range = [acc.range.slice(-1)[0], vpLimit]
						else
							acc.range = [acc.range.slice(-1)[0], `${vpLimit}+`]
					} else 
						acc.range = [acc.range.slice(-1)[0], vpLimit]
				} else if (lastItem) {
					if (rangeContainsAnInfiniteViewportConfig)
						acc.range = [vpLimit, `${vpLimit}+`]
					else
						acc.range = [acc.range.slice(-1)[0], `${vpLimit}+`]	
				} else
					acc.range = [acc.range.slice(-1)[0], vpLimit]
			}
			return acc
		}, { done:false, range:[0] }).range.join(',')
	}

	if (emitViewportChange)
		emitViewportChange({ viewportWidth, viewportRange:currentViewportRange })

	return currentViewportRange
}

/**
 * Gets the breakpoint's value that contains the current cells config based on the current viewport. 
 * 
 * @param  {[Grid]} grids
 * @param  {Number} viewportWidth	Current viewport width (e.g., 1234)
 * 
 * @return {Number}					e.g., 0
 */
export const getActiveBreakpoint = (grids, viewportWidth) => {
	const viewportRange = getViewportRanges(grids)
	const currentViewportRange = getCurrentViewportRange(viewportRange, viewportWidth)
	const largestScreen = /\+$/.test(currentViewportRange)

	return largestScreen && viewportRange[0] == 0 ? 0 : currentViewportRange.split(',')[1]*1
}

/**
 * Gets the current viewport range based on the current window's viewport. 
 * NOTE: The output is a string rather than an array because the same string will not trigger a re-render while
 * the a new array will, even if that array contains the same values.
 * 
 * @param  {[Grid]} grids
 * @param  {Number} viewportWidth			Current viewport width (e.g., 1234)
 * 
 * @return {String}	currentViewportRange	e.g., '0,500', '500,800'  or '800,1200+'
 */

export const getActiveViewportRange = (grids, viewportWidth) => {
	const viewportRange = getViewportRanges(grids)
	return getCurrentViewportRange(viewportRange, viewportWidth)
}

export const getCurrentBreakpointViewport = (viewportRanges, viewportWidth, emitViewportChange) => { 
	const currentViewportRange = getCurrentViewportRange(viewportRanges, viewportWidth, emitViewportChange)
	const [rangeStart, rangeEnd] = currentViewportRange.split(',')
	if (/\+$/.test(rangeEnd)) {
		const vp = rangeEnd.replace('+','')
		return rangeStart == vp ? 0 : +vp
	} else 
		return +rangeEnd
}

/**
 * Creates a new grid array with a new grid for that new breakpoint. 
 * NOTE: This is a pure function.
 * 
 * @param  {[Grid]} grids
 * @param  {Number} viewportWidth
 * @param  {Number} copyFromViewportWith
 * 
 * @return {[Grid]}
 */
export const addBreakpoint = (grids, viewportWidth, copyFromViewportWith) => {
	viewportWidth = viewportWidth || 0
	copyFromViewportWith = copyFromViewportWith || 0

	if ((grids||[]).some(g => g.viewportWidth == viewportWidth))
		return grids.map(x => x)

	const referenceGrid = (grids||[]).find(g => g.viewportWidth == copyFromViewportWith)

	if (!referenceGrid)
		return grids.map(x => x)

	const copy = JSON.parse(JSON.stringify(referenceGrid))
	copy.viewportWidth = viewportWidth

	return push(grids, copy)
}

/**
 * Removes a the grid that matches the breakpoint's viewport width. 
 * NOTE: This is a pure function.
 * 
 * @param  {[Grid]} grids         
 * @param  {Number} viewportWidth 
 * 
 * @return {[Grid]}
 */
export const deleteBreakpoint = (grids, viewportWidth) => {
	const l = (grids||[]).length
	if (l == 0)
		return []

	const idx = (grids||[]).findIndex(g => g.viewportWidth == viewportWidth)
	if (idx < 0)
		return grids.map(x => x)

	if (l == 1)
		return []

	return pop(grids, idx)
}

/**
 * Re-adjust all the row cells width based on an width amount added (a cell inserted) or removed (a cell removed).
 * 
 * NOTE: The returns array is a new one, but some cells might be unmutated reference to the original cells.
 * 
 * @param  {String}   rowCells[].id			Cell's ID
 * @param  {Number}   rowCells[].width		e.g., 33
 * @param  {String}   rowCells[].widthUnit	e.g., '%'
 * @param  {Object}   rowCells[]...			Rest of the cell's props
 * @param  {Number}   widthPerc				Additional width in percentage.
 * @param  {[String]} removeCellIds			If present, ignore the 'widthPerc' prop, remove these cells from rowCells and 
 *                                 			adjust the rest of the cells.
 * 
 * @return {String}  results.adjustedAdditionalFlexWidth
 * @return {String}  results.cells[].id
 * @return {Number}  results.cells[].width	
 * @return {Object}  results.cells[].widthUnit
 * @return {Boolean} results.cells[]...			Rest of the cell's props
 */
export const adjustRowCellsWidth = (rowCells, { additionalFlexWidth, removeCellIds }) => {
	additionalFlexWidth = additionalFlexWidth || 0
	if (!additionalFlexWidth && !removeCellIds)
		return { cells: rowCells, adjustedAdditionalFlexWidth:additionalFlexWidth }

	if (additionalFlexWidth && removeCellIds)
		throw new Error('Invalid argument exception. \'additionalFlexWidth\' and \'removeCellIds\' cannot be specified at the same time.')

	const includeCell = !removeCellIds ? (() => true) : (cell => !removeCellIds.includes(cell.id))

	return adjustCellsWidth(rowCells, includeCell, additionalFlexWidth)
}

/**
 * Gets a cell from a array of rows. Returns null if the cell is not found.
 * 
 * @param  {[Row]} 	rows
 * @param  {String} id		Cell's ID
 * 
 * @return {Cell} 	[0]		Cell 
 * @return {Number} [1]		Row's index
 * @return {Number} [2]		Cell's index in that row 
 */
export const getCell = (rows, id) => {
	if (!id)
		return null 

	let cell, rowIndex, cellIndex
	for (let i=0,l=(rows||[]).length;i<l;i++) {
		const cells = rows[i].cells
		cellIndex = cells.findIndex(c => c.id == id)
		if (cellIndex >= 0) {
			rowIndex = i 
			cell = cells[cellIndex]
			break
		}
	}

	return cell ? [cell, rowIndex, cellIndex] : null
}

const isRowMutation = row => {
	return row && (
		row.height !== undefined
		|| row.flex !== undefined
		|| row.backgroundColor !== undefined
		|| row.backgroundColorOrientation !== undefined
		|| row.sectionId !== undefined
		|| row.show !== undefined
		|| row.showEvent !== undefined
		|| row.hideEvent !== undefined
		|| row.showEffects !== undefined
	)
}

/**
 * Updates a cell and the updated grids. Mutations will only apply to:
 * - The 'grids' array reference will change
 * - Only the grid items for the targetted viewportWidth are mutated. 
 * - The row (i.e., the cells array) containing the cell is mutated.
 * 
 * @param  {[Grid]}  grids						
 * @param  {String}  cell.id						Cell's ID.
 * @param  {String}  cell.backgroundColor			
 * @param  {Layer}   cell.layer
 * @param  {Number}  cell.width
 * @param  {Number}  cell.widthUnit					e.g., '%' or 'px'
 * @param  {String}  cell.overflow.x
 * @param  {String}  cell.overflow.y
 * @param  {Number}  cell.aspectRatio.width
 * @param  {Number}  cell.aspectRatio.height
 * @param  {String}  cell.setLayer[].id				Layer ID
 * @param  {String}  cell.setLayer[].keyValuePairs	e.g., [['toggled', true], ['locked': false]]
 * @param  {String}  cell.moveLayer.id
 * @param  {String}  cell.moveLayer.type			Valid values: 'up','front','down','back'
 * @param  {Object}  options.breakpoints			e.g., 'all' (default), '*' or [1200, 1400]
 * 					
 * @return {[Grid]}  updatedGrids						
 */
export const updateGrids = (grids, { id, backgroundColor, layer, overflow, aspectRatio, width, widthUnit, setLayer, moveLayer, row }, options) => {
	const allBreakpoints = !options || !options.breakpoints || options.breakpoints == 'all' || options.breakpoints == '*' || options.breakpoints.includes('*')
	const includeList = (options || {}).breakpoints || []
	const overflowChangeExists = overflow !== undefined
	const aspectRatioChangeExists = aspectRatio === null || (aspectRatio && aspectRatio.width > 0 && aspectRatio.height > 0)
	const changeExists = layer || width || widthUnit || setLayer || overflowChangeExists || row || backgroundColor !== undefined || aspectRatioChangeExists || moveLayer

	const newGrids = grids.map(grid => {
		if (changeExists && (allBreakpoints || includeList.some(x => x == grid.viewportWidth||0))) {
			// Update a row
			if (row && row.id) {
				const rowIndex = grid.rows.findIndex(r => r.id == row.id)
				if (rowIndex < 0)
					return grid 

				const mutatedGrid = { ...grid }
				mutatedGrid.rows = grid.rows.map(r => {
					if (r.id == row.id && isRowMutation(row)) {
						const rCopy = { ...r }
						if (row.height !== undefined)
							rCopy.height = row.height === 'vh' ? 'var(--lu-height-100vh, 100vh)' : row.height
						if (row.flex !== undefined)
							rCopy.flex = row.flex
						if (row.backgroundColor !== undefined)
							rCopy.backgroundColor = row.backgroundColor
						if (row.backgroundColorOrientation !== undefined)
							rCopy.backgroundColorOrientation = row.backgroundColorOrientation
						if (row.sectionId !== undefined)
							rCopy.sectionId = row.sectionId
						if (row.show !== undefined)
							rCopy.show = row.show
						if (row.showEvent !== undefined)
							rCopy.showEvent = row.showEvent
						if (row.hideEvent !== undefined)
							rCopy.hideEvent = row.hideEvent
						if (row.showEffects !== undefined) {
							if (Array.isArray(row.showEffects))
								rCopy.showEffects = row.showEffects
							else if (row.showEffects.name) {
								if (!rCopy.showEffects || !rCopy.showEffects.length)
									rCopy.showEffects = [row.showEffects]
								else {
									const matchedIndex = rCopy.showEffects.findIndex(e => e.name == row.showEffects.name)
									if (matchedIndex>=0)
										rCopy.showEffects[matchedIndex] = row.showEffects
									else 
										rCopy.showEffects.push(row.showEffects)
								}
							}
						}
						return rCopy
					}
					else
						return r
				})

				return mutatedGrid
			}
			// Update a cell 
			else {
				const [cell, rowIndex, cellIndex] = getCell(grid.rows, id)
				if (cell) {
					const mutatedCell = { ...cell }
					const mutatedGrid = { ...grid }

					// Mutates width
					if (width)
						mutatedCell.width = width

					// Mutates width
					if (backgroundColor !== undefined)
						mutatedCell.backgroundColor = backgroundColor
					
					// Mutates widthUnit
					if (widthUnit)
						mutatedCell.widthUnit = widthUnit

					// Mutates aspect ratio
					if (aspectRatioChangeExists)
						mutatedCell.aspectRatio = aspectRatio

					// Mutates overflow
					if (overflowChangeExists) {
						mutatedCell.overflow = cell.overflow ? { ...cell.overflow } : {}

						if (overflow === null)
							mutatedCell.overflow = null 
						else {						
							if (overflow.x)
								mutatedCell.overflow.x = overflow.x
							if (overflow.y)
								mutatedCell.overflow.y = overflow.y
						}
					}
					
					// Mutates layers
					let layerMutated = false
					if (layer && layer.id !== null && layer.id !== undefined && mutatedCell.layers.some(l => l.id == layer.id)) {
						layerMutated = true
						mutatedCell.layers = mutatedCell.layers.map(l => {
							if (l.id == layer.id)
								return layer 
							else
								return l
						})
					}

					// Mutates specific layer's props
					if (setLayer) {
						if (!layerMutated) {
							layerMutated = true
							mutatedCell.layers = mutatedCell.layers.map(l => l)
						}
						for (let i=0;i<setLayer.length;i++) {
							const { id:layerId, keyValuePairs } = setLayer[i]
							const ll = (keyValuePairs||[]).length
							if (!ll)
								continue
							const layerIndex = mutatedCell.layers.findIndex(l => l.id == layerId)
							if (layerIndex < 0)
								continue
							const _layer = { ...mutatedCell.layers[layerIndex] }
							for (let j=0;j<ll;j++) {
								const kvp = keyValuePairs[j]
								const prop = kvp[0]
								if (prop) {
									const fn = kvp[1]
									_layer[prop] = typeof(fn) == 'function' ? fn(_layer[prop]) : kvp[1]
								}
							}
							mutatedCell.layers[layerIndex] = _layer
						}
					}

					// Re-order the layers
					if (moveLayer && moveLayer.id && ['up', 'down', 'front', 'back'].includes(moveLayer.type)) {
						const layerIndex = mutatedCell.layers.findIndex(l => l.id == moveLayer.id)
						const layerCount = mutatedCell.layers.length
						const lastIndex = layerCount-1
						if (layerIndex >= 0) {
							const newIndex = moveLayer.type == 'up' 
								? layerIndex+1 
								: moveLayer.type == 'down' 
									? layerIndex-1
									: moveLayer.type == 'front' 
										? lastIndex
										: 0
							
							if (layerIndex != newIndex && newIndex >= 0 && newIndex <= lastIndex) {
								const targetLayer = mutatedCell.layers[layerIndex]
								const newLayers = new Array(layerCount)
								let offset = 0
								for (let i=0;i<layerCount;i++) {
									if (i == layerIndex) {
										offset--
										continue
									} else if (i == newIndex) {
										newLayers[i] = targetLayer
										if (offset < 0) {
											newLayers[i+offset] = mutatedCell.layers[i]
											offset++
										} else {
											offset++
											newLayers[i+offset] = mutatedCell.layers[i]
										}
									} else
										newLayers[i+offset] = mutatedCell.layers[i]
								}
								mutatedCell.layers = newLayers
							}
						}
					}

					mutatedGrid.rows = grid.rows.map((row,idx) => {
						if (idx == rowIndex)
							return { ...row, cells: row.cells.map((c,i) => i == cellIndex ? mutatedCell : c) }
						else 
							return row
					})

					return mutatedGrid
				} else 
					return grid
			}
		} else 
			return grid 
	})
	
	return newGrids
}

/**
 * Remove a state from a cell. 
 * 
 * @param  {[Grid]} grids   
 * @param  {String} cellId  
 * @param  {Object} layerId 
 * 
 * @return {[Grid]} newGrids
 */
export const removeLayer = (grids, cellId, layerId) => {
	if (!grids || !grids[0] || !cellId || layerId === null || layerId === undefined)
		return grids

	const gl = grids.length
	const newGrids = new Array(gl)
	for (let i=0;i<gl;i++) {
		const grid = grids[i]
		const newGrid = { ...grid }
		const rows = grid.rows
		const rl = rows.length
		const newRows = new Array(rl)
		for (let j=0;j<rl;j++) {
			const row = rows[j]
			const cl = row.cells.length
			const newCells = new Array(cl)
			let mutated = false
			for (let k=0;k<cl;k++){
				const cell = row.cells[k]
				if (cell.id == cellId && cell.layers && cell.layers.length > 1 && cell.layers.some(c => c.id == layerId)) {
					mutated = true
					const mutatedCell = {...cell}
					mutatedCell.layers = cell.layers.filter(c => c.id != layerId)
					newCells[k] = mutatedCell 
				} else 
					newCells[k] = cell 
			}

			newRows[j] = mutated ? { ...row, cells:newCells } : row
		}

		newGrid.rows = newRows
		newGrids[i] = newGrid
	}

	return newGrids
}

/**
 * Gets the cells in the row that contains cellId for a specific breakpoint's viewport width. 
 * 
 * @param  {[Grid]} grids   
 * @param  {String} cellId  
 * @param  {Number} viewportWidth	window's viewport width
 *
 * @return {[Cell]} [0]				Cells
 * @return {Number} [1]				viewportWidth 
 */
export const getRowCells = (grids, cellId, viewportWidth) => {
	const activeBreakpointViewport = getActiveBreakpoint(grids, viewportWidth)
	const grid = (grids||[]).find(g => (g.viewportWidth||0) == activeBreakpointViewport)

	if (!grid)
		return [[],activeBreakpointViewport]

	for (let i=0,l=(grid.rows||[]).length;i<l;i++) {
		const cells = grid.rows[i].cells
		if (cells.some(c => c.id == cellId))
			return [cells, activeBreakpointViewport]
	}

	return [[], activeBreakpointViewport]
}

/**
 * Adds a component inside a cell in all breakpoints 
 * 
 * @param  {[Grid]}  grids				Array of grids.
 * @param  {String}  cellId
 * @param  {[Layer]} layers
 * @param  {Boolean} options.replace	Default false. When true, the component replaces any existing components.	
 * 
 * @return {[Grid]}  output[0]			newGrids
 * @return {[Layer]} output[1]			newLayers
 */
export const addLayers = (grids, cellId, layers, options) => {
	if (!cellId || !grids || !layers || !layers[0])
		return grids

	// Make sure that all layers have a unique ID.
	const _layers = layers.map(l => ({...l, id:getId() }))

	const replaceMode = options && options.replace
	const gl = grids.length
	const newGrids = new Array(gl) 
	let newLayers = null

	// Update each breakpoint grid 
	for (let i=0;i<gl;i++) {
		const grid = grids[i]
		if (!grid || !grid.rows) {
			newGrids[i] = grid 
			continue 		
		}

		const [cell, rowIndex, cellIndex] = getCell(grid.rows, cellId)||[]

		if (!cell) {
			newGrids[i] = grid 
			continue 
		}

		const newGrid = { ...grid }

		// Update each row
		const rl = grid.rows.length
		const newRows = new Array(rl)
		for (let j=0;j<rl;j++) {
			const row =  grid.rows[j]
			if (j != rowIndex) {
				newRows[j] = row
				continue
			}

			// Update each cell
			const cl = row.cells.length
			const newCells = new Array(cl)
			for (let k=0;k<cl;k++) {
				const c = row.cells[k]
				if (k != cellIndex) {
					newCells[k] = c
					continue
				}

				// Add the new component
				const newCell = { ...c }
				const _newLayers = _layers.map(l => {
					const ll = {...l}
					ll.triggers == ll.triggers || {}
					return ll
				})
				newCell.layers = replaceMode ? _newLayers : [...(c.layers||[]), ..._newLayers]
				newLayers = newCell.layers
				newCells[k] = newCell
			}

			newRows[j] = { ...row, cells:newCells }
		}

		newGrid.rows = newRows
		newGrids[i] = newGrid
	}

	return [newGrids,newLayers]
}

/**
 * Updates the grids based on the component triggers
 * 
 * @param  {[grid]} grids      
 * @param  {Number} viewportWidth
 * @param  {Cell} 	cell      
 * @param  {Object} layer
 * @param  {Object} value
 * @param  {Object} toggledCellLayers
 * @param  {String} event     			Valid values: 'click', 'change'
 * 
 * @return {[grid]} output[0]				newGrids          
 * @return {Object} output[1]				newToggledCellLayers          
 */
export const processTriggers = (grids, viewportWidth, cell, layer, value, toggledCellLayers, event) => {
	const empty = [grids, toggledCellLayers]
	if (!layer || !layer.triggers || !event)
		return empty 

	if (event == 'click' && layer.triggers.click)
		return processEventTriggers(layer.triggers.click, grids, viewportWidth, cell, layer, value, toggledCellLayers)
	else if (event == 'change' && layer.triggers.change)
		return processEventTriggers(layer.triggers.change, grids, viewportWidth, cell, layer, value, toggledCellLayers)

	return empty
}

const arrayExists = a => a && a[0]
const exists = v => v !== undefined && v !== null

/**
 * 
 * @param  {String} triggers.goTo.url
 * @param  {String} triggers.goTo.path
 * @param  {String} triggers.goTo.target						HTML target attribute: '_blank', '_self' (default), '_parent', '_top'
 *
 * @param  {String} triggers.toggleLayers[].cellId				Cell's ID that must move untoggle the current layer and toggle the next one.
 * @param  {String} triggers.toggleLayers[].layerId				Layer ID to be toggled
 * 
 * @param  {String} triggers.toggleNextLayers[].cellId			Cell's ID that must move untoggle the current layer and toggle the next one.
 * @param  {String} triggers.toggleNextLayers[].dir				Determines if the next layer is the one after(asc) or before(desc). 
 *                                            					Valid values: 'asc' (default), 'desc'.
 * @param  {String} triggers.submit.url
 * @param  {Object} triggers.submit.headers						e.g.,  { 'Content-Type': 'multipart/form-data' } for files
 * @param  {Object} triggers.submit.successStates[].cellId		Cell's ID that must move to a new state.
 * @param  {Object} triggers.submit.successStates[].layerId		Layer ID to be toggled
 * @param  {Object} triggers.submit.errorStates[].cellId		Cell's ID that must move to a new state.
 * @param  {Object} triggers.submit.errorStates[].layerId		Layer ID to be toggled
 * 
 * @param  {[grid]} grids      
 * @param  {Number} viewportWidth
 * @param  {Cell} 	cell      
 * @param  {Object} layer
 * @param  {Object} value
 * @param  {Object} toggledCellLayers
 * 
 * @return {[grid]} output[0]				newGrids          
 * @return {Object} output[1]				newToggledCellLayers   
 */
const processEventTriggers = async (triggers, grids, viewportWidth, cell, component, value, toggledCellLayers) => {
	const empty = [grids, toggledCellLayers]
	const grid = grids.find(g => g.viewportWidth == viewportWidth)
	if (!grid)
		return empty
	
	const { goTo, toggleNextLayers, toggleLayers, submit } = triggers

	if (goTo)
		goToUrl(goTo)
	else {
		const toggledCellLayers01 = toggleNextLayerTriggers(toggleNextLayers, grid, toggledCellLayers) 
		const toggledCellLayers02 = toggleLayerTriggers(toggleLayers, grid, toggledCellLayers01) 
		const toggledCellLayers03 = await processSubmitTrigger(submit, value, grid, toggledCellLayers02)
		return [grids, toggledCellLayers03]
	}

	return empty
}

/**
 * 
 * @param  {Number}   index			e.g., 6
 * @param  {[Number]} indexes	e.g., [1,3,6,8,11]
 * @param  {Boolean}  asc			e.g., true
 * 
 * @return {Number}   nextIndex		e.g., 8
 */
const getNextIndex = (index, indexes, asc) => {
	if (asc) {
		const result = indexes.find(i => i > index)
		if (result === undefined)
			return indexes.find(i => i < index)
		else 
			return result
	} else {
		const reverseIndexes = indexes.slice().reverse() 
		const result = reverseIndexes.find(i => i < index)
		if (result === undefined)
			return reverseIndexes.find(i => i > index)
		else 
			return result
	}
}

/**
 * Gets the next sequence of toggled layer IDs for a cell. 
 * 
 * @param  {Cell} 	  cell
 * @param  {String}   newState.cellId
 * @param  {String}   newState.dir			Determines if the next state is the one after(asc) or before(desc). 
 *                                      	Valid values: 'asc' (default), 'desc'.
 * @param  {Object}   toggledCellLayers		e.g., { '_123':[1], '_456':[5] }
 * 
 * @return {[Number]} toggledLayerIds
 */
const getNextToggledLayerIds = (cell, newState, toggledCellLayers, index=0, lastIndex, currentlyToggledIds, candidates, candidatesCount, newToggledLayerIds, asc) => {
	if (index === 0) {
		lastIndex = cell.layers.length-1
		currentlyToggledIds = toggledCellLayers[newState.cellId] || []
		candidates = []
		candidatesCount = 0
		newToggledLayerIds = new Array(lastIndex+1)
		asc = !newState.dir || newState.dir == 'asc'
	}

	const layer = cell.layers[index]
	const isToggled = currentlyToggledIds.some(i => i == layer.id)
	const isCandidate = !layer.locked && !isToggled
	const mustMoveToNext = !layer.locked && isToggled
	if (isCandidate) {
		candidatesCount++
		candidates.push(index)
	}

	if (index < lastIndex)
		candidatesCount = getNextToggledLayerIds(cell, newState, toggledCellLayers, index+1, lastIndex, currentlyToggledIds, candidates, candidatesCount, newToggledLayerIds, asc)

	if (layer.locked && layer.toggled)
		newToggledLayerIds[index] = layer.id 
	else if (mustMoveToNext && candidatesCount) {
		if (candidatesCount == 1) {
			const idx = candidates[0]
			newToggledLayerIds[idx] = cell.layers[idx].id 
		} else {
			const idx = getNextIndex(index, candidates, asc)
			if (idx !== undefined)
				newToggledLayerIds[idx] = cell.layers[idx].id 
		}
	}

	return index == 0 ? newToggledLayerIds.filter(x => x !== undefined) : candidatesCount
}

const getToggledLayerIds = (cell, toggledLayerIds) => {
	if (!cell || !cell.layers || !cell.layers[0] || !toggledLayerIds)
		return []
	else
		return cell.layers.reduce((acc,l) => {
			if (l.toggled && l.locked)
				acc.push(l.id)
			else if (!l.locked && toggledLayerIds.some(i => i == l.id))
				acc.push(l.id)
			return acc
		}, [])
}

/**
 * Gets the sequence of toggled layer IDs for a cell when a specific layer is targetted.
 * 
 * @param  {Cell} 	  cell
 * @param  {String}   newState.cellId
 * @param  {String}   newState.layerId		
 * @param  {Object}   toggledCellLayers		e.g., { '_123':1, '_456':5 }
 * 
 * @return {[Number]} toggledLayerIds
 */
const getTargettedToggledLayerIds = (cell, newState, toggledCellLayers) => {
	// The layer ID exists and is not locked
	if (!exists(newState.layerId) || !cell.layers.some(c => c.id == newState.layerId && !c.locked))
		return null

	// The layer ID is not already toggled
	if (toggledCellLayers[newState.cellId] && toggledCellLayers[newState.cellId].some(i => i == newState.layerId))
		return null 
	
	return getToggledLayerIds(cell, [newState.layerId])
}

const processSubmitTrigger = async (submit, value, grid, toggledCellLayers) => {
	if (submit && submit.url && value) {
		const resp  = await webFetch.post({
			uri: submit.url,
			headers: submit.headers||{},
			body: value
		})

		if (arrayExists(submit.successStates) && resp.status && resp.status < 300)
			return toggleLayerTriggers(submit.successStates, grid, toggledCellLayers) 
		else if (arrayExists(submit.errorStates) && (!resp || !resp.status || resp.status >= 400))
			return toggleLayerTriggers(submit.errorStates, grid, toggledCellLayers) 
	} 

	return toggledCellLayers
}

/**
 * 
 * @param  {[Object]} stateTriggers
 * @param  {String}   triggerType		Valid values: 'nextState' or 'newState' (default)
 * 
 * @return {Function}
 */
const processLayerTriggers = triggerType => {
	const getLayerIds = triggerType == 'nextState' ? getNextToggledLayerIds : getTargettedToggledLayerIds
	/**
	 * [description]
	 * @param  {String} stateTriggers[].cellId
	 * @param  {String} stateTriggers[].dir
	 * @param  {String} stateTriggers[].layerId
	 * @param  {Grid}   grid              
	 * @param  {Object} toggledCellLayers 
	 * 
	 * @return {Object} newToggledCellLayers
	 */
	return (stateTriggers, grid, toggledCellLayers) => {
		if (arrayExists(stateTriggers)) {
			let mutationOccured = false
			const newToggledCellLayers = { ...toggledCellLayers }
			for (let i=0;i<stateTriggers.length;i++) {
				const stateTrigger = stateTriggers[i]
				const cellId = stateTrigger.cellId 

				if (!cellId)
					continue

				const [targetCell] = getCell(grid.rows, cellId)
				if (!targetCell || !targetCell.layers || !targetCell.layers[0]) 
					continue

				const newToggledLayerIds = getLayerIds(targetCell, stateTrigger, newToggledCellLayers)

				if (newToggledLayerIds !== null) {
					mutationOccured = true
					newToggledCellLayers[cellId] = newToggledLayerIds
				}
			}

			return mutationOccured ? newToggledCellLayers : toggledCellLayers 
		} else 
			return toggledCellLayers
	}
}

const toggleNextLayerTriggers = processLayerTriggers('nextState')
const toggleLayerTriggers = processLayerTriggers('setState')

const adjustCellsWidth = (rowCells, includeCellFn, additionalFlexWidth, index=0, lastIndex, includedIndexCursor, totalFlexWidth) => {
	if (index === 0) {
		const l = rowCells.length 
		lastIndex = l-1
		totalFlexWidth = 0
		includedIndexCursor = 0
	}

	const cell = rowCells[index]
	const validCell = includeCellFn(cell)
	const flexWidthCell = cell.widthUnit == '%'
	if (validCell) {
		if (flexWidthCell)
			totalFlexWidth += (cell.width||0)
		
		includedIndexCursor++
	}

	if (index == lastIndex) {
		let adjustmentFactor = 1, adjustedAdditionalFlexWidth
		if (totalFlexWidth > 0) {
			const hundredPercAdjustmentFactor = 100/totalFlexWidth 
			const maxAdditionalFlexWidth = ~~100/(lastIndex+1)
			const a = additionalFlexWidth && additionalFlexWidth > maxAdditionalFlexWidth 
				? maxAdditionalFlexWidth 
				: (additionalFlexWidth||0)
			if (additionalFlexWidth)
				adjustedAdditionalFlexWidth = a
			adjustmentFactor = hundredPercAdjustmentFactor*(totalFlexWidth-(a/hundredPercAdjustmentFactor))/totalFlexWidth
		}

		const cells = new Array(includedIndexCursor)
		includedIndexCursor--
		if (validCell && includedIndexCursor >= 0)
			cells[includedIndexCursor--] = flexWidthCell && adjustmentFactor != 1 
				? { ...cell, width:cell.width*adjustmentFactor } 
				: cell

		return { 
			cells, 
			adjustmentFactor,
			includedIndexCursor,
			adjustedAdditionalFlexWidth
		} 
	} else {
		const results = adjustCellsWidth(rowCells, includeCellFn, additionalFlexWidth, index+1, lastIndex, includedIndexCursor, totalFlexWidth)

		if (validCell && results.includedIndexCursor >= 0)
			results.cells[results.includedIndexCursor--] = flexWidthCell && results.adjustmentFactor != 1 
				? { ...cell, width:cell.width*results.adjustmentFactor } 
				: cell

		return results
	}
}

export const pageSetup = page => {
	if (!DOC || !DOC.body)
		return

	DOC.body.classList.add(BODY_CLASS)

	if (DOC.documentElement && page) {
		DOC.body.classList.add(PAGE_CLASS)

		if (page.backgroundColor) {
			DOC.documentElement.style['background-color'] = page.backgroundColor
			DOC.body.style['background-color'] = page.backgroundColor
		}
	}	
}

export const pageReset = () => {
	if (!DOC || !DOC.body)
		return

	DOC.body.classList.remove(BODY_CLASS)
	DOC.body.classList.remove(PAGE_CLASS)
}






