<svelte:options immutable={true}/>
<script>
	/* 
	=======================================================
	START - DEPENDENCIES
	=======================================================
	*/

	import { onMount, createEventDispatcher, afterUpdate, onDestroy } from 'svelte'
	import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
	import { nodeDim, listenTo } from '../../../core/dom'
	import { getGradientColor } from '../../../core/css'
	import { addUnit } from '../../../core/parse'
	import { exists } from '../../../core/index'
	import { chooseColor } from '../../../core/color'
	import { getStore } from './store'
	import Cell from './Cell.svelte'
	import { rootClass } from './rowStyle'

	/* 
	=======================================================
	END - DEPENDENCIES
	=======================================================
	*/









	/* 
	=======================================================
	START - CONSTANTS
	=======================================================
	*/

	const NO_TRANSITION = 'all 0s ease 0s'

	/* 
	=======================================================
	END - CONSTANTS
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT PROPERTIES
	=======================================================
	*/

	// Content
	export let index = null
	export let row = null // e.g., { id:'_12' height:null, cells:[{ id:'_34', width:33, widthUnit:'%', layers:[{id:0,component:{}}]}}]}
	export let refHeight = null // If defined, it overrides the row's height
	export let cellLayersConfig = null	// e.g., '_123:0;1,_789:,_912:1' 
	export let storeId = null
	// Style
	// Behavior
	export let edit = false
	export let inject = v => v

	/* 
	=======================================================
	END - COMPONENT PROPERTIES
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT EVENTS
	=======================================================
	*/

	const dispatch = createEventDispatcher()
	const emitEvent = eventName => data => dispatch(eventName, data||{})
	
	/**
	 * on:cellmousedown
	 *
	 * @param  {Cell}		data.cell
	 * @param  {[Layer]}	data.layers
	 * 
	 * @return {Void}
	 */
	const emitCellMouseDown = emitEvent('cellmousedown')

	/**
	 * on:deletecell
	 *
	 * @param  {String}		data.id		Cell's ID to delete
	 * 
	 * @return {Void}
	 */
	const emitDeleteCell = emitEvent('deletecell')

	/**
	 * on:resize
	 *
	 * @param  {Number}		data.id					Row's ID 
	 * @param  {Number}		data.height				Row height in px
	 * @param  {String}		data.cells[].id
	 * @param  {Number}		data.cells[].width
	 * @param  {String}		data.cells[].widthUnit
	 * 
	 * @return {Void}
	 */
	const emitResize = emitEvent('resize')

	/**
	 * on:celldragstart
	 *
	 * @param  {String}		data.id	Cell's ID
	 * 
	 * @return {Void}
	 */
	const emitCellDragStart = emitEvent('celldragstart')

	/**
	 * on:celldragend
	 *
	 * 
	 * @return {Void}
	 */
	const emitCellDragEnd = emitEvent('celldragend')
	
	/**
	 * on:nextcell
	 *		
	 * 
	 * @return {Void}
	 */
	const emitNextCell = emitEvent('nextcell')
	
	/**
	 * on:valuechange
	 *
	 * @param  {Cell}	data.cell
	 * @param  {Object}	data.value
	 * @param  {String}	data.name			Field name
	 * @param  {String}	data.type			Field type, e.g., 'textfield', 'slider'
	 * @param  {Object} data.layer
	 * 
	 * @return {Void}
	 */
	const emitValueChange = emitEvent('valuechange')
	
	/**
	 * on:componentclick
	 *		
	 * @param  {Cell}		data.cell					
	 * @param  {Object}		data.layer
	 * 
	 * @return {Void}
	 */
	const emitComponentClick = emitEvent('componentclick')

	/**
	 * on:cellmount
	 *
	 * @param  {String}		data.id				Cell's ID (legacy. Use 'cellId' instead)
	 * @param  {String}		data.cellId			Cell's ID
	 * @param  {String}		data.rowId			Row's ID
	 * @param  {Function}	data.getRowDim
	 * @param  {Function}	data.getDim	
	 * 
	 * @return {Void}
	 */
	const emitCellMount = emitEvent('cellmount')

	/**
	 * on:celldestroy
	 *
	 * @param  {String}		data.id				Cell's ID
	 * 
	 * @return {Void}
	 */
	const emitCellDestroy = emitEvent('celldestroy')

	/**
	 * on:destroy
	 *
	 * @param  {String}		data.id				Row's ID
	 * 
	 * @return {Void}
	 */
	const emitDestroy = emitEvent('destroy')

	/* 
	=======================================================
	END - COMPONENT EVENTS
	=======================================================
	*/









	/* 
	=======================================================
	START - REACTIVE STATE PROPS
	=======================================================
	*/

	let node,
		store = getStore(storeId),
		getRowDim,
		localCells, // this is used to locally re-render the cells grid without touching the original 'cells' variable.
		localHeight = null,
		lastCellResize = Date.now(), // used to throttle excessive cell resize (max 60fps).
		showUnsubscribe = null,
		hideUnsubscribe = null,
		localShow = null
	/* 
	=======================================================
	END - REACTIVE STATE PROPS
	=======================================================
	*/









	/* 
	=======================================================
	START - PRIVATE FUNCTIONS
	=======================================================
	*/

	/**
	 * [description]
	 * @param  {[type]} cells              
	 * @param  {[type]} rowWidth           
	 * @param  {[type]} seq                
	 * @param  {[type]} nextSeq            
	 * @param  {[type]} xMove              
	 * @param  {[type]} lastIndex          
	 * @param  {Number} index              
	 * @param  {Number} totalFlexibleWidth 
	 * @param  {Number} totalFixedWidth    
	 * 
	 * @return {String}  output.sequence[].id		
	 * @return {String}  output.sequence[].widthUnit	Needed to decide whether or not 'widthPx' has to be converted to '%'
	 * @return {Number}  output.sequence[].widthPx		
	 * @return {Number}  output.totalFlexWidthPx	
	 * @return {Boolean} output.failed					True when one of the new widthPx is negative
	 */
	const getSequence = (cells, rowWidth, seq, nextSeq, xMove, lastIndex, index=0, totalFlexibleWidth=0, totalFixedWidth=0) => {
		const cell = cells[index]
		if (cell.widthUnit == '%')
			totalFixedWidth += cell.width 
		else
			totalFlexibleWidth += cell.width

		let totalFlexWidthPx = 0, sequence = null, failed = false
		if (index < lastIndex) {
			const s = getSequence(cells, rowWidth, seq, nextSeq, xMove, lastIndex, index+1, totalFlexibleWidth, totalFixedWidth)
			totalFixedWidth = s.totalFixedWidth
			totalFlexibleWidth = s.totalFlexibleWidth
			totalFlexWidthPx = s.totalFlexWidthPx
			sequence = s.sequence
			failed = s.failed
		} else
			sequence = new Array(lastIndex+1)

		let totalFlexibleWidthPx = rowWidth - totalFixedWidth
		totalFlexibleWidthPx = totalFlexibleWidthPx < 0 ? 0 : totalFlexibleWidthPx

		const output = { 
			id:cell.id, 
			widthUnit: cell.widthUnit
		}

		const widthPx = output.widthUnit == 'px' ? cell.width : cell.width/totalFlexibleWidth*totalFlexibleWidthPx

		// Cell that must be explicitely adjusted
		const target = index == seq
		if (target || index == nextSeq) {
			const adjustmentPx = (index == seq ? 1 : -1)*xMove
			output.widthPx = widthPx + adjustmentPx
		} else
			output.widthPx = widthPx

		if (output.widthUnit == '%')
			totalFlexWidthPx += output.widthPx

		if (!failed && output.widthPx < 0)
			failed = true

		sequence[index] = output

		return { sequence, totalFlexibleWidth, totalFixedWidth, totalFlexWidthPx, failed }
	}

	/**
	 * 
	 * @param  {String} 	cellId		Cell's ID
	 * @param  {Number} 	cellIndex	Cell's index
	 * @param  {String} 	side		Cell's side that is being used for the resizing. Valid values: 'left', 'right' or 'bottom'
	 * @param  {Function} 	getDim		Get the cell's dimensions.
	 * @param  {Number} 	x			Current cursor x pos.
	 * @param  {Number} 	y			Current cursor y pos.
	 * @param  {Boolean} 	commit		If true, this means those changes must be commited, i.e., an event 
	 * 
	 * @return {String}
	 */
	const processCellResize = (cellId, cellIndex, side, getDim, x, y, commit) => {
		// console.log(`Row ${row.id} Cell ${cellId} side:${side} - (${x}, ${y})`)
		const now = Date.now()
		if (!commit && (now - lastCellResize < 16)) // 16ms is equivalent to 60fps
			return
		
		lastCellResize = now

		const createNewCellAtTheBeginning = cellIndex == 0 && side == 'left'
		const createNewCellAtTheEnd = cellIndex == cellsCount-1 && side == 'right'
		
		if (!createNewCellAtTheBeginning && !createNewCellAtTheEnd) {
			if (side == 'bottom') {
				const dim = getRowDim(true)
				const diff = y - dim.bottom
				localHeight = dim.height + diff
			} else {
				const dim = getDim(true)
				const [leftCellIndex, xCorrection] = side == 'left' ? [cellIndex-1,x-dim.x] : [cellIndex, x-dim.right]
				const updatedSequence = resizeTwoAdjacentCells(leftCellIndex, xCorrection, localCells)
				if (updatedSequence)
					localCells = updatedSequence
			}

			if (commit)
				emitResize({ cells:localCells, id:row.id, height:localHeight })
		}
	}

	/**
	 * Calculates the new width for all cells in this row.
	 * [
	 * 	{ id:'_1234', width: 70, widthUnit: '%' }, 
	 * 	{ id:'_5678', width: 30, widthUnit: '%' },
	 * 	{ id:'_9101', width: 120, widthUnit: 'px' }]
	 * 
	 * @param {Number} seq		Left cell affected
	 * @param {Number} xMove	Resize amount in pixels		
	 * 
	 * @return {Void}
	 */
	const resizeTwoAdjacentCells = (seq, xMove, cells) => {
		if (!xMove)
			return 

		const nextSeq = seq+1
		const rowWidth = getRowDim().width
		const xMovePerc = xMove/rowWidth*100

		// When adjacent cells have the same type, their opposite changes neutralizes and the overall 
		// row's breakdown stays the same. If that's not the case, the changes in percentage in one of the row
		// changes the ratio between all the other width percentage cells. Those cells's width must be converted
		// from percentage to pixels and their percentage must be recalculated to make sure their width does not 
		// change.
		const adjacentCellsHaveNoSideEffect = cells[seq].widthUnit ===  cells[nextSeq].widthUnit

		let sequence
		if (adjacentCellsHaveNoSideEffect) {
			sequence = cells.map((c,i) => i == seq || i == nextSeq ? {...c} : c)
			let abort = false
			const leftCell = sequence[seq]
			if (leftCell) {
				const newWidth = leftCell.width + (leftCell.widthUnit == 'px' ? xMove : xMovePerc)
				if (newWidth < 0)
					abort = true 
				else
					leftCell.width = newWidth
			}
			const rightCell = sequence[nextSeq]
			if (rightCell) {
				const newWidth = rightCell.width - (rightCell.widthUnit == 'px' ? xMove : xMovePerc)
				if (newWidth < 0)
					abort = true 
				else
					rightCell.width = newWidth
			}

			if (abort) {
				sequence[seq] = cells[seq]
				sequence[nextSeq] = cells[nextSeq]
			}
		} else {
			const { sequence:data, totalFlexWidthPx, failed } = getSequence(cells, rowWidth, seq, nextSeq, xMove, cells.length-1)

			sequence = failed ? cells : data.map(cell => {
				cell.width = cell.widthUnit == 'px' ? cell.widthPx : cell.widthPx/totalFlexWidthPx*100
				return cell
			})
		}

		return sequence
	}

	const initNode = () => {
		if (!getRowDim)
			getRowDim = nodeDim(node)
	}

	const getNominalHeight = (rowHeight, refHeight) => {
		if (exists(refHeight))
			return addUnit(refHeight)
		else if (exists(rowHeight))
			return addUnit(rowHeight)
		else 
			return 'auto'
	}

	/**
	 * [description]
	 * @param  {Boolean} 	showRow			
	 * @param  {String} 	rowHeight			e.g., 'auto', '34px', '23%'
	 * 
	 * @return {String}							e.g., '0px'
	 */
	const getRenderedHeight = (showRow, rowHeight) => {
		return showRow ? rowHeight : '0px'
	}

	const getCssVars = (cells, rowHeight, fadeEffectOn) => {
		const fixedColumnUnit = cells.length <= 1 ? 'fr' : 'px'
		const gridColumns = cells.map(c => c.width + (c.widthUnit == 'px' ? fixedColumnUnit : 'fr')).join(' ')
		const collapseWithFade = fadeEffectOn && rowHeight == '0px'
		const gridRow = collapseWithFade ? 'auto' : rowHeight

		return `--lu-row-grid-columns:${gridColumns};--lu-row-grid-rows:${gridRow};--lu-row-height:${rowHeight};--lu-row-opacity:${collapseWithFade ? 0 : 1};`
	}

	/**
	 * 
	 * @param  {String}		showEffects[].name		Valid values: 'expand-collapse', 'fade'
	 * @param  {Number}		showEffects[].duration	e.g., 100 (for '100ms')
	 * @param  {String}		showEffects[].curve		e.g., 'linear', 'ease-in'
	 * @param  {Boolean}	edit		
	 * 					
	 * @return {String}		transitionCSS			e.g., 'height 100ms linear, grid-template-rows 100ms linear'
	 */
	const getEffectsTransitionCss = (showEffects, edit) => {
		if (edit || !showEffects || !showEffects.length)
			return NO_TRANSITION

		const expandCollapseEffect = showEffects.find(e => e.name == 'expand-collapse')
		const fadeEffect = showEffects.find(e => e.name == 'fade')

		let trans = ''
		if (expandCollapseEffect) {
			const t = `${expandCollapseEffect.duration||150}ms ${expandCollapseEffect.curve||'linear'}`
			trans += `height ${t}, grid-template-rows ${t}`
		}

		if (fadeEffect) {
			const t = `${fadeEffect.duration||150}ms ${fadeEffect.curve||'linear'}`
			trans += `${expandCollapseEffect?', ':''}opacity ${t}`
		}

		return trans||NO_TRANSITION
	}

	const getRootVars = ({ flex, backgroundColor, backgroundColorOrientation, effectsTransition }) => {
		const _gradientColor = backgroundColor && Array.isArray(backgroundColor)
		const _backgroundColor = backgroundColor && !_gradientColor ? backgroundColor : 'tranparent'

		return {
			classes: _gradientColor ? 'row-grad-on' : '',
			vars: css(`
			--lu-row-flex:${flex == 'auto' ? 'auto' : '0 1 auto'};
			--lu-row-background-color: ${chooseColor(_backgroundColor)};
			--lu-row-background-image: ${_gradientColor ? getGradientColor({ orientation:backgroundColorOrientation||0 })(...(backgroundColor.map(c => chooseColor(c)))) : 'none'};
			--lu-row-transition:${effectsTransition};
			`)
		}
	}

	/* 
	=======================================================
	END - PRIVATE FUNCTIONS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT HANDLERS
	=======================================================
	*/

	const onRightClick = () => {
		// e.preventDefault()
		console.log('RIGHT CLICK')
	}

	const onCellDragStart = (detail, index) => {
		detail.index = index
		emitCellDragStart(detail)
	}

	/**
	 * 
	 * @param  {Object}		data.id					Cell's ID
	 * @param  {[Layers]}	data.layers				Active layers.
	 * 
	 * @return {Void}      
	 */
	const onCellMouseDown = (cell, data) => {
		data.cell = cell
		emitCellMouseDown(data)
	}

	/**
	 * 
	 * @param  {String}		data.id			Cell's ID
	 * @param  {String}		data.side		Valid values: 'left', 'right' or 'bottom'
	 * @param  {Function}	data.getDim		Get cell's dimensions
	 * @param  {Number} 	cellIndex 
	 * 
	 * @return {Void}           
	 */
	const onCellResizeStart = (data, cellIndex) => {
		data.index = cellIndex
		store.mouseMoveHandler = (x,y, commit) => processCellResize(data.id, cellIndex, data.side, data.getDim, x,y, commit)
	}

	const onCellDestroy = data => {
		emitCellDestroy(data)
	}

	/**
	 * 
	 * @param  {Cell}  	 cell					
	 * @param  {String}  data.id					Cell's ID
	 * @param  {Object}	 data.layer
	 * 	
	 * @return {Void}
	 */
	const onComponentClick = (cell, data) => {
		data.cell = cell
		emitComponentClick(data)
	}

	/**
	 *
	 * @param  {Cell}	cell
	 * @param  {Object}	data.value
	 * @param  {String}	data.layer.id
	 * @param  {String}	data.layer.component
	 * @param  {String}	data.name				Field name
	 * @param  {String}	data.type				Field type, e.g., 'textfield', 'slider'
	 * 
	 * @return {Void}
	 */
	const onComponentValueChange = (cell, data) => {
		data.cell = cell 
		emitValueChange(data)
	}

	const onCellMount = data => {
		initNode()
		data.getRowDim = getRowDim
		data.rowId = row.id
		data.cellId = data.id
		emitCellMount(data)
	}

	/* 
	=======================================================
	END - EVENT HANDLERS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT LIFECYCLE
	=======================================================
	*/

	$: { localShow = exists(row.show) ? row.show : true }
	$: cells = row.cells
	$: height = row.height
	$: fadeEffectOn = row.showEffects && row.showEffects.some(e => e.name == 'fade')
	$: effectsTransition = getEffectsTransitionCss(inject(row.showEffects), edit)
	$: rootVars = getRootVars({ 
		flex: row.flex,
		backgroundColor: inject(row.backgroundColor),
		backgroundColorOrientation: inject(row.backgroundColorOrientation),
		effectsTransition
	})
	$: {
		// Creates a local copy with the format that this row can mutate safely
		localCells = (cells||[]).map(c => ({ 
			id:c.id, 
			width:c.width, 
			widthUnit: c.widthUnit 
		}))
		localHeight = height
	}
	$: nominalHeight = getNominalHeight(localHeight, refHeight) // Row height
	$: renderedHeight = getRenderedHeight(localShow, nominalHeight)
	$: cssVars = getCssVars(localCells, renderedHeight, fadeEffectOn)
	$: cellsCount = localCells.length
	$: cellLayers = cellLayersConfig.split(',').map(x => x.replace(/.*:/,''))
	$: {
		if (row.showEvent) {
			if (showUnsubscribe)
				showUnsubscribe()
			showUnsubscribe = listenTo(row.showEvent, () => {
				if (!localShow)
					localShow = true
			})
		}
		if (row.hideEvent) {
			if (hideUnsubscribe)
				hideUnsubscribe()
			hideUnsubscribe = listenTo(row.hideEvent, () => {
				if (localShow)
					localShow = false
			})
		}
	}

	onMount(() => {
		initNode()
	})

	// Uncomment this block to log which property triggers re-renders.
	// 
	// $: { if (row) console.log(`Row ${row.id}: row changed`) }
	// $: { if (cells) console.log(`Row ${row.id}: row.cells changed`) }
	// $: { if (localCells) console.log(`Row ${row.id}: localCells changed`) }
	// $: { if (draggable) console.log(`Row ${row.id}: draggable changed`) }
	// $: { if (cssVars) console.log(`Row ${row.id}: cssVars changed`) }
	// $: { if (edit) console.log(`Row ${row.id}: edit changed`) }
	// $: { if (index) console.log(`Row ${row.id}: index changed`) }
	// $: { if (noresize) console.log(`Row ${row.id}: noresize changed`) }

	afterUpdate(() => {
		// console.log(`Row ${row.id} re-rendered`)
	})

	onDestroy(() => {
		if (showUnsubscribe)
			showUnsubscribe()
		if (hideUnsubscribe)
			hideUnsubscribe()
		emitDestroy({ id:row.id })
	})

	const delay = () => new Promise(next => setTimeout(next, 0))

	/* 
	=======================================================
	END - EVENT LIFECYCLE
	=======================================================
	*/
</script>

{#if row.sectionId}
<section id="{inject(row.sectionId)}"></section>
{/if}
<div 
	id={row.id} 
	data-type="row"
	on:contextmenu={onRightClick}
	style="{cssVars}" 
	class="{rootVars.vars} {rootVars.classes} {rootClass}"
	bind:this={node}
	>
	{#each cellLayers as toggledLayerIds, cellIndex (cells[cellIndex].id)}
	{#await delay() then emptyVal}
	<Cell 
		cell={cells[cellIndex]}
		firstCell={cellIndex === 0}
		lastCell={cellIndex === cellsCount-1} 
		resizeableEdgeTop={index === 0}
		resizeableEdgeLeft={cellIndex === 0}
		rowVisible={localShow}
		{storeId}
		{edit}
		{toggledLayerIds}
		{inject}
		on:mousedown={d => onCellMouseDown(cells[cellIndex], d.detail)}
		on:dragstart={d => onCellDragStart(d.detail, cellIndex)}
		on:dragend={d => emitCellDragEnd(d.detail)}
		on:delete={d => emitDeleteCell(d.detail)}
		on:nextcell={d => emitNextCell({ ...d.detail, cellIndex:cellIndex })}
		on:valuechange={d => onComponentValueChange(cells[cellIndex], d.detail)}
		on:componentclick={d => onComponentClick(cells[cellIndex], d.detail)}
		on:mount={d => onCellMount(d.detail)}
		on:destroy={d => onCellDestroy(d.detail)}
		on:resizestart={d => onCellResizeStart(d.detail, cellIndex)}>	
	</Cell>
	{/await}
	{/each}
</div>
