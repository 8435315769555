import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
import theme, { VAR_PRIMARY_COLOR, VAR_DANGER_COLOR } from '../../../core/theme'
import { fontCss, transition, transform, boxSizing } from '../../../core/css'

const FONT_CSS = fontCss()
const SMALL_FONT_TO_NORMAL_FONT_RATIO = 0.75
export const MAIN_TRANSITION_CURVE = '200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
const VAR_FONT_SIZE = `var(--lu-font-size, ${theme.text.font.size}px)`
const DEFAULT_FONT_SIZE = 16
const SELECT_ICON_SIZE_TO_FONT_SIZE_RATIO = 24/DEFAULT_FONT_SIZE

export const rootClass = css(`
${theme.resetTagStyle}

/* START - BASE */
width: var(--lu-textfield-width);
max-width: var(--lu-textfield-width-max);
min-width: var(--lu-textfield-width-min);
padding: var(--lu-textfield-padding);
margin: var(--lu-textfield-margin);
opacity: 1;
-webkit-tap-highlight-color: transparent;
/* END - BASE */

& .textfield-font {
	${FONT_CSS}
}

& .text-helper {
	font-size: calc(${SMALL_FONT_TO_NORMAL_FONT_RATIO} * ${VAR_FONT_SIZE});
	position: relative;
	padding-top: var(--lu-textfield-adj-height);
	padding-left: var(--lu-textfield-help-pl);
	color: var(--lu-textfield-help-color);
	z-index:1;

	& p, & ul {
		padding-top: var(--lu-textfield-help-pt);
	}

	& ul.helper-text-list {
		list-style-type: var(--lu-textfield-help-list-style);
	}
}

& .textfield-counter {
	font-size: calc(${SMALL_FONT_TO_NORMAL_FONT_RATIO} * ${VAR_FONT_SIZE});
	position: absolute;
	right: 0px;
	padding-top: var(--lu-textfield-help-pt);
}

& .textfield-placeholder {
	color: var(--lu-textfield-placeholder-color);
}

& input, 
& textarea {
	background-color: transparent;
	border-radius: var(--lu-textfield-border-radius);
	border:0;
	cursor: var(--lu-textfield-input-cursor);
	padding-left: var(--lu-textfield-input-pl);
	padding-right: var(--lu-textfield-input-pr);
	position: absolute;
	text-align: var(--lu-textfield-font-justify);
	width: 100%;
	z-index: 3;

	&:focus {
		outline: none;
	}

	&::-webkit-input-placeholder,
	&:-ms-input-placeholder,
	&::placeholder {
		transition: var(--lu-textfield-placeholder-trans);
		color: var(--lu-textfield-placeholder-color);
		opacity: var(--lu-textfield-placeholder-opacity);
	}

	&:focus {
		::-webkit-input-placeholder,
		&:-ms-input-placeholder,
		&::placeholder {
			opacity: 1;
		}
	}
}

& input {
	height: var(--lu-textfield-height);
	max-height: var(--lu-textfield-height-max);
	min-height: var(--lu-textfield-height-min);
	opacity: var(--lu-textfield-input-opacity);

	&::-webkit-calendar-picker-indicator {
		cursor: pointer;
	}
}

& textarea {
	height: var(--lu-textfield-area-height);
	margin-top: var(--lu-textfield-area-mt);
	padding-top: var(--lu-textfield-area-pt);
	resize: var(--lu-textfield-area-resize);
}

& .textfield-container {
	position: relative;
}

& .textfield-border {
	border: 0px;
	position: absolute;
	height: var(--lu-textfield-adj-height);
	width: 100%;
	z-index: 1;
	border-radius: var(--lu-textfield-border-radius);
}

& .textfield-label {
	transform-origin: left top;
	${transition.css({ 'transform':MAIN_TRANSITION_CURVE, opacity:MAIN_TRANSITION_CURVE }, true, true)}
	position: absolute;
	color: var(--lu-textfield-placeholder-color);
	margin-left: var(--lu-textfield-input-pl);
	z-index: 3;
	cursor: text;

	& .textfield-font {
		position: absolute;
		left: 0;
		white-space: nowrap;
		color: var(--lu-textfield-placeholder-color);
	}
}

&:not(.textfield-invalid) .textfield-label .textfield-label--active {
	opacity: 0;
	color: ${VAR_PRIMARY_COLOR};
}

& .textfield-text-clone {
	position: absolute;
	padding-left: var(--lu-textfield-input-pl);
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	z-index: 2;

}

& .textfield-label, 
& .textfield-text-clone {
	${transform.css('var(--lu-textfield-label-t-down)')}
}

& .textfield-select-expand-icon {
	position: absolute;
	width: calc(100% - var(--lu-textfield-input-pr));
	display: flex;
	justify-content: flex-end;
	${transform.css('var(--lu-textfield-label-t-icon)')}
	z-index: 2;
}

& .textfield-expand-icon {
	font-size: calc(${SELECT_ICON_SIZE_TO_FONT_SIZE_RATIO} * ${VAR_FONT_SIZE});
	${transition.css({ 'transform':MAIN_TRANSITION_CURVE }, true)}
}

&.textfield-options-on .textfield-expand-icon {
	${transform.css('rotate(180deg)')}
}

&.textfield-options-on .textfield-label,
& input:focus ~ .textfield-label,
& textarea:focus ~ .textfield-label {
	${transform.css('var(--lu-textfield-label-t-up)')}

	& .textfield-label--inactive {
		opacity: 0;
	}

	& .textfield-label--active {
		opacity: 1;
	}
}

& .textfield-label--inactive,
& .textfield-label--active {
	will-change: opacity;
}

&.textfield-label-up .textfield-label {
	${transform.css('var(--lu-textfield-label-t-up)')}
}

&.variant-underlined, 
&.variant-filled {
	& input {
		padding-top: var(--lu-textfield-filled-pt);
	}
}

&.variant-filled {

	& .textfield-border--inactive {
		${transition.css({ 'border-bottom':MAIN_TRANSITION_CURVE, 'background-color':MAIN_TRANSITION_CURVE }, true, true)}
		background-color: var(--lu-textfield-bgd-main);
	}

	&:not(.textfield-disabled):not(.textfield-invalid) {

		& input,
		& textarea {
			&:hover ~ .textfield-border--inactive {
				background-color: var(--lu-textfield-bgd-hover);
			}

			&:focus ~ .textfield-border--inactive {
				background-color: var(--lu-textfield-bgd-focus);
			}
		}
	}
}

&.variant-outlined {
	& .textfield-border--add-label {
		height: calc(var(--lu-textfield-adj-height) + ${0.5*SMALL_FONT_TO_NORMAL_FONT_RATIO} * ${VAR_FONT_SIZE});
		top: calc(${-0.5*SMALL_FONT_TO_NORMAL_FONT_RATIO} * ${VAR_FONT_SIZE});
		padding-left: var(--lu-textfield-input-pl);
	}

	& .textfield-legend {
		font-size: calc(${SMALL_FONT_TO_NORMAL_FONT_RATIO} * ${VAR_FONT_SIZE});
		color: transparent;
		margin-left: var(--lu-textfield-lgd-ml);
		padding-right: var(--lu-textfield-lgd-pr);

		&.textfield-legend-hide {
			margin-left: 100%;
			padding-right: 0;
		}
	}
}

&.textfield-underlined {
	& .textfield-border {
		border-top: 0;
		border-right: 0;
		border-left: 0;
		border-bottom: var(--lu-textfield-border-main);
	}

	& .textfield-border--active {
		${transition.css({ 'transform':MAIN_TRANSITION_CURVE }, true, true)}
		${transform.css('scaleX(0)')}
		border-bottom: var(--lu-textfield-border-focus);
	}

	& .textfield-border--inactive {
		${transition.css({ 'border-bottom':MAIN_TRANSITION_CURVE }, true, true)}
	}

	&:not(.textfield-disabled):not(.textfield-invalid) {
		& input,
		& textarea {
			&:hover ~ .textfield-border--inactive {
				border-bottom: var(--lu-textfield-border-hover);	
			}
		}
	}

	&.textfield-options-on .textfield-border--active,
	& input:focus ~ .textfield-border--active,
	& textarea:focus ~ .textfield-border--active {
		${transform.css('scaleX(1)')}
	}
}

&.textfield-outlined {
	& .textfield-border {
		${boxSizing.css('border-box')}
		border: var(--lu-textfield-border-main);
	}

	&:not(.textfield-disabled):not(.textfield-invalid) {
		& input,
		& textarea {
			clip-path: var(--lu-textfield-mask);
			-webkit-clip-path: var(--lu-textfield-mask);

			&:hover ~ .textfield-border--inactive {
				border: var(--lu-textfield-border-hover);
			}
		}
	}

	& .textfield-border--active,
	& .textfield-border--inactive {
		${transition.css({ opacity:MAIN_TRANSITION_CURVE, border:MAIN_TRANSITION_CURVE, 'background-color':MAIN_TRANSITION_CURVE }, true, true)}
	}

	& .textfield-border--active {
		opacity: 0;
		border: var(--lu-textfield-border-focus);
	}

	&.textfield-options-on .textfield-border--inactive,
	& input:focus ~ .textfield-border--inactive,
	& textarea:focus ~ .textfield-border--inactive {
		opacity: 0;
		background-color: var(--lu-textfield-filled-bgd-focus);
	}

	&.textfield-options-on .textfield-border--active,
	& input:focus ~ .textfield-border--active,
	& textarea:focus ~ .textfield-border--active {
		opacity: 1;
		box-shadow: var(--lu-textfield-box-shadow);
		background-color: var(--lu-textfield-filled-bgd-focus);
	}

	&.textfield-label-up .textfield-border--labelup {
		opacity:0;
	}
}

& .textfield-label-above-container {
	position: relative;
	height: var(--lu-textfield-label-height);
	margin: var(--lu-textfield-label-margin);
}

& .textfield-label-above {
	position: absolute;
	width: 100%;
	display: flex;
	font-size: var(--lu-textfield-label-font-size);
	font-weight: var(--lu-textfield-label-font-weight);
	font-style: var(--lu-textfield-label-font-style);
	color: var(--lu-textfield-label-font-color);
	justify-content: var(--lu-textfield-label-justify-content);
}

&.textfield-invalid {
	&.variant-underlined, 
	&.variant-filled {
		& .textfield-border--active,
		& .textfield-border--inactive {
			border-bottom: var(--lu-textfield-border-error);
		}
	}

	&.variant-outlined {
		& .textfield-border--active,
		& .textfield-border--inactive {
			border: var(--lu-textfield-border-error);
		}
	}

	& .textfield-label--active,
	& .text-helper,
	& .textfield-label {
		color: ${VAR_DANGER_COLOR};
	}

	& .textfield-border,
	& .textfield-border--inactive {
		border-bottom: var(--lu-textfield-border-error);
	}
}

&.textfield-disabled {
	opacity: 0.7;		

	& .textfield-label {
		cursor: default;		
	}
}
`)