<script>
	/* 
	=======================================================
	START - DEPENDENCIES
	=======================================================
	*/

	import { createEventDispatcher } from 'svelte'
	import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
	import { getId, createBaseEvents, formatValue } from '../../../core/index'
	import { paddingParse, intParse } from '../../../core/parse'
	import { transform, boxSizing, fontCss, noUserSelectCss } from '../../../core/css'
	import { getBoxShadow, getHoverElevation, getPressedElevation } from '../../../core/elevation'
	import theme, {VAR_PRIMARY_COLOR,VAR_FONT_FAMILY,VAR_FONT_COLOR,VAR_ON_PRIMARY_COLOR,VAR_DISABLED_COLOR,VAR_ON_DISABLED_COLOR} from '../../../core/theme'
	import Icon from '../../icon/src/Icon.svelte'

	/* 
	=======================================================
	END - DEPENDENCIES
	=======================================================
	*/









	/* 
	=======================================================
	START - CONSTANTS
	=======================================================
	*/

	const ID = getId() // e.g., '_cwd23d'
	const DEFAULT_THICKNESS = 2
	const DEFAULT_CURSOR_SIZE = 12
	const DEFAULT_STEP_WIDTH = 1
	const BAR_TO_THICKNESS_RATIO = 4
	const UNSELECTED_OPACITY = 0.3
	const TOOLTIP_SIZE_TO_FONT_RATIO = 32/14
	const ICON_SIZE_TO_CURSOR_SIZE_RATIO = 1/3
	const DEFAULT_STEP_COLOR = '#fff'	
	const VAR_FONT_SIZE = `var(--lu-font-size, ${0.875 * theme.text.font.size}px)`
	const MAIN_TRANSITION_CURVE = (duration) => `${duration || 150}ms cubic-bezier(0.4, 0, 0.2, 1)`

	/* 
	=======================================================
	END - CONSTANTS
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT PROPERTIES
	=======================================================
	*/

	// Content
	export let name = ''
	export let value = 0 // Can also be an array if the slider is in range mode (e.g., [20, 80])
	export let customSteps = null
	// Style
	export let primary = null
	export let width = null
	export let thickness = null // line thickness in pixels
	export let cursorBackgroundColor = null // Default is 'primary'
	export let cursorBackgroundScale = null // Default is 2.5
	export let cursorBackgroundOn = true
	export let cursorBorder = null
	export let cursorBorderColor = null
	export let cursorBorderRadius = null
	export let cursorColor = null // Default is 'primary'
	export let cursorElevation = null // Default is 0
	export let cursorElevationColor = null 
	export let cursorElevationStrength = null 
	export let cursorWidth = null
	export let cursorHeight = null
	export let cursorIcon = null
	export let cursorIconBold = false
	export let cursorIconColor = null // Default is 'primary'
	export let cursorIconTransform = null
	export let cursorIconType = null
	export let disabledColor = null
	export let disabledFontColor = null
	export let edges = 'sharp' // valid values: 'sharp' (default), 'round', any number is used for border-radius
	export let fontColor = null // used for the tooltip
	export let fontFamily = null // used for the tooltip
	export let fontSize = null // used for the tooltip
	export let padding = 0
	export let showSteps = false
	export let stepColor = null
	export let stepHeight = null
	export let stepVariant = null // Valid value: 'dot' (default), 'bar', 'bar-up', 'bar-down'
	export let stepWidth = null
	export let switchSelectedColor = false // when true, the unselected and selected color are reversed. Useful in range mode.
	export let tooltipAlwaysOn = false
	export let tooltipColor = null
	export let tooltipPinOn = true
	export let unselectedColor = null // Default grey[300]
	// Behavior
	export let min = 0
	export let max = 100
	export let step = 1
	export let tooltipOn = false
	export let disabled = false

	/* 
	=======================================================
	END - COMPONENT PROPERTIES
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT EVENTS
	=======================================================
	*/

	const dispatch = createEventDispatcher()
	createBaseEvents(dispatch, () => formatValue(name, 'slider', value))
	const emitEvent = eventName => data => dispatch(eventName, data||{})
	
	/**
	 * on:change
	 *
	 * @param  {Object}		data.id
	 * @param  {Object}		data.value
	 * 
	 * @return {Void}
	 */
	const emitChange = emitEvent('change')

	/* 
	=======================================================
	END - COMPONENT EVENTS
	=======================================================
	*/









	/* 
	=======================================================
	START - REACTIVE STATE PROPS
	=======================================================
	*/

	let rootClass,
		cursor01Moveable = false,
		cursor02Moveable = false,
		cursorCurrentPosX = null,
		cursorCurrentValue = null,
		lineNode,
		cursor01Node,
		cursor02Node,
		currentFocusedEl,
		shiftKeyPressed

	/* 
	=======================================================
	END - REACTIVE STATE PROPS
	=======================================================
	*/









	/* 
	=======================================================
	START - PRIVATE FUNCTIONS
	=======================================================
	*/

	/**
	 * Makes sure the min, max and step config produce a reasonable configuration. 
	 * 
	 * @param  {Object} min
	 * @param  {Object} max
	 * @param  {Object} step
	 * 
	 * @return {Number}	output.min
	 * @return {Number}	output.max
	 * @return {Number}	output.step
	 * return {Number}	output.gap
	 * @return {Number}	output.incrementalStep
	 * return {Number}	output.bigIncrementalStep
	 * @return {Number}	output.steps[0].value
	 * @return {Number}	output.steps[0].perc		
	 */
	const getSanitizedConfig = (min, max, step, showSteps, stepWidth, customSteps) => {
		const _min = intParse(min, 0)
		const _max = intParse(max, 100)
		const _step = intParse(step, 0)

		const realRange = _min < _max ? { min:_min, max:_max } : { min:_max, max:_min }
		const gap = realRange.max - realRange.min
		const validStep = _step > gap ? gap : _step
		let steps = []
		const customStepsOn = customSteps && customSteps.length
		const stepsOn = customStepsOn || (showSteps && validStep > 0)
		if (customStepsOn) {
			steps = customSteps.sort((x,y) => x<y ? -1 : 1).reduce((acc, x) => {
				const _x = intParse(x, 0)
				if (realRange.min <= _x && _x <= realRange.max && !acc.keys[_x]) {
					acc.keys[_x] = true
					const perc = Math.round((_x - realRange.min)/gap*100)
					acc.steps.push({ value:_x, perc, style: `width: ${perc ? `${perc}%` : `${stepWidth}px`};` })
				}
				return acc
			}, { keys:[], steps:[] }).steps

		} else if (validStep > 0) {
			let percStep = Math.round(validStep/gap*100)
			let perc = 0
			for(let i=realRange.min;i<=realRange.max;i+=validStep) {
				steps.push({ value:i, perc, style: `width: ${perc ? `${perc}%` : `${stepWidth}px`};` })
				perc += percStep
			}
		}

		// Used to incrementally increase/decrease the slider with the arrow key
		const incrementalStep = validStep || gap/100
		const bigStep = incrementalStep*10
		const bigIncrementalStep = gap/bigStep >= 2 ? bigStep : incrementalStep

		return { 
			...realRange, 
			step: validStep,
			incrementalStep,
			bigIncrementalStep,
			gap,
			steps: stepsOn > 0 ? steps : null,
		}
	}

	/**
	 * Mutates the value to 'inputValue' while making sure that it stays within the range set in the 'config'. Also rounds the new value
	 * to the closest step of a step is defined in the config.
	 * 
	 * @param  {Object} inputValue [description]
	 * 
	 * @return {Void}
	 */
	const setValue = inputValue => {
		const isArray = Array.isArray(inputValue)
		const step = config.step
		const min = config.min
		const max = config.max

		let [_val_start, _val_end] = isArray ? [intParse(inputValue[0], 0), intParse(inputValue[1], 0)] : [intParse(inputValue, 0)]

		// Prevent the start value to be greater than the end value
		if (isArray && _val_start > _val_end) {
			const v = _val_end
			_val_end = _val_start
			_val_start = v
		} 

		// Round the values to the closest step
		if (step > 0) {
			if (_val_start)
				_val_start = Math.round(_val_start/step) * step
			if (_val_end)
				_val_end = Math.round(_val_end/step) * step
		}

		// Prevent the values to be outside of range
		const val_start = _val_start < min ? min : _val_start > max ? max : _val_start
		const val_end = isArray
			? _val_end < min ? min : _val_end > max ? max : _val_end
			: undefined
		
		const newValue = isArray ? [val_start, val_end] : val_start

		value = newValue
		emitChange({ id:ID, value:newValue  })
	}

	/**
	 * Gets the value rounded to the closest step and the percentage that this value represents on the slider.
	 * 
	 * @param  {Object}		value  				 
	 * @param  {Object}		sanitizedConfig		Config from the 'getSanitizedConfig' method.
	 * 
	 * @return {Number}		output.value
	 * @return {Number}		output.perc			e.g., 1 for 1% or 99 for 99%
	 */
	const getSelectecValue = (value, sanitizedConfig) => {
		
		const isArray = Array.isArray(value)
		const [val_start, val_end] = isArray ? [intParse(value[0], 0), intParse(value[1], 0)] : [intParse(value, 0), null]

		const dist = sanitizedConfig.max - sanitizedConfig.min 
		const startPerc = Math.round((val_start - sanitizedConfig.min)/dist*100)
		const endPerc = isArray ? Math.round((val_end - sanitizedConfig.min)/dist*100) : 100

		return {
			value,
			value01: val_start,
			value02: val_end,
			perc: isArray ? [startPerc, endPerc] : startPerc,
			css: `--slider-value-01: ${startPerc}%;--slider-value-02: ${endPerc}%;`
		}
	}

	const getSelectedStepClass = (currentPerc, perc) => {
		if (isArray) {
			let [startPerc, endPerc] = currentPerc
			startPerc = startPerc || 0
			endPerc = endPerc || 0
			return startPerc <= perc && perc <= endPerc ? '' : ' step-selected'
		} else
			return currentPerc <= perc ? '' : ' step-selected'
	}

	const getCursorColor = cursorColor => !cursorColor || cursorColor === 'primary' ? VAR_PRIMARY_COLOR : cursorColor

	/**
	 * Gets the Icon's properties
	 * 
	 * @param  {[type]} cursorIcon      [description]
	 * @param  {[type]} cursorColor     [description]
	 * @param  {[type]} cursorIconColor [description]
	 * 
	 * @return {String}		props.icon				MD icon name
	 * @return {Number}		props.size				MD icon size
	 * @return {String}		props.primary			MD icon color
	 * @return {String}		props.type				Valid values: 'filled', 'outlined', 'round', 'sharp', 'two-tone'
	 * @return {Boolean}	props.bold				
	 * @return {String}		props.transformStart	e.g., 'rotate(90deg)'
	 */
	const getIconConfig = ({ cursorIcon, cursorIconType, cursorIconBold, cursorColor, cursorWidth, cursorHeight, cursorIconColor, cursorIconTransform, disabled }) => {
		if (!cursorIcon)
			return null

		const _cursorColor = getCursorColor(cursorColor)
		const _cursorSize = !cursorWidth && !cursorHeight 
			? DEFAULT_CURSOR_SIZE 
			: cursorWidth && cursorHeight ? Math.min(cursorWidth, cursorHeight) : cursorWidth || cursorHeight

		const _iconColor = cursorIconColor == 'primary'
			? VAR_PRIMARY_COLOR
			: cursorIconColor || (_cursorColor == VAR_PRIMARY_COLOR ? VAR_ON_PRIMARY_COLOR : VAR_FONT_COLOR)

		const _iconSize = ICON_SIZE_TO_CURSOR_SIZE_RATIO * _cursorSize

		return {
			icon: cursorIcon,
			type: cursorIconType,
			primary: disabled ? VAR_ON_DISABLED_COLOR : _iconColor,
			size: _iconSize,
			bold: cursorIconBold,
			transformStart: cursorIconTransform
		}
	}

	const setStyle = ({ width, padding, primary, edges, thickness, cursorWidth, cursorHeight, cursorColor, cursorBorder, cursorBorderColor, cursorBorderRadius, unselectedColor, isArray, switchSelectedColor, cursorBackgroundColor, cursorBackgroundScale, stepWidth, stepHeight, stepColor, stepVariant, fontFamily, fontSize, fontColor, tooltipColor, tooltipPinOn, tooltipAlwaysOn, cursorElevation, cursorElevationStrength, cursorElevationColor, disabledColor, disabledFontColor }) => {

		const _paddingObj = paddingParse(padding)
		const _width = width ? `${width}px` : '100%'
		const _thickness = thickness || DEFAULT_THICKNESS
		const _edgesRadius = !edges || edges == 'sharp' ? '0' : edges == 'round' ? `${_thickness}px` : `${edges}px`
		const _cursorWidth = cursorWidth || cursorHeight || DEFAULT_CURSOR_SIZE
		const _cursorHeight =  cursorHeight || cursorWidth || DEFAULT_CURSOR_SIZE
		const _cursorOffset = Math.ceil(1*_cursorWidth/2)
		const _cursorBorderRadius = cursorBorderRadius === null || cursorBorderRadius === undefined 
			? Math.max(_cursorWidth, _cursorHeight)
			: cursorBorderRadius
		const _cursorColor = getCursorColor(cursorColor)
		const _cursorBorderColor = !cursorBorderColor || cursorBorderColor === 'primary' 
			? VAR_PRIMARY_COLOR
			: cursorBorderColor
		const _tooltipColor = !tooltipPinOn ? 'transparent' : !tooltipColor || tooltipColor == 'primary' 
			? VAR_PRIMARY_COLOR 
			: tooltipColor

		let _tooltipTextColor
		if (fontColor)
			_tooltipTextColor = fontColor == 'primary' ? VAR_PRIMARY_COLOR : VAR_FONT_COLOR
		else if (tooltipPinOn)
			_tooltipTextColor = _tooltipColor == VAR_PRIMARY_COLOR ? VAR_ON_PRIMARY_COLOR : VAR_FONT_COLOR
		else 
			_tooltipTextColor = VAR_FONT_COLOR

		const _boxShadow = cursorElevation ? getBoxShadow({ elevation:cursorElevation, strength:cursorElevationStrength, color:cursorElevationColor}) : null
		const _hoverBoxShadow = cursorElevation ? getBoxShadow({ elevation:getHoverElevation(cursorElevation), strength:cursorElevationStrength, color:cursorElevationColor}) : null
		const _pressedBoxShadow = cursorElevation ? getBoxShadow({ elevation:getPressedElevation(cursorElevation), strength:cursorElevationStrength, color:cursorElevationColor}) : null

		const _switchSelectedColor = isArray ? !switchSelectedColor : switchSelectedColor
		let _selectedColor, _selectedOpacity, _unselectedColor, _unselectedOpacity

		if (_switchSelectedColor) {
			_unselectedColor = VAR_PRIMARY_COLOR
			_unselectedOpacity = 1
			if (unselectedColor) {
				if (unselectedColor == 'primary') {
					_selectedColor = VAR_PRIMARY_COLOR
					_selectedOpacity = UNSELECTED_OPACITY
				} else {
					_selectedColor = unselectedColor
					_selectedOpacity = 1
				}
			} else {
				_selectedColor = VAR_PRIMARY_COLOR
				_selectedOpacity = UNSELECTED_OPACITY
			}
		} else {
			_selectedColor = VAR_PRIMARY_COLOR
			_selectedOpacity = 1
			if (unselectedColor) {
				if (unselectedColor == 'primary') {
					_unselectedColor = VAR_PRIMARY_COLOR
					_unselectedOpacity = UNSELECTED_OPACITY
				} else {
					_unselectedColor = unselectedColor
					_unselectedOpacity = 1
				}
			} else {
				_unselectedColor = VAR_PRIMARY_COLOR
				_unselectedOpacity = UNSELECTED_OPACITY
			}
		}

		const _cursorBackgroundScale = cursorBackgroundScale || 2.5
		const _cursorBackgroundScaleMax = 1.4 * _cursorBackgroundScale

		const _stepWidth = stepWidth || DEFAULT_STEP_WIDTH
		const _stepHeight = !stepVariant || stepVariant == 'dot' 
			? _thickness 
			: stepHeight || (BAR_TO_THICKNESS_RATIO * _thickness)
		const _selectedStepColor = !stepColor 
			? (stepVariant && stepVariant != 'dot' ? _selectedColor : DEFAULT_STEP_COLOR) 
			: stepColor == 'primary' ? VAR_PRIMARY_COLOR : stepColor || DEFAULT_STEP_COLOR

		const _stepVerticalOffset = stepVariant == 'bar-up' 
			? -_stepHeight + _thickness
			: stepVariant == 'bar-down' ? _stepHeight - _thickness : 0

		const _cursorBackgroundColor = !cursorBackgroundColor || cursorBackgroundColor == 'primary'
			? VAR_PRIMARY_COLOR
			: cursorBackgroundColor

		const _tooltipPosY = `calc(${-TOOLTIP_SIZE_TO_FONT_RATIO} * ${VAR_FONT_SIZE} - ${_cursorHeight/3}px)`

		const cssVariables = []
		if (primary)
			cssVariables.push(`--lu-color-primary: ${primary};`)
		if (fontSize)
			cssVariables.push(`--lu-font-size: ${fontSize}px;`)
		if (fontFamily)
			cssVariables.push(`--lu-font-family: ${fontFamily};`)
		if (fontColor && fontColor != 'primary')
			cssVariables.push(`--lu-font-color: ${fontColor};`)
		if (disabledColor && disabledColor != 'primary')
			cssVariables.push(`--lu-color-disabled: ${disabledColor};`)
		if (disabledFontColor && disabledFontColor != 'primary')
			cssVariables.push(`--lu-font-color-disabled: ${disabledFontColor};`)
		
		rootClass = css(`
			${cssVariables.join('')}
			width: ${_width};
			height: ${_cursorHeight}px;
			padding: ${_paddingObj.css};

			position: relative;
			display: flex;
			align-items: center;

			& .slider-lines {
				position: relative;
				cursor: pointer;
				width: 100%;
				height: ${_cursorHeight}px;
				display: flex;
				align-items: center;
			}

			& .slider-line {
				height: ${_thickness}px;
				border-radius: ${_edgesRadius};
			}

			& .slider-line-selected {
				background-color: ${_selectedColor};
				opacity: ${_selectedOpacity};
			}

			& .slider-line-notselected {
				background-color: ${_unselectedColor};
				opacity: ${_unselectedOpacity};
			}

			& .slider-line-01 {
				width: var(--slider-value-01);
			}

			& .slider-line-02 {
				width: calc(var(--slider-value-02) - var(--slider-value-01));
			}

			& .slider-line-03 {
				width: calc(100% - var(--slider-value-02));
			}

			& .slider-cursor-section {
				cursor: pointer;
				position: absolute;
				height: ${_cursorHeight}px;
				width: calc(100% - ${_paddingObj.right + _paddingObj.left}px);
				display: flex;
			}

			& .slider-cursor {
				cursor: pointer;
				outline: none;
				width: ${_cursorWidth}px;
				height: ${_cursorHeight}px;
				background-color: ${_cursorColor};
				border-radius: ${_cursorBorderRadius}px;
				margin-left: ${-_cursorOffset}px;
				margin-right: ${-_cursorOffset}px;
				${!cursorBorder ? '' : `
				border: ${cursorBorder}px solid ${_cursorBorderColor};
				`}
				display: flex;
				align-items: center;
				justify-content: center;
				${boxSizing.css('border-box')}

				${!_boxShadow ? '' : `
				box-shadow: ${_boxShadow};
				transition: box-shadow ${MAIN_TRANSITION_CURVE(200)};
				`}

				${noUserSelectCss}

				& .slider-cursor-background {
					position: absolute;
					background-color: ${_cursorBackgroundColor};
					margin: 0;
					transition: ${transform.transition(MAIN_TRANSITION_CURVE())}, opacity ${MAIN_TRANSITION_CURVE()}; 
					${transform.css('scale(0)')}
					opacity: 0;
					will-change: opacity, ${transform.props};
				}
			}

			& .line-step-pusher {
				position: absolute;
				display: flex;
				justify-content: flex-end;
			}  

			& .line-step {
				width: ${_stepWidth}px;
				height: ${_stepHeight}px;
				background-color: ${_unselectedColor};
				opacity: ${_unselectedOpacity};
				margin-top: ${_stepVerticalOffset}px;
			}

			& .step-selected {
				background-color: ${_selectedStepColor};
				opacity: ${_selectedOpacity};
			}

			& .slider-cursor-tooltip-container {
				position: absolute;
				${transform.css('scale(0)')}
				${tooltipAlwaysOn ? `
				${transform.css(`translateY(${_tooltipPosY})`)}
				` : `
				transition: ${transform.transition(MAIN_TRANSITION_CURVE())};
				will-change: ${transform.props};
				${transform.css(`translateY(${-_cursorHeight/2}px) scale(0)`)}
				`}
			}

			& .slider-cursor-tooltip {
				width: calc(${TOOLTIP_SIZE_TO_FONT_RATIO} * ${VAR_FONT_SIZE});
				height: calc(${TOOLTIP_SIZE_TO_FONT_RATIO} * ${VAR_FONT_SIZE});
				background-color: ${_tooltipColor};
				border-radius: 50% 50% 50% 0%;
				${transform.css('rotate(-45deg)')}
			}

			& .slider-cursor-text {
				${fontCss({ family:VAR_FONT_FAMILY, size: VAR_FONT_SIZE, color: _tooltipTextColor, noUserSelect: true })}
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}


			&:not(.slider-disabled) {
				& .slider-cursor {
					&:hover {
						${!_boxShadow ? '' : `
						box-shadow: ${_hoverBoxShadow};
						`}

						& .slider-cursor-background {
							${transform.css(`scale(${_cursorBackgroundScale})`)}
							opacity: 0.2;
						}
					}

					:focus {
						${!_boxShadow ? '' : `
						box-shadow: ${_pressedBoxShadow};
						`}
					}
				}

				& .slider-cursor:focus, 
				&.slider-cursor-01-focused .cursor-01,
				&.slider-cursor-02-focused .cursor-02 {
					& .slider-cursor-background {
						${transform.css(`scale(${_cursorBackgroundScaleMax}) !important`)}
						opacity: 0.2;
					}
				}

				${tooltipAlwaysOn ? '' : `
				&:not(.slider-disabled) .slider-cursor:hover .slider-cursor-tooltip-container {
					${transform.css(`translateY(${_tooltipPosY}) scale(1)`)}
				}
				`}
			}

			&.slider-disabled {
				& .slider-cursor-tooltip,
				& .slider-cursor,
				& .slider-line-selected,
				& .slider-line-notselected,
				& .line-step,
				& .step-selected {
					background-color: ${VAR_DISABLED_COLOR};
				}

				& .slider-cursor,
				& .slider-lines,
				& .slider-cursor-section {
					cursor: default;
				}

				& .slider-line-selected,
				& .step-selected {
					opacity: 1 !important;
				}

				& .slider-line-notselected,
				& .line-step {
					opacity: ${UNSELECTED_OPACITY};
				}

				${!cursorBorder ? '' : `
				& .slider-cursor {
					border: ${cursorBorder}px solid ${VAR_DISABLED_COLOR};
				}
				`}

				& .slider-cursor-text {
					color: ${VAR_ON_DISABLED_COLOR};
				}
			}
		`)
	}

	/* 
	=======================================================
	END - PRIVATE FUNCTIONS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT HANDLERS
	=======================================================
	*/

	const onmousemove = e => {
		if (disabled)
			return
		
		if (!cursor01Moveable && !cursor02Moveable)
			return 
		
		const move = e.clientX - cursorCurrentPosX
		const { width:totalSize } = lineNode.getBoundingClientRect() 
		const newValue = cursorCurrentValue + move/totalSize*config.gap
		
		if (isArray) {
			const newArray = cursor01Moveable ? [newValue, value[1]] : [value[0], newValue]
			if (newArray[0] <= newArray[1])
				setValue(newArray)
		} else
			setValue(newValue)
	}

	const onmousedown = e => {
		if (disabled)
			return

		if (!isArray && cursor01Node) {
			currentFocusedEl = cursor01Node
			currentFocusedEl.focus()
		}

		const { x:lineStart, width:totalSize } = lineNode.getBoundingClientRect() 
		const newValue = config.min + (e.clientX - lineStart)/totalSize*config.gap

		cursorCurrentValue = newValue
		cursorCurrentPosX = e.clientX

		let closestValueIsCursor01 = true
		if (isArray) {
			closestValueIsCursor01 = Math.abs(intParse(value[0], 0) - newValue) <= Math.abs(intParse(value[1], 0) - newValue)
			if (closestValueIsCursor01) {
				setValue([newValue, value[1]])
				currentFocusedEl = cursor01Node
			} else {
				setValue([value[0], newValue])
				currentFocusedEl = cursor02Node
			}

			if (currentFocusedEl)
				currentFocusedEl.focus()
		} else 
			setValue(newValue)

		if (closestValueIsCursor01) {
			cursor01Moveable = true
			cursor02Moveable = false
		} else {
			cursor01Moveable = false
			cursor02Moveable = true
		}

	}

	const onmouseup = () => {
		if (disabled)
			return

		if (currentFocusedEl)
			currentFocusedEl.blur()
		currentFocusedEl = null
		cursor01Moveable = false
		cursorCurrentPosX = null
		cursor02Moveable = false
	}

	const onkeydown = (e, cursor) => {
		if (disabled)
			return

		const decrease = e.key == 'ArrowLeft'
		const increase = e.key == 'ArrowRight'
		if (decrease || increase) {
			const s = shiftKeyPressed ? config.bigIncrementalStep : config.incrementalStep
			const fn = increase ? (a => a + s) : (a => a - s)
			if (cursor == 'cursor01') {
				if (isArray)
					setValue([fn(value[0]||0), value[1]])
				else
					setValue(fn(value||0))
			} else 
				setValue([value[0], fn(value[1]||0)])
		} else if (e.key == 'Shift')
			shiftKeyPressed = true
	}

	const onkeyup = e => {
		if (disabled)
			return 

		if (e.key == 'Shift')
			shiftKeyPressed = false
	}

	/* 
	=======================================================
	END - EVENT HANDLERS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT LIFECYCLE
	=======================================================
	*/
	
	$: config = getSanitizedConfig(min, max, step, showSteps, stepWidth, customSteps)
	$: isArray = Array.isArray(value)
	$: selected = getSelectecValue(value, config)
	
	$: {
		setStyle({ width, padding, primary, unselectedColor, edges, thickness, cursorWidth, cursorHeight, cursorColor, cursorBackgroundColor, cursorBorder, cursorBorderColor, cursorBorderRadius, cursorElevation, switchSelectedColor, isArray, cursorBackgroundScale, stepColor, stepVariant, stepWidth, stepHeight,fontFamily, fontSize, fontColor, tooltipColor, tooltipPinOn, tooltipAlwaysOn, cursorElevationStrength, cursorElevationColor, disabledColor, disabledFontColor })
	}

	$: focusClass = !currentFocusedEl ? '' : ` slider-cursor-${currentFocusedEl == cursor01Node ? '01' : '02'}-focused`

	$: iconConfig = getIconConfig({ cursorIcon, cursorIconType, cursorIconBold, cursorColor, cursorWidth, cursorHeight, cursorIconColor, cursorIconTransform, disabled })

	/* 
	=======================================================
	END - EVENT LIFECYCLE
	=======================================================
	*/
</script>

<svelte:window on:mousemove={onmousemove} on:mouseup={onmouseup}/>
<div 
	id={ID} 
	data-type="slider"  
	class="{rootClass}{focusClass}{disabled ? ' slider-disabled' : ''}"
	style="{selected.css}">
	<input type="hidden" {name} value={selected.value} tabindex="-1">
	<div
		class="slider-lines" bind:this={lineNode}>
		<div class="slider-line slider-line-selected slider-line-01"></div>
		<div class="slider-line slider-line-notselected slider-line-02"></div>
		{#if isArray}
		<div class="slider-line slider-line-selected slider-line-03"></div>
		{/if}
		{#if config.steps}
		{#each config.steps as s}
		<div class="line-step-pusher" style="{s.style}">
			<span class="line-step{getSelectedStepClass(selected.perc, s.perc)}"></span>
		</div>
		{/each}
		{/if}
	</div>
	<div 
		class="slider-cursor-section"
		on:mousedown={onmousedown}>
		<div class="slider-line-01"></div>
		<div 
			class="slider-cursor cursor-01" 
			tabindex="{disabled ? '-1' : '0'}"
			style="z-index: 1;" 
			bind:this={cursor01Node} 
			on:keydown={e => onkeydown(e, 'cursor01')}
			on:keyup={e => onkeyup(e, 'cursor01')}>
			{#if cursorBackgroundOn}
			<div class="slider-cursor slider-cursor-background"></div>
			{/if}
			{#if tooltipOn || tooltipAlwaysOn}
			<div class="slider-cursor-tooltip-container">
				<div class="slider-cursor-tooltip"></div>
				<div class="slider-cursor-text">{selected.value01}</div>
			</div>
			{/if}
			{#if iconConfig}
				<div class="cursor-icon">
					<Icon 
						{...iconConfig}>
					</Icon>
				</div>
			{/if}
		</div>
		{#if isArray}
		<div class="slider-line-02"></div>
		<div 
			class="slider-cursor cursor-02" 
			tabindex="{disabled ? '-1' : '0'}"
			style="z-index: 1;" 
			bind:this={cursor02Node} 
			on:keydown={e => onkeydown(e, 'cursor02')}
			on:keyup={e => onkeyup(e, 'cursor02')}>
			{#if cursorBackgroundOn}
			<div class="slider-cursor slider-cursor-background"></div>
			{/if}
			{#if tooltipOn || tooltipAlwaysOn}
			<div class="slider-cursor-tooltip-container">
				<div class="slider-cursor-tooltip"></div>
				<div class="slider-cursor-text">{selected.value02}</div>
			</div>
			{/if}
			{#if iconConfig}
				<div class="cursor-icon">
					<Icon 
						{...iconConfig}>
					</Icon>
				</div>
			{/if}
		</div>
		{/if}
	</div>
</div>
