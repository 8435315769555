import Lineup from './Lineup.svelte'
import { 
	addBreakpoint, deleteBreakpoint, getActiveBreakpoint, getActiveViewportRange, 
	updateGrids, getRowCells, addLayers, removeLayer, pageReset } from './Lineup.js'
import { injectInlineCssRules, injectLineupProps } from '../../../core/dom'

Lineup.pageReset = pageReset
Lineup.injectInlineCssRules = injectInlineCssRules
Lineup.injectLineupProps = injectLineupProps

/**
 * Gets the current viewport range based on the current window's viewport. 
 * NOTE: The output is a string rather than an array because the same string will not trigger a re-render while
 * the a new array will, even if that array contains the same values.
 * 
 * @param  {[Grid]} grids
 * @param  {Number} viewportWidth			Current viewport width (e.g., 1234)
 * 
 * @return {String}		currentViewportRange	e.g., '0,500', '500,800'  or '800,1200+'
 */
Lineup.prototype.getActiveViewportRange = function _getActiveViewportRange(...args) {
	const l = args.length
	if (l == 1)
		return getActiveViewportRange(this.grids, args[0])
	else if (l == 2)
		return getActiveViewportRange(args[0], args[1])
	else
		throw new Error(`Invalid argument exception. 'getActiveViewportRange' expects 1 or 2 arguments, not ${l}.`)
}

Lineup.prototype.addBreakpoint = function _addBreakpoint(viewportWidth, copyFromViewportWidth) {
	const grids = addBreakpoint(this.grids, viewportWidth, copyFromViewportWidth)
	this.$set({ grids })
	return grids
}

Lineup.prototype.deleteBreakpoint = function _deleteBreakpoint(viewportWidth) {
	const grids = deleteBreakpoint(this.grids, viewportWidth)
	this.$set({ grids })
	return grids
}

/**
 * Gets the breakpoint's value that contains the current grids config based on the current viewport width. 
 * Example: comp.getActiveBreakpoint(1234) or comp.getActiveBreakpoint(grids, 1234)
 *
 * 
 * @param  {[Cell]} grids
 * @param  {Number} viewportWidth	Current screen width (e.g., 1234)
 * 
 * @return {Number}					e.g., 0
 */
Lineup.prototype.getActiveBreakpoint = function _getActiveBreakpoint(...args) {
	const l = args.length
	if (l == 1)
		return getActiveBreakpoint(this.grids, args[0])
	else if (l == 2)
		return getActiveBreakpoint(args[0], args[1])
	else
		throw new Error(`Invalid argument exception. 'getActiveBreakpoint' expects 1 or 2 arguments, not ${l}.`)
}

/**
 * Updates a cell and the updated grids. Mutations will only apply to:
 * - The 'grids' array reference will change
 * - Only the grid items for the targetted viewportWidth are mutated. 
 * - The row (i.e., the cells array) containing the cell is mutated.
 * 
 * Example: comp.updateGrids({ id:'_1234', width:25, widthUnit:'%' }) or 
 * 			comp.updateGrids(grids, { id:'_1234', width: 25, widthUnit:'%' }) or
 * 			comp.updateGrids(grids, { id:'_1234', width: 25, widthUnit:'%' }, { breakpoints:[1200] }) or
 * 			comp.updateGrids({ id:'_1234', width: 25, widthUnit:'%' }, { breakpoints:[1200] })
 * 
 * @param  {[Grid]}  grids						
 * @param  {String}  update.id						
 * @param  {Object}  update.component 
 * @param  {Number}  update.width			
 * @param  {String}  update.widthUnit			
 * @param  {Object}  options.breakpoints	e.g., 'all', or '*' or [1200, 1400]
 * 					
 * @return {[Grid]}  updatedGrids						
 */
Lineup.prototype.updateGrids = function _updateGrids(...args) {
	const l = args.length
	if (l == 1) {
		if (!args[0].id)
			throw new Error('Invalid argument exception. When \'updateGrids\' gets a single argument, that argument must be an object with a valid \'id\' property.')

		return updateGrids(this.grids, args[0])
	} else if (l == 2) {
		if (Array.isArray(args[0])) {
			if (!args[1].id)
				throw new Error('Invalid argument exception. When \'updateGrids\' gets two arguments and the 1st one is a cell array, the 2nd argument must be an object with a valid \'id\' property.')
			return updateGrids(args[0], args[1])
		} else {
			if (!args[0].id)
				throw new Error('Invalid argument exception. When \'updateGrids\' gets two arguments and the 1st one is an object, that 1st argument must be an object with a valid \'id\' property.')
			return updateGrids(this.grids, args[0], args[1])
		}
	} else if (l == 3) {
		if (!Array.isArray(args[0]))
			throw new Error('Invalid argument exception. When \'updateGrids\' gets three arguments, the 1st one must be an array of cells.')
		else
			return updateGrids(...args)
	} else
		throw new Error(`Invalid argument exception. 'updateGrids' expects 1, 2 or 3 arguments, not ${l}.`)
}

/**
 * Gets the cells in the row that contains cellId for a specific breakpoint's viewport width.
 * 
 * Examples: 
 * 	component.getRowCells(grids, '_1234', 1321) or 
 * 	component.getRowCells('_1234', 1321)
 * 
 * @param  {[Grid]} grids   
 * @param  {String} cellId  
 * @param  {Number} viewportWidth	window's viewport width
 *
 * @return {[Cell]} [0]				Cells
 * @return {Number} [1]				viewportWidth 				
 */
Lineup.prototype.getRowCells = function _getRowCells(...args) {
	const l = args.length
	if (l == 2)
		return getRowCells(this.grids, ...args)
	else if (l == 3) 
		return getRowCells(...args)
	else
		throw new Error(`Invalid argument exception. 'getRowCells' expects 2 or 3 arguments, not ${l}.`)
}

/**
 * Adds a component inside a cell in all breakpoints 
 * 
 * @param  {[Grid]}  grids				Array of grids.
 * @param  {String}  cellId
 * @param  {[Layer]} layers				
 * @param  {Boolean} options.replace	Default false. When true, the component replaces any existing components.	
 * 
 * @return {[Grid]}  output[0]			newGrids
 * @return {Object}  output[1].stateId	New component's state ID
 * @return {Object}  output[1].config	New component's config
 */
Lineup.prototype.addLayers = function _addLayers (...args) {
	const l = args.length
	if (l == 2) // [cellId, layers]
		return addLayers(this.grids, ...args)
	else if (l == 3) {
		if (Array.isArray(args[0])) // [grids, cellId, layers]
			return addLayers(...args)
		else // [cellId, layers, options]
			return addLayers(this.grids, ...args)
	} else if (l == 4) // [grids, cellId, layers, options]
		return addLayers(...args)
	else
		throw new Error(`Invalid argument exception. 'addLayers' expects 2, 3 or 4 arguments, not ${l}.`)
}

/**
 * Remove a state from a cell. 
 * 
 * @param  {[Grid]} grids   
 * @param  {String} cellId  
 * @param  {Object} stateId 
 * 
 * @return {[Grid]} newGrids
 */
Lineup.prototype.removeLayer = function _removeLayer(...args) {
	const l = args.length
	if (l == 2) // [cellId, stateId]
		return removeLayer(this.grids, ...args)
	else if (l == 3) // [grids, cellId, stateId]
		return removeLayer(...args)
	else
		throw new Error(`Invalid argument exception. 'removeLayer' expects 2, or 3 arguments, not ${l}.`)
}

// const Doc = (typeof document !== 'undefined') ? document : null
const Win = (typeof window !== 'undefined') ? window : null

if (Win)
	Win.Lineup = Lineup

export {
	Lineup
}