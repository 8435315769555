<script>
	/* 
	=======================================================
	START - DEPENDENCIES
	=======================================================
	*/

	import { createEventDispatcher } from 'svelte'
	import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
	import { getId, exists } from '../../../core/index'
	import { addUnit, alignParse, paddingParse } from '../../../core/parse'
	import { rowRootClass } from './style'
	import Ripple from '../../ripple/src/Ripple.svelte'

	/* 
	=======================================================
	END - DEPENDENCIES
	=======================================================
	*/









	/* 
	=======================================================
	START - CONSTANTS
	=======================================================
	*/

	/* 
	=======================================================
	END - CONSTANTS
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT PROPERTIES
	=======================================================
	*/

	// Content
	export let id = getId() // e.g., '_cwd23d'
	export let item
	// Style
	export let width = null
	export let height = null
	export let padding = 0 // Can be an object such as { top: 5, right: 6, bottom: 7, left: 8 }
	export let align = { horizontal:'left', vertical:'top' } // Possible values: 'top' (left), 'bottom' (left), 'left' (top), 'right' (top), { horizontal:'left|center|right', vertical: 'top|center|bottom' } 
	export let hoverEffect = false 
	export let rippleOn = false
	export let rippleColor = null
	export let rippleOpacity = 0.2
	// Behavior
	export let clickable = false
	export let selected = false
	export let focused = false

	/* 
	=======================================================
	END - COMPONENT PROPERTIES
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT EVENTS
	=======================================================
	*/

	const dispatch = createEventDispatcher()
	const emitEvent = eventName => data => dispatch(eventName, data||{})
	
	/**
	 * on:click
	 *
	 * @param {Object}	data.id
	 * @param {Object}	data.value
	 * 
	 * @return {Void}
	 */
	const emitClick = emitEvent('click')

	/* 
	=======================================================
	END - COMPONENT EVENTS
	=======================================================
	*/









	/* 
	=======================================================
	START - REACTIVE STATE PROPS
	=======================================================
	*/

	let rippleClickEvent

	/* 
	=======================================================
	END - REACTIVE STATE PROPS
	=======================================================
	*/









	/* 
	=======================================================
	START - PRIVATE FUNCTIONS
	=======================================================
	*/

	const getRootVars = ({ width, height, align, padding, clickable, rippleOn }) => {
		const _align = alignParse(align)

		return css(`
		--lu-list-row-width: ${exists(width) ? addUnit(width) : 'auto'};
		--lu-list-row-height: ${exists(height) ? addUnit(height) : 'auto'};
		--lu-list-row-min-height: ${exists(height) ? addUnit(height) : '0'};
		--lu-list-row-overflow: ${rippleOn ? 'hidden' : 'unset'};
		--lu-list-row-padding: ${paddingParse(padding).css};
		--lu-list-row-align-items: ${_align.alignItems};
		--lu-list-row-justify-content: ${_align.justifyContent};
		--lu-list-row-cursor: ${clickable ? 'pointer' : 'default'};
		`)
	}

	/* 
	=======================================================
	END - PRIVATE FUNCTIONS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT HANDLERS
	=======================================================
	*/

	const onclick = e => {
		emitClick({ id, value:item })
		rippleClickEvent = e
	}

	/* 
	=======================================================
	END - EVENT HANDLERS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT LIFECYCLE
	=======================================================
	*/
	
	$: rootVars = getRootVars({ width, height, align, padding, hoverEffect, clickable, rippleOn })

	/* 
	=======================================================
	END - EVENT LIFECYCLE
	=======================================================
	*/
</script>

<div 
	{id} 
	data-type="row" 
	class="{rootVars} {rowRootClass}{selected ? ' selected-item' : ''}{focused ? ' focused-item' : ''}{hoverEffect ? ' hover-effect' : ''}"
	on:click={onclick}
	>
	<slot>
		<div class="list-text">{@html item}</div>
	</slot>
	<div class="list-background"></div>
	{#if rippleOn}
	<Ripple 
		color={rippleColor}
		opacity={rippleOpacity}
		clickEvent={rippleClickEvent}>
	</Ripple>
	{/if}
</div>



