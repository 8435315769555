const CONFIG = [
	{ id:'button', config: { text:'submit', padding:10 } },
	{ id:'checkbox', config: {} },
	{ id:'slider', config: { padding:10 } },
	{ id:'switch', config: { padding:10 } },
	{ id:'text', config: { html: 'Type something', padding:10 } },
	{ id:'textfield', config: { padding:10, variant:'outlined', placeholder:'Enter something' } },
]

/**
 * 
 * @param  {String}			components[].id		e.g., 'textfield', 'slider'
 * @param  {SvelteClass}	components[].class	e.g., Textfield, Slider
 * 
 * @return {String}			components[].id		e.g., 'textfield', 'slider'
 * @return {SvelteClass}	components[].class	e.g., Textfield, Slider
 * @return {Object}			components[].config	
 */
export const addConfig = components => (components||[]).map(c => {
	const { config } = CONFIG.find(cfg => cfg.id == (c.id||'')) || {}
	return { ...c, config:config||{} }
})