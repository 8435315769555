import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
import { transition, transform } from '../../../core/css'

const MAIN_TRANSITION_CURVE = '200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'

export const rootClass = css(`
position: absolute;
width: var(--lu-selectlist-width);
top: var(--lu-selectlist-top);
left: var(--lu-selectlist-left);
z-index: 20;
transform-origin: center var(--lu-selectlist-origin-y, top);
${transition.css({ 'transform':MAIN_TRANSITION_CURVE, opacity:MAIN_TRANSITION_CURVE }, true, true)}
${transform.css('var(--lu-selectlist-transform)')}
opacity: var(--lu-selectlist-opacity);
`)