<script>
	/* 
	=======================================================
	START - DEPENDENCIES
	=======================================================
	*/

	import { createEventDispatcher } from 'svelte'
	import { css, keyframes } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
	import { getId, createBaseEvents, formatValue } from '../../../core/index'
	import theme, {VAR_PRIMARY_COLOR,VAR_FONT_FAMILY,VAR_DISABLED_COLOR,VAR_BORDER_COLOR} from '../../../core/theme'
	import { transform, transition, border, boxSizing, fontCss } from '../../../core/css'
	import { getBoxShadow } from '../../../core/elevation'
	import { paddingParse } from '../../../core/parse'
	import { chooseColor } from '../../../core/color'

	/* 
	=======================================================
	END - DEPENDENCIES
	=======================================================
	*/









	/* 
	=======================================================
	START - CONSTANTS
	=======================================================
	*/

	const ID = getId() // e.g., '_cwd23d'
	const DEFAULT_CURSOR_SIZE = 20
	const DEFAULT_FONT_SIZE_TO_LINE_HEIGHT_RATIO = 9/14
	const FONT_OFFSET_FACTOR = 2/15
	const DEFAULT_LINE_WIDTH_TO_SIZE_RATIO = 36/DEFAULT_CURSOR_SIZE
	const DEFAULT_LINE_HEIGHT_TO_SIZE_RATIO = 14/DEFAULT_CURSOR_SIZE
	const DEFAULT_FONT_COLOR = theme.color.grey[900]
	const DEFAULT_DISABLED_COLOR_TEXT = '#000'
	const DEFAULT_LINE_UNSELECTED_COLOR = theme.color.grey[500]
	const DEFAULT_CURSOR_BACKGROUND_UNSELECTED_COLOR = '#000'
	const DEFAULT_CURSOR_UNSELECTED_COLOR = '#fff'
	const VAR_DISABLED_COLOR_TEXT = `var(--lu-font-color-disabled, ${DEFAULT_DISABLED_COLOR_TEXT})`
	const MAIN_TRANSITION_CURVE = duration => `${duration || 150}ms cubic-bezier(0.4, 0, 0.2, 1)`
	const RIPPLE_DURATION = 500
	const RIPPLE_ANIM = keyframes(`
		to {
			transform: scale(2);
			opacity: 0;
		}
	`)

	/* 
	=======================================================
	END - CONSTANTS
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT PROPERTIES
	=======================================================
	*/

	// Content
	export let name = null
	export let labelOn = null
	export let labelOff = null
	export let value = null
	// Style
	export let primary = null 
	export let lineSelectedColor = null
	export let lineUnselectedColor = null
	export let lineBorder = null
	export let lineSelectedBorderColor = null
	export let lineUnselectedBorderColor = null
	export let lineWidth = null
	export let lineHeight = null // line thickness in pixels
	export let lineBorderRadius = null // valid values: 'sharp', 'round' (default), any number is used for border-radius
	export let cursorOffset = null
	export let cursorSelectedColor = null // Default is 'primary'
	export let cursorUnselectedColor = null // Default grey[300]
	export let cursorBorder = null	
	export let cursorSelectedBorderColor = null
	export let cursorUnselectedBorderColor = null
	export let cursorBorderRadius = null
	export let activeSelectedColor = null // Default is 'primary'
	export let activeUnselectedColor = null
	export let activeLightOn = true
	export let cursorElevation = 2 // Default is 0
	export let cursorElevationColor = null 
	export let cursorElevationStrength = null 
	export let cursorWidth = null
	export let cursorHeight = null
	export let disabledColor = null
	export let disabledFontColor = null
	export let fontSelectedColor = null
	export let fontUnselectedColor = null
	export let fontFamily = null
	export let fontSize = null
	export let fontWeight = null
	export let fontLetterSpacing = null 
	export let fontTransformText = null 
	export let fontTransition = false
	export let rippleOn = true
	export let padding = 0
	// Behavior
	export let transitionSpeed = null
	export let disabled = false
	export let useLabelValue = false // When true and if both 'labelOn' and 'labelOff' exist and are different, then this input becomes a text input rather than a checkbox.

	/* 
	=======================================================
	END - COMPONENT PROPERTIES
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT EVENTS
	=======================================================
	*/

	const dispatch = createEventDispatcher()
	createBaseEvents(dispatch, () => formatValue(name, 'switch', value))
	const emitEvent = eventName => data => dispatch(eventName, data||{})
	
	/**
	 * on:change
	 *
	 * @param  {Object}		data.id
	 * @param  {Object}		data.value
	 * 
	 * @return {Void}
	 */
	const emitChange = emitEvent('change')

	/* 
	=======================================================
	END - COMPONENT EVENTS
	=======================================================
	*/









	/* 
	=======================================================
	START - REACTIVE STATE PROPS
	=======================================================
	*/

	let rootClass,
		node,
		showRipple = false

	/* 
	=======================================================
	END - REACTIVE STATE PROPS
	=======================================================
	*/









	/* 
	=======================================================
	START - PRIVATE FUNCTIONS
	=======================================================
	*/

	/**
	 * Changes the value of the switch. 
	 * 
	 * @param  {String} option.force	Valid value: 'on', 'off'
	 * 
	 * @return {Void}
	 */
	const switchValue = (options) => {

		options = options || {}
		const forceOn = options.force == 'on'
		const forceOff = options.force == 'off'
		const turnOn = forceOn ? true : forceOff ? false : !isOn

		if (textModeOn)
			value = turnOn ? labelOn : labelOff
		else
			value = turnOn

		emitChange({ id:ID, value })
		
		if (rippleOn) {
			showRipple = true
			setTimeout(() => {
				showRipple = false
			}, RIPPLE_DURATION)
		}
	}

	const setStyle = ({ primary, cursorWidth, cursorHeight, cursorBorderRadius, lineWidth, lineHeight, lineBorderRadius, cursorSelectedColor, cursorUnselectedColor, cursorBorder, cursorSelectedBorderColor, cursorUnselectedBorderColor, lineSelectedColor, lineUnselectedColor, lineBorder, lineSelectedBorderColor, lineUnselectedBorderColor, cursorElevation, cursorElevationColor, cursorElevationStrength, activeSelectedColor, activeUnselectedColor, disabledColor, disabledFontColor,padding, cursorOffset, fontSelectedColor, fontUnselectedColor, fontFamily, fontSize, fontWeight, fontLetterSpacing, fontTransformText, transitionSpeed, fontTransition }) => {

		const _size = cursorWidth || DEFAULT_CURSOR_SIZE

		const _cursorWidth = _size
		const _cursorHeight = cursorHeight || _cursorWidth
		const _cursorBorderRadius = cursorBorderRadius === null || cursorBorderRadius === undefined
			? Math.min(_cursorWidth, _cursorHeight)
			: cursorBorderRadius
		const _cursorSelectedColor = chooseColor(cursorSelectedColor, VAR_PRIMARY_COLOR)
		const _cursorUnselectedColor = chooseColor(cursorUnselectedColor, DEFAULT_CURSOR_UNSELECTED_COLOR)
		const _cursorSelectedBorderColor = chooseColor(cursorSelectedBorderColor, VAR_PRIMARY_COLOR)
		const _cursorUnselectedBorderColor = chooseColor(cursorUnselectedBorderColor, DEFAULT_CURSOR_UNSELECTED_COLOR)
		const _activeSelectedColor = chooseColor(activeSelectedColor, VAR_PRIMARY_COLOR)
		const _activeUnselectedColor = chooseColor(activeUnselectedColor, DEFAULT_CURSOR_BACKGROUND_UNSELECTED_COLOR) 

		const _lineWidth = lineWidth || DEFAULT_LINE_WIDTH_TO_SIZE_RATIO * _size
		const _lineHeight = lineHeight || Math.ceil(DEFAULT_LINE_HEIGHT_TO_SIZE_RATIO * _size)
		const _lineBorderRadius = lineBorderRadius === null || lineBorderRadius === undefined || lineBorderRadius == 'round' ? _lineHeight : lineBorderRadius == 'sharp' ? 0 : lineBorderRadius
		const _lineSelectedColor = chooseColor(lineSelectedColor, VAR_PRIMARY_COLOR)
		const _lineUnselectedColor = chooseColor(lineUnselectedColor, DEFAULT_LINE_UNSELECTED_COLOR)
		const _lineSelectedBorderColor = chooseColor(lineSelectedBorderColor, 'transparent')
		const _lineUnselectedBorderColor = chooseColor(lineUnselectedBorderColor, VAR_BORDER_COLOR)
		const _lineSelectedOpacity = _lineSelectedColor == VAR_PRIMARY_COLOR ? 0.5 : 1

		const _fontSelectedColor = chooseColor(fontSelectedColor, DEFAULT_FONT_COLOR)
		const _fontUnselectedColor = chooseColor(fontUnselectedColor, DEFAULT_FONT_COLOR)
		const _fontSize = fontSize || Math.round(DEFAULT_FONT_SIZE_TO_LINE_HEIGHT_RATIO * _lineHeight)
		const _fontOffset = Math.round(FONT_OFFSET_FACTOR * _fontSize)
		const _fontCss = fontCss({
			family:VAR_FONT_FAMILY, 
			size: `${_fontSize}px`, 
			noUserSelect: true, 
			weight: fontWeight, 
			letterSpacing:fontLetterSpacing,
			transform: fontTransformText
		})

		const _boxShadow = cursorElevation ? getBoxShadow({elevation:cursorElevation, strength:cursorElevationStrength, color:cursorElevationColor}) : null

		const _cursorOffset = cursorOffset || 0
		const _translateDist = _lineWidth - _cursorWidth - _cursorOffset

		const _paddingObj = paddingParse(padding)

		const _transition = MAIN_TRANSITION_CURVE(transitionSpeed)
		const _rippleTransition = MAIN_TRANSITION_CURVE(600)

		const cssVariables = []
		if (primary)
			cssVariables.push(`--lu-color-primary: ${primary};`)
		if (fontFamily)
			cssVariables.push(`--lu-font-family: ${fontFamily};`)
		if (disabledColor && disabledColor != 'primary')
			cssVariables.push(`--lu-color-disabled: ${disabledColor};`)
		if (disabledFontColor && disabledFontColor != 'primary')
			cssVariables.push(`--lu-font-color-disabled: ${disabledFontColor};`)

		rootClass = css(`
			${cssVariables.join('')}
			display: flex;
			height: ${Math.max(_cursorHeight, _lineHeight)}px;

			padding: ${_paddingObj.css};
			
			cursor: default;

			& .switch-container {
				outline: none;
				position: relative;
				width: ${_lineWidth}px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			& .switch-line {
				width: ${_lineWidth}px;
				position: absolute;
				height: ${_lineHeight}px;
				border-radius: ${_lineBorderRadius}px;
				transition: opacity ${_transition};
				will-change: opacity;
				overflow: hidden;
			}

			& .switch-line-selected {
				display: flex;
				justify-content: flex-start;
				${border.css({ thickness:lineBorder, color:_lineSelectedBorderColor }, lineBorder)}
				background-color: ${_lineSelectedColor};
				opacity: 0;
			}

			& .switch-line-unselected {	
				display: flex;
				justify-content: flex-end;
				${border.css({ thickness:lineBorder, color:_lineUnselectedBorderColor }, lineBorder)}		
				background-color: ${_lineUnselectedColor};
				opacity: 1;
			}

			& .switch-cursor-container {
				width: 100%;
				position: absolute;
				display: flex;
				align-items: center;
			}

			& .switch-cursor {
				${boxSizing.css('border-box')}
				border-radius: ${_cursorBorderRadius}px;
				box-shadow: ${_boxShadow || 'none'};
				width: ${_cursorWidth}px;
				height: ${_cursorHeight}px;
				transition: ${transform.transition(_transition)};
				transform: translateX(${_cursorOffset}px);
				will-change: transform;
			}

			& .switch-cursor-shape {
				position: absolute;
				border-radius: ${_cursorBorderRadius}px;
				width: 100%;
				height: 100%;
				transition: opacity ${_transition};
				will-change: opacity;
			}

			& .switch-cursor-background {
				transition: 
					${transform.transition(_transition)},
					background-color ${_transition}, 
					opacity ${_transition};
				${transform.css('scale(0)')}
				background-color: ${_activeUnselectedColor};
				opacity: 0;
			}

			& .switch-cursor-ripple {
				transition: background-color ${_rippleTransition};
				${transform.css('scale(0.9)')}
				background-color: ${_activeUnselectedColor};
				opacity: 1;
			}

			& .switch-cursor-selected {
				${border.css({ thickness:cursorBorder, color:_cursorSelectedBorderColor, boxSizing:'border-box' }, cursorBorder)}
				background-color: ${_cursorSelectedColor};
				opacity: 0;
			}

			& .switch-cursor-unselected {
				${border.css({ thickness:cursorBorder, color:_cursorUnselectedBorderColor, boxSizing:'border-box' }, cursorBorder)}
				background-color: ${_cursorUnselectedColor};
				opacity: 1;
			}

			& .switch-label {
				${_fontCss}
				display: flex;
				align-items: center;
				height: 100%;
				width: calc(100% - ${_cursorWidth}px);
				${transition.css({ transform: _transition }, fontTransition, true)}
			}

			& .switch-label-on {
				${transform.css(`translateX(${-_translateDist}px)`, fontTransition)}
				color: ${_fontSelectedColor};
				justify-content: center;
				margin-left: ${_fontOffset}px;
			}

			& .switch-label-off {
				${transform.css('translateX(0px)', fontTransition)}
				color: ${_fontUnselectedColor};
				justify-content: center;
				margin-right: ${_fontOffset}px;
			}

			& .switch-show-ripple {
				animation: ${RIPPLE_ANIM} ${RIPPLE_DURATION}ms linear;
			}

			&.switch-on {
				& .switch-cursor {
					${transform.css(`translateX(${_translateDist}px)`)}
				}

				& .switch-cursor-foreground {
					opacity: 1;
				}

				& .switch-cursor-selected {
					opacity: 1;
				}

				& .switch-line-selected {
					opacity: ${_lineSelectedOpacity};
				}

				& .switch-line-unselected,
				& .switch-cursor-unselected {
					opacity: 0;
				}

				& .switch-label-on {
					${transform.css('translateX(0px)', fontTransition)}
				}

				& .switch-label-off {
					${transform.css(`translateX(${_translateDist}px)`, fontTransition)}
				}
			}

			&:not(.switch-disabled) {

				cursor: pointer;

				& .switch-container:hover .switch-cursor-background,
				& .switch-container:focus .switch-cursor-background {
					transform: scale(2);
					opacity: 0.1;
				}

				&.switch-on {
					& .switch-container:hover .switch-cursor-background,
					& .switch-container:focus .switch-cursor-background,
					& .switch-cursor-ripple {
						background-color: ${_activeSelectedColor};
					}
				}
			}

			&.switch-disabled {
				& .switch-line {
					background-color: ${VAR_DISABLED_COLOR} !important;
					opacity: 0.3;
					${border.css({ thickness:lineBorder, color:`${VAR_DISABLED_COLOR} !important`, boxSizing:'border-box' }, lineBorder)}
				}

				& .switch-cursor {
					box-shadow: none;
				}

				& .switch-cursor-shape {
					background-color: ${VAR_DISABLED_COLOR} !important;
					${border.css({ thickness:cursorBorder, color:`${VAR_DISABLED_COLOR} !important`, boxSizing:'border-box' }, cursorBorder)}
				}

				& .switch-label-on,
				& .switch-label-off {
					color: ${VAR_DISABLED_COLOR_TEXT};
				}
			}
		`)
	}

	/* 
	=======================================================
	END - PRIVATE FUNCTIONS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT HANDLERS
	=======================================================
	*/

	const onclick = () => {
		if (disabled)
			return 

		switchValue()
	}

	const onkeydown = (e) => {
		if (disabled)
			return

		if (e.key == 'Enter') 
			switchValue()
	}

	/* 
	=======================================================
	END - EVENT HANDLERS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT LIFECYCLE
	=======================================================
	*/
	
	$: {		
		setStyle({ primary, cursorWidth, cursorHeight, cursorBorderRadius, lineWidth, lineHeight, lineBorderRadius, cursorSelectedColor,cursorUnselectedColor, cursorBorder, cursorSelectedBorderColor, cursorUnselectedBorderColor, lineSelectedColor, lineUnselectedColor, lineBorder, lineSelectedBorderColor, lineUnselectedBorderColor, cursorElevation, cursorElevationColor, cursorElevationStrength, activeSelectedColor, activeUnselectedColor, disabledColor, disabledFontColor, fontSelectedColor, fontUnselectedColor, fontFamily, fontSize, padding, cursorOffset, fontWeight, fontLetterSpacing, fontTransformText, transitionSpeed, fontTransition })
	}

	$: textModeOn = useLabelValue && labelOn && labelOff
	$: isOn = textModeOn ? value == labelOn : value

	/* 
	=======================================================
	END - EVENT LIFECYCLE
	=======================================================
	*/
</script>

<div 
	id={ID} 
	data-type="switch"  
	class={rootClass}{isOn ? ' switch-on' : ''}{disabled ? ' switch-disabled' : ''}>
	{#if textModeOn} 
	<input type="text" {name} {value} style="opacity: 0;position: absolute;width: 1px;height: 1px;" tabindex="-1">
	{:else}
	<input type="checkbox" {name} checked={value} style="opacity: 0;position: absolute;width: 1px;height: 1px;" tabindex="-1">
	{/if}
	<div class="switch-container" tabindex="{disabled ? '-1' : '0'}" bind:this={node} on:click={onclick} on:keydown={onkeydown}>
		<div class="switch-line switch-line-selected">
			{#if labelOn}
			<div class="switch-label switch-label-on">{labelOn}</div>
			{/if}
		</div>
		<div class="switch-line switch-line-unselected">
			{#if labelOff}
			<div class="switch-label switch-label-off">{labelOff}</div>
			{/if}
		</div>
		<div class="switch-cursor-container">
			<div class="switch-cursor">
				{#if activeLightOn}
				<div class="switch-cursor-shape switch-cursor-background"></div>
				{/if}
				{#if rippleOn}
				<div class="switch-cursor-shape switch-cursor-ripple{showRipple ? ' switch-show-ripple' : ''}"></div>
				{/if}
				<div class="switch-cursor-shape switch-cursor-unselected"></div>
				<div class="switch-cursor-shape switch-cursor-selected"></div>
			</div>
		</div>
	</div>
</div>





