<script>
	/* 
	=======================================================
	START - DEPENDENCIES
	=======================================================
	*/
	import { createEventDispatcher, afterUpdate, tick } from 'svelte'
	import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
	import { getId, createBaseEvents, exists } from '../../../core/index'
	import { addUnit, rangeParse } from '../../../core/parse'
	import { chooseColor } from '../../../core/color'
	import { rootClass } from './style'
	import { VAR_FONT_SIZE, VAR_FONT_FAMILY, VAR_ON_PRIMARY_COLOR, VAR_FONT_COLOR } from '../../../core/theme'
	import Row from './Row.svelte'

	/* 
	=======================================================
	END - DEPENDENCIES
	=======================================================
	*/









	/* 
	=======================================================
	START - CONSTANTS
	=======================================================
	*/

	const ID = getId() // e.g., '_cwd23d'

	/* 
	=======================================================
	END - CONSTANTS
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT PROPERTIES
	=======================================================
	*/

	// Content
	export let items = []
	// Style
	export let color = null
	export let fontFamily = null
	export let fontSize = null
	export let fontColor = null
	export let width = null
	export let height = null
	export let backgroundColor = null
	export let rowPadding = 0
	export let rowHeight = 0
	export let rowAlign = null
	export let rowHoverEffect = false
	export let rowHoverBackground = null
	export let rowHoverOpacity = null
	export let rippleOn = false
	export let rippleColor = null
	export let rippleOpacity = 0.2
	// Behavior
	export let clickable = false
	export let focus = false
	export let active = false

	/* 
	=======================================================
	END - COMPONENT PROPERTIES
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT EVENTS
	=======================================================
	*/

	const dispatch = createEventDispatcher()
	createBaseEvents(dispatch)
	const emitEvent = eventName => data => dispatch(eventName, data||{})
	
	/**
	 * on:click
	 *
	 * @param  {Object}		data.id		Selected item.id
	 * @param  {Object}		data.value	Selected item
	 * 
	 * @return {Void}
	 */
	const emitClick = emitEvent('click')

	/**
	 * on:escape
	 *
	 * @param  {Object}		data.id		Selected item.id
	 * 
	 * @return {Void}
	 */
	const emitEscape = emitEvent('escape')

	/**
	 * on:blur
	 *
	 * @param  {Object}		data
	 * 
	 * @return {Void}
	 */
	const emitBlur = emitEvent('blur')

	/**
	 * on:sizechange
	 *
	 * @param  {Number}		data.width
	 * @param  {Number}		data.height
	 * 
	 * @return {Void}
	 */
	const emitSizeChange = emitEvent('sizechange')

	/* 
	=======================================================
	END - COMPONENT EVENTS
	=======================================================
	*/









	/* 
	=======================================================
	START - REACTIVE STATE PROPS
	=======================================================
	*/

	let node,
		itemsCount,
		sizeMayHaveChanged = false,
		focusedIndex // this is the currently focused item when using the arrow keys

	/* 
	=======================================================
	END - REACTIVE STATE PROPS
	=======================================================
	*/









	/* 
	=======================================================
	START - PRIVATE FUNCTIONS
	=======================================================
	*/

	const zeroOneParse = rangeParse()

	const setFocusedIndexBasedOnSelectedRow = (rowData) => {
		const detail = (rowData || {}).detail || {}
		const key = typeof(detail) == 'object' 
			? detail.id !== undefined ? detail.id : detail.value
			: detail

		if (key !== undefined) {
			let index = 0
			for(let item of (items||[])) {
				const t = typeof(item)
				if (t == 'object') {
					const k = item.id !== undefined ? item.id : item.value
					if (k == key) {
						focusedIndex = index
						break
					}
				} else if (item == key) {
					focusedIndex = index
					break
				}
				index++
			}
		}
	}

	const onKeypressHandler = eventType => event => {
		if (!event)
			return

		const isEnter = event.key == 'Enter'
		const isEscape = event.key == 'Escape'
		const isArrowDown = event.key == 'ArrowDown'
		const isArrowUp = event.key == 'ArrowUp'

		if (eventType == 'keypress' && isEnter) {
			const selectedItem = items[focusedIndex || 0] || {}
			const id = selectedItem.id || null

			emitClick({ 
				id, 
				value: typeof(selectedItem) == 'object' ? selectedItem.value : selectedItem
			})
		}
		else if (eventType == 'keydown' && isEscape) 
			emitEscape({ id:ID })
		else if (isArrowUp || isArrowDown) {

			// Change the 'focusedIndex' to the new next one based on the arrow direction
			// and scroll to that new item (we have to explicitely scroll there otherwise the selected item my fall
			// outside of the list)

			if (focusedIndex === undefined) {
				const index = (items || []).map(x => !x || !x.selected ? 'no' : 'yes').indexOf('yes')
				if (index >= 0)
					focusedIndex = index
				else
					focusedIndex = 0
			} else if (isArrowDown || isArrowUp) {
				const rowEls = node.querySelectorAll('div[data-type="row"]')

				let scrollY = 0
				if (isArrowDown && focusedIndex < (items || []).length) {
					for (let i=0;i<focusedIndex;i++)
						scrollY += ((rowEls[i] || {}).scrollHeight || 0)
					focusedIndex++
				} else if (isArrowUp && focusedIndex > 0) {
					focusedIndex--
					for (let i=0;i<focusedIndex;i++)
						scrollY += ((rowEls[i] || {}).scrollHeight || 0)
				}

				node.scrollTop = scrollY
			}
		}
	}

	const getRootVars = ({ width, height, color, fontFamily, fontSize, fontColor, backgroundColor, rowHoverBackground, rowHoverOpacity }) => {

		return css(`
			--lu-list-width: ${exists(width) ? addUnit(width) : 'auto'};
			--lu-list-height: ${exists(height) ? addUnit(height) : 'auto'};
			--lu-list-font-family: ${fontFamily||VAR_FONT_FAMILY};
			--lu-list-font-size: ${fontSize ? addUnit(fontSize) : VAR_FONT_SIZE};
			--lu-list-font-color: ${chooseColor(fontColor||VAR_FONT_COLOR)};
			--lu-list-color: ${chooseColor(color)};
			--lu-list-background-color: ${chooseColor(backgroundColor||VAR_ON_PRIMARY_COLOR)};
			--lu-list-hover-color:${chooseColor(rowHoverBackground)};
			--lu-list-hover-opacity: ${exists(rowHoverOpacity) ? zeroOneParse(rowHoverOpacity) : 0.04};
		`)
	}

	/* 
	=======================================================
	END - PRIVATE FUNCTIONS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT HANDLERS
	=======================================================
	*/

	const onRowClick = (data) => {
		setFocusedIndexBasedOnSelectedRow(data)
		emitClick((data || {}).detail||{})
	}
	const onkeypress = onKeypressHandler('keypress')
	const onkeydown = onKeypressHandler('keydown')

	/* 
	=======================================================
	END - EVENT HANDLERS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT LIFECYCLE
	=======================================================
	*/
	
	// Style changes
	$: rootVars = getRootVars({ width, height, color, fontFamily, fontSize, fontColor, backgroundColor, rowHoverBackground, rowHoverOpacity })

	// Focus changes
	$: {
		if (focus && node)
			node.focus()
	}

	// Active row changes
	$: {
		// Used to set the focus on the first item if the list was opened in 'active' mode. This typically happens
		// when the list is used by the 'textfield' in 'select' mode and the options list is opened using the 'Enter'
		// key. This allows to use the arrow keys to select an option.
		if (active && focusedIndex === undefined)
			focusedIndex = 0
	}

	// Item count changes
	$: {
		const c = (items || []).length
		if (itemsCount != c) {
			itemsCount = c
			sizeMayHaveChanged = true
		}
	}

	// Size changes
	$: {
		if (height || width)
			sizeMayHaveChanged = true
	}

	afterUpdate(async () => {
		if (sizeMayHaveChanged && node) {
			sizeMayHaveChanged = false
			await tick()
			emitSizeChange({
				el:node,
				width: node ? node.clientWidth : 0,
				height: node ? node.clientHeight : 0
			})
		}
	})

	/* 
	=======================================================
	END - EVENT LIFECYCLE
	=======================================================
	*/
</script>

<div 
	id={ID} 
	data-type="list"
	tabindex="-1"
	class="{rootVars} {rootClass}"
	bind:this={node}
	on:blur={emitBlur}
	on:keypress={onkeypress}
	on:keydown={onkeydown}
	>
	{#each items as item, i}
		<Row 
			id={item.id}
			item={typeof(item) == 'object' ? item.value||'' : item}
			{clickable}
			{rippleOn}
			{rippleColor}
			{rippleOpacity}
			selected={item.selected}
			focused={focusedIndex === i}
			padding={rowPadding}
			width="100%"
			height={rowHeight}
			align={rowAlign || undefined}
			hoverEffect={rowHoverEffect || undefined}
			on:click={onRowClick}
			>	
		</Row>
	{/each}
</div>
