import { css, keyframes } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
import { transform } from '../../../core/css'

export const ANIMATION_LENGTH = 700
const RIPPLE_ANIM = keyframes(`
	to {
		${transform.css('scale(4)')}
		opacity: 0;
	}
`)
export const RIPPLE_BASE_CLASS = css(`
	position: absolute;
	top: 0;
	left: 0;
	
	& div {
		position: absolute;
		border-radius: 50%;
		transform: scale(0);
		animation: ${RIPPLE_ANIM} ${ANIMATION_LENGTH}ms linear; 
	}
	`)

export const rootClass = css(`
& div {
	background-color: var(--lu-ripple-color, #fff);
	opacity: var(--lu-ripple-opacity, 0.2);
}
`)