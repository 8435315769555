<script>
	/* 
	=======================================================
	START - DEPENDENCIES
	=======================================================
	*/

	import { getId } from '../../../core/index'
	import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
	import { requestAnimFrame } from '../../../core/dom'
	import { ANIMATION_LENGTH, RIPPLE_BASE_CLASS, rootClass } from './style'

	/* 
	=======================================================
	END - DEPENDENCIES
	=======================================================
	*/









	/* 
	=======================================================
	START - CONSTANTS
	=======================================================
	*/

	const ID = getId() // e.g., '_cwd23d'

	/* 
	=======================================================
	END - CONSTANTS
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT PROPERTIES
	=======================================================
	*/

	// Content
	export let clickEvent // Parent's click event 
	// Style
	export let color = null
	export let opacity = null
	export let centeredOrigin = false // When set to true, the ripple always starts from the center of the container. This is used in the checkbox.

	/* 
	=======================================================
	END - COMPONENT PROPERTIES
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT EVENTS
	=======================================================
	*/


	/* 
	=======================================================
	END - COMPONENT EVENTS
	=======================================================
	*/









	/* 
	=======================================================
	START - REACTIVE STATE PROPS
	=======================================================
	*/

	let node, 
		ripples=[]

	/* 
	=======================================================
	END - REACTIVE STATE PROPS
	=======================================================
	*/









	/* 
	=======================================================
	START - PRIVATE FUNCTIONS
	=======================================================
	*/

	const getCoord = (event, node, centeredOrigin) => {
		if (!node || !node.getBoundingClientRect || !event)
			return

		const parentEl = node.parentElement || {}
		const parentWidth = parentEl.clientWidth || 0
		const parentHeight = parentEl.clientHeight || 0

		const diameter = 1.1*Math.max(parentWidth, parentHeight)
		if (!diameter)
			return 

		if (centeredOrigin)
			return { diameter, left:0, top:0 }
		else {
			const { x:rippleX, y:rippleY } = node.getBoundingClientRect() || {}
			
			const offsetX = event.clientX - rippleX
			const offsetY = event.clientY - rippleY

			return { 
				diameter,
				left: Math.round(parentWidth/2 - diameter) + offsetX,
				top: Math.round(-diameter/2) + offsetY
			}
		}
	}

	const start = (event) => {
		const coord = getCoord(event, node, centeredOrigin)
		if (!coord)
			return

		ripples = [...ripples, {
			id: Date.now(),
			style: `width: ${coord.diameter}px;height: ${coord.diameter}px;left: ${coord.left}px;top: ${coord.top}px;`
		}]

		setTimeout(() => {
			const [, ...rest] = ripples
			ripples = rest
		}, ANIMATION_LENGTH)
	}

	const getRootVars = (color, opacity) => {
		const opacityExists = opacity !== null && opacity !== undefined
		if (!opacityExists && !color)
			return ''

		return css(`
			${color ? `--lu-ripple-color:${color};` : ''}
			${opacityExists ? `--lu-ripple-opacity:${opacity < 0 ? 0 : opacity > 1 ? 1 : opacity};` : ''}
		`)
	}

	/* 
	=======================================================
	END - PRIVATE FUNCTIONS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT HANDLERS
	=======================================================
	*/

	

	/* 
	=======================================================
	END - EVENT HANDLERS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT LIFECYCLE
	=======================================================
	*/
	
	$: rootVars = getRootVars(color, opacity)
	$: {
		requestAnimFrame(() => start(clickEvent))
	}

	/* 
	=======================================================
	END - EVENT LIFECYCLE
	=======================================================
	*/
</script>

<div 
	id={ID} 
	data-type="ripple" 
	class="{rootVars} {RIPPLE_BASE_CLASS} {rootClass}"
	bind:this={node}>
	{#each ripples as ripple (ripple.id)}
		<div data-key={ripple.id} style="{ripple.style}"></div>
	{/each}
</div>



