import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'

export const rootClass = css(`
position: relative;
width: var(--lu-shape-width);
height: var(--lu-shape-height);
margin: var(--lu-shape-margin);
padding: var(--lu-shape-padding);
background-color: var(--lu-shape-color);
background-image: var(--lu-shape-gradient);
border-radius: var(--lu-shape-border-radius);
opacity: var(--lu-shape-opacity);
`)