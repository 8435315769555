import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'

export const rootClass = css(`
outline: none;

width: var(--lu-paper-width);
height: var(--lu-paper-height);
min-width: var(--lu-paper-min-width);
min-height: var(--lu-paper-min-height);
max-width: var(--lu-paper-max-width);
max-height: var(--lu-paper-max-height);
padding: var(--lu-paper-padding);

& .lu-paper {
	overflow: hidden;
	display: flex;

	border-radius: var(--lu-paper-border-radius);
	align-items: var(--lu-paper-align-items);
	justify-content: var(--lu-paper-justify-content);
	background-color: var(--lu-paper-background-color); 
	border: var(--lu-paper-border); 
	box-shadow: var(--lu-paper-box-shadow);
}
`)