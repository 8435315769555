<script>
	/* 
	=======================================================
	START - DEPENDENCIES
	=======================================================
	*/

	// import { createEventDispatcher } from 'svelte'
	import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
	import { getId } from '../../../core/index'
	import { unitParse, paddingParse, cornerParse, getNumberDetails, opacityParse } from '../../../core/parse'
	import { getGradientColor } from '../../../core/css'
	import { chooseColor } from '../../../core/color'
	import { rootClass } from './style'

	/* 
	=======================================================
	END - DEPENDENCIES
	=======================================================
	*/









	/* 
	=======================================================
	START - CONSTANTS
	=======================================================
	*/

	const ID = getId() // e.g., '_cwd23d'

	/* 
	=======================================================
	END - CONSTANTS
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT PROPERTIES
	=======================================================
	*/

	// Content
	export let shape = 'rectangle'
	// Style
	export let width = '100%'
	export let height = 1
	export let padding = 0
	export let margin = 0
	export let color = 'black'
	export let colorOrientation = null
	export let borderRadius = null
	export let opacity = null
	// Behavior

	/* 
	=======================================================
	END - COMPONENT PROPERTIES
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT EVENTS
	=======================================================
	*/

	// const dispatch = createEventDispatcher()
	// createBaseEvents(dispatch)
	// const emitEvent = eventName => (data,fn) => { 
	// 	if (fn)
	// 		fn()
	// 	dispatch(eventName, data||{})
	// }
	
	// /**
	//  * on:toohigh
	//  *
	//  * @param  {Object}		data
	//  * 
	//  * @return {Void}
	//  */
	// const emitTooHigh = emitEvent('toohigh')

	/* 
	=======================================================
	END - COMPONENT EVENTS
	=======================================================
	*/









	/* 
	=======================================================
	START - REACTIVE STATE PROPS
	=======================================================
	*/

	/* 
	=======================================================
	END - REACTIVE STATE PROPS
	=======================================================
	*/









	/* 
	=======================================================
	START - PRIVATE FUNCTIONS
	=======================================================
	*/

	const getCircleDim = (width,height) => width ? [width,width,width] : height ? [height,height,height] : [20,20,20]
	
	const isHorinzontalRectangle = (shape, width, height) => {
		if (shape && shape != 'rectangle')
			return false 

		if (width > 0 && height > 0 && width > height)
			return true

		const _w = getNumberDetails(width)
		const _h = getNumberDetails(height)

		if (_w.unit && !_h.unit)
			return true
		if (_w.unit === _h.unit)
			return _w.value > _h.value

		return false
	}

	const getShapeDim = (shape, width, height, borderRadius) => {
		if (shape == 'circle')
			return getCircleDim(width, height)
		else if (shape == 'square') {
			let size = (width && height 
				? width > height ? width : height
				: width || height) || 0

			return [size, size, borderRadius]
		}
		else 
			return [width, height, borderRadius]
	}

	const getRootVars = ({ width, height, padding, margin, color, colorOrientation, shape, borderRadius, opacity }) => {

		const [_width, _height, _borderRadius] =getShapeDim(shape, width, height, borderRadius)
		const _isGradient = color && Array.isArray(color)
		const _colorOrientation = _isGradient 
			? colorOrientation || (isHorinzontalRectangle(shape, width, height) ? 90 : 0)
			: 0

		const _gradient = _isGradient 
			? getGradientColor({ orientation:_colorOrientation })(...color.map(chooseColor)) 
			: null
		const _color = _gradient ? 'transparent' : (color ? chooseColor(color) : 'transparent')

		return css(`
		--lu-shape-width: ${_width ? unitParse(_width) : 'auto'};
		--lu-shape-height: ${_height ? unitParse(_height) : 'auto'};
		--lu-shape-padding: ${padding ? paddingParse(padding).css : '0px'};
		--lu-shape-margin: ${margin ? paddingParse(margin).css : '0px'};
		--lu-shape-gradient: ${_gradient||'none'};
		--lu-shape-color: ${_color};
		--lu-shape-border-radius: ${_borderRadius ? cornerParse(_borderRadius).css : '0px'};
		--lu-shape-opacity: ${opacityParse(opacity)};
		`)
	}

	/* 
	=======================================================
	END - PRIVATE FUNCTIONS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT HANDLERS
	=======================================================
	*/

	/* 
	=======================================================
	END - EVENT HANDLERS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT LIFECYCLE
	=======================================================
	*/
	
	$: rootVars = getRootVars({ width, height, padding, margin, color, colorOrientation, shape, borderRadius, opacity })

	/* 
	=======================================================
	END - EVENT LIFECYCLE
	=======================================================
	*/
</script>

<div 
	id={ID} 
	data-type="shape"  
	class="{rootVars} {rootClass}">
</div>
