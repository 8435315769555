<script>
	/* 
	=======================================================
	START - DEPENDENCIES
	=======================================================
	*/

	import { createEventDispatcher } from 'svelte'
	import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
	import { getId, createBaseEvents, deepMergeObj, isMobile } from '../../../core/index'
	import { getGradientColor, transition as trans, getTransitionCss } from '../../../core/css'
	import { VAR_FONT_FAMILY, VAR_FONT_SIZE, VAR_FONT_COLOR, VAR_FONT_LETTER_SPACING, VAR_FONT_LINE_HEIGHT, VAR_FONT_STYLE } from '../../../core/theme'
	import { chooseColor } from '../../../core/color'
	import { paddingParse, addUnit, cornerParse, addToCardinal } from '../../../core/parse'
	import { getBoxShadow } from '../../../core/elevation'
	import { processPressHandler } from '../../../core/dom'
	import Ripple from '../../ripple/src/Ripple.svelte'
	import { rootClass, TRANSPARENT_IMAGE } from './style'


	/* 
	=======================================================
	END - DEPENDENCIES
	=======================================================
	*/









	/* 
	=======================================================
	START - CONSTANTS
	=======================================================
	*/

	const ID = getId() // e.g., '_cwd23d'
	const NO_TRANSITION = 'all 0s ease 0s'

	/* 
	=======================================================
	END - CONSTANTS
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT PROPERTIES
	=======================================================
	*/

	// Content
	export let id = ID
	export let url = '' // Also supports object (1)
	export let alt = ''
	export let captionHtml = ''
	export let name = ''
	// Style
	export let width = null
	export let height = null
	export let padding = 0
	export let margin = 0
	export let captionSize = null
	export let captionFamily = null
	export let captionColor = null
	export let captionStyle = null
	export let captionLetterSpacing = null
	export let captionLineHeight = null
	export let captionOverlay = false // when true, the caption overlay the image
	export let captionAlign = null // { horizontal:'right', vertical:'top' } // vertical only makes sense when 'captionOverlay' is true.
	export let captionMargin = null
	export let captionPadding = null
	export let captionBackgroundColor = null
	export let border = null // Border's thickness 
	export let borderColor = null
	export let borderRadius = null // Number or object (e.g., { left: 4, right:0 } or { top:4, bottom:2 } or { top: { left:1, right:2 }, bottom: { left:3, right:4 } } or { left: { top:1, bottom:2 }, right: { top:3, bottom:4 } })
	export let elevation = null
	export let elevationStrength = 0
	export let elevationColor = '#000'
	export let elevationOffset = null // { x:2,y:2 }
	export let frame = null
	export let frameColor = null // e.g., 'red', or ['blue', 'green'] for gradient
	export let frameColorOrientation = null // Number representing degrees. Only valid when 'frameColor' is an array (i.e., gradient color) 
	export let rippleLight = '#fff'
	export let rippleOpacity = 0.2
	export let x = null
	export let y = null
	export let scale = null
	export let aspectRatio = null
	export let transition = null
	// Behavior
	export let on = null
	export let fit = false
	export let clickable = false
	export let rippleEnabled = false

	/*
	 (1) Ref url_def: The following example is the most detailed version of the 'url' object. Each property represents the screen size the image should be used for. For example, '320' means: 'for screen larger then 320 pixels'. The 'src' property indicates the default configuration for fallback. Each value can be a string (just URL), and object (URL with type) or an array. The array is generally used to support multiple browsers (e.g., Safari does not support webp format, so we need to add png or jpeg fallback).

	 	{ 
	 		src:[{ 
	 			value: 'https://img.co/mickey_std.webp',
	 			type:'image/webp'
	 		},{ 
	 			value: 'https://img.co/mickey_std.jpg',
	 			type:'image/jpeg'
	 		}],
	 		320:[{ 
	 			value: 'https://img.co/mickey_small.webp',
	 			type:'image/webp'
	 		},{ 
	 			value: 'https://img.co/mickey_small.jpg',
	 			type:'image/jpeg'
	 		}],
	 		640:[{ 
	 			value: 'https://img.co/mickey_large.webp',
	 			type:'image/webp'
	 		},{ 
	 			value: 'https://img.co/mickey_large.jpg',
	 			type:'image/jpeg'
	 		}]
	 	}

	 	or ['https://img.co/mickey_std.webp', 'https://img.co/mickey_std.jpg'], which is a shortcut for: 
	 	{ 
	 		src:[{ 
	 			value: 'https://img.co/mickey_std.webp'
	 		},{ 
	 			value: 'https://img.co/mickey_std.jpg'
	 		}]
	 	}
	 */

	/* 
	=======================================================
	END - COMPONENT PROPERTIES
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT EVENTS
	=======================================================
	*/

	const dispatch = createEventDispatcher()
	createBaseEvents(dispatch)
	const emitEvent = eventName => (data,fn) => { 
		if (fn)
			fn()
		dispatch(eventName, data||{})
	}
	
	/**
	 * on:mousedown
	 *
	 * @param  {Object}		data
	 * 
	 * @return {Void}
	 */
	const emitMouseDown = emitEvent('mousedown')

	/* 
	=======================================================
	END - COMPONENT EVENTS
	=======================================================
	*/









	/* 
	=======================================================
	START - REACTIVE STATE PROPS
	=======================================================
	*/

	let rippleClickEvent = null,
		hovering = false,
		pressed = false,
		clickEventContext = { clickEventSeqIndex: 0 } // Only used when the 'emitEventSeq' property is set in the 'press' event.

	/* 
	=======================================================
	END - REACTIVE STATE PROPS
	=======================================================
	*/









	/* 
	=======================================================
	START - PRIVATE FUNCTIONS
	=======================================================
	*/

	/**
	 * Gets the 'inset' CSS value. 
	 * 
	 * @param  {String} captionAlign.horizontal		Valid values: 'left', 'right' 
	 * @param  {String} captionAlign.vertical		Valid values: 'top', 'center', 'bottom'
	 *  
	 * @return {String} inset						e.g., '10px auto auto auto'
	 */
	const getInset = (captionAlign, captionSize) => {
		captionSize = captionSize || 16
		if (!captionAlign)
			return null
		const inset = { top:'auto', right:'auto', bottom:'auto', left:'auto' }
		
		if (captionAlign.horizontal == 'right')
			inset.right = 0
		if (captionAlign.vertical == 'top')
			inset.top = 0
		else if (captionAlign.vertical == 'center')
			inset.top = `calc(50% - ${captionSize/2}px)`
		else if (captionAlign.vertical == 'bottom')
			inset.bottom = 0
		else
			inset.top = 0

		return `${inset.top} ${inset.right} ${inset.bottom} ${inset.left}`
	}

	/**
	 * Gets the picture config. 
	 * 
	 * @param  {Object} url							Refer to ref 'url_def' in this doc.
	 * 
	 * @return {String} config.src					Fallback src (e.g., 'https://img.co/img_pink_flowers_std.jpg')
	 * @return {String} config.sources[].srcset		e.g., 'https://img.co/img_pink_flowers.jpg'
	 * @return {String} config.sources[].type		e.g., 'image/jpeg'
	 * @return {String} config.sources[].media		e.g., '(min-width:650px)'
	 */
	const getPictureConfig = url => {
		const emptyConfig = { src: TRANSPARENT_IMAGE, sources: [] }
		if (!url)
			return emptyConfig

		if (Array.isArray(url)) {
			const sources = []
			for (let j=0;j<url.length;j++) {
				const val = url[j]
				if (val) {
					if (val.value) 
						sources.push({ srcset:val.value, type:val.type })
					else
						sources.push({ srcset:val })
				}
			}

			return {
				src: (sources[0]||{}).srcset||TRANSPARENT_IMAGE,
				sources
			}
		} else {
			const t = typeof(url)
			if (t == 'string')
				return {
					src: url,
					sources: [{
						srcset: url
					}]
				}
			else if (t == 'object') {
				const config = {
					sources:[]
				}
				for (let [k,v] of Object.entries(url)) {
					if (!v)
						continue

					let index = k*1
					const isSrc = k == 'src'
					const isNumber = !isNaN(index)

					if (!isSrc && !isNumber)
						continue

					if (isSrc)
						index = 0
					const media = isSrc ? undefined : `(min-width:${k}px)`
					const tt = Array.isArray(v) ? 'array' : typeof(v)
					const sources = []

					if (tt == 'array') {
						for (let j=0;j<v.length;j++) {
							const val = v[j]
							if (val) {
								if (val.value) 
									sources.push({ index ,media, srcset:val.value, type:val.type })
								else
									sources.push({ index, media, srcset:val })
							}
						}
					} else if (tt == 'string')
						sources.push({ index, media, srcset:v })
					else if (tt == 'object' && v.value)
						sources.push({ index, media, srcset:v.value, type:v.type })
					else 
						continue
					
					if (isSrc)
						config.src = (sources[0]||{}).srcset
					config.sources.push(...sources)
				}

				config.sources = config.sources.sort((a,b) => a.index - b.index > 0 ? -1 : 1)

				if (!config.src)
					config.src = (config.sources[0]||{}).srcset

				return !config.src ? emptyConfig : config
			} else 
				return emptyConfig
		}
	}

	const getState = (originalState, hovering, onHoverConfig, pressed, onPressedConfig) => {
		let newState = {...originalState}

		const applyHoverConfig = hovering && onHoverConfig
		const applyPressedConfig = pressed && onPressedConfig

		if (applyHoverConfig || applyPressedConfig) {
			if (applyHoverConfig)
				newState = deepMergeObj(newState, onHoverConfig)
			if (applyPressedConfig)
				newState = deepMergeObj(newState, onPressedConfig)
		}

		return newState
	}

	const getImgTransition = transition => {
		const transDetected = transition && (transition.scale)
		if (transDetected) {
			const transProps = {}
			if (transition.scale)
				transProps.transform = getTransitionCss(transition.scale)

			return trans.css(transProps, true, false, true)
		} else 
			return NO_TRANSITION
	}

	const getRootTransition = transition => {
		const transDetected = transition && (transition.elevation)
		if (transDetected) {
			const transProps = {}
			if (transition.elevation)
				transProps['box-shadow'] = getTransitionCss(transition.elevation)

			return trans.css(transProps, true, false, true)
		} else 
			return NO_TRANSITION
	}

	const getInnerBorderRadius = (borderRadius, border, frame) => {
		if (!borderRadius)
			return '0px' 

		if (border || frame)
			return addToCardinal(cornerParse(borderRadius), -(border||0)-(frame||0)).css		
		else
			return cornerParse(borderRadius).css		
	}

	const getAspectRatio = aspectRatio => aspectRatio && aspectRatio.width > 0 && aspectRatio.height > 0
		? (100*aspectRatio.height/aspectRatio.width).toFixed(2)+'%'
		: null

	const getRootVars = ({ width, height, padding, margin, captionSize, captionFamily, captionColor, captionLetterSpacing, captionLineHeight, captionStyle, captionAlign , captionOverlay, captionMargin, captionPadding, captionBackgroundColor, border, borderRadius, borderColor, elevation, elevationStrength, elevationColor, elevationOffset, frame, frameColor, frameColorOrientation, clickable, x, y, scale, fit, aspectRatio, url, transition }) => {

		const _frameGradient = frameColor && Array.isArray(frameColor) 
			? getGradientColor({ orientation:frameColorOrientation })(...(frameColor.map(c => chooseColor(c)))) 
			: null
		const _frameColor = _frameGradient ? 'transparent' : (frameColor ? chooseColor(frameColor) : (frame ? 'white' : 'transparent'))
		const _aspectRatio = getAspectRatio(aspectRatio)
		const _emptyMedia = !url
		const _shadow = getBoxShadow({
			elevation: elevation||0, 
			strength: elevationStrength, 
			color: elevationColor, 
			offset: elevationOffset,
			defaultValue: 'none'
		})

		return css(`
		--lu-media-width: ${width ? addUnit(width) : 'auto'};
		--lu-media-height: ${height ? addUnit(height) : 'auto'};
		--lu-media-frame: ${frame ? addUnit(frame) : '0px'};
		--lu-media-padding: ${padding ? paddingParse(padding).css : '0px'};
		--lu-media-margin: ${margin ? paddingParse(margin).css : '0px'};
		--lu-media-font-family: ${captionFamily||VAR_FONT_FAMILY};
		--lu-media-font-size: ${captionSize ? addUnit(captionSize) : VAR_FONT_SIZE};
		--lu-media-font-color: ${chooseColor(captionColor||VAR_FONT_COLOR)};
		--lu-media-font-letter-spacing: ${captionLetterSpacing ? addUnit(captionLetterSpacing) : VAR_FONT_LETTER_SPACING};
		--lu-media-font-line-height: ${captionLineHeight ? addUnit(captionLineHeight) : VAR_FONT_LINE_HEIGHT};
		--lu-media-font-style: ${captionStyle||VAR_FONT_STYLE};
		--lu-media-text-align: ${!captionOverlay && captionAlign && captionAlign.horizontal ? captionAlign.horizontal : 'left'};
		--lu-media-caption-pos: ${captionOverlay? 'absolute': 'static'};
		--lu-media-caption-inset: ${captionOverlay ? getInset(captionAlign) : '0px'};
		--lu-media-caption-margin: ${captionMargin ? paddingParse(captionMargin).css : '0px'};
		--lu-media-caption-padding: ${captionPadding ? paddingParse(captionPadding).css : '0px'};
		--lu-media-caption-bg-color: ${captionBackgroundColor||'transparent'};
		--lu-media-border: ${border ? addUnit(border): '0px'};
		--lu-media-border-radius: ${borderRadius ? cornerParse(borderRadius).css : '0px'};
		--lu-media-inner-border-radius: ${getInnerBorderRadius(borderRadius, border, frame)};
		--lu-media-border-color: ${borderColor||'black'};
		--lu-media-box-shadow: ${_shadow};
		--lu-media-img-bgd-color: ${_frameColor};
		--lu-media-img-bgd-gradient: ${_frameGradient||'none'};
		--lu-media-img-cursor: ${clickable ? 'pointer' : 'default'};
		--lu-media-img-rel-position: ${x || y ? `${addUnit(x||0)} ${addUnit(y||0)}` : '50% 50%'};
		--lu-media-img-scale: ${`scale(${scale === undefined || scale === null ? 1 : scale})`};
		--lu-media-img-object-fit: ${fit ? 'contain' : 'cover'};
		--lu-media-img-aspect-ratio: ${_aspectRatio||'100%'};
		--lu-media-img-position: ${_aspectRatio ? 'absolute' : 'static'};
		--lu-media-img-size: ${_emptyMedia ? 'auto' : '100%'};
		--lu-media-root-transition: ${getRootTransition(transition)};
		--lu-media-img-transition: ${getImgTransition(transition)};
		`)
	}

	

	/* 
	=======================================================
	END - PRIVATE FUNCTIONS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT HANDLERS
	=======================================================
	*/

	const onMouseDown = e => {
		if (onPressedHandler) {
			pressed = true
			processPressHandler(onPressedHandler, clickEventContext)
		}

		emitMouseDown({})
		if (rippleEnabled)
			rippleClickEvent = e
	}

	const onMouseUp = () => {
		if (onPressedHandler)
			pressed = false
	}

	const onMouseOver = () => {
		hovering = true
	}

	const onMouseLeave = () => {
		hovering = false
	}

	/* 
	=======================================================
	END - EVENT HANDLERS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT LIFECYCLE
	=======================================================
	*/
	
	$: onPressedHandler = on ? on.find(e => e.event == 'press') : null 
	$: onHoverConfig = on && !isMobile() ? (on.find(e => e.event == 'hover')||{}).config : null
	$: onPressedConfig = onPressedHandler ? onPressedHandler.config : null
	$: state = getState({ 
		width, 
		height, 
		padding, 
		margin, 
		captionSize, 
		captionFamily, 
		captionColor, 
		captionLetterSpacing, 
		captionLineHeight, 
		captionStyle, 
		captionAlign, 
		captionOverlay, 
		captionMargin, 
		captionPadding, 
		captionBackgroundColor, 
		border, 
		borderRadius, 
		borderColor, 
		elevation, 
		elevationStrength, 
		elevationColor, 
		elevationOffset, 
		frame, 
		frameColor, 
		frameColorOrientation, 
		clickable, 
		x, 
		y, 
		scale, 
		fit, 
		aspectRatio, 
		transition,
		url }, hovering, onHoverConfig, pressed, onPressedConfig)
	$: rootVars = getRootVars(state)
	$: aspectRatioOn = aspectRatio && aspectRatio.width > 0 && aspectRatio.height > 0
	$: pictureConfig = getPictureConfig(url)

	/* 
	=======================================================
	END - EVENT LIFECYCLE
	=======================================================
	*/
</script>

<div
	{id} 
	data-type="media" 
	data-name="{name}"
	class="{rootVars} {rootClass}"
	on:mousedown={clickable ? onMouseDown : null}
	on:mouseup={clickable ? onMouseUp : null}
	on:mouseover={onHoverConfig ? onMouseOver : undefined}
	on:mouseleave={onHoverConfig ? onMouseLeave : undefined}>
	<figure>
		<div class="lu-media-container">
			{#if aspectRatioOn}
			<div class="lu-media-aspect-ratio"></div>
			{/if}
			<picture>
				{#each pictureConfig.sources as source}
				<source media={source.media} srcset={source.srcset} type={source.type}>
				{/each}
				<img src={pictureConfig.src} {alt}>
			</picture>
			{#if rippleEnabled}
			<Ripple 
				color={rippleLight}
				opacity={rippleOpacity}
				clickEvent={rippleClickEvent}>
			</Ripple>
			{/if}
		</div>
		{#if captionHtml}
		<figcaption>{@html captionHtml}</figcaption>
		{/if}
	</figure>
</div> 
