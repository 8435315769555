import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
import { transform, transition } from '../../../core/css'

export const TRANSITION_CURVE = '280ms cubic-bezier(0.4, 0, 0.2, 1)'

export const rootClass = css(`
padding: var(--lu-checkbox-padding);
margin: var(--lu-checkbox-margin);
outline: none;
-webkit-tap-highlight-color: transparent; /* required to prevent the blue click highlight on Android */

& .checkbox-tick {
	visibility: hidden;
}

& .checkbox-input {
	position: absolute;
	visibility:hidden;
}

& .checkbox-container {
	cursor: pointer;
	width: var(--lu-checkbox-width);
	height:  var(--lu-checkbox-height);
	border-radius: var(--lu-checkbox-halo-border-radius);
	position: relative;
	overflow: hidden;
	/* Safari hack for border-radius with overflow: hidden */
	-webkit-mask-image: -webkit-radial-gradient(white, black);
	outline: none;
	display: flex;
	align-items: center;
	justify-content: center;

	& .checkbox-content {
		position: relative;
		transition: var(--lu-checkbox-hover-border-trans);
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		width: var(--lu-checkbox-size);
		height: var(--lu-checkbox-size);
		border: var(--lu-checkbox-border) solid var(--lu-checkbox-border-color);
		border-radius: var(--lu-checkbox-border-radius);
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

	}

	& .checkbox-container-accent {
		border-radius: var(--lu-checkbox-halo-border-radius);
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: var(--lu-checkbox-background-color);
		opacity: 0;
		${transform.css('var(--lu-checkbox-hover-trans-off)')}
		transition: var(--lu-checkbox-hover-trans);
		will-change: var(--lu-checkbox-hover-will-change);
	}

	&:hover,
	&:focus {
		& .checkbox-container-accent {
			opacity: 0.07;
			${transform.css('var(--lu-checkbox-hover-trans-on)')}
		}

		& .checkbox-content {
			border: var(--lu-checkbox-hover-border);
		}
	}
}

& .radio-button {
	background-color: var(--lu-checkbox-color);
	width: 100%;
	height: 100%;
	border-radius: var(--lu-checkbox-border-radius);
	transform: scale(0);
	${transition.css({ transform:TRANSITION_CURVE })}
}

&.checkbox-ticked {
	& .checkbox-tick {
		visibility: visible;
	}	

	& .checkbox-container .checkbox-content {
		transition: var(--lu-checkbox-content-border-trans);
		border: var(--lu-checkbox-content-border);
		background-color: var(--lu-checkbox-content-background-color);
	}

	& .radio-button {
		transform: scale(0.65);
	}
}

&.checkbox-disabled {

	& .checkbox-container {
		cursor: default;

		& .checkbox-content {
			border: var(--lu-checkbox-disabled-border);
		}

		&:hover,
		&:focus {
			& .checkbox-container-accent {
				opacity: 0;
			}
		}
	}

	&.checkbox-ticked .checkbox-content {
		border: var(--lu-checkbox-disabled-border);
		background-color: var(--lu-checkbox-disabled-background-color);
	}
}
`)