import { VAR_PRIMARY_COLOR, VAR_SECONDARY_COLOR, VAR_ON_PRIMARY_COLOR, VAR_ON_SECONDARY_COLOR } from './theme'

export const chooseColor = (color, defaultColor) => {
	if (color == 'primary')
		return VAR_PRIMARY_COLOR
	else if (color == 'secondary')
		return VAR_SECONDARY_COLOR
	else if (color == 'on-primary')
		return VAR_ON_PRIMARY_COLOR
	else if (color == 'on-secondary')
		return VAR_ON_SECONDARY_COLOR
	else if (color == 'dark')
		return '#000'
	else if (color == 'bright')
		return '#fff'
	else 
		return color || defaultColor || VAR_PRIMARY_COLOR
}
