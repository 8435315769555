import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
import { noUserSelectCss, transition, transform, textGradientCss } from '../../../core/css'

export const rootClass = css(`
position: relative;
overflow: hidden;
cursor: pointer;
outline: none;
-webkit-tap-highlight-color: transparent;

width: var(--lu-btn-width);
height: var(--lu-btn-height);
padding: var(--lu-btn-padding);
margin: var(--lu-btn-margin);
background-color: var(--lu-btn-color);
background-image: var(--lu-btn-background-image);
border-radius: var(--lu-btn-border-radius);
box-shadow: var(--lu-btn-box-shadow);
border: var(--lu-btn-border);
${transition.css('var(--lu-btn-root-transition)')};
will-change: var(--lu-btn-will-change);
font-family: var(--lu-btn-font-family);
font-size: var(--lu-btn-font-size);
font-weight: var(--lu-btn-font-weight);
text-transform: var(--lu-btn-text-transform);
letter-spacing: var(--lu-btn-font-letter-spacing);
color: transparent;

& .lu-btn-content {
	${noUserSelectCss}
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	display:flex;
	align-items: center;
	flex-direction: var(--lu-btn-content-direction);
	grid-gap: var(--lu-btn-content-gap);
	padding: var(--lu-btn-content-padding);

	font-family: var(--lu-btn-font-family);
	font-size: var(--lu-btn-font-size);
	font-weight: var(--lu-btn-font-weight);
	text-transform: var(--lu-btn-text-transform);
	letter-spacing: var(--lu-btn-font-letter-spacing);
	opacity: var(--lu-btn-content-opacity);
	color: var(--lu-btn-font-color);
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	${transition.css('var(--lu-btn-content-transition)')};

	& span {
		white-space: nowrap;
	}

	&.lu-btn-text-gradient span {
		${textGradientCss('var(--lu-btn-font-background-image)')}
	}
}

& .lu-btn-overlay {
	position: absolute;
	top: 0;
	left: 0;
	border-radius: var(--lu-btn-border-radius);
	width: 100%;
	height: 100%;
	background-color: var(--lu-btn-overlay-color);
	opacity: var(--lu-btn-overlay-opacity);
	${transform.css('var(--lu-btn-overlay-transform)')}
	${transition.css('var(--lu-btn-overlay-transition)')}
}

& .lu-btn-underline {
	position: absolute;
	bottom: var(--lu-btn-ul-bottom, unset);
	top: var(--lu-btn-ul-top, unset);
	left: var(--lu-btn-ul-x);
	width: var(--lu-btn-ul-width, 100%);
	height: var(--lu-btn-ul-height, 2px);
	background-color: var(--lu-btn-ul-color);
	background-image: var(--lu-btn-ul-background-image);
	border-radius: var(--lu-btn-ul-border-radius, 0px);
	opacity: var(--lu-btn-ul-opacity);
	transform-origin: var(--lu-btn-ul-transform-origin);
	${transform.css('var(--lu-btn-ul-transform)')}
	${transition.css('var(--lu-btn-ul-transition)')}
}
`)

