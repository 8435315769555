import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
import theme from '../../../core/theme'
import { fontCss, gradient } from '../../../core/css'
const FONT_CSS = fontCss()

export const rootClass = css(`
${theme.resetTagStyle}
${FONT_CSS}

/* START - BASE */
position: relative;
width: var(--lu-text-width);
max-width: var(--lu-text-width-max);
min-width: var(--lu-text-width-min);
height: var(--lu-text-height);
max-height: var(--lu-text-height-max);
min-height: var(--lu-text-height-min);
padding: var(--lu-text-padding);
margin: var(--lu-text-margin);
/* END - BASE */

display: flex;
flex-direction: column;
align-items: var(--lu-text-align-items);
justify-content: var(--lu-text-justify-content);
text-transform: var(--lu-text-font-text-transform);
font-style: normal;
white-space: var(--lu-text-nowrap);

&.text-grad-on {
	${gradient.css('var(--lu-text-grad)')}

	& span, & p, & div, & h1, & h2, & h3, & h4, & h5, & h6 {
		${gradient.css('var(--lu-text-grad)')}
	}
}

&:not(.text-grad-on) {
	color: var(--lu-text-font-color);

	& span, & p, & div, & h1, & h2, & h3, & h4, & h5, & h6 {
		color: var(--lu-text-font-color);
	}
}

& h1, & h2, & h3, & h4, & h5, & h6 {
	margin-bottom: 8px;
	font-weight: 500;
	line-height: 1.25;
}

& span, & p, & div, & h1, & h2, & h3, & h4, & h5, & h6 {
	${FONT_CSS}
	text-transform: var(--lu-text-font-text-transform);
	font-style: normal;
}

& h1 { font-size: 2.5em; }
& h2 { font-size: 2em; }
& h3 { font-size: 1.75em; }
& h4 { font-size: 1.5em; }
& h5 { font-size: 1.25em; }
& h6 { font-size: 1em; }

& p, & span, & div {
	font-size: 1em;
}

& a {
	color: var(--lu-text-link-font-color);
	text-decoration: var(--lu-text-link-decoration);
	font-style: var(--lu-text-link-font-style);
	font-weight: var(--lu-text-link-font-weight);
}`)