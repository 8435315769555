<script>
	/* 
	=======================================================
	START - DEPENDENCIES
	=======================================================
	*/

	import { createEventDispatcher } from 'svelte'
	import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'
	import { getId, createBaseEvents, exists } from '../../../core/index'
	import { addUnit, cornerParse, alignParse, paddingParse } from '../../../core/parse'
	import { VAR_BASE_BORDER_COLOR, VAR_BASE_BORDER_RADIUS } from '../../../core/theme'
	import { chooseColor } from '../../../core/color'
	import { getBoxShadow } from '../../../core/elevation'
	import { rootClass } from './style'

	/* 
	=======================================================
	END - DEPENDENCIES
	=======================================================
	*/









	/* 
	=======================================================
	START - CONSTANTS
	=======================================================
	*/

	const ID = getId() // e.g., '_cwd23d'

	/* 
	=======================================================
	END - CONSTANTS
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT PROPERTIES
	=======================================================
	*/

	// Content
	export let html = ''
	// Style
	export let width = ''
	export let height = ''
	export let minWidth = ''
	export let minHeight = ''
	export let maxWidth = ''
	export let maxHeight = ''
	export let elevation = 1
	export let elevationStrength = 0
	export let elevationColor = '#000'
	export let padding = 0 // Can be an object such as { top: 5, right: 6, bottom: 7, left: 8 }
	export let align = 'center' // Possible values: 'top' (left), 'bottom' (left), 'left' (top), 'right' (top), { horizontal:'left|center|right', vertical: 'top|center|bottom' } 
	export let backgroundColor = null
	export let border = null
	export let borderColor = null
	export let borderRadius = 4 // Number of object (e.g., { left: 4, right:0 } or { top:4, bottom:2 } or { top: { left:1, right:2 }, bottom: { left:3, right:4 } } or { left: { top:1, bottom:2 }, right: { top:3, bottom:4 } })
	// Behavior
	export let focus = false

	/* 
	=======================================================
	END - COMPONENT PROPERTIES
	=======================================================
	*/









	/* 
	=======================================================
	START - COMPONENT EVENTS
	=======================================================
	*/

	const dispatch = createEventDispatcher()
	createBaseEvents(dispatch)
	const emitEvent = eventName => data => dispatch(eventName, data||{})
	
	/**
	 * on:blur
	 *
	 * @param  {Object}		data
	 * 
	 * @return {Void}
	 */
	const emitBlur = emitEvent('blur')

	/* 
	=======================================================
	END - COMPONENT EVENTS
	=======================================================
	*/









	/* 
	=======================================================
	START - REACTIVE STATE PROPS
	=======================================================
	*/

	let node

	/* 
	=======================================================
	END - REACTIVE STATE PROPS
	=======================================================
	*/









	/* 
	=======================================================
	START - PRIVATE FUNCTIONS
	=======================================================
	*/

	// const setStyle = ({ width, height, minWidth, minHeight, maxWidth, maxHeight, borderRadius, border, borderColor, background, align, elevation, elevationStrength, elevationColor, padding }) => {

	// 	const _width = addUnit(width)
	// 	const _widthCss = width ? `width: ${_width}` : null
	// 	const _height = addUnit(height)
	// 	const _heightCss = height ? `height: ${_height}` : null
	// 	const _minWidth = addUnit(minWidth)
	// 	const _minWidthCss = minWidth ? `min-width: ${_minWidth}` : null
	// 	const _minHeight = addUnit(minHeight)
	// 	const _minHeightCss = minHeight ? `min-height: ${_minHeight}` : null
	// 	const _maxWidth = addUnit(maxWidth)
	// 	const _maxWidthCss = maxWidth ? `max-width: ${_maxWidth}` : null
	// 	const _maxHeight = addUnit(maxHeight)
	// 	const _maxHeightCss = maxHeight ? `max-height: ${_maxHeight}` : null
	// 	const _borderRadius = cornerParse(borderRadius, { css:true })
	// 	const { alignItems, justifyContent } = alignParse(align)
	// 	const _boxShadow = getBoxShadow({ elevation, strength:elevationStrength, color:elevationColor})
	// 	const _paddingObj = paddingParse(padding)

	// 	style = [
	// 		_widthCss,
	// 		_heightCss,
	// 		_minWidthCss,
	// 		_minHeightCss,
	// 		_maxWidthCss,
	// 		_maxHeightCss,
	// 		'overflow: hidden',
	// 		`border-radius: ${_borderRadius}`,
	// 		`border: ${border}px solid ${borderColor}`,
	// 		`background: ${background}`,
	// 		'display: flex',
	// 		`align-items: ${alignItems}`,
	// 		`justify-content: ${justifyContent}`,
	// 		`${_boxShadow ? `box-shadow: ${_boxShadow}` : ''}`
	// 	].filter(x => x).join(';')

	// 	rootStyle = [
	// 		_widthCss,
	// 		_heightCss,
	// 		_minWidthCss,
	// 		_minHeightCss,
	// 		_maxWidthCss,
	// 		_maxHeightCss,
	// 		'outline: none',
	// 		`padding-top: ${_paddingObj.top}px`,
	// 		`padding-bottom: ${_paddingObj.bottom}px`,
	// 		`padding-right: ${_paddingObj.right}px`,
	// 		`padding-left: ${_paddingObj.left}px`,
	// 	].filter(x => x).join(';')
	// }

	const getRootVars = ({ width, height, minWidth, minHeight, maxWidth, maxHeight, borderRadius, border, borderColor, backgroundColor, align, elevation, elevationStrength, elevationColor, padding }) => {
		const _align = alignParse(align)

		return css(`
			--lu-paper-width: ${exists(width) ? addUnit(width) : 'auto'};
			--lu-paper-height: ${exists(height) ? addUnit(height) : 'auto'};
			--lu-paper-min-width: ${minWidth ? addUnit(minWidth) : 0};
			--lu-paper-min-height: ${minHeight ? addUnit(minHeight) : 0};
			--lu-paper-max-width: ${maxWidth ? addUnit(maxWidth) : 'none'};
			--lu-paper-max-height: ${maxHeight ? addUnit(maxHeight) : 'none'};
			--lu-paper-border-radius: ${exists(borderRadius) ? cornerParse(borderRadius).css : VAR_BASE_BORDER_RADIUS};
			--lu-paper-align-items: ${_align.alignItems};
			--lu-paper-justify-content: ${_align.justifyContent};
			--lu-paper-padding: ${paddingParse(padding).css};
			--lu-paper-background-color: ${chooseColor(backgroundColor||'white')};
			--lu-paper-border: ${border ? `${border}px solid ${chooseColor(borderColor||VAR_BASE_BORDER_COLOR)}` : 0};
			--lu-paper-box-shadow: ${getBoxShadow({ elevation, strength:elevationStrength, color:chooseColor(elevationColor)})};
		`)
	}

	/* 
	=======================================================
	END - PRIVATE FUNCTIONS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT HANDLERS
	=======================================================
	*/


	/* 
	=======================================================
	END - EVENT HANDLERS
	=======================================================
	*/









	/* 
	=======================================================
	START - EVENT LIFECYCLE
	=======================================================
	*/
	
	$: rootVars = getRootVars({ width, height, minWidth, minHeight, maxWidth, maxHeight, borderRadius, border, borderColor, backgroundColor, align, elevation, elevationStrength, elevationColor, padding })

	$: {
		if (focus && node)
			node.focus()
	}

	/* 
	=======================================================
	END - EVENT LIFECYCLE
	=======================================================
	*/
</script>

<div 
	id={ID} 
	data-type="paper" 
	tabindex="-1" 
	class="{rootVars} {rootClass}" 
	bind:this={node} 
	on:blur={emitBlur}>
	<div class="lu-paper">
		<slot>
			{@html html}
		</slot>
	</div>
</div>
