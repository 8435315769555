import SelectList from './SelectList.svelte'
import { DOC } from '../../../core/dom'

// const createSubscription = unsubscribes => (node, name, fn) => {
// 	node.addEventListener(name, fn)
// 	unsubscribes.push(() => node.removeEventListener(name, fn))
// }

const emitNodeEvent = node => (name, data) => node.dispatchEvent(new CustomEvent(name, { detail: data }))


/**
 * Creates a Svelte Action with the following custom events:
 * 	- on:translatex: data: { event:Event, scroll:Object, config:Object }
 * 	- on:translatey: data: { event:Event, scroll:Object, config:Object }
 * 	
 * @param  {DOM}		node					
 * @param  {[Object]}	props.options						
 * @param  {Number}		props.backgroundColor
 * @param  {Number}		props.borderRadius
 * @param  {Number}		props.rowHeight						
 * @param  {Number}		props.rowPadding.left						
 * @param  {Number}		props.rowPadding.right						
 * @param  {Writable}	props.selectState	
 * 		
 * @return {Void}
 */
export function addSelectList (node, props) {
	let component
	const emitEvent = emitNodeEvent(node)
	if (DOC) {
		component = new SelectList({
			target:DOC.body,
			props
		})
	}

	component.$on('sizechange', data => {
		emitEvent('sizechange', data.detail)
	})

	component.$on('click', data => {
		emitEvent('click', data.detail)
	})

	component.$on('blur', data => {
		emitEvent('blur', data.detail)
	})

	component.$on('escape', data => {
		emitEvent('escape', data.detail)
	})

	return {
		destroy() {
			if (component)
				component.$destroy()
		}
	}
}