import { css } from '../../../../node_modules/@emotion/css/dist/emotion-css.umd.min.js'

export const rootClass = css(`
outline: none;
display: flex;
flex-direction: column;
overflow-y: scroll;

width: var(--lu-list-width);
height: var(--lu-list-height);
font-family: var(--lu-list-font-family);
font-size: var(--lu-list-font-size);
background-color: var(--lu-list-background-color);
`)

export const rowRootClass = css(`
position: relative;
display: flex;
font-family: var(--lu-list-font-family);
font-size: var(--lu-list-font-size);
box-sizing: border-box;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
-webkit-tap-highlight-color: transparent;

width: var(--lu-list-row-width);
height: var(--lu-list-row-height);
min-height: var(--lu-list-row-min-height);
overflow: var(--lu-list-row-overflow);
padding: var(--lu-list-row-padding); 
align-items: var(--lu-list-row-align-items);
justify-content: var(--lu-list-row-justify-content);
cursor: var(--lu-list-row-cursor);

&:before {
	content:'';
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

&.hover-effect:hover:before {
	background-color: var(--lu-list-hover-color);
	opacity: var(--lu-list-hover-opacity);
}

&.selected-item:before, &.selected-item:hover:before  {
	background-color: var(--lu-list-color);
}

&.selected-item:before {
	opacity: 0.14;
}

&.selected-item:hover:before, &.selected-item.focused-item:hover:before {
	opacity: 0.2;
}

&.focused-item:before, &.focused-item:hover:before {
	background-color: var(--lu-list-hover-color);
}

&.focused-item:before {
	opacity: calc(0.97468 * var(--lu-list-hover-opacity) + 0.0813628);
}

&.focused-item:hover:before {
	opacity: calc(0.911048 * var(--lu-list-hover-opacity) + 0.142451);
}

&.selected-item.focused-item:before, &.selected-item.focused-item:hover:before {
	background-color: var(--lu-list-color);
	opacity: 0.2;
}

&.selected-item.focused-item:hover:before {
	opacity: 0.25;
}

& .list-text {
	overflow: hidden;
	white-space: nowrap;
	color: var(--lu-list-font-color);
}
`)
