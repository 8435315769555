import { addToHead } from '../dom'

const load = () => addToHead([{
	tag:'link',
	id: 'bootstrap-icons',
	attributes:[
		['rel','stylesheet'],
		['href', 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css']
	]
}])

export default {
	load
}