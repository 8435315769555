import TinyColor from './tinycolor'

const getElevationLayerOpacity = factor => strength => factor + (strength >= 0 ? (1-factor) : factor)*strength

const getRGB = color => {
	if (!color)
		return 'var(--lu-elevation-rgb, 0,0,0)'

	const _color = (color||'#000').toLowerCase().trim()
	const { r, g, b } = _color == '#000' || _color == '#000000' || _color == 'black' 
		? { r:0, g:0, b:0 } 
		: _color == '#fff' || _color == '#ffffff' || _color == 'white'
			? { r:255, g:255, b:255 } : TinyColor(_color).toRgb()

	return `${r},${g},${b}`
}

/**
 * Creates a CSS 'box-shadow' for the elevation effect. 
 * 
 * @param  {Number} elevation		Default is 1
 * @param  {Number} strength		Default is 0. Goes from -1 to 1
 * @param  {String} color			Default '#000'
 * 
 * @return {String} boxShadowCss	e.g., '0px 2px 2px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
 */
export const getBoxShadow = ({ elevation=1, strength=0, color, defaultValue='', offset }) => {
	if (!elevation)
		return defaultValue

	strength = strength > 1 ? 1 : strength < -1 ? -1 : strength
	const rgb = getRGB(color)
	const offsetX = (offset ? offset.x : 0)||0
	const offsetY = (offset ? offset.y : 0)||0

	const cl_y = Math.ceil(0.451722 * elevation + 1.05807) + offsetY
	const cl_blur = Math.ceil(0.589467 * elevation + 0.703579)
	const cl_spread = Math.ceil(-0.288994 * elevation - 0.49156)
	const ml_blur = Math.ceil(1.58001 * elevation - 1.5368)
	const ml_spread = Math.ceil(0.162728 * elevation - 0.433491)
	const fl_y = Math.ceil(0.392302 * elevation - 0.0243079) + offsetY
	const fl_blur = Math.ceil(1.79811 * elevation + 1.15192)
	const fl_spread = Math.ceil(0.392302 * elevation - 1.02431)

	const closestLayer = `${offsetX}px ${cl_y}px ${cl_blur}px ${cl_spread}px rgba(${rgb},${getElevationLayerOpacity(0.2)(strength)})`
	const middleLayer = `${offsetX}px ${elevation}px ${ml_blur}px ${ml_spread}px rgba(${rgb},${getElevationLayerOpacity(0.14)(strength)})`
	const furthestLayer = `${offsetX}px ${fl_y}px ${fl_blur}px ${fl_spread}px rgba(${rgb},${getElevationLayerOpacity(0.12)(strength)})`
	return `${closestLayer}, ${middleLayer}, ${furthestLayer}`
}

export const getHoverElevation = elevation => {
	if (!elevation)
		return 0 
	else if (elevation < 3)
		return 4
	else
		return Math.ceil(1.5 * elevation)
}

export const getPressedElevation = elevation => {
	if (!elevation)
		return 0 
	else if (elevation == 1)
		return 0
	else if (elevation < 4)
		return 1
	else
		return Math.floor(0.8 * elevation)
}





